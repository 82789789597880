import type {
  DayValue,
  CalendarProps,
} from '@amir04lm26/react-modern-calendar-date-picker'

import type { RHFInputProps } from '../controller'
import { useRHFInput } from '../controller'

import { CalendarDateInput } from './CalendarDateInput'

export function RHFCalendarDateInput<T>(
  props: RHFInputProps<T, DayValue> & {
    daysWithValues?: CalendarProps<DayValue>['customDaysClassName']
  },
) {
  const innerProps = useRHFInput<T, DayValue>(props)

  return (
    <CalendarDateInput {...innerProps} daysWithValues={props.daysWithValues} />
  )
}
