import type { DataProvider, MetaDataQuery } from '@pankod/refine-core'

import { isRecord } from './fileUploadProxy'

export function defaultValuesProxy(dataProvider: DataProvider): DataProvider {
  return {
    ...dataProvider,
    async create(params) {
      const isCsvImport =
        params.resource?.includes('naturalPersons/import') ||
        params.resource?.includes('juridicalPersons/import')

      if (isCsvImport) {
        return dataProvider.create(params)
      }

      return dataProvider.create({
        ...params,
        variables: applyDefaultValues(
          params.variables as Record<string, any>,
          params.metaData,
        ),
      })
    },
  }
}

function applyDefaultValues(
  variables: Record<string, any>,
  metaData?: MetaData,
) {
  function recurse(record: unknown): any {
    if (!isRecord(record)) return record

    const entries = Object.entries(record).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [key, value.map(recurse)]
      }

      if (isRecord(value)) {
        return [key, recurse(value)]
      }
      return [key, value]
    })

    return { ...metaData?.defaultValues, ...Object.fromEntries(entries) }
  }

  // sometimes @type is not provided for the root values
  // it fixes the algorythm

  return recurse({ '@type': 'defaultType', ...variables })
}

type MetaData = MetaDataQuery & { defaultValues?: Record<string, any> }
