import { makeForm, unit } from '@aubade/core/ui-kit'
import { HStack, VStack } from '@chakra-ui/react'

import type { ExtendedUser } from '../../../../../types/src'
import {
  CollaboratersJob,
  CustomerRole,
  UserRole,
} from '../../../../../types/src/index'
import { isAdmin, isAgency, isSociety, useGetUser } from '../../../components'

const { Input, useWatch } = makeForm<
  ExtendedUser,
  'firstname' | 'lastname' | 'craftCompany' | 'agency' | 'acRoles' | 'id'
>()

type FormContentProps = {
  profile: 'customer' | 'collaborater'
}

export function FormContent(props: FormContentProps) {
  const { profile } = props
  const [acRoles] = useWatch(['acRoles', 'id'])

  const { role } = useGetUser()
  const isAubade = Boolean(acRoles && isAdmin(acRoles.AC as any))

  const canEdit = canEditJob(acRoles?.AC, role!)

  return (
    <VStack width="full" gap={unit('40')}>
      {profile === 'customer' && (
        <Input.Text label="users.fields.username" name="username" disabled />
      )}
      <HStack width="full" gap={unit('20')}>
        <Input.Text label="users.fields.firstname" name="firstname" disabled />
        <Input.Text label="users.fields.lastname" name="lastname" disabled />
      </HStack>
      <Input.Text label="users.fields.email" name="email" disabled />
      {profile === 'customer' && (
        <Input.Text
          name="craftCompany.name"
          label="users.fields.craftCompany"
          disabled
        />
      )}

      <Input.Select
        label="users.fields.role"
        name="acRoles.AC"
        disabled
        optionsSelect={profile === 'customer' ? CustomerRole : UserRole}
      />
      {profile === 'collaborater' && !isAubade && (
        <Input.Select
          label="users.fields.job"
          name="job"
          optionsSelect={CollaboratersJob}
          disabled={!canEdit}
        />
      )}

      <HStack width="full" gap={unit('20')}>
        {!isAubade && (
          <>
            <Input.Text
              name="society.name"
              label="users.fields.society"
              disabled
            />
            <Input.Text
              name="agency.name"
              label="users.fields.agency"
              disabled
            />
          </>
        )}
      </HStack>
    </VStack>
  )
}

function canEditJob(
  userRole: UserRole | CustomerRole,
  role:
    | 'ROLE_SUPER_ADMIN'
    | 'ROLE_APP_CRAFTER_COMPANY'
    | 'ROLE_APP_CRAFTER_STORE'
    | 'ROLE_APP_CRAFTER_ADMIN',
) {
  if (isAdmin(role)) {
    return true
  }
  if (isSociety(role) && isAgency(userRole as any)) {
    return true
  }
  return false
}
