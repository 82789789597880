import type { DataProvider } from '@aubade/core/adapters'
// `conversations/${id}/messages`,
export function MessagesProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    async getList(params) {
      const id = params.resource.split('/')[1]

      return dataProvider.getList({
        ...params,
        // @ts-ignore
        resource: `conversations/${id}/messages`,
      })
    },
  }
}
