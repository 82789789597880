import type { DataProvider } from '@aubade/core/adapters'

import type { SearchResponse } from '@nartex/typesense'

import { sortBy } from 'remeda'

import { encodePayloadApiToTypesense } from '../index'

export function SocietiesProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    async getList(params) {
      const { metaData } = params

      const payload = encodePayloadApiToTypesense(params, 'societies', 'name')
      const searchResponse = await dataProvider.custom!<{
        results: [SearchResponse<any>]
      }>({
        method: 'post',
        url: `societies/ts/search`,
        metaData,
        payload,
      })
      const result = searchResponse.data.results[0]

      const data = result.hits?.map((hit) => hit.document) ?? []
      const sortData = sortBy(data, (d) => d.name)
      return {
        data: sortData,
        total: data.length,
        raw: { typesense: result },
      }
    },
  }
}
