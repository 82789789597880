import { IconCheck, IconFermer } from '@aubade/design/graphics'
import { useTranslate } from '@aubade/translation'
import type { TranslationKey } from '@aubade/translation'

import { Paragraph } from '../Paragraph'

import type { RHFInputProps } from './controller'
import { useInput } from './controller'

type DisabledInputProps<T, Outer = string, Inner = Outer> = Omit<
  ReadOnlyInputRenderProps<Inner>,
  'label'
> &
  RHFInputProps<T, Outer, Inner>
export function ReadOnlyInput<T, Outer = string, Inner = Outer>(
  props: DisabledInputProps<T, Outer, Inner>,
) {
  const { field, wrap } = useInput<T, Outer, Inner>(props)

  return wrap(<ReadOnlyInputRender<T, Inner> {...props} field={field} />)
}

type ReadOnlyInputRenderProps<Inner> = {
  translationKey?: string | ((value: Inner) => string)
  emptyLabel?: TranslationKey
  label?: TranslationKey
}

export function ReadOnlyInputRender<T, Inner>(
  props: ReadOnlyInputRenderProps<Inner> & {
    field: ReturnType<typeof useInput<T, Inner>>['field']
  },
) {
  const { field, translationKey, emptyLabel, label } = props
  const translate = useTranslate()

  function getPrintedValue() {
    const typeOf = typeof field.value
    if (field.value && translationKey) {
      if (typeof translationKey === 'function') {
        return translate(translationKey(field.value))
      }
      return translate(`${translationKey}.${field.value}`)
    }

    if (typeOf === 'boolean') {
      return (
        <Paragraph as="em" color="grey.500">
          {field.value ? <IconCheck /> : <IconFermer />}
        </Paragraph>
      )
    }
    if (typeOf === 'string' && (field.value as string).startsWith('/enums/')) {
      return translate.enum(field.value as string)
    }

    if ((typeOf === 'string' && field.value !== '') || typeOf === 'number') {
      return field.value as string | number
    }

    if (field.value == null || field.value === '') {
      return (
        <Paragraph as="em" color="grey.500">
          {translate(emptyLabel ?? 'app.fields.emptyValue')}
        </Paragraph>
      )
    }

    throw Error(`Can't print this value ${JSON.stringify(field.value)}`)
  }
  return (
    <Paragraph
      role="textbox"
      aria-disabled
      aria-label={translate(label ?? '')}
      color="grey.500"
    >
      {getPrintedValue()}
    </Paragraph>
  )
}
