import type {
  QueryParamConfigMapWithInherit,
  QueryParamConfig,
} from 'use-query-params'
import {
  useQueryParams,
  useQueryParam,
  createEnumParam,
  StringParam,
} from 'use-query-params'

export function useQueryParamState(
  name: string,
  queryParam: QueryParamConfig<any, any>,
) {
  return useQueryParam(name, queryParam, {
    removeDefaultsFromUrl: true,
    enableBatching: true,
    skipUpdateWhenNoChange: true,
    updateType: 'replaceIn',
  })
}

export function useQueryParamsState(names: QueryParamConfigMapWithInherit) {
  return useQueryParams<any>(names, {
    removeDefaultsFromUrl: true,
    enableBatching: true,
    skipUpdateWhenNoChange: true,
    updateType: 'push',
  })
}

export const CreateEditParam = createEnumParam(['create', 'edit', 'profile'])

export const drawerQueryParams = {
  page: CreateEditParam,
  id: StringParam,
}
