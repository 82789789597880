import { cleanHydraId } from '@nartex/api-platform'
import { getUnixTime } from 'date-fns'

/**
 * Escapes a value for typesense querying
 */
export function tsEsc(value: unknown, field: string): string {
  if (typeof value === 'string') {
    if (field === 'id' || field.endsWith('.id')) {
      value = cleanHydraId(value)
    }
    return '`' + (value as string).replace('`', '\\`') + '`'
  }
  if (typeof value === 'number') {
    return String(value)
  }
  if (typeof value === 'boolean') {
    return String(value)
  }
  if (value instanceof Date) {
    return getUnixTime(value).toString()
  }
  if (Array.isArray(value)) {
    return `[${value
      .filter((item) => item != null)
      .map((item) => tsEsc(item, field))
      .join(',')}]`
  }

  throw Error(`The escaping of '${JSON.stringify(value)}' is not supported yet`)
}
