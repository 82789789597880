// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconArrowRight = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M38.6326 22.3678C40.0865 23.8217 40.0865 26.1796 38.6326 27.6334L17.3564 48.9096C15.902 50.3635 13.5446 50.3635 12.0908 48.9096C10.6364 47.4558 10.6364 45.0978 12.0908 43.6441L30.7349 25L12.0909 6.35594C10.6364 4.9021 10.6364 2.54415 12.0909 1.09038C13.5447 -0.363459 15.902 -0.363459 17.3564 1.09038L38.6326 22.3678Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconArrowRight)
export default Memo
