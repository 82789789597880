// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconSend = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={51}
    height={50}
    viewBox="0 0 51 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.865028 0.612644C1.23818 0.288488 1.69906 0.0822293 2.18941 0.0199346C2.67976 -0.04236 3.17757 0.0421061 3.61992 0.262657L48.6182 22.7618C49.0342 22.9691 49.3842 23.2883 49.6288 23.6836C49.8734 24.0788 50.003 24.5344 50.003 24.9992C50.003 25.464 49.8734 25.9196 49.6288 26.3148C49.3842 26.7101 49.0342 27.0293 48.6182 27.2366L3.61992 49.7358C3.1776 49.9571 2.67957 50.0422 2.18884 49.9804C1.69811 49.9186 1.23675 49.7126 0.863138 49.3885C0.489521 49.0644 0.220442 48.6367 0.0899491 48.1597C-0.0405441 47.6826 -0.0265861 47.1775 0.130057 46.7084L6.53481 27.4991H20.0018C20.6648 27.4991 21.3007 27.2357 21.7695 26.7669C22.2383 26.2981 22.5017 25.6622 22.5017 24.9992C22.5017 24.3362 22.2383 23.7003 21.7695 23.2315C21.3007 22.7627 20.6648 22.4993 20.0018 22.4993H6.53481L0.127556 3.29004C-0.0282794 2.82109 -0.0416332 2.31647 0.0891814 1.83993C0.219996 1.36339 0.49161 0.936311 0.865028 0.612644Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconSend)
export default Memo
