import { useStorage } from '@aubade/core/adapters'
import { useEffect } from 'react'
import { useLocation } from 'react-router'

const storageKeyPrefix = 'listSearchParams::'

export type ListURLProps = {
  searchParams?: string | null
}

export function getListUrlFn(prefix: string, storage: Storage) {
  return (listProps?: ListURLProps) => {
    if (listProps?.searchParams === null) {
      return prefix
    }
    if (listProps?.searchParams) {
      return `${prefix}?${listProps.searchParams}`
    }
    const searchParams = storage.getItem(`${storageKeyPrefix}${prefix}`)
    if (searchParams) return `${prefix}?${searchParams}`
    return prefix
  }
}

export function useSaveSearch(searchParams: URLSearchParams) {
  const storage = useStorage()
  const location = useLocation()
  useEffect(() => {
    storage.setItem(
      `${storageKeyPrefix}${location.pathname}`,
      searchParams.toString(),
    )
  }, [searchParams, location, storage])
}
