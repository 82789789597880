import type { SwitchProps as ChakraSwitchProps } from '@chakra-ui/react'

import { useRHFInput } from '..'
import type { RHFInputProps } from '../controller'

import { SwitchInput } from './SwitchInput'

export { SwitchInput } from './SwitchInput'

export type SwitchInputProps<T, Out = boolean> = RHFInputProps<
  T,
  Out,
  boolean
> &
  Omit<ChakraSwitchProps, 'defaultValue'>

export const RHFSwitchInput = function <T, Out = boolean>(
  props: SwitchInputProps<T, Out>,
) {
  const innerProps = useRHFInput<T, Out, boolean>(props)

  return <SwitchInput {...innerProps} />
}
