import { useQueryBuilder, type BaseRecord } from '../../../adapters'

import { DataPickerOptions } from '../DataPicker/Options'
import type { PickerRenderOptionsProps } from '../Picker'

import type { ResourcePickerProps } from './index'

type ResourcePickerOptionsProps<T extends BaseRecord> = Pick<
  ResourcePickerProps<T>,
  'renderOption' | 'filters' | 'resource' | 'getListParams'
> &
  PickerRenderOptionsProps<T['@id']>

export function ResourcePickerOptions<T extends BaseRecord>(
  props: ResourcePickerOptionsProps<T>,
) {
  const {
    resource,
    filters,
    renderOption,
    getListParams,
    withoutEmptyText,
    ...renderParams
  } = props

  const queryBuilder = useQueryBuilder()
  return (
    <DataPickerOptions<T>
      {...renderParams}
      withoutEmptyText={withoutEmptyText}
      getQuery={(opts) => {
        return queryBuilder.getList<T>({
          ...getListParams,
          resource,
          filters: [
            { field: 'q', operator: 'eq', value: opts.search },
            ...(filters ?? []),
          ],
        })
      }}
      renderOption={renderOption}
    />
  )
}
