import type {
  BaseRecord,
  CrudFilters,
  CrudSorting,
  ResourceOf,
  SearchFilter,
} from '@aubade/core/adapters'
import { Title, unit } from '@aubade/core/ui-kit'
import { useTranslate } from '@aubade/translation'
import { Flex, VStack, Text, Box } from '@chakra-ui/react'
import { isEqual } from 'lodash'
import type { ReactNode } from 'react'
import { isValidElement, memo, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Boundary } from '../Boundary'

import { useResourceList } from './useResourceList'

export type ResourceListContainerProps<T extends BaseRecord> = {
  resource: ResourceOf<T>
  filters?: CrudFilters<T>
  sort?: CrudSorting<T>
  scope: string
  pageLimit?: number
  actions?: ReactNode
  dataProviderName?: 'default' | 'typesense'
  title?:
    | string
    | {
        mainTitle: string
        extraTitle: string
        color?: string
      }
    | ReactNode
    | ((count: number) => ReactNode)
  children: (data: T[], pagination: ReactNode) => ReactNode
}

function Component<T extends BaseRecord>(props: ResourceListContainerProps<T>) {
  const {
    resource,
    filters,
    sort,
    title,
    scope,
    dataProviderName = 'default',
    children,
  } = props

  const [searchParams] = useSearchParams({ scope })
  const translate = useTranslate()

  function getTitle() {
    if (!title) return <></>
    if (typeof title === 'string') {
      return (
        <Title.H4 color="darkGrey.500" fontWeight="bold">
          {translate(title)}
        </Title.H4>
      )
    }
    if (typeof title === 'function') {
      return title(data?.length ?? 0)
    }
    if (isValidElement(title)) return title
    if (typeof title === 'object' && 'mainTitle' in title) {
      return (
        <>
          <Title.H4 color="darkGrey.500" fontWeight="bold">
            <>
              {translate(title.mainTitle)}
              <Text as="span" color={title?.color ?? 'blue.500'}>
                {translate(title.extraTitle)}
              </Text>
            </>
          </Title.H4>
        </>
      )
    }
  }
  const listFilters = useMemo(() => {
    const search = searchParams.get(`${scope}.q`)
    if (search) {
      const qFilter: SearchFilter = { q: search }
      if (filters) {
        return [qFilter, ...filters]
      }
      return [qFilter]
    }
    return filters
  }, [searchParams, scope, filters])

  const [[data], Pagination] = useResourceList<T>({
    resource,
    filters: listFilters,
    sort,
    hasPagination: true,
    scope,
    dataProviderName,
  })

  if (!data) return null

  return (
    <VStack gap={'30px'} alignItems="flex-start" width="full">
      {title && (
        <Box paddingX={unit('60')} width="full">
          {getTitle()}
        </Box>
      )}
      <Flex direction="column" gap={6} width={'full'} paddingLeft={unit('10')}>
        {children(data, Pagination)}
      </Flex>
    </VStack>
  )
}

// Needed to memoize the column props https://react-table-v7.tanstack.com/docs/quick-start#define-columns
const MemoizedComponent = memo(Component, function (prevProps, newProps) {
  return isEqual(prevProps, newProps)
}) as typeof Component

export function ResourceListContainer<T extends BaseRecord>(
  props: ResourceListContainerProps<T>,
) {
  return (
    <Boundary skeletonsCount={10}>
      <MemoizedComponent<T> {...props} />
    </Boundary>
  )
}
