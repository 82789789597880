import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const inputPaddingX = 6

const baseStyle = definePartsStyle({
  field: {
    ...basefieldStyle(),
    backgroundColor: 'white',
  },
  element: {
    top: 0,
    bottom: 0,
    right: 4,
    padding: 0,
    background: 'white',
    height: 'full',
    width: 'auto',
  },
})
const search = definePartsStyle({
  field: {
    backgroundColor: 'lightGrey.500',
  },
})

const rounded = definePartsStyle({
  field: {
    borderColor: 'transparent',
    borderRadius: '25px',
  },
})

export function basefieldStyle() {
  return {
    // BoxStyle
    minHeight: '50px',
    width: 'full',
    background: 'white',
    paddingLeft: inputPaddingX,
    borderRadius: '10px',
    transition: '.2s ease-out',
    // TextStyle
    fontWeight: 'bold',
    fontSize: 'sm',
    color: 'darkGrey.500',
    _placeholder: {
      color: 'grey.500',
      fontWeight: 'bold',
      fontSize: 'sm',
    },
    _disabled: {
      borderColor: 'transparent',
      color: 'grey.500',
      background: 'white.500',
      cursor: 'not-allowed',
      opacity: 1,
    },
    _hover: {
      outline: '1px solid',
      outlineColor: 'blue.500',
      outlineOffset: 0,
    },
    _focus: {
      borderColor: 'blue.500',
    },
    _focusVisible: {
      borderColor: 'blue.500',
    },
    _error: {
      borderColor: 'error.dark',
      background: 'error.light',
      _hover: {
        boxShadow: 'none',
        outline: '1px solid',
        outlineColor: 'error.dark',
        outlineOffset: 0,
      },
      _invalid: {
        boxShadow: 'none',
      },
      _focus: {
        outline: 'none',
        borderWidth: '1px',
      },
      _focusVisible: {},
    },
    lineHeight: 3,
  }
}

export const inputTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { search, rounded },
})
