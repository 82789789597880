import { Box } from '@chakra-ui/react'
import type {
  DraggableSyntheticListeners,
  UniqueIdentifier,
} from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { CSSProperties, PropsWithChildren } from 'react'
import { createContext, useContext, useMemo } from 'react'

type Props = {
  id: UniqueIdentifier
  hasDragHandler?: boolean
}

type Context = {
  attributes: Record<string, any>
  listeners: DraggableSyntheticListeners
  ref(node: HTMLElement | null): void
}

const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {},
})

export function SortableItem(props: PropsWithChildren<Props>) {
  const { children, id, hasDragHandler = false } = props
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id })
  const context = useMemo(() => {
    return { attributes, listeners, ref: setActivatorNodeRef }
  }, [attributes, listeners, setActivatorNodeRef])

  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <SortableItemContext.Provider value={context}>
      <div ref={setNodeRef} style={style}>
        {hasDragHandler ? <>{children}</> : <DragHandle>{children}</DragHandle>}
      </div>
    </SortableItemContext.Provider>
  )
}

export function DragHandle(props: PropsWithChildren<{}>) {
  const { children } = props
  const { attributes, listeners, ref } = useContext(SortableItemContext)

  return (
    <Box {...attributes} {...listeners} ref={ref}>
      {children}
    </Box>
  )
}
