import type { Day } from '@amir04lm26/react-modern-calendar-date-picker'
import { formatISO, fromUnixTime, getDate, getMonth, getYear } from 'date-fns'

export function dateToDayValue(date: Date): Day {
  return {
    day: getDate(date),
    month: getMonth(date) + 1,
    year: getYear(date),
  }
}

export function dayValueToDate(date?: Day): string | undefined {
  if (!date) return undefined
  const newDate = new Date(date.year, date.month - 1, date.day)
  return formatISO(newDate)
}

export function timeToDayValue(time: number): Day {
  const unixTime = String(time).length > 12 ? time / 1000 : time
  const date = fromUnixTime(unixTime)
  return dateToDayValue(date)
}

export function timeToDate(time: number): string {
  const unixTime = String(time).length > 12 ? time / 1000 : time
  const date = fromUnixTime(unixTime)
  return formatISO(date)
}
