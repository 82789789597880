import { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import { useEvent } from '../../../libs'

const NONE = Symbol('NONE')

// Replaces rhf defaultValue option which is not working as expected
export function useDefaultValue(name: string, defaultValue: unknown) {
  const { getFieldState, setValue, getValues, resetField } =
    useFormContext<Record<string, any>>()

  const lastSetValue = useRef<unknown | typeof NONE>(NONE)
  const updateValue = useEvent(() => {
    function shouldUpdate() {
      // don't run if no defaultValue provided
      if (defaultValue === undefined) return false
      // don't run if is modified by user
      if (getFieldState(name).isDirty) return false
      const currentValue = getValues(name)
      // don't run if currentValue is the same as the defaultValue
      if (currentValue === defaultValue) return false
      // run if currentValue is not defined
      if (currentValue === undefined) return true
      // rerun if currentValue comes from an automatic update
      const isReRun = lastSetValue.current === currentValue
      if (isReRun) return true
    }

    if (shouldUpdate()) {
      lastSetValue.current = defaultValue
      resetField(name, { defaultValue })
      setValue(name, defaultValue, { shouldDirty: false })
    }
  })

  useEffect(updateValue, [defaultValue, updateValue])
}
