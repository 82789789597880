import { Caption, Paragraph, unit } from '@aubade/core/ui-kit'
import type { TranslationKey } from '@aubade/translation'
import { useTranslate } from '@aubade/translation'
import type { InputProps } from '@chakra-ui/react'
import {
  HStack,
  VStack,
  useMergeRefs,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  Box,
} from '@chakra-ui/react'
import type { ForwardedRef, PropsWithChildren, ReactNode } from 'react'
import { useRef, useEffect, memo, forwardRef } from 'react'
import type { TextareaAutosizeProps } from 'react-textarea-autosize'
import TextareaAutosize from 'react-textarea-autosize'

import { IconRecherche } from '../../../../../design/src/graphics'
import type { BaseInputProps } from '../controller'
import { useBaseInput } from '../controller'
import { textTransformer } from '../TextInput'

export type InputState = 'default' | 'active' | 'disabled' | 'error' | 'hover'

type Variant = 'TextArea' | 'search' | 'textAreaMessageStyle' | undefined

const textAreaStyle = {
  padding: '20px',
  lineHeight: '20px',
} satisfies TextareaAutosizeProps['style']

const textAreaMessageStyle = {
  padding: '20px',
  lineHeight: '20px',
} satisfies TextareaAutosizeProps['style']

export type TextInputProps = BaseInputProps<string, string, HTMLInputElement> &
  Pick<InputProps, 'onKeyDown' | 'autoFocus'> & {
    type?: TextInputType
    suggestions?: string[]
    placeholder?: TranslationKey
    iconRight?: ReactNode
    variant?: Variant
    readOnly?: boolean
    maxLength?: number
  }
export const TextInput = memo(
  forwardRef(function (
    props: TextInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    const {
      type,
      placeholder,
      iconRight,
      label,
      suggestions,
      variant,
      onKeyDown,
      maxLength,
      autoFocus,
      ...rest
    } = props
    const { id, field, wrap } = useBaseInput<
      string,
      string,
      string,
      HTMLInputElement
    >(rest, {
      baseTransformer: textTransformer,
    })

    const translate = useTranslate()
    const dataListId = `${id}-datalist`

    const innerRef = useAutoFocus(autoFocus, field.onFocus)
    const variantStyle = variant === 'TextArea' ? textAreaStyle : undefined

    const inputRef = useMergeRefs(ref, innerRef)

    if (variant === 'search') {
      return wrap(
        <BaseInputGroup label={label} id={id}>
          <InputRightElement
            pointerEvents="none"
            right="auto"
            left={5}
            backgroundColor={'transparent'}
          >
            <Icon
              as={IconRecherche}
              width={'15px'}
              height={'15px'}
              color="grey.500"
              backgroundColor="transparent"
            />
          </InputRightElement>

          <Input
            {...field}
            variant={'search'}
            placeholder={placeholder ?? translate('typesense.placeholder')}
            onChange={(e) => field.onChange(e.target.value)}
            borderRadius="25px"
            border="none"
            paddingLeft={14}
            paddingRight={14}
            backgroundColor={'white'}
          />
        </BaseInputGroup>,
      )
    }

    if (variant === 'textAreaMessageStyle') {
      return wrap(
        <Input
          {...field}
          {...textAreaMessageStyle}
          as={TextareaAutosize}
          resize="none"
          minRows={1}
          maxRows={1}
          fontSize={unit('12')}
          fontWeight="400"
          placeholder={placeholder ?? translate('typesense.placeholder')}
          onChange={(e) => field.onChange(e.target.value)}
          height="40px!important"
          minHeight="40px!important"
          borderRadius="20px"
          border="none"
          paddingX="20px"
          paddingY="10px"
          backgroundColor={'lightGrey.500'}
          _hover={{ borderColor: 'transparent' }}
          _focus={{ borderColor: 'transparent' }}
          _focusVisible={{ borderColor: 'transparent' }}
        />,
      )
    }

    return wrap(
      <BaseInputGroup label={label} id={id}>
        <Input
          onKeyDown={onKeyDown}
          maxLength={maxLength}
          {...field}
          disabled={field.readOnly || field.disabled}
          as={variant === 'TextArea' ? TextareaAutosize : undefined}
          ref={inputRef}
          value={field.value ?? undefined}
          placeholder={placeholder && translate(placeholder)}
          type={type}
          role="textbox"
          autoComplete={suggestions ? 'off' : 'on'}
          list={suggestions?.length ? dataListId : undefined}
          {...variantStyle}
          fontWeight="bold"
          fontSize="sm"
        />
        {suggestions?.length && (
          <datalist id={dataListId}>
            {suggestions.map((listOption, key) => {
              return <option key={key} value={translate(listOption)} />
            })}
          </datalist>
        )}

        {iconRight && <InputRightElement>{iconRight}</InputRightElement>}
        {maxLength && (
          <HStack width="full" justifyContent={'flex-end'}>
            <Paragraph
              text={translate('input.remainingChar', {
                value: maxLength - Number(field?.value?.length ?? 0),
              })}
            />
          </HStack>
        )}
      </BaseInputGroup>,
    )
  }),
)

function useAutoFocus(autoFocus: boolean | undefined, onFocus: () => void) {
  const innerRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (autoFocus) {
      innerRef.current?.focus()
      innerRef.current?.setSelectionRange(
        innerRef.current.value.length,
        innerRef.current.value.length,
      )
      onFocus()
    }
  }, [autoFocus, onFocus])

  return innerRef
}

export function computeStatus(
  params: Pick<TextInputProps, 'disabled' | 'error' | 'readOnly'>,
): InputState {
  const { disabled, error, readOnly } = params
  if (error) return 'error'
  if (disabled || readOnly) return 'disabled'
  return 'default'
}

const textInputTypes = [
  'color',
  'date',
  'datetime-local',
  'email',
  'month',
  'number',
  'password',
  'search',
  'tel',
  'text',
  'time',
  'url',
  'week',
] as const
export type TextInputType = (typeof textInputTypes)[number]
export function isTextInputType(type: string): type is TextInputType {
  return textInputTypes.includes(type as any)
}

export function BaseInputGroup(
  props: PropsWithChildren<{ label: string | undefined; id: string }>,
) {
  const { label, id, children } = props

  const hasChildren = Boolean(children)

  if (!hasChildren && !label) return null

  return (
    <InputGroup margin={0}>
      <VStack alignItems={'flex-start'} gap={'5px'} width="full">
        {label && (
          <FormLabel
            zIndex={5}
            htmlFor={id}
            display={'flex'}
            margin={0}
            _disabled={{ opacity: 1 }}
            requiredIndicator={
              <Box marginLeft={1}>
                <Paragraph fontWeight="bold" color={'red.500'}></Paragraph>
              </Box>
            }
          >
            <Caption as={'span'} text={label} />
          </FormLabel>
        )}
        {children}
      </VStack>
    </InputGroup>
  )
}
