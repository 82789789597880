// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconDot = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={25.5} cy={25.5} r={16.5} fill="currentColor" />
  </svg>
)

const Memo = memo(IconDot)
export default Memo
