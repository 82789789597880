export function formatDate(date: Date): Date {
  if (date.getHours() < 8) {
    date.setHours(8, 0, 0, 0)
    return date
  }
  if (date.getHours() >= 19) {
    const nextDay = date.getDate() + 1
    date.setDate(nextDay)
    date.setHours(8, 0, 0, 0)
    return date
  }

  const nextHour = date.getHours() + 1
  if (date.getMinutes() < 30) {
    date.setMinutes(30, 0, 0)
    return date
  } else {
    date.setHours(nextHour, 0, 0, 0)
    return date
  }
}
