import { Input } from '@chakra-ui/react'
import { format, isValid } from 'date-fns'
import type { ForwardedRef } from 'react'
import { memo, forwardRef } from 'react'

import type { Transformer } from '..'
import type { BaseInputProps } from '../controller'
import { useBaseInput } from '../controller'
import { BaseInputGroup } from '../TextInput/TextInput'

export const dateTransformer: Transformer<Date | null | undefined, string> = {
  read(value) {
    if (!value) return ''
    return format(new Date(value), 'yyyy-MM-dd')
  },
  write(value) {
    if (!value) return undefined
    const date = new Date(value)
    if (isValid(date)) {
      return date
    } else {
      return undefined
    }
  },
}

type DateInputProps = BaseInputProps<Date> & {
  variant?: 'rounded'
  min?: Date
  max?: Date
}

export const DateInput = memo(
  forwardRef(function (
    props: DateInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    const { label, variant, min, max, ...rest } = props
    const { id, field, wrap } = useBaseInput<Date, Date, string>(rest, {
      baseTransformer: dateTransformer,
    })

    return wrap(
      <BaseInputGroup label={label} id={id}>
        <Input
          {...field}
          variant={variant}
          disabled={field.readOnly || field.disabled}
          min={min ? format(new Date(min), 'yyyy-MM-dd') : undefined}
          max={max ? format(new Date(max), 'yyyy-MM-dd') : undefined}
          ref={ref}
          type="date"
          role="textbox"
          value={String(field.value)}
        />
      </BaseInputGroup>,
    )
  }),
)
