import type { Tint } from '@aubade/design'
import { themeColors } from '@aubade/design/themeColors'
import type { BoxProps, FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'
import Color from 'color'
import type { PropsWithChildren } from 'react'

const TintStyleMap: Record<Tint, BoxProps> = {
  primary: {
    borderColor: 'primary.500',
    background: 'white.500',
  },
  success: {
    borderColor: 'success.medium',
    background: Color(themeColors.success.light).alpha(0.6).string(),
  },
  warning: {
    borderColor: 'warning.medium',
    background: Color(themeColors.warning.light).alpha(0.5).string(),
  },
  error: {
    borderColor: 'error.medium',
    background: Color(themeColors.error.medium).alpha(0.05).string(),
  },
  orange: {
    borderColor: 'orange.alpha50',
    background: 'orange.alpha10',
  },
  violet: {
    borderColor: 'violet.alpha50',
    background: 'violet.alpha10',
  },
  pink: {
    borderColor: 'pink.alpha50',
    background: 'pink.alpha10',
  },
  teal: {
    borderColor: 'teal.alpha50',
    background: 'teal.alpha10',
  },
  amber: {
    borderColor: 'amber.alpha50',
    background: 'amber.alpha10',
  },
  disabled: {
    borderColor: 'greyscale.500',
    background: 'greyscale.100',
  },
}

export type FrameProps = {
  tint?: Tint
} & Pick<
  FlexProps,
  | 'padding'
  | 'paddingX'
  | 'paddingY'
  | 'direction'
  | 'alignItems'
  | 'justifyContent'
  | 'width'
  | 'minWidth'
  | 'maxWidth'
  | 'height'
  | 'minHeight'
  | 'maxHeight'
  | 'gap'
  | 'overflowY'
  | 'transform'
  | 'flex'
  | 'position'
>
export function Frame(props: PropsWithChildren<FrameProps>) {
  const { tint = 'primary', children, ...flexProps } = props

  return (
    <Flex
      padding={5}
      border="1px"
      borderRadius="16px"
      width="full"
      {...TintStyleMap[tint]}
      {...flexProps}
    >
      {children}
    </Flex>
  )
}
