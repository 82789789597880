import type { HydraId } from '@nartex/api-platform'
import type { ReactNode } from 'react'

import type { BaseRecord } from '../../adapters'
import { useOne, useMany } from '../../adapters'
import { Boundary } from '../Boundary'

export type QueryOneProps<T extends BaseRecord> = {
  iri: HydraId
  children: (record: T) => ReactNode
}
export function QueryOne<T extends BaseRecord>(props: QueryOneProps<T>) {
  return (
    <Boundary>
      <QueryOneImpl {...props} />
    </Boundary>
  )
}

function QueryOneImpl<T extends BaseRecord>(props: QueryOneProps<T>) {
  const { iri, children } = props

  if (typeof iri !== 'string') {
    throw Error(
      `Prop 'iri' is required on <QueryOne />, and should be a string. Got ${JSON.stringify(
        iri,
      )}`,
    )
  }
  const [item] = useOne<T>({ iri })

  if (!item) return null
  return <>{children(item)}</>
}

export type QueryManyProps<T extends BaseRecord> = {
  iris: HydraId[]
  children: (record: T[]) => ReactNode
}
export function QueryMany<T extends BaseRecord>(props: QueryManyProps<T>) {
  return (
    <Boundary>
      <QueryManyImpl {...props} />
    </Boundary>
  )
}

function QueryManyImpl<T extends BaseRecord>(props: QueryManyProps<T>) {
  const { iris, children } = props

  const [item] = useMany<T>({ iris })

  if (!item) return null
  return <>{children(item)}</>
}
