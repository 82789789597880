import { unit, Paragraph, Button } from '@aubade/core/ui-kit'
import { PictureCarousel } from '@aubade/core/ui-kit/Carousel'
import { LogoEspaceAubade } from '@aubade/design/graphics'
import { Box, HStack, VStack, Icon, AspectRatio } from '@chakra-ui/react'
import { useGetTimeDiff } from 'src/libs/getTimediff'

import type { ExtendedTypesensePublication } from '../types'

import { FeedPicture } from './FeedPicture'

export function PublicationPreview(props: {
  publication: ExtendedTypesensePublication
}) {
  const { publication } = props
  const { author, publishedAt, title, urlLabel, url, content, documents } =
    publication
  const authorName = typeof author === 'object' ? author.name : author

  const publishedFrom = useGetTimeDiff(new Date(publishedAt))

  return (
    <VStack
      gap={0}
      width="full"
      height="90vh"
      borderRadius={unit('20')}
      overflow={'hidden'}
    >
      <AspectRatio
        ratio={1}
        width="full"
        backgroundColor="white.500"
        // overflow="hidden"
        borderRadius={'20px 20px 0 0'}
        justifyContent={'center'}
      >
        <PictureCarousel multi={documents.length > 1}>
          {documents.map((fullDocument, index) => {
            return (
              <FeedPicture
                key={`${fullDocument.url} - ${index}`}
                document={fullDocument}
                clickable
              />
            )
          })}
        </PictureCarousel>
      </AspectRatio>
      <VStack
        width="full"
        overflowY={'auto'}
        backgroundColor="white.500"
        padding={unit('20')}
        paddingTop={unit('7')}
        gap={'20px'}
      >
        <Box
          width={unit('80')}
          minHeight={unit('3')}
          borderRadius={unit('2')}
          backgroundColor="grey.500"
          marginBottom={unit('30')}
        />
        <VStack
          justifyContent="flex-start"
          width="full"
          alignItems="flex-start"
          gap={unit('20')}
        >
          <VStack gap={0} width="full" alignItems="flex-start">
            <HStack gap={unit('5')} width="full">
              {getAuthor(authorName)}
              <Paragraph
                fontWeight="bold"
                color="greyDark.500"
                text={`- ${publishedFrom.diff} ${publishedFrom.text}`}
              />
            </HStack>
            <Paragraph fontWeight="bold" size="md" text={title} />
          </VStack>
          {url && (
            <Box width="full" marginY={unit('20')}>
              <Button variant="primary" label={urlLabel ?? url} to={url} />
            </Box>
          )}

          <Paragraph text={content} whiteSpace={'pre-line'} />
        </VStack>
      </VStack>
    </VStack>
  )
}

export function getAuthor(publicationAuthor: string) {
  if (publicationAuthor === 'Aubade')
    return (
      <>
        <Paragraph fontWeight="bold" color="blue.500" text="Publication" />
        <Icon as={LogoEspaceAubade} width={unit('40')} />
      </>
    )
  return (
    <Paragraph fontWeight="bold" color="blue.500" text={publicationAuthor} />
  )
}
