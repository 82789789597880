import { useSyncExternalStore } from 'react'

import { useEvent } from './useEvent'

type Listener = () => void
type UpdateFunc<T> = (prevState: T) => T

export class ExternalStore<T> {
  constructor(initialState: T) {
    this.#state = initialState
  }

  #listeners: Listener[] = []
  subscribe = (onStoreChange: Listener) => {
    this.#listeners.push(onStoreChange)
    return () => {
      this.#listeners = this.#listeners.filter((item) => item !== onStoreChange)
    }
  }

  setState = (state: T | ((prevState: T) => T)) => {
    if (typeof state === 'function') {
      this.#state = (state as any)(this.#state)
    } else {
      this.#state = state
    }

    this.notifyChange()
  }

  notifyChange = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Promise.resolve().then(() => {
      this.#listeners.forEach((listener) => listener())
    })
  }

  #state: T
  getSnapShot = () => {
    return this.#state
  }

  use = <U = T>(
    lens?: {
      get: (val: T) => U
      set: (oldState: T, newVal: U) => T
    },
    shouldUpdate?: (oldState: U, newState: U) => boolean,
  ) => {
    const setState = useEvent((state: U | UpdateFunc<U>) => {
      this.setState((parentState: T): T => {
        if (lens) {
          if (typeof state === 'function') {
            state = (state as UpdateFunc<U>)(lens.get(parentState))
          }
          return lens.set(parentState, state)
        } else {
          if (typeof state === 'function') {
            return (state as UpdateFunc<T>)(parentState)
          }
          return state as unknown as T
        }
      })
    })

    const getSnapShot = useEvent(() => {
      if (!lens) return this.getSnapShot() as unknown as U
      return lens.get(this.getSnapShot()) as U
    })

    const shouldUpdateLatest = useEvent(() => {
      if (!shouldUpdate) return true
      return shouldUpdate(state, getSnapShot())
    })

    const subscribe = useEvent((onStoreChange: Listener) => {
      return this.subscribe(() => {
        if (shouldUpdateLatest()) {
          onStoreChange()
        }
      })
    })

    const state = useSyncExternalStore<U>(subscribe, getSnapShot)

    return [state, setState] as const
  }
}
