import type { SurfaceProps } from '@aubade/core/ui-kit'
import { Surface, unit, Title } from '@aubade/core/ui-kit'
import type { TranslationKey } from '@aubade/translation'
import type { BoxProps } from '@chakra-ui/react'
import { Box, SimpleGrid, Flex } from '@chakra-ui/react'
import { isValidElement, useMemo } from 'react'
import type { PropsWithChildren, ReactNode } from 'react'
import flattenChildren from 'react-flatten-children'

import { isEmpty } from '../libs'

const paddingProps: BoxProps['padding'] = {
  sm: 0,
  xl: unit('64'),
}
const itemStyle = {
  borderTopWidth: '1px',
  borderColor: 'greyscale.100',
  paddingTop: unit('10'),
} satisfies BoxProps

const notFirstColumnStyle = {
  ...itemStyle,
  borderTopWidth: { sm: itemStyle.borderTopWidth, xl: 0 },
  paddingTop: { sm: itemStyle.paddingTop, xl: 0 },
} satisfies BoxProps

type DetailListProps = {
  singleColumn?: boolean
  surfaceProps?: SurfaceProps
}
export const DetailList = Object.assign(
  function DetailList(props: PropsWithChildren<DetailListProps>) {
    const { singleColumn, surfaceProps } = props

    const columns = useMemo(() => {
      const filteredChildren = flattenChildren(props.children).filter(
        (child) => {
          if (isValidElement(child)) {
            if (child.type === DetailListValue) {
              return !isEmpty(child.props.children)
            }
          }

          return child !== undefined
        },
      )

      if (singleColumn) return [filteredChildren]
      if (filteredChildren.length < 2) return [filteredChildren]
      const columnLength = Math.ceil(filteredChildren.length / 2)
      return [
        filteredChildren.slice(0, columnLength),
        filteredChildren.slice(columnLength),
      ]
    }, [props.children, singleColumn])

    const hasMultipleColumns = singleColumn ? false : columns.length > 1

    const borderRightWidthXl = surfaceProps?.tint ? '2px' : '1px'
    const borderRightColor = surfaceProps?.tint
      ? `${surfaceProps.tint}.500`
      : 'greyscale.200'

    return (
      <Surface
        elevation={0}
        paddingX={unit('64')}
        paddingY={unit('44')}
        {...surfaceProps}
      >
        <SimpleGrid
          width="full"
          columns={singleColumn ? 1 : { sm: 1, xl: 2 }}
          rowGap={unit('10')}
        >
          {columns.map((column, index) => (
            <Flex
              direction="column"
              gap={unit('10')}
              key={index}
              _odd={
                hasMultipleColumns
                  ? {
                      paddingRight: paddingProps,
                      borderRightWidth: {
                        sm: 'none',
                        xl: borderRightWidthXl,
                      },
                      borderRightColor: borderRightColor,
                    }
                  : undefined
              }
              _even={{
                paddingLeft: paddingProps,
              }}
              _notFirst={notFirstColumnStyle}
            >
              {column.map((child, childIndex) => (
                <Box key={childIndex} _notFirst={itemStyle}>
                  {child}
                </Box>
              ))}
            </Flex>
          ))}
        </SimpleGrid>
      </Surface>
    )
  },
  {
    Value: DetailListValue,
    value: detailListValue,
  },
)

type DetailListValueProps = {
  label: TranslationKey
  key?: string
}
function DetailListValue(props: PropsWithChildren<DetailListValueProps>) {
  const { label, children } = props

  if (isEmpty(children)) return null

  return (
    <Flex
      width="full"
      justifyContent="space-between"
      alignItems="center"
      gap={1}
    >
      <Title.H6 color="greyscale.500" text={label} />
      <Title.H6 fontWeight="normal" textAlign="end">
        {children}
      </Title.H6>
    </Flex>
  )
}

function detailListValue(
  props: DetailListValueProps,
  value: ReactNode,
): ReactNode
function detailListValue<T>(
  props: DetailListValueProps & { format: (value: T) => ReactNode },
  value: T | undefined | null,
): ReactNode
function detailListValue(
  props: DetailListValueProps & { format?: (value: unknown) => ReactNode },
  value: unknown,
): ReactNode {
  if (isEmpty(value)) return null
  return (
    <DetailListValue {...props}>
      {props.format ? props.format(value) : String(value)}
    </DetailListValue>
  )
}

type DetailFieldProps = {
  label: TranslationKey
  value?: ReactNode
  key?: string
}
export function detailFieldRender(props: DetailFieldProps) {
  const { value, ...rest } = props
  if (isEmpty(value)) return undefined

  return <DetailListValue {...rest}>{value}</DetailListValue>
}
