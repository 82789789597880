// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconArtisan = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4402_39222)">
      <path
        d="M28.3641 29.1442H22.4016C21.6647 29.1442 20.9501 29.2112 20.2355 29.3452V50.0018H30.2399V29.3005C29.637 29.2112 29.0117 29.1442 28.3864 29.1442H28.3641Z"
        fill="currentColor"
      />
      <path
        d="M35.6185 13.7376H38.4099V11.0355L36.3108 10.053C35.7972 4.42545 31.1299 0.0261688 25.3907 0.0261688C19.6516 0.0261688 14.962 4.42545 14.4707 10.0306L12.3715 11.0132V13.7153H15.1629C15.029 14.3629 14.962 15.0329 14.962 15.7251C14.962 18.4942 16.0785 21.1517 18.0214 23.0945C19.9642 25.0373 22.6216 26.1539 25.3907 26.1539C28.1598 26.1539 30.7949 25.0597 32.7601 23.0945C34.7253 21.1293 35.8195 18.4942 35.8195 15.7251C35.8195 15.0552 35.7525 14.3629 35.6185 13.7153V13.7376Z"
        fill="currentColor"
      />
      <path
        d="M9 42.5382C9 44.5033 9.7816 46.4015 11.1885 47.8084C12.2157 48.8356 13.5333 49.5502 14.9625 49.8405V31.3948C14.2479 31.8861 13.5556 32.4444 12.9303 33.0697C10.4069 35.5708 9 38.9875 9 42.5382Z"
        fill="currentColor"
      />
      <path
        d="M37.8546 33.0738C37.14 32.3592 36.3584 31.7563 35.5098 31.2203V49.8671C37.0284 49.6214 38.4799 48.9068 39.5965 47.7902C40.981 46.4057 41.785 44.5075 41.785 42.52C41.785 38.9693 40.3781 35.5526 37.8546 33.0515V33.0738Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4402_39222">
        <rect width={50} height={50} fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const Memo = memo(IconArtisan)
export default Memo
