import type { Merge } from '@aubade/core/libs'
import type { Publication, Document } from '@aubade/types/api'

import type { ExtendedUser } from '../../../../../packages/types/src/index'

export enum PublicationLinkEnum {
  'enum./publicationlink/WITHOUT_LINK' = 'enum./publicationlink/WITHOUT_LINK',
  'enum./publicationlink/TOOL_LINK' = 'enum./publicationlink/TOOL_LINK',
  'enum./publicationlink/EXTERNAL_LINK' = 'enum./publicationlink/EXTERNAL_LINK',
}

export type FormType = Merge<
  Publication.Write,
  {
    linkType: PublicationLinkEnum
    target: 'national' | 'society' | 'agency'
    agencies: Record<string, boolean>
    societies: Record<string, boolean>
    newId: string
    isNational: 'true' | 'false'
    isAgencies: 'true' | 'false'
    toolId: string
    documents: Document.Read[]
    author: `/users/${string}`
    fullAuthor: ExtendedUser
  }
>
