import { type LogicalFilter } from '@aubade/core/adapters'
import type { DeepPartial } from 'react-hook-form'

export function logicalToCrudApiFilter<T>(
  filters: LogicalFilter<T>[],
): DeepPartial<T> {
  return filters.reduce((res, element) => {
    res[element.field] = element.value
    return res
  }, {} as any)
}
