import { type LogicalFilter } from '@aubade/core/adapters'
import { useQueryParamState } from '@aubade/core/libs'
import { Surface, unit } from '@aubade/core/ui-kit'
import type { Typesense } from '@aubade/types/typesense'
import { useMemo } from 'react'
import type { DeepPartial } from 'react-hook-form'
import { ResourcePageLayout } from 'src/components/ResourcePageLayout'
import { logicalToCrudApiFilter } from 'src/libs/logicalToCrudApiFilter'

import { StringParam } from 'use-query-params'

import { InfiniteResourceList } from '../../components/InfiniteResourceList'
import { ListHeader } from '../../components/ListHeader'
import { PublicationEdit } from '../Publications/CreateEdit'
import { usePublicationFilters } from '../Publications/filters'
import { FilterBar } from '../Publications/filters/FilterForm'

import { FeedCard } from './components'
import type { ExtendedTypesensePublication } from './types'

export function FeedList() {
  const { crudFilters, filtersStore } = usePublicationFilters('publications')

  const paramName = `publications.q`
  const [queryValue] = useQueryParamState(paramName, StringParam)

  const searchFilters: LogicalFilter<Typesense.Publications>[] = useMemo(() => {
    const search = queryValue
    if (search) {
      const qFilter = { q: search }
      if (crudFilters) {
        return [
          { field: 'q', operator: 'eq', value: qFilter.q },
          ...crudFilters,
        ]
      }
      return [{ field: 'q', operator: 'eq', value: qFilter.q }]
    } else {
      return crudFilters
    }
  }, [crudFilters, queryValue])
  const crudApiFilter = logicalToCrudApiFilter(searchFilters)

  return (
    <ResourcePageLayout>
      <Surface
        direction={'column'}
        overflowY="auto"
        gap={unit('40')}
        paddingX={unit('60')}
        paddingY={unit('40')}
        maxHeight={'full'}
        minHeight={'full'}
      >
        <ListHeader
          scope="publications"
          variant="feed"
          filters={
            <FilterBar
              onChange={filtersStore.setState}
              filterState={filtersStore.state}
              resource="publications"
              context="feed"
            />
          }
        />
        <Feed apifilters={crudApiFilter} />
      </Surface>
    </ResourcePageLayout>
  )
}

type FeedProps = {
  apifilters?: DeepPartial<Typesense.Publications>
}

function Feed(props: FeedProps) {
  const { apifilters } = props

  return (
    <>
      <InfiniteResourceList<
        ExtendedTypesensePublication,
        Typesense.Publications
      >
        resource="feed"
        filters={apifilters}
        useWindow={false}
        renderItem={(publication) => <FeedCard publication={publication} />}
      />
      <PublicationEdit />
    </>
  )
}
