import type { LogicalFilter } from '@aubade/core/adapters'
import { compactObj } from '@aubade/core/libs'
import { toCrudFilters, useFiltersSearchParams } from '@aubade/core/ui-kit'
import type { User } from '@aubade/types/api'
import { cleanHydraId } from '@nartex/api-platform'

import type { PublicationFilterType } from '../../Publications/filters/types'
import type { ConversationFilterType } from '../types'

export function useConversationFilters(scope: 'conversations' | 'summary') {
  return useFiltersSearchParams<ConversationFilterType, User.Read>({
    scope: scope,
    defaultFilters: { multi: false, internal: false, external: false },
    toCrudFilters(filtersState) {
      const { agencies, q, internal, external, ...defaultParse } = filtersState
      return [
        ...toCrudFilters(defaultParse),
        agencies && agenciesFilterToCrud(agencies),
        q && queryToCrud(q),
        (internal || external) && profileToCrudFilter(internal, external),
      ]
    },
  })
}

function agenciesFilterToCrud<T extends Record<string, any>>(
  values: T,
): LogicalFilter<PublicationFilterType> {
  const agencies = Object.entries(compactObj(values)).map(([hydraId]) => {
    return cleanHydraId(hydraId)
  })
  return {
    field: 'agencies',
    operator: 'eq',
    value: agencies.length > 0 ? agencies : undefined,
  }
}

function queryToCrud(query: string): LogicalFilter<PublicationFilterType> {
  return {
    field: 'q',
    operator: 'eq',
    value: query,
  }
}

function profileToCrudFilter(
  internal?: boolean,
  external?: boolean,
): LogicalFilter<PublicationFilterType> {
  if (internal && !external) {
    return {
      field: 'isInternal',
      operator: 'eq',
      value: true,
    }
  }
  if (!internal && external) {
    return {
      field: 'isInternal',
      operator: 'eq',
      value: false,
    }
  }

  return {
    field: 'isInternal',
    operator: 'eq',
    value: undefined,
  }
}
