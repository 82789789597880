// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconExit = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M35.2361 36.875C34.7268 36.2731 34.4722 35.5907 34.4722 34.8278C34.4722 34.0648 34.7268 33.4278 35.2361 32.9167L40.375 27.7778H20.4444C19.6574 27.7778 18.9972 27.5111 18.4639 26.9778C17.9306 26.4444 17.6648 25.7852 17.6667 25C17.6667 24.213 17.9333 23.5528 18.4667 23.0194C19 22.4861 19.6593 22.2204 20.4444 22.2222H40.375L35.2361 17.0833C34.6805 16.5278 34.4028 15.8676 34.4028 15.1028C34.4028 14.338 34.6805 13.6787 35.2361 13.125C35.7454 12.5694 36.3824 12.2917 37.1472 12.2917C37.912 12.2917 38.5481 12.5463 39.0555 13.0556L49.0555 23.0556C49.3333 23.3333 49.5305 23.6343 49.6472 23.9583C49.7639 24.2824 49.8213 24.6296 49.8194 25C49.8194 25.3704 49.762 25.7176 49.6472 26.0417C49.5324 26.3657 49.3352 26.6667 49.0555 26.9444L39.0555 36.9444C38.4537 37.5463 37.7935 37.813 37.075 37.7444C36.3565 37.6759 35.7435 37.3861 35.2361 36.875ZM6.55556 50C5.02778 50 3.71945 49.4555 2.63056 48.3667C1.54167 47.2778 0.998153 45.9704 1 44.4444V5.55556C1 4.02778 1.54445 2.71945 2.63334 1.63056C3.72223 0.541671 5.02963 -0.00184714 6.55556 4.71609e-06H23.2222C24.0093 4.71609e-06 24.6694 0.266672 25.2028 0.800005C25.7361 1.33334 26.0018 1.9926 26 2.77778C26 3.56482 25.7333 4.225 25.2 4.75834C24.6667 5.29167 24.0074 5.55741 23.2222 5.55556H6.55556V44.4444H23.2222C24.0093 44.4444 24.6694 44.7111 25.2028 45.2444C25.7361 45.7778 26.0018 46.437 26 47.2222C26 48.0093 25.7333 48.6694 25.2 49.2028C24.6667 49.7361 24.0074 50.0018 23.2222 50H6.55556Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconExit)
export default Memo
