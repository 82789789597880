export function once<T extends () => any>(func: T): T {
  let didRun = false
  let result: ReturnType<T>
  return function () {
    if (!didRun) {
      result = func()
      didRun = true
    }
    return result
  } as T
}
