import type { DataProvider, CrudSort } from '@aubade/core/adapters'

import type {
  BaseRecord,
  GetListParams,
} from '@aubade/core/adapters/dataProviders/types'
import { encodeFilters } from '@aubade/core/adapters/dataProviders/TypesenseDataProvider/encodeFilter'
import { encodePagination } from '@aubade/core/adapters/dataProviders/TypesenseDataProvider/encodePagination'
import type { AutoCompleteString } from '@aubade/core/libs'
import type { ExtendedConversation } from '@aubade/types'
import type { Typesense } from '@aubade/types/typesense'
import { groupBy } from 'remeda'

import {
  AgenciesProxy,
  ClientsProxy,
  ContactsProxy,
  FeedProxy,
  MessagesProxy,
  NotificationsCreateProxy,
  NotificationsProxy,
  PublicationsCreateProxy,
  PublicationsListProxy,
  PublicationsProxy,
  ToolsProxy,
} from './proxies'
import { CollaboratersProxy, recordFilters } from './proxies/collaboraters'
import { SocietiesProxy } from './proxies/societies'
import {
  ExportStatisticsProxy,
  StatisticsCountProxy,
  StatisticsProxy,
} from './proxies/statistics'
import { UsersProxy } from './proxies/users'

export function antiCorruptionLayerProxy(dataProvider: DataProvider) {
  const proxies = Proxies(dataProvider)
  return new Proxy(dataProvider, {
    get(_, methodName: keyof DataProvider) {
      if (typeof methodName !== 'string') return dataProvider[methodName]
      return function wrappedMethod(params: any) {
        if (!params) return (dataProvider[methodName] as any)(params)

        const { resource } = params
        if (resource in proxies) {
          // @ts-ignore
          const proxy = proxies[resource]
          if (proxy && methodName in proxy) {
            // @ts-ignore
            return proxy[methodName](params)
          }
        }

        return (dataProvider[methodName] as any)(params)
      }
    },
  })
}

function Proxies(
  dataProvider: DataProvider,
): Record<string, Partial<DataProvider>> {
  return {
    publications: PublicationsProxy(dataProvider),
    notifications: NotificationsProxy(dataProvider),
    'publications/create': PublicationsCreateProxy(dataProvider),
    'notifications/create': NotificationsCreateProxy(dataProvider),
    feed: FeedProxy(dataProvider),
    contacts: ContactsProxy(dataProvider),
    agencies: AgenciesProxy(dataProvider),
    'messages/': MessagesProxy(dataProvider),
    tools: ToolsProxy(dataProvider),
    'tools/create': ToolsProxy(dataProvider),
    users: UsersProxy(dataProvider),
    conversations: ConversationsProxy(dataProvider),
    'summary/conversations': SummaryProxy(dataProvider),
    'publications/list': PublicationsListProxy(dataProvider),
    clients: ClientsProxy(dataProvider),
    associates: CollaboratersProxy(dataProvider),
    statistics: StatisticsProxy(dataProvider),
    countStatistics: StatisticsCountProxy(dataProvider),
    'export/customers': ExportStatisticsProxy(dataProvider, 'customers'),
    'export/associates': ExportStatisticsProxy(dataProvider, 'associates'),
    societies: SocietiesProxy(dataProvider),
  }
}

function SummaryProxy(dataProvider: DataProvider): Partial<DataProvider> {
  return {
    // @ts-expect-error
    async getList(params) {
      const { filters } = params
      const data = await dataProvider.custom!<ExtendedConversation[]>({
        ...params?.metaData,
        method: 'post',
        payload: recordFilters(filters),
        url: `summary/conversations`,
      })

      return { ...data, data: data.data, total: data.data.length }
    },
  }
}

function ConversationsProxy(dataProvider: DataProvider): Partial<DataProvider> {
  return {
    // @ts-ignore
    async getOne(params) {
      const conversation = await dataProvider.custom!({
        ...params?.metaData,
        method: 'get',
        url: `summary${params.id}`,
      })

      const { id: conversationId } = conversation.data
      const { data: messages } = await dataProvider.getList({
        ...params,
        // @ts-ignore
        resource: `conversations/${conversationId}/messages`,
      })
      return {
        ...conversation,
        data: {
          ...conversation.data,
          messages,
        },
      }
    },
  }
}

export function encodePayloadApiToTypesense<T extends BaseRecord>(
  params: GetListParams<T>,
  collection: AutoCompleteString<Typesense.Collection>,
  queryBy: string,
) {
  const { filters, pagination, sort } = params
  const { search, tsFilters } = groupBy(filters ?? [], (filter) => {
    if ('q' in filter) return 'search'
    if ('field' in filter) {
      return 'tsFilters'
    }
  })
  const sortBy = encodeSortBy(sort).sort_by
  return {
    searches: [
      {
        collection: collection,
        query_by: queryBy,
        ...encodePagination(pagination ?? {}),
        ...encodeFilters([...(search ?? []), ...(tsFilters ?? [])]),
        sort_by: sortBy,
      },
    ],
  }
}

function encodeSortBy<T>(sort: CrudSort<T>[] | undefined) {
  return {
    sort_by:
      sort === undefined
        ? undefined
        : sort
            .map((item) => {
              const { field, order } = item
              return `${field}:${order}`
            })
            .join(','),
  }
}
