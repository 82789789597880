import type { ReactNode } from 'react'
import { isTruthy } from 'remeda'

import type { Falsy } from './truthy'

export function listify(
  items: (string | Falsy)[],
  separator: string,
  lastSeparator = separator,
): string {
  return withSeparator(items, separator, lastSeparator).join('')
}

export function listifyNodes(
  items: (ReactNode | Falsy)[],
  separator: ReactNode,
  lastSeparator = separator,
) {
  return <>{withSeparator(items, separator, lastSeparator)}</>
}

// implementation

function withSeparator<T>(
  items: (T | Falsy)[],
  separator: T,
  lastSeparator = separator,
): T[] {
  const result: T[] = []

  const values = items.filter(isTruthy)
  values.forEach((value, index) => {
    const isFirstIndex = index === 0
    const isLastIndex = index === values.length - 1

    if (!isFirstIndex) {
      result.push(isLastIndex ? lastSeparator : separator)
    }

    result.push(value)
  })

  return result
}
