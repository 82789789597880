import { isEmpty } from '@aubade/core/libs'
import type { SearchParams } from '@nartex/typesense'
import { groupBy } from 'remeda'

import type { SearchFilter, CrudFilter, CrudFilters } from '../types'

import { tsEsc } from './utils'

export function encodeFilters(
  filters: CrudFilters<unknown>,
): Pick<SearchParams, 'filter_by' | 'q'> {
  const { qFilters, otherFilters } = groupBy(
    filters,
    (filter): 'qFilters' | 'otherFilters' => {
      if ('q' in filter) return 'qFilters'
      else return 'otherFilters'
    },
  )

  const q =
    qFilters?.map((filter) => (filter as SearchFilter).q).join(' ') || '*'
  const filter_by = (otherFilters as CrudFilter<unknown>[])
    ?.filter((filter) => !isEmpty((filter as any)?.value))
    .map(encodeFilter)
    .filter(Boolean)
    .join(' && ')

  return {
    q,
    filter_by: filter_by || undefined,
  }
}

const geopointRegex = /^\(-?\d+(.\d+)?, -?\d+(.\d+)?, (\d+(.\d+)?)km\)$/

function encodeFilter(
  filter: Exclude<CrudFilter<unknown>, SearchFilter>,
): string {
  const { operator } = filter

  if (operator === 'eq') {
    const { field, value } = filter

    if (typeof value === 'string' && geopointRegex.test(value)) {
      return `${field}:${value}`
    }

    if (
      value &&
      typeof value === 'object' &&
      value.constructor.name === 'Object'
    ) {
      const nestedFilters = Object.entries(value)
        .map(([key, innerValue]) => {
          if (innerValue == null) return undefined
          return encodeFilter({
            operator: 'eq',
            field: `${field}.${key}`,
            value: innerValue,
          })
        })
        .filter(Boolean)

      if (!nestedFilters.length) return ''
      return `(${nestedFilters.join(' && ')})`
    }

    return `${field}:=${tsEsc(value, field)}`
  }

  if (operator === 'ne') {
    const { field, value } = filter
    return `${field}:!=${tsEsc(value, field)}`
  }

  if (operator === 'between') {
    const { field, value } = filter
    if (typeof value === 'string' && value.startsWith('[')) {
      return `${field}:${value}`
    }
    if (Array.isArray(value) && value.length === 2) {
      return `${field}:[${tsEsc(value[0], field)}..${tsEsc(value[1], field)}]`
    }

    throw Error(
      `This filter format (${JSON.stringify(filter)}) is not supported`,
    )
  }

  if (operator === 'lt') {
    const { field, value } = filter
    return `${field}:<${tsEsc(value, field)}`
  }

  if (operator === 'lte') {
    const { field, value } = filter
    return `${field}:<=${tsEsc(value, field)}`
  }

  if (operator === 'gt') {
    const { field, value } = filter
    return `${field}:>${tsEsc(value, field)}`
  }

  if (operator === 'gte') {
    const { field, value } = filter
    return `${field}:>=${tsEsc(value, field)}`
  }

  if (operator === 'or') {
    const { value } = filter
    return `(${value
      .filter((val) => val !== undefined)
      .map(encodeFilter)
      .join(' || ')})`
  }

  if (operator === 'and') {
    const { value } = filter
    return `(${value
      .filter((val) => val !== undefined)
      .map(encodeFilter)
      .join(' && ')})`
  }

  throw Error(
    `The ${JSON.stringify(
      operator,
    )} is not yet supported. Don't hesitate to implement it`,
  )
}
