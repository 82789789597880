import type { BoxProps } from '@chakra-ui/react'

export function cssSize(
  size: BoxProps['paddingLeft'],
): BoxProps['paddingLeft'] {
  // provided values are not always part of chakra system of sizes
  if (typeof size === 'number') {
    return `${size / 4}rem`
  }
  if (Array.isArray(size)) {
    return size.map(cssSize as any) as any
  }

  return size
}

/* eslint-disable prettier/prettier */
export const units = [
  '0',
  '-2', '2',
  '-3', '3',
  '-7', '7',
  '-4', '4',
  '-5', '5',
  '-6', '6',
  '-8', '8',
  '-9', '9',
  '-10', '10',
  '-12', '12',
  '-14', '14',
  '-15', '15',
  '-16', '16',
  '-20', '20',
  '-24', '24',
  '-25', '25',
  '-30', '30',
  '-32', '32',
  '-36', '36',
  '-40', '40',
  '-42', '42',
  '-44', '44',
  '-48', '48',
  '-50', '50',
  '-56', '56',
  '-60', '60',
  '-64', '64',
  '70', '-70',
  '-80', '80',
  '-90', '90',
  '-96', '96',
  '-100', '100',
  '-104', '104',
  '-106', '106',
  '-128', '128',
  '-145', '145',
  '-150', '150',
  '-170', '170',
  '-192', '192',
  '-200', '200',
  '-256', '256',
  '-384', '384',
  '-500', '500',
  '-512', '512',
  '-768', '768',
  '-1024', '1024',
] as const
/* eslint-enable prettier/prettier */

/** On va essayer de maintenir cette liste la plus petite possible, quitte à arrondir certaines mesures */
export type Unit = (typeof units)[number]

export const unit = Object.assign(
  function unit(pxValue: Unit) {
    return pxToRem(parseInt(pxValue))
  },
  {
    negate: function negate(pxValue: Unit) {
      return pxToRem(-1 * parseInt(pxValue))
    },
  },
)

export function pxToRem(pxValue: number) {
  return `${pxValue / 16}rem`
}
