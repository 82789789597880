import { ERROR_STYLE, Paragraph } from '@aubade/core/ui-kit'
import { RadioOff, RadioOn } from '@aubade/design/graphics'
import { useTranslate } from '@aubade/translation'
import type { RadioProps } from '@chakra-ui/react'
import {
  Box,
  Flex,
  Icon,
  Input,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react'

import type { BaseInputProps } from '../controller'
import { useBaseInput } from '../controller'
import { getSelectOptions } from '../getSelectOptions'

export type RadioButtonsProps = BaseInputProps<string | undefined> & {
  options: Record<string, string> | string[]
  inverse?: boolean
  inline?: boolean
  borderColor?: string
}

export function RadioButtons(props: RadioButtonsProps) {
  const { inverse, inline, options, borderColor, ...rest } = props
  const { field, wrap } = useBaseInput<string | undefined>(rest)
  const translate = useTranslate()
  function getOptionLabel(optionLabel: string) {
    if (optionLabel.startsWith('/enums/')) {
      return translate.enum(optionLabel)
    }
    return optionLabel
  }

  const optionsToRender = getSelectOptions(options)

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: field.name,
    value: field.value,
    defaultValue: field.value,
    onChange: field.onChange,
  })

  const group = getRootProps()
  const errorStyle = field.error ? ERROR_STYLE : {}

  return wrap(
    <Flex
      direction={inline ? 'row' : 'column'}
      wrap={inline ? 'wrap' : 'nowrap'}
      gap={5}
      {...group}
      padding="0px"
      alignItems="flex-start"
      borderRadius="sm"
      {...errorStyle}
    >
      {optionsToRender.map(([value, label]) => {
        const radio = getRadioProps({ value })

        return (
          <RadioComponent
            key={value}
            label={getOptionLabel(label)}
            inverse={inverse}
            {...radio}
            value={value}
            isDisabled={field.readOnly || field.disabled}
            borderColor={borderColor}
          />
        )
      })}
    </Flex>,
  )
}

const disabledStyle = {
  borderColor: 'transparent',
  color: 'grey.500',
  cursor: 'not-allowed',
  opacity: 1,
}

type RadioComponentProps = {
  label: string
  inverse?: boolean
} & RadioProps
function RadioComponent(props: RadioComponentProps) {
  const {
    label,
    inverse = false,
    isDisabled,
    borderColor = 'transparent',
    ...radioProps
  } = props

  const { state, getInputProps, getRadioProps } = useRadio(radioProps)

  const input = getInputProps()
  const radio = getRadioProps()

  const { isChecked } = state

  const paragraphStyle = isDisabled ? disabledStyle : {}

  return (
    <Flex
      {...radio}
      as="label"
      justifyContent={inverse ? 'flex-end' : 'flex-start'}
      alignItems="center"
      gap={'10px'}
      _hover={{
        cursor: 'pointer',
      }}
      borderRadius="sm"
      padding={0}
    >
      <Input {...input} disabled={isDisabled} />
      <Icon
        height={5}
        width={5}
        as={isChecked ? RadioOn : RadioOff}
        color={isDisabled ? 'grey.500' : 'blue.500'}
        border="1px solid"
        borderColor={borderColor}
        borderRadius={'50%'}
      />
      <Box flex={1}>
        <Paragraph
          text={label}
          size="sm"
          fontWeight="bold"
          {...paragraphStyle}
        />
      </Box>
    </Flex>
  )
}
