// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const CheckboxOff = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={20} height={20} rx={5} fill="#FFFFFC" />
  </svg>
)

const Memo = memo(CheckboxOff)
export default Memo
