import type { StrictTranslationKey } from '@aubade/translation'
import { useTranslate } from '@aubade/translation'
import { useCallback } from 'react'
import type { FieldPath, FieldValues } from 'react-hook-form'
import { test } from 'vest'

export function useMakeTest<TFieldValues extends FieldValues>() {
  const translate = useTranslate()
  return useCallback(
    (
      name: FieldPath<TFieldValues>,
      message: StrictTranslationKey,
      cb: () => void,
    ) => {
      return test(name, translate(message), cb)
    },
    [translate],
  )
}
