import type { DataProvider } from '@aubade/core/adapters'
import type { Agency, Society } from '@aubade/types/api'
import type { GetOneParams } from '@nartex/api-platform'
import type { SearchResponse } from '@nartex/typesense'

import { sortBy } from 'remeda'

import { encodePayloadApiToTypesense } from '../index'

export function AgenciesProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    async getOne<ExtendedAgency>(params: GetOneParams) {
      const agency = await dataProvider.getOne<Agency.Read>(params)
      const { society } = agency.data
      const { data: fullSociety } = await dataProvider.getOne<Society.Read>({
        ...params,
        resource: 'societies',
        id: society,
      })
      const data = { ...agency.data, society: fullSociety } as ExtendedAgency
      return {
        ...agency,
        data,
      }
    },
    async getList(params) {
      const { metaData } = params

      const payload = encodePayloadApiToTypesense(params, 'agencies', 'name')
      const searchResponse = await dataProvider.custom!<{
        results: [SearchResponse<any>]
      }>({
        method: 'post',
        url: `agencies/ts/search`,
        metaData,
        payload,
      })

      const result = searchResponse.data.results[0]

      const data = result.hits?.map((hit) => hit.document) ?? []
      const sortData = sortBy(data, (d) => d.name)

      return {
        data: sortData,
        total: data.length,
        raw: { typesense: result },
      }
    },
  }
}
