import type { LogicalFilter } from '@pankod/refine-core'
import { startOfDay, endOfDay } from 'date-fns'
import type { DeepPartial } from 'react-hook-form'
import { isTruthy } from 'remeda'

import type { Filters, MinMaxFilter } from './types'

export function toCrudFilters<T extends Filters>(
  filterState: DeepPartial<T>,
  specificOperators: Partial<Record<keyof T, LogicalFilter['operator']>> = {},
): LogicalFilter[] {
  return Object.entries(filterState)
    .map(([field, value]): LogicalFilter | undefined => {
      return {
        field,
        operator: specificOperators[field] ?? 'eq',
        value,
      }
    })
    .filter(isTruthy)
}

export function minMaxFilterToCrud<T extends Record<string, any>>(
  field: keyof T,
  filter: Partial<MinMaxFilter> | undefined,
): LogicalFilter | undefined {
  if (!filter) return undefined

  const { min, max } = filter
  if (min !== undefined && max !== undefined) {
    return {
      field: field as string,
      operator: 'between',
      value: [min, max],
    }
  }
  if (min !== undefined) {
    return {
      field: field as string,
      operator: 'gte',
      value: min,
    }
  }
  if (max !== undefined) {
    return {
      field: field as string,
      operator: 'lte',
      value: max,
    }
  }
  return undefined
}

export function datetimeFilterToCrud<T extends Record<string, any>>(
  field: keyof T,
  filter: Partial<MinMaxFilter> | undefined,
): LogicalFilter | undefined {
  const min = filter?.min ? startOfDay(filter.min) : undefined
  const max = filter?.max ? endOfDay(filter.max) : undefined
  const dateFilter = { min, max }
  return minMaxFilterToCrud<T>(field, dateFilter)
}
