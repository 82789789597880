import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys)
const baseStyle = definePartsStyle({
  container: {
    bg: 'white.500',
  },
  thumb: {
    height: '12px',
    width: '12px',
    marginTop: '4px',
    bg: 'black.500',
    _checked: {
      bg: 'blue.500',
    },
  },
  track: {
    height: '20px',
    width: '26px',
    bg: 'lightGrey.500',
    padding: 0,
    paddingX: '4px',
  },
})
const negative = definePartsStyle({
  container: {
    bg: 'lightGrey.500',
  },
  track: {
    bg: 'white.500',
  },
})

export const switchTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { negative },
})
