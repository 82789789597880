import { useOne } from '@aubade/core/adapters'
import { makeInputs } from '@aubade/core/ui-kit'
import { isAdmin, useGetUser } from '@aubade/domain/components'
import { useTranslate } from '@aubade/translation'
import type { Publication } from '@aubade/types/api'
import type { Contact } from '@aubade/types/index'
import { cleanHydraId, toHydraId } from '@nartex/api-platform'
import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { SocietyAgencyFilter } from 'src/components/Filters'

import { AgencyFilter } from '../../../components/Filters/AgencyFilters'

const Input = makeInputs<Publication.Write>()

export function TargetSection() {
  const { role } = useGetUser()
  return <>{isAdmin(role) ? <AubadeTargetList /> : <AgencyTargetList />}</>
}

function AubadeTargetList() {
  const { setValue } = useFormContext()

  const translate = useTranslate()
  const [isNational] = useWatch<{ isNational: 'true' | 'false' }>({
    name: ['isNational'],
  })

  if (!isNational) return <></>

  return (
    <>
      <Input.RadioButtons
        name="isNational"
        label="publications.fields.target"
        onChange={() => {
          setValue('agencies', {})
          setValue('societies', {})
        }}
        options={{
          true: translate('publications.fields.targetNational'),
          false: translate('publications.fields.targetSocieties'),
        }}
      />
      {isNational === 'false' && <SocietyAgencyFilter />}
    </>
  )
}

export function AgencyTargetList() {
  const translate = useTranslate()
  const { id: me } = useGetUser()
  const { setValue } = useFormContext()

  const [isAgencies] = useWatch<{ isAgencies: 'true' | 'false' }>({
    name: ['isAgencies'],
  })

  const [actualUser] = useOne<Contact>({
    iri: toHydraId('users', me!),
  })

  const societyId = cleanHydraId(actualUser!.society!['@id'])

  useEffect(() => {
    if (isAgencies === 'true') {
      setValue('isNational', 'false')
      setValue(`societies.${toHydraId('societies', societyId)}`, true)
    } else {
      setValue('isNational', 'false')
      setValue(`societies.${toHydraId('societies', societyId)}`, false)
    }
  }, [isAgencies, setValue, societyId])

  if (!isAgencies) return <></>

  return (
    <>
      <Input.RadioButtons
        name="isAgencies"
        label="publications.fields.target"
        onChange={(value) => {
          if (value === 'true') {
            setValue('agencies', undefined)
            setValue('societies', undefined)
            setValue('isNational', 'false')
            setValue(`societies.${toHydraId('societies', societyId)}`, true)
          } else {
            setValue('isNational', 'false')
          }
        }}
        options={{
          true: translate('publications.fields.targetSociety'),
          false: translate('publications.fields.targetAgencies'),
        }}
      />
      {isAgencies === 'false' && <AgencyFilter />}
    </>
  )
}
