// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconPen = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.18928 29.6316C2.69286 32.1281 0 45.3673 0 46.4286C0 48.2892 1.4501 50 3.57143 50C4.63214 50 17.8714 47.307 20.3684 44.8107L40.8041 24.375L25.6255 9.19643L5.18928 29.6316ZM39.2852 0C37.1352 0 34.1102 0.710694 31.6389 3.18204L30.6244 4.19647L45.803 19.375L46.8174 18.3606C49.0922 16.0858 49.9995 13.1964 49.9995 10.7143C49.9995 4.79633 45.1996 0 39.2852 0Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconPen)
export default Memo
