import type { HydraId } from '@nartex/api-platform'
import { getResourceType, toHydraId } from '@nartex/api-platform'
import type { GetListResponse, GetOneResponse } from '@pankod/refine-core'
import type { UseQueryOptions, QueriesOptions } from '@tanstack/react-query'

import type { BaseRecord, CrudFilter, DataProviders } from '../types'

import type {
  GetListQueryParams,
  GetManyQueryParams,
  GetOneQueryParams,
} from './types'

type GetDataProviders = {
  [K in keyof DataProviders]: () => DataProviders[K]
}

export class QueryBuilder {
  #dataProviders: GetDataProviders
  #metaData: NxDataProvider.QueryMetaData | undefined

  constructor(
    dataProviders: GetDataProviders,
    metaData: NxDataProvider.QueryMetaData | undefined,
  ) {
    this.#dataProviders = dataProviders
    this.#metaData = metaData
  }

  getList<T extends BaseRecord, TError = unknown>(
    params: GetListQueryParams<T>,
  ) {
    const {
      resource,
      dataProviderName,
      filters,
      hasPagination,
      pagination,
      sort,
    } = params

    const dataProvider = this.#dataProviders[dataProviderName ?? 'typesense']

    const metaData = {
      ...this.#metaData,
      ...params.metaData,
    }

    const options = { filters, hasPagination, pagination, sort, metaData }

    return {
      // refine's nomenclature
      queryKey: [dataProviderName, resource, 'getList', options] as const,
      queryFn: (opts) => {
        const { signal } = opts
        return dataProvider().getList<T>({
          resource,
          ...options,
          filters: options.filters as CrudFilter<T>[],
          metaData: {
            ...options.metaData,
            signal,
          },
        })
      },
    } satisfies UseQueryOptions<GetListResponse<T>, TError>
  }

  getOne<T extends BaseRecord, TError = unknown>(params: GetOneQueryParams<T>) {
    const { dataProviderName } = params

    let iri: HydraId
    let resource: string
    if ('iri' in params) {
      iri = params.iri
      resource = getResourceType(iri)
    } else {
      resource = params.resource
      iri = toHydraId(resource, String(params.id))
    }

    const dataProvider = this.#dataProviders[dataProviderName ?? 'default']

    const metaData = {
      ...this.#metaData,
      ...params.metaData,
    }

    const options = { metaData }

    return {
      // refine's nomenclature
      queryKey: [dataProviderName, resource, 'getOne', iri, options] as const,
      queryFn: (opts) => {
        const { signal } = opts
        return dataProvider().getOne<T>({
          resource,
          id: iri,
          metaData: {
            ...options.metaData,
            signal,
          },
        })
      },
    } satisfies UseQueryOptions<GetOneResponse<T>, TError>
  }

  getMany<T extends BaseRecord, TError = unknown>(
    params: GetManyQueryParams<T>,
  ) {
    let iris: HydraId[]
    if ('iris' in params) {
      iris = params.iris
    } else {
      const { resource, ids } = params
      iris = ids.map((id) => toHydraId(resource, String(id)))
    }

    return {
      queries: iris.map((iri) =>
        this.getOne<T, TError>({ ...params, iri }),
      ) satisfies readonly [...QueriesOptions<GetOneResponse<T>[]>],
    }
  }
}
