import { compactObj } from '@aubade/core/libs'
import { Accordion, makeInputs } from '@aubade/core/ui-kit'
import type { AccordionItemProps } from '@aubade/core/ui-kit'
import { HStack } from '@chakra-ui/react'
import { useMemo, type PropsWithChildren, useEffect } from 'react'
import { useWatch } from 'react-hook-form'

import { fromBooleanRecordToArray } from '../adapters/antiCorruptionLayerProxy/proxies/publications'

const Input = makeInputs()

export function AccordionInput(
  props: PropsWithChildren<
    AccordionItemProps & {
      id: string
      onChange?: () => void
      disabled?: boolean
      setSelectedParent?: (id: string | undefined) => void
      single?: boolean
      selectedParent?: string
      societyAgencies?: any[]
    }
  >,
) {
  const {
    id,
    label,
    onChange,
    disabled = false,
    single = false,
    setSelectedParent,
    selectedParent,
    societyAgencies,
  } = props
  const agenciesList = societyAgencies
  const formSocieties = useWatch<Record<string, any>>({ name: 'societies' })
  const formAgencies = useWatch<Record<string, any>>({ name: 'agencies' })

  const checkTargets = useMemo(() => {
    const arrayAgencies = fromBooleanRecordToArray(formAgencies)
    const arraySocieties = fromBooleanRecordToArray(formSocieties)

    const hasSociety = arraySocieties.length > 0
    const hasAgencies = arrayAgencies.length > 0

    return {
      hasSociety,
      hasAgencies,
    }
  }, [formSocieties, formAgencies])

  useEffect(() => {
    if (!checkTargets?.hasAgencies) {
      setSelectedParent?.(undefined)
    }
    if (single && !checkTargets?.hasSociety) {
      setSelectedParent?.(undefined)
    }
    if (single && checkTargets?.hasSociety) {
      const arraySocieties = fromBooleanRecordToArray(formSocieties)
      setSelectedParent?.(arraySocieties[0])
    }
    if (single && !checkTargets?.hasSociety) {
      const arrayAgencies = fromBooleanRecordToArray(formAgencies)

      setSelectedParent?.(arrayAgencies[0])
    }
  }, [
    checkTargets?.hasAgencies,
    checkTargets?.hasSociety,
    formAgencies,
    formSocieties,
    id,
    setSelectedParent,
    single,
  ])

  return (
    <HStack width="full">
      <Accordion.Item
        label={''}
        rightElement={
          <Input.Checkbox
            name={`societies.${id}`}
            label={label ?? ' '}
            disabled={disabled || checkTargets?.hasAgencies}
            onChange={() => {
              onChange?.()
            }}
          />
        }
      >
        {agenciesList &&
          agenciesList.map((agn) => {
            if (formAgencies && compactObj(formAgencies)[agn['@id']]) {
              setSelectedParent?.(id)
            }
            return (
              <Input.Checkbox
                key={agn.id}
                name={`agencies.${agn.id}`}
                label={agn.name!}
                disabled={
                  Boolean(
                    single && selectedParent && selectedParent !== agn.id,
                  ) || checkTargets?.hasSociety
                }
                onChange={() => {
                  onChange?.()
                }}
              />
            )
          })}
      </Accordion.Item>
    </HStack>
  )
}
