import { useHistory } from '@aubade/core/adapters'
import { useEvent } from '@aubade/core/libs'
import { useTranslate } from '@aubade/translation'
import { HStack, ModalFooter, ModalHeader } from '@chakra-ui/react'
import type { Blocker } from 'history'
import { useEffect, useRef } from 'react'

import { Button } from '../Button'
import { useConfirmModal } from '../ConfirmModal'

type BlockOptions = {
  message?: string
  confirmable?: string
  cancellable?: string
}
export function useBlockNavigation(
  shouldBlock: boolean,
  blockOptions: BlockOptions = {},
) {
  const history = useHistory()
  const translate = useTranslate()
  const {
    message = 'actions.titleConfirm',
    confirmable = 'actions.continue',
    cancellable = 'actions.cancel',
  } = blockOptions

  const confirm = useConfirmModal()

  const unblockRef = useRef<() => void>(() => {})

  const blocker = useEvent<Blocker>(async (transition) => {
    const userDidConfirm = await confirm((onConfirm, onCancel) => {
      return (
        <>
          <ModalHeader>{translate(message)}</ModalHeader>
          <ModalFooter>
            <HStack>
              <Button
                variant="primary"
                label={cancellable}
                onClick={onCancel}
              />
              <Button
                variant="primary"
                isDangerous
                label={confirmable}
                onClick={onConfirm}
                autoFocus
              />
            </HStack>
          </ModalFooter>
        </>
      )
    })
    if (userDidConfirm) {
      unblockRef.current()
      transition.retry()
    }
  })

  useEffect(() => {
    if (shouldBlock) {
      unblockRef.current = history.block(blocker)
      return () => unblockRef.current()
    }
  }, [shouldBlock, blocker, history])

  return useEvent(unblockRef.current)
}
