import { replaceEqualDeep } from '@tanstack/react-query'
import { useMemo } from 'react'

export function useStabilizeResult<T>(cb: () => T, deps?: unknown[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => stabilizeResult(cb), deps ?? [cb])
}

export function stabilizeResult<T>(cb: () => T): () => T {
  let lastResult: T | undefined
  return (): T => {
    const newResult = cb()
    lastResult = replaceEqualDeep(lastResult, newResult)
    return lastResult as T
  }
}
