import type { LogicalFilter } from '@aubade/core/adapters'
import { useList } from '@aubade/core/adapters'
import { compactObj, isEmpty } from '@aubade/core/libs'
import { unit, Paragraph, makeForm } from '@aubade/core/ui-kit'
import { HStack, VStack } from '@chakra-ui/react'
import { differenceInDays } from 'date-fns'
import type { EChartsOption } from 'echarts'
import ReactEcharts from 'echarts-for-react'

import { type BaseSyntheticEvent } from 'react'
import { Trans } from 'react-i18next'

import { getTotalStats } from '../../../adapters/antiCorruptionLayerProxy/proxies/statistics'
import { type StatisticsFiltersType } from '../Filters'
import type { TemporaryStatSummary } from '../types'

const { Input, useWatch } = makeForm<
  StatisticsFiltersType,
  'agencies' | 'societies' | 'date' | 'displayType'
>()

type AverageStats = {
  notifications: number
  publications: number
}

type GraphSectionProps = {
  onChange: (
    e?: BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>
  options?: Record<string, string>
  crudFilters: LogicalFilter<TemporaryStatSummary>[]
  filterState: StatisticsFiltersType
}
export function GraphSection(props: GraphSectionProps) {
  const { onChange, filterState, crudFilters } = props
  const [agencies] = useWatch(['agencies'])

  const [stats] = useList<TemporaryStatSummary>({
    dataProviderName: 'default',
    resource: 'statistics',
    filters: crudFilters,
  })
  if (!stats) return <></>

  const { graph } = stats[0]

  const { sommePublications, sommeNotifications } = getTotalStats(stats[0])

  if (!isEmpty(compactObj(agencies ?? {}))) return <></>

  return (
    <VStack
      width="full"
      backgroundColor={'white'}
      padding={unit('60')}
      borderRadius={unit('30')}
      gap={unit('20')}
    >
      <Header
        total={{
          notifications: sommeNotifications,
          publications: sommePublications,
        }}
        onChange={onChange}
        filterState={filterState}
      />

      {graph.length ? <Graph datas={graph} /> : <NoGraph />}
    </VStack>
  )
}

type HeaderProps = Pick<GraphSectionProps, 'onChange' | 'filterState'> & {
  total: AverageStats
}

function Header(props: HeaderProps) {
  const { total, onChange, filterState } = props
  const { notifications, publications } = total

  const options = getRadioOptions(filterState)

  return (
    <HStack width="full" justifyContent={'space-between'}>
      <Trans
        i18nKey="statistics.graph.total"
        components={{
          paragraph: (
            <Paragraph
              whiteSpace={'pre'}
              textAlign={'center'}
              fontWeight="bold"
            />
          ),
          publications: (
            <span style={{ fontWeight: 'bold', color: '#009EE0' }} />
          ),
          notifications: (
            <span style={{ fontWeight: 'bold', color: '#CE0E2D' }} />
          ),
        }}
        values={{
          publications: publications ?? 0,
          notifications: notifications ?? 0,
        }}
      />
      <HStack>
        <Input.RadioButtons
          name="displayType"
          borderColor="grey.500"
          inline={true}
          options={options}
          onChange={() => onChange()}
        />
      </HStack>
    </HStack>
  )
}

export function getRadioOptions(
  filters: StatisticsFiltersType,
): Record<string, string> {
  const { date, societies, agencies } = filters
  const options: Record<string, string> = {}

  if (!date || !date.startDate || !date.endDate) {
    return {}
  }

  if (
    differenceInDays(new Date(date!.endDate!), new Date(date!.startDate!)) > 30
  ) {
    Object.assign(options, { month: 'filters.displayType.month' })
  } else {
    Object.assign(options, { day: 'filters.displayType.day' })
  }
  if (!societies && !agencies) {
    Object.assign(options, { society: 'filters.displayType.society' })
  }
  if (societies) {
    Object.assign(options, { agency: 'filters.displayType.agency' })
  }
  if (agencies) return {}
  return options
}

function NoGraph() {
  return <Paragraph text="graph.noData" />
}

function getOptions(
  datas: TemporaryStatSummary['graph'],
): EChartsOption | undefined {
  const xLabels = datas.map((data) => data.name)
  const publications = datas.map((data) => {
    return data.publications
  })
  const notifications = datas.map((data) => {
    return data.notifications
  })
  const transformedDatas = {
    labels: xLabels,
    publications: publications,
    notifications: notifications,
  }

  const itemStyle = {
    borderRadius: 10,
  }

  return {
    legend: {},
    tooltip: {},
    backgroundColor: 'rgb(243, 245, 247)',
    xAxis: {
      data: transformedDatas.labels,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      axisLabel: { show: false },
      axisLine: { show: false },
      axisTick: { show: false },
    },
    grid: {
      backgroundColor: 'rgb(243, 245, 247)',
      borderWidth: 0,
      show: true,
      top: 60,
      bottom: 60,
      left: 60,
      right: 60,

      containLabel: true,
    },
    // dataZoom: [
    //   {
    //     type: 'slider',
    //     // xAxisIndex: 0,
    //     zoomLock: true,
    //     moveOnMouseWheel: true,
    //     moveOnMouseMove: true,
    //     width: '100%',
    //     height: '20px',
    //     // bottom: 10,
    //     // left: 10,
    //     start: 0,
    //     end: 75,
    //     handleSize: 20,
    //     moveHandleSize: 20,
    //   },
    // ],

    series: [
      {
        type: 'bar',
        data: transformedDatas.publications,
        itemStyle: { ...itemStyle, color: '#009EE0' },
        barWidth: 4,
        barGap: '160%',
        large: true,
      },
      {
        type: 'bar',
        data: transformedDatas.notifications,
        itemStyle: { ...itemStyle, color: '#CE0E2D' },
        barWidth: 4,
        barGap: '160%',
        barCategoryGap: '10px',
        large: true,
      },
    ],
  }
}

type GraphProps = {
  datas: TemporaryStatSummary['graph']
}

function Graph(props: GraphProps) {
  const { datas } = props
  const options = getOptions(datas)
  return (
    <ReactEcharts
      option={options}
      lazyUpdate={true}
      style={{
        width: '100%',
        height: '500px',
        borderRadius: '20px',
      }}
    />
  )
}
