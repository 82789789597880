/* eslint-disable import/export */
export * from 'instantsearch.js'
export type { SearchClient as AlgoliaSearchClient } from 'instantsearch.js'
export * from 'react-instantsearch-hooks-web'
export { Hits, SortBy } from 'react-instantsearch-hooks-web'
export { InstantSearch } from './InstantSearch'
export { searchClient } from './searchClient'
export { useSearchClient, SearchClientProvider } from './Provider'

export * from 'typesense'
export type { SearchClient } from 'typesense'

export * from './hooks'

// Copied from typesense package cause it's not exported
export type SearchParams = {
  q: string
  query_by: string
  query_by_weights?: string
  prefix?: string | boolean
  filter_by?: string
  sort_by?: string
  facet_by?: string
  max_facet_values?: number
  facet_query?: string
  page?: number
  per_page?: number
  group_by?: string
  group_limit?: number
  include_fields?: string
  exclude_fields?: string
  highlight_fields?: string
  highlight_full_fields?: string
  highlight_affix_num_tokens?: number
  highlight_start_tag?: string
  highlight_end_tag?: string
  snippet_threshold?: number
  num_typos?: string | number
  min_len_1typo?: number
  min_len_2typo?: number
  exhaustive_search?: boolean
  drop_tokens_threshold?: number
  typo_tokens_threshold?: number
  pinned_hits?: string
  hidden_hits?: string
  limit_hits?: number
  pre_segmented_query?: boolean
  enable_overrides?: boolean
  prioritize_exact_match?: boolean
  search_cutoff_ms?: number
  use_cache?: boolean
}

export type SearchResponse<T extends Record<string, any>> = {
  facet_counts?: SearchResponseFacetCountSchema<T>[]
  found: number
  out_of: number
  page: number
  request_params: SearchParams
  search_time_ms: number
  hits?: SearchResponseHit<T>[]
  grouped_hits?: {
    group_key: string[]
    hits: SearchResponseHit<T>[]
  }[]
}

export type SearchResponseFacetCountSchema<T extends Record<string, any>> = {
  counts: {
    count: number
    highlighted: string
    value: string
  }[]
  field_name: keyof T
  stats: {
    avg?: number
    max?: number
    min?: number
    sum?: number
  }
}

export type SearchResponseHit<T extends Record<string, any>> = {
  highlights?: [
    {
      field: keyof T
      snippet?: string
      value?: string
      snippets?: string[]
      indices?: string[]
      matched_tokens: string[]
    },
  ]
  document: T
  text_match: number
}
