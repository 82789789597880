import { createContextFunction } from '@aubade/core/libs'
import env from '@beam-australia/react-env'

export type IEnv = ReturnType<typeof Env>

export function Env() {
  function resolve<T = string>(
    name: string,
    getDefaultValue: () => T = () => isMissing(name),
  ): string | T {
    return env(name) ?? getDefaultValue()
  }

  const APP_ENV = validateAppEnv(resolve('APP_ENV', () => 'production'))

  return {
    APP_ENV: APP_ENV,
    IS_DEV: APP_ENV === 'development',

    BASE_PATH: removeTrailingSlash(resolve('BASE_PATH')),
    API_URL: formatUrl(resolve('API_URL')),
    KEYCLOAK_CONFIG: {
      url: resolve('KEYCLOAK_CONFIG_URL'),
      realm: resolve('KEYCLOAK_CONFIG_REALM'),
    },
    S3_URL: formatUrl(resolve('S3_URL')),
    RESIZE_URL: formatUrl(resolve('RESIZE_URL')),
    FIREBASE_CONFIG: {
      apiKey: resolve('FIREBASE_CONFIG_API_KEY'),
      authDomain: resolve('FIREBASE_CONFIG_AUTH_DOMAIN'),
      projectId: resolve('FIREBASE_CONFIG_PROJECT_ID'),
      storageBucket: resolve('FIREBASE_CONFIG_STORAGE_BUCKET'),
      messagingSenderId: resolve('FIREBASE_CONFIG_MESSAGING_SENDER_ID'),
      appId: resolve('FIREBASE_CONFIG_MESSAGING_APP_ID'),
    },
    FIREBASE_VAPID_KEY: resolve('FIREBASE_CONFIG_VAPID_KEY'),
    SENTRY_DSN: resolve('SENTRY_DSN', () => undefined),
  } as const
}

const [EnvProvider, useEnv] = createContextFunction<IEnv>('env')
export { useEnv, EnvProvider }

function isMissing(name: string): never {
  throw new Error(`Missing env ${name}`)
}

function removeTrailingSlash(path: string) {
  return path.replace(/(\/)+$/, '')
}

function formatUrl(url: string) {
  if (url.startsWith('/')) {
    url = `${window.location.protocol}//${window.location.hostname}${url}`
  }
  return removeTrailingSlash(url)
}

function validateAppEnv(appEnv: string) {
  const validValues = ['development', 'production', 'integ', 'staging']
  if (!validValues.includes(appEnv)) {
    throw new Error(
      `Invalid value for APP_ENV: ${JSON.stringify(
        appEnv,
      )}. Valid values are: ${validValues.join(', ')}`,
    )
  }

  return appEnv as 'development' | 'production' | 'integ' | 'staging'
}
