import type { LogicalFilter } from '@aubade/core/adapters'
import { useList } from '@aubade/core/adapters'
import { Paragraph, unit } from '@aubade/core/ui-kit'
import { LogoEBat, LogoscanBat } from '@aubade/design/graphics'
import { HStack, Box, VStack, Icon } from '@chakra-ui/react'

import type { TemporaryStatSummary } from '../types'

type Props = {
  crudFilters: LogicalFilter<TemporaryStatSummary>[]
}
export function ClickSection(props: Props) {
  const { crudFilters } = props
  const [stats] = useList<TemporaryStatSummary>({
    dataProviderName: 'default',
    resource: 'statistics',
    filters: crudFilters,
  })
  if (!stats) return <></>
  const { nbEbat, nbScanBat } = stats[0]

  return (
    <HStack width="full" paddingY={unit('10')} gap={unit('10')}>
      <HStack
        width="full"
        justifyContent={'space-around'}
        alignItems={'center'}
        paddingY={unit('40')}
        paddingX={unit('60')}
        borderRadius={'30px'}
        backgroundColor="white"
      >
        <Icon as={LogoEBat} height="26px" width={'88px'} />
        <Box width="1px" height="65px" backgroundColor={'darkGrey.alpha10'} />
        <VStack gap={0} alignItems={'flex-start'}>
          <Paragraph size="3xl" text={String(nbEbat)} fontWeight="bold" />
          <Paragraph size="sm" text={`ebat.click.title`} />
        </VStack>
      </HStack>
      <HStack
        width="full"
        justifyContent={'space-around'}
        alignItems={'center'}
        paddingY={unit('40')}
        paddingX={unit('60')}
        borderRadius={'30px'}
        backgroundColor="white"
      >
        <Icon as={LogoscanBat} height="26px" width={'135px'} />
        <Box width="1px" height="65px" backgroundColor={'darkGrey.alpha10'} />
        <VStack gap={0} alignItems={'flex-start'}>
          <Paragraph size="3xl" text={String(nbScanBat)} fontWeight="bold" />
          <Paragraph size="sm" text={`scanBat.click.title`} />
        </VStack>
      </HStack>
    </HStack>
  )
}
