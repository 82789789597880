import type {
  BaseKey,
  BaseRecord,
  GetOneResponse,
  HttpError,
  LiveModeProps,
  MetaDataQuery,
  SuccessErrorNotification,
  CrudFilters,
  CrudSorting,
  Pagination,
  GetListResponse,
} from '@pankod/refine-core'
import {
  useList as useRefineList,
  useOne as useRefineOne,
  useMany as useRefineMany,
  useCreate as useRefineCreate,
  useCreateMany as useRefineCreateMany,
  useUpdate as useRefineUpdate,
  useUpdateMany as useRefineUpdateMany,
  useDelete as useRefineDelete,
  useDeleteMany as useRefineDeleteMany,
} from '@pankod/refine-core'
import type { UseQueryOptions } from '@tanstack/react-query'

import { cleanHydraId } from '../DataProvider'
import { useQueryMetaData } from '../QueryMetaDataProvider'

type UseListConfig = {
  pagination?: Pagination
  hasPagination?: boolean
  sort?: CrudSorting
  filters?: CrudFilters
}
type UseListProps<TData, TError> = {
  resource: string
  config?: UseListConfig
  queryOptions?: UseQueryOptions<GetListResponse<TData>, TError>
  metaData?: MetaDataQuery
  dataProviderName?: string
} & SuccessErrorNotification &
  LiveModeProps

export function useList<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
>(params: UseListProps<TData, TError>) {
  const queryMetaData = useQueryMetaData()

  return useRefineList<TData, TError>({
    ...params,
    metaData: {
      ...queryMetaData,
      ...params.metaData,
    },
  })
}

type UseOneProps<TData, TError> = {
  resource: string
  id: BaseKey
  queryOptions?: UseQueryOptions<GetOneResponse<TData>, TError>
  metaData?: MetaDataQuery
  dataProviderName?: string
} & SuccessErrorNotification &
  LiveModeProps

export function useOne<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
>(params: UseOneProps<TData, TError>) {
  const queryMetaData = useQueryMetaData()

  return useRefineOne<TData, TError>({
    ...params,
    id: normalizeId(params.id),
    metaData: {
      ...queryMetaData,
      ...params.metaData,
    },
  })
}

export function useMany<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
>(params: any) {
  const queryMetaData = useQueryMetaData()

  // Nice to have: useQueries instead. But will require to build the request ourselves
  return useRefineMany<TData, TError>({
    ...params,
    ids: params.ids.map(normalizeId),
    metaData: {
      ...queryMetaData,
      ...params.metaData,
    },
  })
}

export function useCreate<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables = {},
>() {
  const queryMetaData = useQueryMetaData()

  const create = useRefineCreate<TData, TError, TVariables>()
  return {
    ...create,
    mutate(variables, options) {
      return create.mutate(
        {
          ...variables,
          values: normalizeRecordId(variables.values),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    },
    async mutateAsync(variables, options) {
      return create.mutateAsync(
        {
          ...variables,
          values: normalizeRecordId(variables.values),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    },
  } as typeof create
}

export function useCreateMany<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables = {},
>() {
  const queryMetaData = useQueryMetaData()

  const createMany = useRefineCreateMany<TData, TError, TVariables>()
  return {
    ...createMany,
    mutate(variables, options) {
      return createMany.mutate(
        {
          ...variables,
          values: variables.values.map(normalizeRecordId),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    },
    async mutateAsync(variables, options) {
      return createMany.mutateAsync(
        {
          ...variables,
          values: variables.values.map(normalizeRecordId),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    },
  } as typeof createMany
}

export function useUpdate<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables = {},
>() {
  const queryMetaData = useQueryMetaData()

  const update = useRefineUpdate<TData, TError, TVariables>()
  return {
    ...update,
    mutate(variables, options) {
      return update.mutate(
        {
          ...variables,
          id: normalizeId(variables.id),
          values: normalizeRecordId(variables.values),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    },
    async mutateAsync(variables, options) {
      return update.mutateAsync(
        {
          ...variables,
          id: normalizeId(variables.id),
          values: normalizeRecordId(variables.values),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    },
  } as typeof update
}

export function useUpdateMany<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables = {},
>() {
  const queryMetaData = useQueryMetaData()

  const updateMany = useRefineUpdateMany<TData, TError, TVariables>()
  return {
    ...updateMany,
    mutate(variables, options) {
      return updateMany.mutate(
        {
          ...variables,
          ids: variables.ids.map(normalizeId),
          values: normalizeRecordId(variables.values),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    },
    async mutateAsync(variables, options) {
      return updateMany.mutateAsync(
        {
          ...variables,
          ids: variables.ids.map(normalizeId),
          values: normalizeRecordId(variables.values),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    },
  } as typeof updateMany
}

export function useDelete<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables = {},
>() {
  const queryMetaData = useQueryMetaData()

  const refineDelete = useRefineDelete<TData, TError, TVariables>()
  return {
    ...refineDelete,
    mutate(variables, options) {
      return refineDelete.mutate(
        {
          ...variables,
          id: normalizeId(variables.id),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    },
    async mutateAsync(variables, options) {
      return refineDelete.mutateAsync(
        {
          ...variables,
          id: normalizeId(variables.id),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    },
  } as typeof refineDelete
}

export function useDeleteMany<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables = {},
>() {
  const queryMetaData = useQueryMetaData()

  const refineDeleteMany = useRefineDeleteMany<TData, TError, TVariables>()
  return {
    ...refineDeleteMany,
    mutate(variables, options) {
      return refineDeleteMany.mutate(
        {
          ...variables,
          ids: variables.ids.map(normalizeId),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    },
    async mutateAsync(variables, options) {
      return refineDeleteMany.mutateAsync(
        {
          ...variables,
          ids: variables.ids.map(normalizeId),
          metaData: {
            ...queryMetaData,
            ...variables.metaData,
          },
        },
        options,
      )
    },
  } as typeof refineDeleteMany
}

function normalizeRecordId(variables: any): any {
  const id = (variables as any).id ?? variables['@id']
  return {
    ...variables,
    id: normalizeId(id),
  }
}

function normalizeId(id?: BaseRecord['id']) {
  if (typeof id === 'string') return cleanHydraId(id)
  return id as Required<BaseRecord>['id'] // only for type inference
}
