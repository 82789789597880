import type { LogicalFilter } from '@aubade/core/adapters'
import type { Falsy } from '@aubade/core/libs'
import { useSaveSearch, useSearchParamsState } from '@aubade/core/libs'
import { useCallback, useMemo } from 'react'
import type { DeepPartial } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { isTruthy } from 'remeda'

import type { Filters } from './types'

import { toCrudFilters as defaultToCrudFilters } from '.'

export type filtersStore<T> = {
  state: DeepPartial<T>
  setState: (newFilters: DeepPartial<T>) => void
}

type UseFiltersSearchParamsProps<
  T extends Filters,
  Resource extends Record<string, any>,
> = {
  scope: string
  defaultFilters?: DeepPartial<T>
  toCrudFilters?: (
    filtersState: DeepPartial<T>,
  ) => (LogicalFilter<Resource> | Falsy)[]
}

export type UseFiltersSearchParamsReturn<
  T extends Filters,
  Resource extends Record<string, any>,
> = {
  filtersStore: filtersStore<T>
  crudFilters: LogicalFilter<Resource>[]
}

export function useFiltersSearchParams<
  T extends Filters,
  Resource extends Record<string, any> = Record<string, any>,
>(
  props: UseFiltersSearchParamsProps<T, Resource>,
): UseFiltersSearchParamsReturn<T, Resource> {
  const { defaultFilters, toCrudFilters = defaultToCrudFilters, scope } = props

  const [searchParams] = useSearchParams()
  useSaveSearch(searchParams)
  const filterScope = `${scope}.filters`
  const [searchParamsState, setSearchParamsState] = useSearchParamsState({
    scope: filterScope,
    defaultState: defaultFilters,
  })

  const onFilterStateChange = useCallback(
    (newFilters: DeepPartial<T>) => {
      setSearchParamsState(newFilters, (newSearchParams) => {
        return {
          ...newSearchParams,
        }
      })
    },
    [setSearchParamsState],
  )
  const crudFilters = useMemo(() => {
    return toCrudFilters(searchParamsState).filter(isTruthy)
  }, [searchParamsState, toCrudFilters])

  return useMemo(() => {
    return {
      filtersStore: {
        state: searchParamsState,
        setState: onFilterStateChange,
      },
      crudFilters,
    }
  }, [searchParamsState, onFilterStateChange, crudFilters])
}
