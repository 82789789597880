import { createContextFunction } from '@aubade/core/libs'
import type { PropsWithChildren } from 'react'
import type { DeepPartial } from 'react-hook-form'
import { mapValues } from 'remeda'
import { useDeepCompareMemo } from 'use-deep-compare'

import type { S3Config } from './Inputs/DropZoneInput/DropZoneInput'

export type IDesignSystemConfig = {
  s3: S3Config
  map: MapConfig
}

type MapConfig = {
  maptilerKey: string
}

const [Provider, useDesignSystemConfig] =
  createContextFunction<IDesignSystemConfig>(
    'Design system config',
    function () {
      return {
        s3: { url: '', resizeUrl: '' },
        map: { maptilerKey: '' },
      }
    },
  )

function DesignSystemConfig(
  props: PropsWithChildren<DeepPartial<IDesignSystemConfig>>,
) {
  const { children, ...value } = props

  const previous = useDesignSystemConfig()
  const merged = mapValues(previous, (previousConfig, configName) => {
    const partialNewConfig = value[configName] ?? {}
    return { ...previousConfig, ...partialNewConfig }
  }) as IDesignSystemConfig
  const memoValue = useDeepCompareMemo(() => merged, [merged])
  return <Provider value={memoValue}>{children}</Provider>
}

export { DesignSystemConfig, useDesignSystemConfig }
