import { useQueryMetaData } from '@nartex/api-platform'
import { useDataProvider } from '@pankod/refine-core'
import type { UseQueryOptions } from '@tanstack/react-query'
import { useQueries, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { isTruthy } from 'remeda'

import { useNxDataProviders } from '..'
import type {
  BaseRecord,
  QueryMetaData,
  GetListResponse,
  GetOneResponse,
} from '../types'

import { QueryBuilder } from './QueryBuilder'
import type {
  GetListQueryParams,
  GetManyQueryParams,
  GetOneQueryParams,
} from './types'

export * from './types'

export function useQueryBuilder(): QueryBuilder {
  const metaData = useQueryMetaData() as QueryMetaData | undefined

  const nxDataProviders = useNxDataProviders()
  const getRefineDataProvider = useDataProvider()

  return useMemo(() => {
    return new QueryBuilder(
      {
        // @ts-expect-error
        default() {
          return nxDataProviders?.default ?? getRefineDataProvider('default')
        },
        // @ts-expect-error
        typesense() {
          return (
            nxDataProviders?.typesense ?? getRefineDataProvider('typesense')
          )
        },
      },
      metaData,
    )
  }, [getRefineDataProvider, metaData, nxDataProviders])
}

export function useList<T extends BaseRecord>(
  params: GetListQueryParams<T>,
  queryOptions?: UseQueryOptions<
    GetListResponse<T>,
    unknown,
    GetListResponse<T>
  >,
) {
  const queryBuilder = useQueryBuilder()

  // eslint-disable-next-line @tanstack/query/prefer-query-object-syntax
  const queryResult = useQuery({
    ...queryBuilder.getList(params),
    ...queryOptions,
  } as UseQueryOptions<GetListResponse<T>, unknown, GetListResponse<T>>)

  return [queryResult.data?.data, queryResult] as const
}

export function useOne<T extends BaseRecord>(
  params: GetOneQueryParams<T>,
  queryOptions?: UseQueryOptions<GetOneResponse<T>, unknown, GetOneResponse<T>>,
) {
  const queryBuilder = useQueryBuilder()

  // eslint-disable-next-line @tanstack/query/prefer-query-object-syntax
  const queryResult = useQuery({
    ...queryBuilder.getOne(params),
    ...queryOptions,
  } as UseQueryOptions<GetOneResponse<T>, unknown, GetOneResponse<T>>)

  return [queryResult.data?.data, queryResult] as const
}

export function useMany<T extends BaseRecord>(
  params: GetManyQueryParams<T>,
  queryOptions?: UseQueryOptions<GetOneResponse<T>, unknown, GetOneResponse<T>>,
) {
  const queryBuilder = useQueryBuilder()

  const queryResults = useQueries({
    queries: queryBuilder.getMany<T, unknown>(params).queries.map((query) => {
      return { ...query, ...queryOptions } as UseQueryOptions<
        GetOneResponse<T>,
        unknown,
        GetOneResponse<T>
      >
    }),
  })

  const items = useMemo(() => {
    return queryResults.map((query) => query.data?.data).filter(isTruthy)
  }, [queryResults])

  return [items, queryResults] as const
}
