import { createContextFunction } from '@aubade/core/libs'

import type { AxiosInstance } from 'axios'
import axios from 'axios'

const DEV_HOSTNAME = 'aubade.test' // no need for an env variable since this is for a DEV only patch
const DEV_BASE_URL = `https://${DEV_HOSTNAME}`

type Params = {
  baseURL: string
  getAuthToken: () => string | null
}

export function HTTPClient(params: Params) {
  const { baseURL, getAuthToken } = params
  const httpClient = axios.create({
    baseURL,
    withCredentials: true,
  })

  const authToken = getAuthToken()

  if (authToken && authToken !== null) {
    httpClient.interceptors.request.use((requestConfig) => {
      const { url } = requestConfig
      const isMinioUrl = Boolean(url?.includes('minio'))

      if (isMinioUrl) {
        return {
          ...requestConfig,
          headers: {
            ...requestConfig.headers,
          },
        }
      }

      return {
        ...requestConfig,
        headers: {
          ...requestConfig.headers,
          Authorization: `Bearer ${authToken}`,
        },
      }
    })
  }

  // For embedded dev purpose only. Allows the app to work outside of the dev machine, with ngrok
  if (
    process.env.NODE_ENV === 'development' &&
    window.location.hostname !== DEV_HOSTNAME
  ) {
    /*
     * This interceptor transforms the urls starting with ${DEV_BASE_URL} to the current hostname.
     * This is usefull with ngrok because kratos hardcodes the hostname inside its actions handlers.
     * Ex:
     * `GET https://aubade.test/kratos` -> `GET https://nx-aubade.eu.ngrok.io/kratos`
     */
    httpClient.interceptors.request.use((requestConfig) => {
      if (!requestConfig.url?.startsWith(DEV_BASE_URL)) return requestConfig

      return {
        ...requestConfig,
        url: requestConfig.url.replace(
          DEV_BASE_URL,
          `${window.location.protocol}//${window.location.hostname}`,
        ),
      }
    })
  }

  return httpClient
}

export const [HTTPClientProvider, useHTTPClient] =
  createContextFunction<AxiosInstance>('HTTP Client')
