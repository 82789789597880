import type { DataProvider } from '@aubade/core/adapters'
import type { ExtendedUser } from '@aubade/types'
import { cleanHydraId } from '@nartex/api-platform'

export function UsersProxy(dataProvider: DataProvider): Partial<DataProvider> {
  return {
    // @ts-ignore
    async getOne(params) {
      const { data: user } = await dataProvider.getOne<ExtendedUser>(params)
      const {
        agency,
        society,
        craftCompany,
        societyAgency,
        isAvailable,
        avatar,
      } = user
      let fullCraftCompany
      let fullSociety
      let fullAgency
      let fullSocietyAgency
      let fullAvatar

      if (avatar) {
        const { data: fullDocumentData } = await dataProvider.getOne({
          ...params,
          resource: 'documents',
          id: cleanHydraId(avatar!),
        })
        fullAvatar = fullDocumentData
      }
      if (agency) {
        const { data } = await dataProvider.getOne({
          ...params,
          resource: 'agencies',
          id: agency,
        })
        fullAgency = data
      }
      if (society) {
        const { data } = await dataProvider.getOne({
          ...params,
          resource: 'societies',
          id: society,
        })
        fullSociety = data
      }
      if (craftCompany) {
        const { data } = await dataProvider.getOne({
          ...params,
          resource: 'craft_companies',
          id: cleanHydraId(craftCompany),
        })
        fullCraftCompany = data
        const { society: craftSociety, agency: craftAgency } = fullCraftCompany
        const { data: craftSocietyData } = await dataProvider.getOne({
          ...params,
          resource: 'societies',
          id: cleanHydraId(craftSociety),
        })
        fullSociety = craftSocietyData

        const { data: craftAgencyData } = await dataProvider.getOne({
          ...params,
          resource: 'agencies',
          id: cleanHydraId(craftAgency),
        })
        fullAgency = craftAgencyData
      }
      if (societyAgency) {
        const { data } = await dataProvider.getOne({
          ...params,
          resource: 'societies',
          id: societyAgency,
        })
        fullSocietyAgency = data
      }

      return {
        data: {
          ...user,
          avatar: [fullAvatar],
          isNotAvailable: !isAvailable,
          agency: fullAgency,
          society: fullSociety,
          craftCompany: fullCraftCompany,
          societyAgency: fullSocietyAgency,
        },
      }
    },
    async update(params) {
      const variables = params.variables as ExtendedUser
      const { avatar, job, isNotAvailable } = variables

      return dataProvider.update({
        ...params,
        variables: {
          ...variables,
          isAvailable: !isNotAvailable,
          avatar,
          job,
        },
      })
    },
  }
}
