import { calc, Flex, HStack } from '@chakra-ui/react'

import type { PropsWithChildren } from 'react'

import { Suspense } from 'react'

export const Padding = '10px'

export function ResourcePageLayout(props: PropsWithChildren<{}>) {
  const { children } = props
  return (
    <Flex as="article" min-width="full" direction="column" gap={8} margin={0}>
      <Suspense fallback={<></>}>
        <HStack
          alignItems="flex-start"
          paddingY={Padding}
          gap={Padding}
          height={calc.subtract('100vh', Padding)}
        >
          {children}
        </HStack>
      </Suspense>
    </Flex>
  )
}

// Old suspense Fallback: <Skeleton />
