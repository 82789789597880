import { unit, SearchBar } from '@aubade/core/ui-kit'
import { Box, HStack, VStack } from '@chakra-ui/react'
import type { ReactNode } from 'react'

type Props = {
  mainAction?: ReactNode
  filters?: ReactNode
  variant?: 'feed' | 'default'
  scope: string
}

export function ListHeader(props: Props) {
  const { mainAction, filters, variant = 'default', scope } = props

  const paddingX = variant === 'feed' ? unit('10') : unit('60')
  return (
    <VStack width={'full'} gap={unit('40')} paddingX={paddingX}>
      <HStack width={'full'} gap={5}>
        <Box flex={1}>
          <SearchBar scope={scope} variant="search" />
        </Box>
        {mainAction}
      </HStack>
      {filters}
    </VStack>
  )
}
