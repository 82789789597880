import { selectAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

import { basefieldStyle } from './input'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys)
const baseStyle = definePartsStyle({
  field: {
    ...basefieldStyle(),
    fontSize: 'sm',
  },
  icon: {
    color: 'darkGrey.500',
    width: 6,
    height: 6,
  },
})
const light = definePartsStyle({
  field: {
    paddingLeft: '0px!important',
    // paddingRight: 6,
    minHeight: 'fit-content',
    width: 'full',
    paddingY: 0,
    background: 'transparent',
    fontSize: 'md',
    overflow: 'hidden',
    _hover: {
      outline: '1px solid',
      outlineColor: 'transparent',
      outlineOffset: 0,
    },
  },
  icon: {
    color: 'black',
    width: 2,
    height: 2,
  },
})

export const selectTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { light },
})
