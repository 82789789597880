import { useOne } from '@aubade/core/adapters'
import type { User } from '@aubade/types/api'
import { toHydraId } from '@nartex/api-platform'
import jwt_decode from 'jwt-decode'

import { isInvalidToken } from '../../../../apps/bo/src/components/Auth'

export type ConnectedUser = {
  id: string
  role:
    | 'ROLE_SUPER_ADMIN' // aubade
    | 'ROLE_APP_CRAFTER_ADMIN' // aubade
    | 'ROLE_APP_CRAFTER_COMPANY' // society
    | 'ROLE_APP_CRAFTER_STORE' // agency
  society?: string
}

export enum UserRole {
  'ROLE_SUPER_ADMIN' = 'ROLE_SUPER_ADMIN',
  'ROLE_APP_CRAFTER_ADMIN' = 'ROLE_APP_CRAFTER_ADMIN',
  'ROLE_APP_CRAFTER_COMPANY' = 'ROLE_APP_CRAFTER_COMPANY',
  'ROLE_APP_CRAFTER_STORE' = 'ROLE_APP_CRAFTER_STORE',
}

export type ApiToken = {
  iat: number
  exp: number
  roles: ConnectedUser['role'][]
  id: string
}

export function useGetUser() {
  const apiToken = sessionStorage.getItem('jwt')
  const [user] = useOne<User.Read>(
    {
      iri: toHydraId(
        'users',
        apiToken ? jwt_decode<ApiToken>(apiToken!).id : '',
      ),
    },
    { enabled: Boolean(apiToken) },
  )

  if (!apiToken || isInvalidToken(apiToken)) {
    return { id: undefined, role: undefined }
  }

  const adminRole = jwt_decode<ApiToken>(apiToken).roles.find(
    (role) => role === 'ROLE_SUPER_ADMIN' || role === 'ROLE_APP_CRAFTER_ADMIN',
  )
  const userRole = jwt_decode<ApiToken>(apiToken).roles.find((role) =>
    role.startsWith('ROLE_APP'),
  )

  return {
    id: user?.id,
    role: (adminRole || userRole) as ConnectedUser['role'],
    society: user?.society,
    agency: user?.agency,
  }
}

export function isAdmin(role?: ConnectedUser['role']) {
  return role === 'ROLE_SUPER_ADMIN' || role === 'ROLE_APP_CRAFTER_ADMIN'
}
export function isSociety(role?: ConnectedUser['role']) {
  return role === 'ROLE_APP_CRAFTER_COMPANY'
}
export function isAgency(role?: ConnectedUser['role']) {
  return role === 'ROLE_APP_CRAFTER_STORE'
}
