import { modalAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

import { surfaceStyle } from '../../../core/src/ui-kit/Surface'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys)

const baseStyle = definePartsStyle({
  overlay: {
    backgroundColor: 'overlay.base',
  },
  dialog: { ...surfaceStyle(), maxWidth: '1024px' },
})

export const modalTheme = defineMultiStyleConfig({ baseStyle })
