import { useHTTPClient, useOne } from '@aubade/core/adapters'
import { useToastMessage } from '@aubade/core/ui-kit'
import { isAgency, useGetUser } from '@aubade/domain/components'
import type {
  ExtendedConversation,
  ExtendedUserConversation,
} from '@aubade/types'
import type { BlackList } from '@aubade/types/api'
import type { ExtendedOneConversation } from '@aubade/types/index'
import {
  useDelete,
  useQueryMetaData,
  cleanHydraId,
  toHydraId,
} from '@nartex/api-platform'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

export function useActions(
  id: string,
  users: ExtendedUserConversation[],
  blackList: ExtendedOneConversation['blackList'],
) {
  const httpClient = useHTTPClient()
  const metaData = useQueryMetaData()
  const [searchParams, setSearchParams] = useSearchParams()

  const toast = useToastMessage()
  const queryClient = useQueryClient()
  const { id: me, role } = useGetUser()

  const { mutate: deleteConversation } = useMutation({
    mutationFn(): Promise<any> {
      return httpClient.request({
        method: 'put',
        url: `/conversations/leave/${id}/users/${me}`,
        data: {},
        headers: metaData?.headers,
      })
    },

    async onSuccess() {
      await queryClient.invalidateQueries({
        queryKey: ['getOne', 'conversations'],
        refetchType: 'all',
      })
      await queryClient.invalidateQueries()
      toast('success', 'notifications.deleteConversation')
      searchParams.delete('id')
      setSearchParams(searchParams)
    },
    onError(error: Error) {
      toast('error', error.message)
    },
  })

  const otherUsers = useMemo(
    () => users.filter((user) => cleanHydraId(user.id) !== cleanHydraId(me)),
    [me, users],
  )

  const canBlock = Boolean(
    isAgency(role) &&
      otherUsers.length === 1 &&
      (otherUsers[0].craftCompany || otherUsers[0].craftCompanyId),
  )
  const craft = users.find((user) => user.craftCompany)

  const [fullBlackList] = useOne<BlackList.Read>(
    // @ts-expect-error
    { iri: toHydraId('black_lists', blackList?.id! ?? '') },
    { enabled: Boolean(blackList?.id) },
  )

  const isBlocked = useMemo(() => {
    if (
      craft &&
      craft.craftCompany &&
      typeof craft.craftCompany === 'object' &&
      fullBlackList
    ) {
      if (
        fullBlackList.craftCompany ===
        toHydraId('craft_companies', craft.craftCompany.id)
      ) {
        return true
      }
    }
    if (craft && craft.craftCompanyId && fullBlackList) {
      if (
        fullBlackList.craftCompany ===
        toHydraId('craft_companies', craft.craftCompanyId)
      ) {
        return true
      }
    }

    return false
  }, [craft, fullBlackList])

  const { mutate: blockCrafter } = useMutation({
    mutationFn(): Promise<ExtendedConversation> {
      return httpClient.request({
        method: 'post',
        url: `/black_lists`,
        data: {
          newId: uuid(),
          craftCompany: craft?.craftCompany?.['@id'] ?? craft?.craftCompany,
        },
        headers: metaData?.headers,
      })
    },

    async onSuccess() {
      await queryClient.invalidateQueries()
      toast('success', 'notifications.blockCrafter')
    },
    onError(error: Error) {
      toast('error', error.message)
    },
  })

  const { mutate: unblockCrafter } = useDelete()

  function toggleBlackList() {
    if (isBlocked === true) {
      unblockCrafter(
        {
          resource: 'black_lists',
          id: blackList!.id!,
        },
        {
          async onSuccess() {
            await queryClient.invalidateQueries()
            toast('success', 'notifications.unblockCrafter')
          },
        },
      )
    } else {
      blockCrafter()
    }
  }

  return {
    deleteConversation,
    toggleBlackList,
    canBlock,
    isBlocked,
  }
}
