import type { RHFInputProps } from '../controller'
import { useRHFInput } from '../controller'
import type { TextInputProps } from '../TextInput/TextInput'

import { ColorInput } from './ColorInput'

export const RHFColorInput = function <T, Outer = string>(
  props: RHFInputProps<T, Outer, string, HTMLInputElement> &
    Omit<TextInputProps, 'onKeyDown'>,
) {
  const innerProps = useRHFInput<T, Outer, string, string, HTMLInputElement>(
    props,
  )

  return <ColorInput {...innerProps} />
}
