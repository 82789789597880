// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const LogoPro = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={100}
    height={100}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.5766 45.9759H7.16436L8.90663 40.8217L10.5766 45.9759ZM0 52.1656H4.9618L5.83293 49.6534H11.8599L12.6828 52.1656H18.8302L11.8599 33.4149H7.23791L0 52.1656Z"
      fill="#004080"
    />
    <path
      d="M33.9098 37.9981V52.1644H28.3432V50.1699C27.7142 51.025 26.5755 52.5264 24.2766 52.5264C22.6307 52.5264 21.3233 51.8011 20.5739 50.7396C19.7751 49.5743 19.751 48.2014 19.751 46.7519V37.9981H25.2454V46.6753C25.2454 47.1931 25.2454 48.7983 26.7226 48.7983C27.5215 48.7983 28.0059 48.2286 28.3444 47.8146V37.9993H33.9111L33.9098 37.9981Z"
      fill="#004080"
    />
    <path
      d="M41.9688 41.8573C42.4532 41.5471 42.864 41.2617 43.5171 41.2617C44.7521 41.2617 45.7919 42.5567 45.7919 44.9651C45.7919 46.9076 45.1148 48.5907 43.5652 48.5907C42.8146 48.5907 42.3302 48.2533 41.9675 47.9951V41.8573H41.9688ZM36.4262 52.1656H41.9688V50.249C42.7423 51.3105 43.6388 52.5549 45.816 52.5549C48.7933 52.5549 51.5766 49.4989 51.5766 44.8625C51.5766 41.625 49.9789 37.4037 45.6955 37.4037C43.493 37.4037 42.4519 38.7754 41.9688 39.4229V33.4149H36.4262V52.1656Z"
      fill="#004080"
    />
    <path
      d="M60.9671 48.1767C60.5081 48.6957 60.0478 49.1616 59.249 49.1616C58.3537 49.1616 57.8452 48.4102 57.8452 47.633C57.8452 46.9336 58.2815 45.8968 59.7574 45.8968C60.2177 45.8968 60.5322 45.9747 60.9671 46.0785V48.1755V48.1767ZM66.5338 43.5415C66.5338 41.987 66.5097 39.864 64.3071 38.3614C63.8709 38.0512 62.6371 37.2221 59.9996 37.2221C56.0789 37.2221 54.3594 39.1127 53.1738 40.5363L54.8197 42.0129C55.5222 41.2616 56.5138 40.2001 58.2079 40.2001C58.5947 40.2001 59.3212 40.2261 59.9996 40.8217C60.943 41.6509 60.9671 42.8162 60.9671 43.6441C60.2418 43.3846 59.2008 43.0225 57.7489 43.0225C54.8451 43.0225 52.4967 44.7834 52.4967 47.9172C52.4967 50.4554 54.0703 52.5783 56.8777 52.5783C59.2984 52.5783 60.29 51.2314 60.9684 50.2737V52.1644H66.535V43.5403L66.5338 43.5415Z"
      fill="#004080"
    />
    <path
      d="M77.7663 48.047C77.2819 48.3324 76.8711 48.5907 76.1927 48.5907C74.6444 48.5907 73.8938 47.0374 73.8938 45.0429C73.8938 42.532 74.9348 41.2098 76.2421 41.2098C76.9205 41.2098 77.3314 41.494 77.7676 41.8054V48.047H77.7663ZM77.7663 39.371C77.1602 38.5678 76.218 37.3247 73.8697 37.3247C70.0935 37.3247 68.1344 41.0034 68.1344 45.1208C68.1344 49.2382 70.0947 52.5796 73.7733 52.5796C76.2421 52.5796 77.2337 51.1029 77.7663 50.3009V52.1656H83.333V33.4149H77.7663V39.371Z"
      fill="#004080"
    />
    <path
      d="M90.6403 43.308C90.7608 42.3231 91.003 40.4584 92.6235 40.4584C94.3899 40.4584 94.4875 42.4269 94.5357 43.308H90.639H90.6403ZM96.4441 48.8218C95.8823 49.1653 95.1925 49.4198 94.343 49.4198C93.4934 49.4198 92.7947 49.2394 92.0922 48.6957C91.1234 47.9703 90.9548 47.0646 90.8331 46.2354H99.8614C99.8614 44.3954 99.8614 40.5894 96.666 38.4665C95.7213 37.8189 94.3417 37.2752 92.5271 37.2752C88.1702 37.2752 85.2905 40.4868 85.2905 44.9676C85.2905 49.4483 88.122 52.5808 92.8175 52.5808C95.3587 52.5808 97.0565 51.4946 98.1052 50.4653"
      fill="#004080"
    />
    <path
      d="M17.3911 33.5015V34.2058L16.0596 34.2083V34.7001L17.1641 34.6989V35.3365L16.0622 35.3378V35.9272L17.5813 35.9247V36.6291L14.8271 36.6328L14.822 33.5064L17.3911 33.5015Z"
      fill="#004080"
    />
    <path
      d="M18.3184 35.67C18.4769 35.8566 18.6823 36.1038 19.1502 36.1025C19.49 36.1025 19.6891 35.9641 19.6891 35.7788C19.6891 35.5798 19.4926 35.518 19.4013 35.4909C19.296 35.46 18.7749 35.3599 18.6607 35.324C18.5073 35.2796 17.924 35.1127 17.9227 34.4664C17.9227 33.9969 18.3082 33.4247 19.3683 33.4235C20.3333 33.421 20.611 33.793 20.8126 34.0723L20.3358 34.3565C20.2204 34.2193 20.0632 34.0204 19.6523 34.0204C19.3036 34.0204 19.1515 34.1723 19.1515 34.3404C19.1515 34.464 19.2275 34.5221 19.3087 34.5579C19.4228 34.6098 20.0543 34.7334 20.1925 34.7778C20.3599 34.826 20.9242 34.9978 20.9254 35.6181C20.9254 36.2471 20.2914 36.7093 19.3315 36.7105C18.2956 36.7117 17.9697 36.1631 17.8441 35.9468L18.3171 35.6713L18.3184 35.67Z"
      fill="#004080"
    />
    <path
      d="M22.6341 34.1341C22.8915 34.1379 23.0158 34.1465 23.121 34.186C23.3366 34.2614 23.3797 34.4344 23.3797 34.558C23.3797 34.7347 23.3036 34.8904 23.1413 34.9707C22.998 35.0461 22.8027 35.051 22.6354 35.051V34.1341H22.6341ZM21.4016 36.6204L22.6392 36.6179L22.6366 35.4625C23.282 35.4613 23.7018 35.4613 24.0644 35.292C24.4562 35.1141 24.6033 34.8002 24.6021 34.4591C24.6021 34.3257 24.5868 33.9945 24.2812 33.7474C23.9706 33.4866 23.5927 33.4866 23.3061 33.4879L21.3952 33.4916L21.4003 36.6229L21.4016 36.6204Z"
      fill="#004080"
    />
    <path
      d="M26.2295 35.554H25.5562L25.8986 34.6729L26.2295 35.554ZM24.1436 36.6155H25.1238L25.295 36.1842L26.4844 36.1817L26.648 36.6105L27.8615 36.6081L26.4793 33.4842H25.5676L24.1449 36.6155H24.1436Z"
      fill="#004080"
    />
    <path
      d="M31.2322 35.9876C31.075 36.1779 30.6502 36.6882 29.6281 36.6907C28.4337 36.692 27.8402 35.8875 27.839 35.0954C27.8377 34.3515 28.4096 33.4025 29.7372 33.4C30.6058 33.3988 30.9849 33.8189 31.2144 34.0698L30.7516 34.4034C30.6121 34.244 30.4257 34.0413 30.0301 34.0413C29.3606 34.0413 29.1146 34.5789 29.1146 35.0262C29.1159 35.6329 29.5229 35.9777 30.0098 35.9765C30.4346 35.9765 30.7161 35.7231 30.8302 35.6255L31.2322 35.9876Z"
      fill="#004080"
    />
    <path
      d="M34.226 33.4718V34.1761L32.8945 34.1786V34.6704L33.999 34.668V35.3056L32.8971 35.3068V35.8963L34.4174 35.8938V36.5982L31.6633 36.6031L31.6582 33.4767L34.2285 33.4718H34.226Z"
      fill="#004080"
    />
    <path
      d="M77.8809 60.3843C77.8809 62.5567 76.1982 63.9086 73.5214 63.9086H71.8108V66.4084H69.0351V56.8452H73.5214C76.1982 56.8452 77.8809 58.1983 77.8809 60.3843ZM75.0772 60.3843C75.0772 59.4958 74.5028 58.9768 73.3527 58.9768H71.8108V61.7782H73.3527C74.5028 61.7782 75.0772 61.2592 75.0772 60.3843Z"
      fill="#009DE2"
    />
    <path
      d="M83.5138 63.8678H81.9998V66.4084H79.2241V56.8452H83.7104C86.3872 56.8452 88.0699 58.1983 88.0699 60.3843C88.0699 61.7906 87.3686 62.8298 86.1488 63.4032L88.2651 66.4084H85.2929L83.5126 63.8678H83.5138ZM83.5417 58.9768H81.9998V61.7782H83.5417C84.6918 61.7782 85.2662 61.2592 85.2662 60.3843C85.2662 59.5094 84.6918 58.9768 83.5417 58.9768Z"
      fill="#009DE2"
    />
    <path
      d="M89.095 61.6275C89.095 58.7581 91.4078 56.655 94.5475 56.655C97.6871 56.655 100 58.7581 100 61.6275C100 64.4968 97.6871 66.6 94.5475 66.6C91.4078 66.6 89.095 64.4968 89.095 61.6275ZM97.1964 61.6275C97.1964 59.9741 96.0336 58.8953 94.5475 58.8953C93.0613 58.8953 91.8973 59.9741 91.8973 61.6275C91.8973 63.2809 93.0613 64.3596 94.5475 64.3596C96.0336 64.3596 97.1964 63.2809 97.1964 61.6275Z"
      fill="#009DE2"
    />
  </svg>
)

const Memo = memo(LogoPro)
export default Memo
