// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const LogoEspaceAubade = (
  props: SVGProps<SVGSVGElement> & { slot?: string },
) => (
  <svg
    width={156}
    height={48}
    viewBox="0 0 156 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4406_8990)">
      <path
        d="M16.4695 19.424H11.156L13.8691 11.4817L16.4695 19.424ZM0 28.9844H7.7471L9.10362 25.1057H18.4636L19.7442 28.9844H29.3199L18.4785 0.0291595H11.2686L0 28.9844Z"
        fill="#004380"
      />
      <path
        d="M52.8012 7.10309V28.9841H44.1303V25.9039C43.4853 26.9837 42.5644 27.8853 41.4556 28.5224C40.3469 29.1596 39.0875 29.5109 37.7981 29.5427C36.6741 29.5787 35.5584 29.3455 34.5502 28.8639C33.542 28.3824 32.6725 27.6673 32.0193 26.7825C30.774 24.9847 30.7387 22.8631 30.7387 20.6196V7.10309H39.3065V20.5015C39.3065 21.3001 39.3065 23.7797 41.6045 23.7797C42.1164 23.7352 42.6108 23.5768 43.0489 23.3171C43.4871 23.0573 43.8572 22.7031 44.1303 22.2822V7.10309H52.8012Z"
        fill="#004380"
      />
      <path
        d="M65.3518 13.0588C66.0338 12.5119 66.879 12.1903 67.7637 12.141C69.6845 12.141 71.3069 14.138 71.3069 17.862C71.3069 20.8569 70.2746 23.4637 67.8369 23.4637C66.9246 23.4419 66.0478 23.1179 65.3531 22.5458L65.3518 13.0588ZM56.7216 28.9841H65.3518V26.0233C66.555 27.661 67.9292 29.5834 71.3422 29.5834C75.9896 29.5834 80.3129 24.8628 80.3129 17.702C80.3129 12.71 77.8236 6.17997 71.1563 6.17997C69.9941 6.17951 68.8511 6.46578 67.8353 7.01166C66.8195 7.55755 65.9647 8.345 65.3518 9.29946V0.0236053H56.7216V28.9841Z"
        fill="#004380"
      />
      <path
        d="M94.9295 22.8238C94.636 23.2584 94.2411 23.6203 93.7764 23.8804C93.3118 24.1405 92.7905 24.2915 92.2544 24.3213C91.9483 24.3117 91.6474 24.2423 91.3699 24.1171C91.0924 23.9919 90.8441 23.8136 90.6399 23.5929C90.4357 23.3723 90.28 23.1139 90.1821 22.8334C90.0842 22.5529 90.0462 22.2561 90.0704 21.961C90.0602 21.5894 90.1317 21.22 90.2804 20.8772C90.4291 20.5343 90.6514 20.226 90.9327 19.9725C91.214 19.7191 91.5478 19.5264 91.9121 19.4071C92.2763 19.2878 92.6625 19.2448 93.0453 19.2808C93.6839 19.2924 94.318 19.3863 94.9309 19.5601L94.9295 22.8238ZM103.6 15.6604C103.6 13.2647 103.56 9.98395 100.13 7.6722C98.1662 6.36139 95.7973 5.73952 93.417 5.90986C91.3429 5.83166 89.2819 6.25992 87.4243 7.1551C85.5667 8.05028 83.9724 9.38351 82.7886 11.0316L85.366 13.3132C85.9733 12.4959 86.762 11.8203 87.6743 11.3357C88.5866 10.851 89.5995 10.5698 90.6388 10.5124C91.6599 10.4744 92.6563 10.8219 93.417 11.4814C93.9824 12.0575 94.4107 12.7461 94.672 13.499C94.9332 14.2519 95.0211 15.0509 94.9295 15.84C93.3364 15.2202 91.6371 14.8951 89.9199 14.8815C85.3959 14.8815 81.7387 17.6024 81.7387 22.4449C81.7387 26.3642 84.1804 29.6437 88.5606 29.6437C89.8579 29.672 91.1382 29.3548 92.2615 28.7269C93.3848 28.099 94.3078 27.1846 94.9295 26.0836V29.0038H103.611L103.6 15.6604Z"
        fill="#004380"
      />
      <path
        d="M121.093 22.6232C120.383 23.1383 119.527 23.4311 118.64 23.4624C116.228 23.4624 115.062 21.0615 115.062 17.9708C115.062 14.0921 116.689 12.0505 118.724 12.0505C119.603 12.0778 120.443 12.4027 121.099 12.9684L121.093 22.6232ZM121.093 9.21947C120.464 8.21802 119.57 7.39704 118.504 6.84146C117.437 6.28587 116.236 6.01586 115.025 6.05934C109.143 6.05934 106.091 11.741 106.091 18.1006C106.091 24.3409 109.143 29.6227 114.87 29.6227C116.141 29.6563 117.395 29.3442 118.491 28.7219C119.587 28.0996 120.48 27.1923 121.068 26.1033V28.988H129.734V0.0236053H121.093V9.21947Z"
        fill="#004380"
      />
      <path
        d="M141.141 15.3024C141.327 13.805 141.704 10.9044 144.239 10.9044C146.991 10.9044 147.142 13.9453 147.223 15.3024H141.141ZM150.179 23.8256C149.201 24.4286 148.065 24.7472 146.905 24.7435C146.211 24.7696 145.519 24.6629 144.869 24.4296C144.218 24.1962 143.622 23.8408 143.114 23.3838C142.606 22.9267 142.197 22.3771 141.91 21.7666C141.623 21.156 141.463 20.4966 141.441 19.8263H155.502C155.502 16.9861 155.502 11.1051 150.529 7.84527C148.633 6.59849 146.383 5.95113 144.09 5.99246C137.307 5.99246 132.821 10.9543 132.821 17.8738C132.821 24.7133 137.231 29.6358 144.54 29.6358C146.071 29.6607 147.591 29.3835 149.007 28.8212C150.423 28.2589 151.706 27.4233 152.777 26.3655"
        fill="#004380"
      />
      <path
        d="M27.083 0.153427V1.24177H25.017V1.99575H26.7398V2.99362H25.0115V3.90232H27.3719V4.99198H23.0907V0.163917L27.083 0.153427Z"
        fill="#004380"
      />
      <path
        d="M28.5235 3.4932C28.6634 3.7017 28.8557 3.87245 29.0826 3.98942C29.3094 4.10639 29.5633 4.16575 29.8203 4.16194C30.3372 4.16194 30.6573 3.9469 30.6573 3.66235C30.6573 3.37781 30.3521 3.25848 30.2137 3.21783C30.0753 3.17719 29.237 3.02377 29.0566 2.99361C28.7293 2.93722 28.433 2.77122 28.2197 2.52473C28.0063 2.27824 27.8896 1.967 27.89 1.64563C27.89 0.916573 28.5099 0.0327829 30.1622 0.0327829C30.5962 -0.0266043 31.0387 0.0358876 31.437 0.212811C31.8353 0.389734 32.1725 0.673626 32.4086 1.03065L31.6652 1.49484C31.5481 1.32882 31.3884 1.19509 31.2017 1.10681C31.015 1.01853 30.8078 0.978725 30.6003 0.991314C30.0835 0.991314 29.8203 1.22603 29.8203 1.4909C29.819 1.56503 29.8425 1.63758 29.8872 1.69781C29.9319 1.75804 29.9955 1.80274 30.0686 1.82528C30.2436 1.90526 31.2311 2.0954 31.4427 2.16489C31.7575 2.2231 32.0423 2.38356 32.2498 2.61966C32.4573 2.85577 32.575 3.15335 32.5836 3.46304C32.5836 4.43206 31.5974 5.14539 30.1052 5.15063C29.6411 5.19959 29.1725 5.11341 28.7594 4.90314C28.3463 4.69287 28.0077 4.3681 27.7869 3.9705L28.5235 3.4932Z"
        fill="#004380"
      />
      <path
        d="M35.2425 1.13295C35.4985 1.11778 35.7554 1.14483 36.0021 1.21293C36.1261 1.25162 36.2334 1.329 36.307 1.43293C36.3807 1.53687 36.4166 1.66149 36.4091 1.78726C36.4156 1.91668 36.3837 2.04519 36.3171 2.15764C36.2505 2.27009 36.1521 2.36179 36.0333 2.42191C35.7838 2.52438 35.513 2.56928 35.2425 2.55304V1.13295ZM33.3216 4.99198H35.2479V3.18507C35.9991 3.25762 36.7575 3.16805 37.4685 2.92282C37.7281 2.81745 37.9477 2.63727 38.0975 2.40684C38.2473 2.17641 38.3199 1.90694 38.3055 1.63516C38.3117 1.42566 38.2683 1.21756 38.1788 1.0267C38.0892 0.835829 37.9558 0.667221 37.7887 0.533699C37.3513 0.223888 36.8098 0.0835546 36.2707 0.140321H33.3162L33.3216 4.99198Z"
        fill="#004380"
      />
      <path
        d="M40.842 3.32797H39.8097L40.3198 1.96033L40.842 3.32797ZM37.5932 4.99065H39.1423L39.4055 4.33502H41.2599L41.5135 4.99852H43.3828L41.2287 0.128494H39.8084L37.5932 4.99065Z"
        fill="#004380"
      />
      <path
        d="M48.6354 3.99279C48.3414 4.3564 47.9599 4.64511 47.5242 4.83387C47.0885 5.02262 46.6118 5.1057 46.1353 5.07589C45.7816 5.1053 45.4255 5.0634 45.0895 4.95284C44.7534 4.84228 44.4448 4.66547 44.1831 4.4336C43.9215 4.20174 43.7124 3.91988 43.5693 3.60587C43.4262 3.29186 43.3521 2.95254 43.3517 2.60941C43.4097 2.04492 43.6385 1.50947 44.0096 1.07001C44.3806 0.630564 44.8775 0.30662 45.4381 0.138696C45.9986 -0.0292281 46.5979 -0.0336787 47.1611 0.125901C47.7242 0.28548 48.2262 0.602006 48.6042 1.03589L47.8866 1.53548C47.758 1.36469 47.5882 1.22681 47.392 1.13381C47.1958 1.04081 46.9791 0.995497 46.7607 1.0018C46.5636 1.00163 46.3686 1.0408 46.1879 1.11687C46.0072 1.19294 45.8448 1.30426 45.7107 1.44386C45.5765 1.58346 45.4737 1.74833 45.4086 1.92813C45.3435 2.10794 45.3175 2.2988 45.3323 2.48877C45.3085 2.67882 45.327 2.87159 45.3866 3.05416C45.4461 3.23673 45.5454 3.40487 45.6778 3.5473C45.8101 3.68974 45.9724 3.80318 46.1538 3.88001C46.3352 3.95685 46.5315 3.9953 46.7295 3.99279C47.222 3.97804 47.6889 3.77754 48.0304 3.43419L48.6354 3.99279Z"
        fill="#004380"
      />
      <path
        d="M53.2977 0.108841V1.19719H51.2318V1.96165H52.9464V2.95952H51.2263V3.85249H53.5921V4.93428H49.3001V0.108841H53.2977Z"
        fill="#004380"
      />
      <path
        d="M155.999 43.1418C137.458 47.7941 117.307 48.7802 97.0199 47.4598C83.0247 46.4317 69.648 44.002 55.6419 42.9661C37.0351 42.283 18.4095 43.704 0.143929 47.2001V31.7941H155.969L155.999 43.1418Z"
        fill="#009FE3"
      />
      <path
        d="M3.70336 38.6035C3.80902 38.8139 3.9544 39.0033 4.13202 39.1621C4.4946 39.4551 4.9566 39.6078 5.42886 39.5909C6.2292 39.5909 6.62124 39.1569 6.62124 38.732C6.62124 38.1931 6.1356 38.0331 5.44378 37.9138C4.75195 37.7945 3.61519 37.5899 3.61519 36.4412C3.61519 35.8866 3.95567 34.8782 5.79512 34.8782C6.33244 34.8535 6.86135 35.0143 7.28729 35.3319C7.52479 35.5518 7.66974 35.8489 7.69425 36.1659L7.36326 36.2406C7.30217 36.0877 7.20672 35.9497 7.08381 35.8367C6.76269 35.5916 6.36127 35.466 5.95247 35.4827C5.3122 35.4827 4.74381 35.7371 4.74381 36.2563C4.74451 36.34 4.76011 36.423 4.78993 36.5015C4.96086 36.8713 5.39359 36.9461 6.00402 37.0562C6.48906 37.1068 6.952 37.279 7.34698 37.5558C7.48941 37.6755 7.60331 37.8237 7.68082 37.9901C7.75834 38.1565 7.79763 38.3371 7.79599 38.5196C7.79033 38.7274 7.74169 38.9321 7.65293 39.1215C7.56416 39.3109 7.43708 39.4812 7.27915 39.6224C6.77618 40.0102 6.14485 40.2086 5.50211 40.181C4.92026 40.2071 4.34815 40.0297 3.89056 39.6814C3.59936 39.4199 3.4158 39.0655 3.37373 38.6835L3.70336 38.6035Z"
        fill="white"
      />
      <path
        d="M11.5575 38.2836H9.90385L10.7178 36.6563L11.5575 38.2836ZM8.00472 40.0511H9.00177L9.5593 38.9379H11.8776L12.4405 40.0459H13.6614L11.084 35.0539H10.6906L8.00472 40.0511Z"
        fill="white"
      />
      <path
        d="M14.1919 35.0395H15.2242L18.0729 37.8797C18.1502 37.9702 18.176 37.999 18.2845 38.1092V35.0395H19.1825V40.1115H18.9031L15.1157 36.3639V40.0538H14.1919V35.0395Z"
        fill="white"
      />
      <path
        d="M20.5392 35.0395H21.6909V40.0354H20.5392V35.0395Z"
        fill="white"
      />
      <path
        d="M22.2077 35.0395H26.706V35.7279H25.0374V40.0551H23.8912V35.7279H22.2077V35.0395Z"
        fill="white"
      />
      <path
        d="M29.4378 38.2836H27.7693L28.5832 36.6563L29.4378 38.2836ZM25.8892 40.0511H26.855L27.4329 38.9379H29.7729L30.3304 40.0459H31.5513L28.9685 35.0539H28.5615L25.8892 40.0511Z"
        fill="white"
      />
      <path
        d="M32.0614 35.0395H33.2077V40.0354H32.0614V35.0395Z"
        fill="white"
      />
      <path
        d="M35.7173 35.7332H36.1867C36.435 35.7211 36.6828 35.766 36.9097 35.8643C37.0401 35.9251 37.1494 36.0211 37.2246 36.1407C37.2997 36.2603 37.3373 36.3984 37.3329 36.5383C37.3239 36.7266 37.2552 36.9076 37.136 37.0568C37.0168 37.206 36.853 37.3162 36.6669 37.3722C36.359 37.461 36.0384 37.5013 35.7173 37.4916V35.7332ZM34.571 40.0472H35.7173V37.8312C35.9357 37.8792 36.1596 37.8995 36.3834 37.8915L37.8253 40.0472H39.1059L37.5255 37.6712C37.8167 37.5601 38.0707 37.3737 38.2594 37.1323C38.4255 36.9103 38.5143 36.6431 38.5131 36.3691C38.5169 36.1544 38.4633 35.9425 38.3575 35.7537C38.2518 35.5648 38.0975 35.4056 37.9094 35.2913C37.5 35.0897 37.0408 35.0017 36.5828 35.0369H34.571V40.0472Z"
        fill="white"
      />
      <path
        d="M39.7664 35.0342H43.3096V35.7227H40.8923V37.11H42.9949V37.7984H40.8923V39.3719H43.6243V40.0511H39.7664V35.0342Z"
        fill="white"
      />
      <path
        d="M47.4308 38.4685C47.2973 38.4813 47.1625 38.467 47.0351 38.4265C46.9076 38.3859 46.7904 38.32 46.6909 38.233C46.5915 38.1459 46.512 38.0397 46.4576 37.9212C46.4031 37.8026 46.375 37.6744 46.375 37.5447C46.375 37.415 46.4031 37.2867 46.4576 37.1682C46.512 37.0496 46.5915 36.9434 46.6909 36.8564C46.7904 36.7694 46.9076 36.7034 47.0351 36.6629C47.1625 36.6223 47.2973 36.608 47.4308 36.6209C47.5613 36.6142 47.6919 36.6328 47.8148 36.6755C47.9378 36.7183 48.0506 36.7843 48.1468 36.8698C48.243 36.9554 48.3205 37.0586 48.3748 37.1735C48.4291 37.2883 48.4592 37.4125 48.4631 37.5388C48.4612 37.6656 48.4326 37.7908 48.379 37.9066C48.3254 38.0225 48.2479 38.1265 48.1513 38.2124C48.0547 38.2983 47.941 38.3643 47.8171 38.4062C47.6932 38.4482 47.5618 38.4654 47.4308 38.4567V38.4685Z"
        fill="white"
      />
      <path
        d="M56.4489 38.9379C56.3505 39.2392 56.1724 39.5104 55.9321 39.7247C55.4565 40.0765 54.8671 40.2536 54.269 40.2242C53.904 40.2516 53.5371 40.2077 53.1901 40.095C52.843 39.9824 52.523 39.8034 52.2491 39.5686C51.9658 39.3154 51.7428 39.0056 51.5957 38.6608C51.4486 38.3159 51.3809 37.9442 51.3972 37.5716C51.3855 37.2043 51.4536 36.8386 51.597 36.4983C51.7404 36.158 51.956 35.8505 52.2299 35.5956C52.5038 35.3407 52.8299 35.1442 53.1872 35.0186C53.5444 34.893 53.925 34.8412 54.3043 34.8664C54.9139 34.8212 55.5188 34.9995 55.9985 35.366C56.2355 35.5674 56.3912 35.8433 56.4381 36.1449L56.1179 36.2301C56.0382 36.0956 55.9411 35.9713 55.829 35.8604C55.4985 35.5925 55.0771 35.4524 54.6461 35.467C54.37 35.4573 54.0949 35.5039 53.8387 35.6037C53.5825 35.7036 53.3508 35.8545 53.1589 36.0466C52.967 36.2386 52.819 36.4676 52.7247 36.7186C52.6303 36.9696 52.5917 37.237 52.6113 37.5034C52.5875 37.7649 52.6205 38.0283 52.7083 38.2768C52.796 38.5252 52.9366 38.7532 53.121 38.946C53.3053 39.1387 53.5294 39.2921 53.7787 39.3962C54.0281 39.5002 54.2972 39.5527 54.5688 39.5502C54.8735 39.5664 55.1773 39.505 55.4498 39.3722C55.7223 39.2395 55.9541 39.04 56.122 38.7937L56.4489 38.9379Z"
        fill="white"
      />
      <path
        d="M60.1508 38.2836H58.4877L59.3084 36.6563L60.1508 38.2836ZM56.5981 40.0511H57.5951L58.1526 38.9379H60.4926L61.0556 40.0459H62.2764L59.6991 35.0539H59.307L56.5981 40.0511Z"
        fill="white"
      />
      <path
        d="M63.9276 35.7345H64.3969C64.6453 35.7224 64.893 35.7673 65.1199 35.8656C65.2503 35.9264 65.3597 36.0224 65.4348 36.142C65.5099 36.2616 65.5476 36.3997 65.5432 36.5396C65.5349 36.7294 65.4658 36.9121 65.3456 37.0625C65.2253 37.2128 65.0597 37.3233 64.8717 37.3788C64.5638 37.4675 64.2432 37.5078 63.9221 37.4981L63.9276 35.7345ZM62.7854 40.0485H63.9316V37.8325C64.15 37.8805 64.3739 37.9008 64.5977 37.8928L66.0383 40.0485H67.3189L65.7385 37.6725C66.0297 37.5614 66.2836 37.3749 66.4724 37.1336C66.6365 36.9102 66.7251 36.6431 66.7261 36.3691C66.7287 36.1529 66.6728 35.9397 66.5641 35.7506C66.4554 35.5616 66.2975 35.4033 66.1061 35.2913C65.6984 35.0889 65.2403 35.0008 64.7835 35.0369H62.7745L62.7854 40.0485Z"
        fill="white"
      />
      <path
        d="M69.1065 35.7332H69.5813C69.8279 35.721 70.0739 35.7659 70.2989 35.8643C70.4293 35.9251 70.5387 36.0211 70.6138 36.1407C70.6889 36.2603 70.7265 36.3984 70.7221 36.5383C70.7139 36.7281 70.6448 36.9108 70.5245 37.0612C70.4043 37.2115 70.2387 37.322 70.0507 37.3775C69.7429 37.4668 69.4222 37.5071 69.1011 37.4968L69.1065 35.7332ZM67.9603 40.0472H69.1065V37.8312C69.3249 37.8792 69.5488 37.8994 69.7726 37.8915L71.2145 40.0472H72.4951L70.9202 37.6712C71.2089 37.5584 71.4606 37.3722 71.6486 37.1323C71.8155 36.9104 71.9057 36.6434 71.9064 36.3691C71.909 36.154 71.8544 35.9419 71.7478 35.7531C71.6411 35.5644 71.486 35.4053 71.2973 35.2913C70.8891 35.0902 70.4314 35.0021 69.9747 35.0369H67.9603V40.0472Z"
        fill="white"
      />
      <path
        d="M73.1396 35.0342H76.6774V35.7227H74.2804V37.11H76.383V37.7984H74.2804V39.3719H77.0125V40.0511H73.1396V35.0342Z"
        fill="white"
      />
      <path
        d="M77.8086 35.0395H78.9549V39.3483H81.5323V40.0525H77.829L77.8086 35.0395Z"
        fill="white"
      />
      <path
        d="M85.359 38.2836H83.6959L84.5166 36.6563L85.359 38.2836ZM81.8063 40.0511H82.8033L83.3609 38.9379H85.7009L86.2435 40.0511H87.4644L84.887 35.0592H84.4949L81.8063 40.0511Z"
        fill="white"
      />
      <path
        d="M92.8851 39.6971C92.1666 40.0255 91.3819 40.1961 90.5871 40.1967C89.8314 40.2337 89.0904 39.9849 88.5212 39.503C88.2433 39.2599 88.0236 38.9612 87.8774 38.6276C87.7312 38.294 87.662 37.9336 87.6747 37.5715C87.6775 37.2049 87.7571 36.8425 87.9087 36.5064C88.0604 36.1702 88.2809 35.8673 88.5571 35.6158C88.8332 35.3644 89.1591 35.1696 89.5153 35.0433C89.8714 34.917 90.2503 34.8617 90.6292 34.8808C91.2305 34.8531 91.8205 35.0455 92.2814 35.4198C92.4969 35.6143 92.648 35.8662 92.7155 36.1436L92.4008 36.2485C92.2778 36.0403 92.0993 35.8678 91.884 35.7489C91.604 35.591 91.2842 35.5116 90.9602 35.5194C90.6895 35.504 90.4186 35.5432 90.1645 35.6346C89.9104 35.726 89.6788 35.8676 89.4844 36.0503C89.2901 36.233 89.1372 36.4528 89.0356 36.6958C88.934 36.9387 88.8858 37.1995 88.8942 37.4614C88.8751 37.7192 88.9099 37.978 88.9967 38.2224C89.0835 38.4668 89.2204 38.6918 89.3992 38.8839C89.578 39.076 89.7951 39.2312 90.0375 39.3403C90.2798 39.4494 90.5424 39.5101 90.8096 39.5188C91.1478 39.5156 91.4823 39.4511 91.7958 39.3286V38.201H90.7377V37.5073H92.9082L92.8851 39.6971Z"
        fill="white"
      />
      <path
        d="M93.9948 35.0342H97.5326V35.7227H95.1356V37.11H97.2382V37.7984H95.1356V39.3719H97.8731V40.0511H93.9948V35.0342Z"
        fill="white"
      />
      <path
        d="M101.696 38.4685C101.562 38.4813 101.427 38.467 101.3 38.4265C101.173 38.3859 101.055 38.32 100.956 38.233C100.856 38.1459 100.777 38.0397 100.722 37.9212C100.668 37.8026 100.64 37.6744 100.64 37.5447C100.64 37.415 100.668 37.2867 100.722 37.1682C100.777 37.0496 100.856 36.9434 100.956 36.8564C101.055 36.7694 101.173 36.7034 101.3 36.6629C101.427 36.6223 101.562 36.608 101.696 36.6209C101.826 36.6136 101.957 36.6318 102.08 36.6744C102.204 36.7169 102.317 36.783 102.413 36.8686C102.509 36.9542 102.587 37.0576 102.641 37.1728C102.695 37.2879 102.725 37.4124 102.728 37.5388C102.726 37.6656 102.697 37.7908 102.644 37.9066C102.59 38.0225 102.513 38.1265 102.416 38.2124C102.32 38.2983 102.206 38.3643 102.082 38.4062C101.958 38.4482 101.827 38.4654 101.696 38.4567V38.4685Z"
        fill="white"
      />
      <path
        d="M110.712 38.9379C110.613 39.2386 110.435 39.5095 110.196 39.7246C109.718 40.0765 109.127 40.2535 108.527 40.2242C108.162 40.251 107.795 40.2068 107.449 40.0942C107.102 39.9815 106.782 39.8028 106.507 39.5686C106.224 39.3153 106.002 39.0054 105.856 38.6605C105.71 38.3155 105.643 37.9439 105.661 37.5715C105.649 37.2042 105.717 36.8386 105.861 36.4983C106.004 36.1579 106.22 35.8504 106.493 35.5956C106.767 35.3407 107.093 35.1441 107.451 35.0185C107.808 34.893 108.189 34.8411 108.568 34.8664C109.176 34.822 109.779 35.0003 110.257 35.366C110.494 35.5674 110.649 35.8433 110.696 36.1449L110.376 36.2301C110.237 35.9763 110.022 35.7684 109.76 35.6331C109.498 35.4978 109.2 35.4414 108.904 35.4709C108.629 35.4659 108.355 35.5169 108.102 35.6205C107.848 35.7241 107.62 35.878 107.431 36.0723C107.243 36.2665 107.099 36.4969 107.008 36.7484C106.918 36.9998 106.883 37.2669 106.906 37.5322C106.882 37.7937 106.915 38.0572 107.003 38.3056C107.091 38.5541 107.231 38.782 107.416 38.9748C107.6 39.1676 107.824 39.3209 108.074 39.425C108.323 39.5291 108.592 39.5816 108.864 39.5791C109.168 39.5942 109.471 39.5321 109.743 39.3989C110.015 39.2657 110.245 39.0661 110.413 38.8199L110.712 38.9379Z"
        fill="white"
      />
      <path
        d="M111.554 35.0395H112.69V37.1008H115.071V35.0395H116.202V40.0354H115.066V37.7801H112.685V40.0512H111.554V35.0395Z"
        fill="white"
      />
      <path
        d="M120.257 38.2836H118.599L119.413 36.6563L120.257 38.2836ZM116.719 40.0511H117.716L118.268 38.9379H120.612L121.17 40.0459H122.399L119.822 35.0539H119.429L116.719 40.0511Z"
        fill="white"
      />
      <path
        d="M122.849 35.0395H124.006V38.2141C123.989 38.4838 124.039 38.7536 124.152 39.0008C124.242 39.1698 124.38 39.3103 124.55 39.4058C124.721 39.5012 124.915 39.5477 125.112 39.5398C125.354 39.5333 125.591 39.4801 125.811 39.3833C126.031 39.2864 126.229 39.1479 126.393 38.9759V35.0421H127.533V40.0354H126.393V39.4663C126.231 39.6653 126.033 39.8345 125.809 39.9659C125.491 40.1273 125.137 40.2127 124.777 40.2151C124.274 40.2458 123.778 40.0864 123.393 39.7705C123.047 39.4611 122.876 39.0716 122.876 38.1525L122.849 35.0395Z"
        fill="white"
      />
      <path
        d="M128.906 35.0342H132.433V35.7227H130.047V37.0903H132.113V37.7945H130.047V40.0511H128.906V35.0342Z"
        fill="white"
      />
      <path
        d="M133.086 35.0342H136.613V35.7227H134.221V37.0903H136.287V37.7945H134.221V40.0511H133.084L133.086 35.0342Z"
        fill="white"
      />
      <path
        d="M139.545 38.2836H137.893L138.714 36.6563L139.545 38.2836ZM135.993 40.0511H136.99L137.547 38.9379H139.887L140.476 40.0511H141.697L139.119 35.0592H138.727L135.993 40.0511Z"
        fill="white"
      />
      <path
        d="M147.071 39.6971C146.35 40.0264 145.563 40.197 144.765 40.1967C144.01 40.2331 143.269 39.9844 142.699 39.503C142.421 39.2599 142.202 38.9612 142.055 38.6276C141.909 38.294 141.84 37.9336 141.853 37.5715C141.856 37.2049 141.935 36.8425 142.087 36.5064C142.238 36.1702 142.459 35.8673 142.735 35.6159C143.011 35.3644 143.337 35.1696 143.693 35.0433C144.049 34.917 144.428 34.8617 144.807 34.8808C145.409 34.852 145.999 35.0446 146.459 35.4198C146.676 35.6128 146.825 35.8655 146.888 36.1436L146.579 36.2485C146.456 36.0403 146.277 35.8678 146.062 35.7489C145.782 35.591 145.462 35.5115 145.138 35.5194C144.868 35.504 144.597 35.5432 144.343 35.6346C144.088 35.726 143.857 35.8676 143.662 36.0503C143.468 36.233 143.315 36.4528 143.214 36.6958C143.112 36.9387 143.064 37.1995 143.072 37.4614C143.053 37.7192 143.088 37.978 143.175 38.2224C143.262 38.4668 143.398 38.6918 143.577 38.8839C143.756 39.076 143.973 39.2312 144.216 39.3403C144.458 39.4494 144.72 39.5101 144.988 39.5188C145.326 39.5156 145.66 39.4511 145.974 39.3286V38.2023H144.916V37.5086H147.086L147.071 39.6971Z"
        fill="white"
      />
      <path
        d="M148.222 35.0342H151.76V35.7227H149.368V37.11H151.465V37.7984H149.368V39.3719H152.1V40.0511H148.222V35.0342Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4406_8990">
        <rect width={156} height={48} fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const Memo = memo(LogoEspaceAubade)
export default Memo
