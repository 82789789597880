import { unit, Paragraph } from '@aubade/core/ui-kit'
import { HStack, VStack } from '@chakra-ui/react'

import { useGetTimeDiff } from 'src/libs/getTimediff'

import type { ExtendedTypesensePublication } from '../types'

import { getAuthor } from './PublicationPreview'

export function FeedContent(props: {
  publication: ExtendedTypesensePublication
}) {
  const { publication } = props
  const { author, title, publishedAt } = publication

  // Tue Apr 16 2024 10:13:41 GMT+0200 (heure d’été d’Europe centrale)

  const publishedFrom = useGetTimeDiff(new Date(publishedAt))
  const authorName = typeof author === 'object' ? author.name : author

  return (
    <VStack
      width="full"
      maxHeight="100%"
      overflow="hidden"
      padding={unit('30')}
      gap={unit('5')}
      alignItems="flex-start"
      justifyContent="center"
      cursor="pointer"
    >
      <HStack gap={unit('5')} width="full">
        {getAuthor(authorName)}
        <Paragraph
          fontWeight="bold"
          color="greyDark.500"
          text={`- ${publishedFrom.diff} ${publishedFrom.text}`}
        />
      </HStack>
      <HStack width="full" justifyContent="flex-start">
        <Paragraph color="greyDark.500" text={title} />
      </HStack>
    </VStack>
  )
}
