import { useOne } from '@aubade/core/adapters'
import { useGetUser, isAdmin, isSociety } from '@aubade/domain/components'
import type { Contact } from '@aubade/types'
import { useDelete, toHydraId } from '@nartex/api-platform'
import { useQueryClient } from '@pankod/refine-core'
import { useMemo } from 'react'

export function useActions(authorId: string | undefined) {
  const queryclient = useQueryClient()
  const { mutate: mutateDelete } = useDelete()

  const { role } = useGetUser()

  const [fullAuthor] = useOne<Contact>(
    {
      iri: toHydraId('users', authorId ?? ''),
    },
    { enabled: Boolean(authorId) },
  )

  const canDelete = useMemo(() => {
    if (isAdmin(role)) return true
    if (isSociety(role) && !isAdmin(fullAuthor?.acRoles.AC)) {
      return true
    }
    return false
  }, [fullAuthor, role])

  function onDelete(notificationId: string) {
    mutateDelete(
      {
        resource: 'notifications',
        id: notificationId,
      },
      {
        async onSuccess() {
          await queryclient.invalidateQueries()
        },
      },
    )
  }
  return { canDelete, onDelete }
}
