import { unit } from '@aubade/core/ui-kit'
import { CheckboxMinus, CheckboxOff, CheckboxOn } from '@aubade/design/graphics'

import { useTranslate } from '@aubade/translation'
import { Box, Flex, Icon, Input, VStack } from '@chakra-ui/react'
import type { ForwardedRef } from 'react'
import { forwardRef, memo } from 'react'

import { ERROR_STYLE } from '..'

import { getParagraphStyle, Paragraph } from '../../Paragraph'

import type { BaseInputProps } from '../controller'
import { useBaseInput } from '../controller'
import { BaseInputGroup } from '../TextInput/TextInput'

export type CheckboxProps = Omit<BaseInputProps<boolean>, 'label'> &
  Pick<Required<BaseInputProps<boolean>>, 'label'> & {
    isIndeterminate?: boolean
    inverse?: boolean
    fullWidth?: boolean
    inputLabel?: string
    subtext?: string
  }

const disabledStyle = {
  borderColor: 'transparent',
  color: 'grey.500',
  cursor: 'not-allowed',
  opacity: 1,
}

export const CheckboxInput = memo(
  forwardRef(function (
    props: CheckboxProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    const {
      label,
      isIndeterminate,
      inverse,
      fullWidth,
      inputLabel,
      subtext,
      ...rest
    } = props
    const { id, field, wrap } = useBaseInput<boolean>(rest)

    const isChecked = field.value || false

    const hasValue = isChecked || isIndeterminate

    function getIcon() {
      if (isChecked) {
        return CheckboxOn
      }
      if (isIndeterminate) {
        return CheckboxMinus
      }
      return CheckboxOff
    }

    function getColor() {
      const { error, disabled, readOnly } = props
      if (error) {
        return 'error.dark'
      }
      if (disabled || readOnly) {
        return 'grey.500'
      }

      if (hasValue) return 'blue.500'
      return 'primary.300'
    }

    const translate = useTranslate()
    const errorStyle = field.error ? ERROR_STYLE : {}

    const paragraphStyle = props.disabled || props.readOnly ? disabledStyle : {}

    return (
      <Box width="fitContent" maxWidth="60ch" alignItems={'center'}>
        {wrap(
          <BaseInputGroup label={inputLabel} id={id}>
            <Flex
              as={'label'}
              flexDir={inverse ? 'row-reverse' : undefined}
              justifyContent={inverse ? 'space-between' : undefined}
              width={fullWidth ? 'full' : 'fit-content'}
              gap={4}
              cursor={props.disabled ? 'not-allowed' : 'pointer'}
              borderRadius="sm"
              padding={1}
              htmlFor={field.id}
              {...errorStyle}
              _hover={{
                color: 'blue.500',
              }}
            >
              <Input
                type="checkbox"
                {...field}
                onClick={(e) => e.preventDefault()}
                width="fitContent"
                ref={ref}
                disabled={props.disabled || props.readOnly}
                value={undefined}
                checked={isChecked}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    field.onChange(!isChecked)
                  }
                }}
                style={{
                  opacity: 0,
                  position: 'absolute',
                }}
              />
              <Box borderColor="grey.500" height="24px">
                <Icon width={6} height={6} as={getIcon()} color={getColor()} />
              </Box>
              <VStack alignItems="flex-start" gap={unit('5')}>
                <Box
                  as="span"
                  {...getParagraphStyle({
                    fontWeight: 'bold',
                    isDangerous: Boolean(props.error),
                    size: 'sm',
                  })}
                  flex={1}
                  {...paragraphStyle}
                  lineHeight={'200%'}
                >
                  {translate(label)}
                </Box>
                {subtext && <Paragraph text={subtext} />}
              </VStack>
            </Flex>
          </BaseInputGroup>,
        )}
      </Box>
    )
  }),
)

export function useCheckboxGroup(
  checkboxes: { name: string; value: boolean }[],
) {
  const isChecked = checkboxes.every((checkbox) => Boolean(checkbox.value))
  const isIndeterminate = checkboxes.some((checkbox) => Boolean(checkbox.value))

  function setCheckboxesValues() {
    const newValue = !isChecked
    return Object.fromEntries(
      checkboxes.map((checkbox) => [checkbox.name, newValue]),
    )
  }

  return { isChecked, isIndeterminate, setCheckboxesValues }
}

type BaseCheckboxesGroupProps = {
  parentCheckboxProps: CheckboxProps
  childCheckboxesProps: CheckboxProps[]
}
export function BaseCheckboxesGroup(props: BaseCheckboxesGroupProps) {
  const { parentCheckboxProps, childCheckboxesProps } = props

  return (
    <Flex flexDirection="column" gap={2}>
      <CheckboxInput {...parentCheckboxProps} />
      <Flex marginLeft={8} flexDirection="column" gap={2}>
        {childCheckboxesProps.map((childCheckboxProps) => {
          return (
            <CheckboxInput
              key={childCheckboxProps.label}
              {...childCheckboxProps}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}
