import type { TranslationKey } from '@aubade/translation'
import { useTranslate } from '@aubade/translation'
import type { PropsWithChildren } from 'react'

type TranslatableProps = { label?: TranslationKey; text?: TranslationKey }

export function useTranslatable<T extends PropsWithChildren<TranslatableProps>>(
  props: T,
): T {
  const { label, text, children } = props

  const translate = useTranslate()

  return {
    ...props,
    children:
      children || (label || text ? translate(label ?? text ?? '') : undefined),
  }
}
