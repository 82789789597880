import {
  drawerQueryParams,
  useQueryParamsState,
} from '@aubade/core/libs/useParams'
import { FormDrawer } from '@aubade/core/ui-kit'

import { useGetUser } from '@aubade/domain/components'

import { FormEdit } from './FormEdit'

export function Profile() {
  const { id: me } = useGetUser()
  const [modalState] = useQueryParamsState(drawerQueryParams)
  const { page } = modalState
  const matchUpdate = page === 'profile'

  return (
    <FormDrawer isOpenProps={Boolean(matchUpdate)} variant="users">
      {matchUpdate && <FormEdit id={me} profile="collaborater" />}
    </FormDrawer>
  )
}
