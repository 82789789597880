import { drawerQueryParams, useQueryParamsState } from '@aubade/core/libs'
import type { FormSubmitProps } from '@aubade/core/ui-kit'
import {
  Button,
  ButtonWithConfirm,
  Form,
  FormDrawer,
  Paragraph,
  makeForm,
  useForm,
  unit,
} from '@aubade/core/ui-kit'
import {
  useGetUser,
  DrawerContent,
  isAdmin,
  isSociety,
} from '@aubade/domain/components'
import { useTranslate } from '@aubade/translation'
import { HStack, VStack } from '@chakra-ui/react'
import { cleanHydraId, toHydraId } from '@nartex/api-platform'

import { forwardRef, Fragment, memo, useMemo } from 'react'
import { useUrls } from 'src/App'
import { useMemoOne } from 'use-memo-one'
import { v4 as uuid } from 'uuid'

import { TargetSection } from '../Publications/Components/TargetSection'

import { useActions } from './actions'
import { PlanifySection } from './Components/PlanifySection'
import { formatDate } from './formUtils/formatDate'
import { useFormValidation } from './formUtils/formValidators'

import type { NotificationFormType } from './types'

const { Input, useWatch, useFormContext } = makeForm<
  NotificationFormType,
  | 'targets'
  | 'planify'
  | 'isDraft'
  | 'publishedAt'
  | 'title'
  | 'content'
  | 'fullAuthor'
  | '@id'
>()

export function NotificationsEdit() {
  const [modalState] = useQueryParamsState(drawerQueryParams)

  const { page, id } = modalState

  const matchCreate = page === 'create'
  const matchUpdate = page === 'edit' && id
  const match = Boolean(matchCreate || matchUpdate)
  return (
    <FormDrawer isOpenProps={Boolean(match)}>
      <DrawerContent>
        {matchCreate && <FormCreate />}
        {matchUpdate && <FormUpdate id={id} />}
      </DrawerContent>
    </FormDrawer>
  )
}

export function FormCreate() {
  const urls = useUrls()
  const { id: me } = useGetUser()
  const newId = useMemoOne(() => {
    return uuid()
  }, [])

  const defaultPublishedAt = formatDate(new Date())

  const { saveButtonProps, ...methods } = useForm<NotificationFormType>({
    mode: 'create',
    resource: 'notifications/create',
    redirect() {
      return urls.aubade().notifications()
    },
    blockNavigation: false,
    hookFormOptions: {
      defaultValues: {
        newId,
        author: toHydraId('users', me),
        isDraft: false,
        planify: false,
        isNational: 'true',
        isAgencies: 'true',
        publishedAt: defaultPublishedAt,
      },
    },
  })
  return (
    <>
      <Paragraph
        fontWeight="bold"
        size="lg"
        color="black.base"
        text="notifications.title.create"
      />
      <Form {...methods} bucket={{ type: 'Notification' }}>
        <FormContent {...saveButtonProps} newPulicationId={newId} />
      </Form>
    </>
  )
}

export function FormUpdate(props: { id: string }) {
  const { id } = props
  const { role } = useGetUser()
  const urls = useUrls()

  const { saveButtonProps, ...methods } = useForm<NotificationFormType>({
    mode: 'update',
    id,
    resource: 'notifications',
    redirect() {
      return urls.aubade().notifications()
    },
    blockNavigation: false,
  })
  const isDraft = methods.getValues('isDraft')

  const fullAuthor = methods.getValues('fullAuthor')
  const hasEditRights = useMemo(() => {
    if (!fullAuthor) return false
    if (isAdmin(role) && isAdmin(fullAuthor?.acRoles.AC)) {
      return true
    }
    if (isSociety(role) && !isAdmin(fullAuthor?.acRoles.AC)) {
      return true
    }
    return false
  }, [fullAuthor, role])
  const publishedAt = methods.getValues('publishedAt')
  const canEdit =
    isDraft ||
    (hasEditRights && new Date(publishedAt!).getTime() > new Date().getTime())
  const title = isDraft
    ? 'notifications.title.edit'
    : 'notifications.title.show'

  return (
    <>
      <Paragraph fontWeight="bold" size="lg" color="black.base" text={title} />
      <Form {...methods} bucket={{ type: 'Notification' }} readOnly={!canEdit}>
        <FormContent {...saveButtonProps} canEdit={canEdit} />
      </Form>
    </>
  )
}

type FormContentProps = FormSubmitProps & {
  newPulicationId?: string
  canEdit?: boolean
}

export const FormContent = memo(
  forwardRef<HTMLDivElement, FormContentProps>(function (props, ref) {
    const { newPulicationId, canEdit = true, ...saveButtonProps } = props
    const { role } = useGetUser()
    const translate = useTranslate()

    const [iri, isDraft, publishedAt, targets, title, content, fullAuthor] =
      useWatch([
        '@id',
        'isDraft',
        'publishedAt',
        'targets',
        'title',
        'content',
        'fullAuthor',
      ])

    const { setValue } = useFormContext()
    const saveButtonLabel =
      !iri || isDraft ? 'buttons.sendnotification' : 'buttons.save'

    const showBottonDraft = Boolean(!iri || isDraft)

    const { canDelete, onDelete } = useActions(fullAuthor?.id)
    const canDeleteNotification = Boolean(
      (canDelete && isDraft) ||
        (canDelete &&
          publishedAt &&
          new Date(publishedAt).getTime() >= Date.now()),
    )
    const suite = useFormValidation()
    const canPublish = useMemo(() => {
      return suite({ targets, title, content }).isValidByGroup('publish')
    }, [suite, targets, title, content])
    const canDraft = suite({
      targets,
      title,
      content,
    }).isValidByGroup('draft')

    const showButtonSave = useMemo(() => {
      if (isAdmin(role)) return true
      if (isSociety(role)) {
        if (newPulicationId) {
          return true
        }
        if (!newPulicationId) {
          if (fullAuthor && isAdmin(fullAuthor?.acRoles.AC)) {
            return false
          } else {
            return true
          }
        }
      }
    }, [fullAuthor, newPulicationId, role])

    return (
      <Fragment ref={ref}>
        <Input.Text
          name="title"
          label="notifications.fields.title"
          maxLength={50}
        />
        <Input.TextArea
          name="content"
          label="notifications.fields.description"
          maxLength={100}
        />
        <VStack gap={unit('40')} alignItems={'flex-start'} width="full">
          <TargetSection />
          <PlanifySection />
          <HStack gap={'30px'} justifyContent={'flex-start'} width="full">
            {canEdit && showButtonSave && (
              <Button
                {...saveButtonProps}
                label={saveButtonLabel}
                variant="primary"
                isDisabled={!canPublish}
                onClick={(evt) => {
                  setValue('isDraft', false)
                  if (!publishedAt || publishedAt === null) {
                    setValue('publishedAt', new Date())
                  }
                  saveButtonProps?.onClick?.(evt)
                }}
              />
            )}

            {showBottonDraft && (
              <Button
                {...saveButtonProps}
                label="buttons.draft"
                variant="text"
                isDisabled={!canDraft}
                onClick={(evt) => {
                  setValue('isDraft', true)
                  setValue('publishedAt', undefined)
                  saveButtonProps?.onClick?.(evt)
                }}
              />
            )}

            {iri && canDeleteNotification && (
              <ButtonWithConfirm
                dialogProps={{
                  title: 'notifications.confirmTitle',
                  children: translate('notifications.confirmText'),
                }}
                confirmButtonProps={{
                  isDisabled: false,
                  label: 'notifications.confirmButton',
                  variant: 'primary',
                }}
                buttonProps={{
                  label: 'buttons.delete',
                  isDisabled: false,
                  variant: 'text',
                  isDangerous: true,
                }}
                onConfirm={() => {
                  onDelete(cleanHydraId(iri))
                }}
              />
            )}
          </HStack>
        </VStack>
      </Fragment>
    )
  }),
)
