import type { DeepPartial } from 'react-hook-form'
import { equals } from 'remeda'

export function objectIsEmpty(object: Record<string, any>): boolean {
  return Object.values(object).every((value) => {
    if (value === null || value === undefined) return true
    if (typeof value === 'object') return objectIsEmpty(value)

    if (Array.isArray(value)) {
      if (value.length === 0) return true

      return objectIsEmpty(
        Object.fromEntries(value.map((item, index) => [index, item])),
      )
    }
    if (value === 0) return false
    return !value
  })
}

export function removeEmptyValues<T extends Record<string, any>>(
  item: T,
): DeepPartial<T> {
  return (removeEmpty(item) ?? {}) as DeepPartial<T>
}

function removeEmpty<T extends unknown>(item: T) {
  if (isEmpty(item) || item === null) return undefined
  if (typeof item !== 'object') return item

  if (Array.isArray(item)) {
    const result: any[] = []
    for (let value of item) {
      value = removeEmpty(value)
      if (!isEmpty(value)) {
        result.push(value)
      }
    }
    if (!result.length) return undefined
    else return result
  }

  if (item.constructor.name === 'Object') {
    const result: Record<string, unknown> = {}

    for (const key in item) {
      const value = removeEmpty(item[key])
      if (!isEmpty(value)) {
        result[key] = value
      }
    }
    if (isEmpty(result)) return undefined
    else return result
  }

  return item
}

type EmptyObject = Record<string, never>

type Empty = EmptyObject | [] | '' | null | undefined
export function isEmpty<T>(value: T): value is Exclude<T, Empty> {
  if (value == null) return true
  if (equals(value, {})) return true
  if (equals(value, [])) return true
  if (equals(value, '')) return true
  if (typeof value === 'object' && value.constructor.name === 'Object') {
    return Object.values(value).every(isEmpty)
  }
  if (Array.isArray(value)) {
    return value.every(isEmpty)
  }

  return false
}
