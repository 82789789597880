import { IconArrowLeft, IconArrowRight } from '@aubade/design/graphics'
import { Icon } from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'
import Carousel from 'react-multi-carousel'

import 'react-multi-carousel/lib/styles.css'
import './styles.css'

type Props = { multi: boolean }

export function PictureCarousel(props: PropsWithChildren<Props>) {
  const { multi, children } = props
  return (
    <Carousel
      responsive={responsive}
      showDots={multi}
      arrows={multi}
      infinite={true}
      sliderClass="carousel-custom-slider"
      containerClass="carousel-custom-container"
      customLeftArrow={<CustomArrow direction="left" />}
      customRightArrow={<CustomArrow direction="right" />}
      customDot={<CustomDot />}
    >
      {children}
    </Carousel>
  )
}

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
}

function CustomArrow(props: {
  onClick?: () => void
  direction: 'left' | 'right'
}) {
  const { onClick, direction } = props
  const iconProps = direction === 'left' ? { left: '10px' } : { right: '10px' }
  return (
    <Icon
      position="absolute"
      {...iconProps}
      backgroundColor="lightGrey.500"
      color="darkGrey.500"
      borderRadius={'50%'}
      padding="5px"
      width="25px"
      height="25px"
      as={direction === 'left' ? IconArrowLeft : IconArrowRight}
      onClick={(e: any) => {
        e.stopPropagation()
        onClick?.()
      }}
    />
  )
}

function CustomDot(props: { onClick?: () => void; active?: boolean }) {
  const { active, onClick } = props
  return (
    <li
      className={`react-multi-carousel-dot ${
        active ? 'react-multi-carousel-dot--active' : ''
      }`}
    >
      <button
        onClick={(e) => {
          e.stopPropagation()
          onClick?.()
        }}
      />
    </li>
  )
}
