import { useHTTPClient } from '@aubade/core/adapters'
import { fromS3ToResizedUrl } from '@aubade/core/libs'
import type { GetRowProps } from '@aubade/core/ui-kit'
import {
  Button,
  Surface,
  unit,
  ResourceList,
  Chip,
  useDocumentUrl,
  useToastMessage,
  Paragraph,
  Separator,
} from '@aubade/core/ui-kit'
import {
  IconPlus,
  IconRecherche,
  IconDot,
  IconEyeShow,
  IconEyeHide,
} from '@aubade/design/graphics'
import type { Tool } from '@aubade/types/api'
import { ToolCategoriesEnum } from '@aubade/types/api'
import type { Typesense } from '@aubade/types/typesense'
import { VStack, Box, HStack, Image } from '@chakra-ui/react'
import { useQueryMetaData } from '@nartex/api-platform'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { ColumnDef } from '@tanstack/react-table'
import { useCallback, useMemo } from 'react'
import { useUrls } from 'src/App'
import { ResourcePageLayout } from 'src/components/ResourcePageLayout'

import { ListHeader } from '../../components/ListHeader'

import { ToolsEdit } from './CreateEdit'

export function ToolsList() {
  return (
    <ResourcePageLayout>
      <List />
      <ToolsEdit />
    </ResourcePageLayout>
  )
}

function List() {
  const columns = useGetColumns()
  const urls = useUrls()

  return (
    <Surface
      direction={'column'}
      overflowY="auto"
      gap={unit('40')}
      paddingX={unit('10')}
      paddingY={unit('40')}
      maxHeight={'full'}
      minHeight={'full'}
      width="full"
    >
      <ListHeader
        scope="tool"
        mainAction={
          <Button
            variant="primary"
            label={'actions.newTool'}
            leftIcon={IconPlus}
            to={urls.aubade().toolsCreate()}
          />
        }
      />
      <ResourceList<Typesense.Tools>
        title={<ListTitle title="tools.tabletitle.generic" />}
        scope="tool"
        resource="tools"
        dataProviderName="default"
        filters={[
          {
            field: 'toolCategory',
            operator: 'eq',
            value: ToolCategoriesEnum.OUTILS_GENERIQUES,
          },
        ]}
        tableProps={{
          templateColumns: '80px 450px 100px 1fr',
          columns: columns,
          noDataLabel: 'table.noData.tools',
          getRowProps: useCallback<GetRowProps<Typesense.Tools>>(
            (row) => {
              return {
                to: urls.aubade().toolsEdit(row.id),
              }
            },
            [urls],
          ),
        }}
      />
      <ResourceList<Typesense.Tools>
        title={<ListTitle title="tools.tabletitle.bathroom" />}
        scope="tool"
        resource="tools"
        dataProviderName="default"
        filters={[
          {
            field: 'toolCategory',
            operator: 'eq',
            value: ToolCategoriesEnum.SDB_CARRELAGE,
          },
        ]}
        tableProps={{
          templateColumns: '80px 450px 100px 1fr',
          columns: columns,
          noDataLabel: 'table.noData.tools',
          getRowProps: useCallback<GetRowProps<Typesense.Tools>>(
            (row) => {
              return {
                to: urls.aubade().toolsEdit(row.id),
              }
            },
            [urls],
          ),
        }}
      />
      <ResourceList<Typesense.Tools>
        title={<ListTitle title="tools.tabletitle.heating" />}
        scope="tool"
        resource="tools"
        dataProviderName="default"
        filters={[
          {
            field: 'toolCategory',
            operator: 'eq',
            value: ToolCategoriesEnum.WARM_CLIM_ENERGY,
          },
        ]}
        tableProps={{
          templateColumns: '80px 450px 100px 1fr',
          columns: columns,
          noDataLabel: 'table.noData.tools',
          getRowProps: useCallback<GetRowProps<Typesense.Tools>>(
            (row) => {
              return {
                to: urls.aubade().toolsEdit(row.id),
              }
            },
            [urls],
          ),
        }}
      />
    </Surface>
  )
}

type ListTitleProps = {
  title: string
}

function ListTitle(props: ListTitleProps) {
  const { title } = props
  return (
    <VStack width="full" gap={unit('15')} alignItems={'flex-start'}>
      <Paragraph color="black.500" text={title} />
      <Separator color="lightGrey.500" />
    </VStack>
  )
}

function useGetColumns() {
  return useMemo(() => {
    return [
      {
        header: '',
        accessorKey: 'cover',
        cell(props) {
          const { row } = props

          return (
            <Box>
              <ListItemPicture tool={row.original} />
            </Box>
          )
        },
      },
      {
        header: '',
        accessorKey: 'name',
        cell(props) {
          const { row } = props
          const { name } = row.original
          return (
            <VStack
              height="full"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Paragraph fontWeight="bold" text={name} ellipsis />
            </VStack>
          )
        },
      },
      {
        header: '',
        accessorKey: 'isPublished',
        cell(props) {
          const { row } = props
          const { isPublished } = row.original
          const status = isPublished
            ? 'tool.status.published'
            : 'tool.status.masked'
          const color = isPublished ? 'darkGrey.500' : 'red.500'
          return (
            <VStack height="full" justifyContent="center">
              <Chip
                size="xsmall"
                label={status}
                iconLeftProps={{ as: IconDot, color }}
                textProps={{ color }}
              />
            </VStack>
          )
        },
      },
      {
        header: '',
        accessorKey: 'id',
        cell(cellProps) {
          const { row } = cellProps
          const { id, isPublished } = row.original
          return <Actions isPublished={isPublished} id={id} />
        },
      },
    ] satisfies ColumnDef<Typesense.Tools>[]
  }, [])
}

type ListItemPictureProps = {
  tool: Typesense.Tools
}

function ListItemPicture(props: ListItemPictureProps) {
  const { tool } = props
  const { photo, lineOne, lineTwo, backgroundColor } = tool
  const parsedUrl = fromS3ToResizedUrl(photo)
  const pictureUrl = useDocumentUrl(parsedUrl, {
    size: { width: 60, height: 60 },
    ratio: 'fit',
  })

  return (
    <Box
      width={unit('80')}
      height={unit('60')}
      borderRadius={unit('12')}
      backgroundColor={backgroundColor}
      display="flex"
      justifyContent={'center'}
      alignItems={'center'}
    >
      {photo ? (
        <Image width={unit('60')} height={'auto'} src={pictureUrl} />
      ) : (
        <VStack
          gap={unit('2')}
          height="full"
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Paragraph size="3xs" text={lineOne} color="lightGrey.500" />
          <Paragraph
            size="3xs"
            fontWeight="bold"
            text={lineTwo}
            color="lightGrey.500"
            casing="uppercase"
          />
        </VStack>
      )}
    </Box>
  )
}

type ActionsProps = {
  id: string
  isPublished?: boolean
}

function Actions(props: ActionsProps) {
  const { id, isPublished = false } = props

  const queryClient = useQueryClient()

  const urls = useUrls()
  const httpClient = useHTTPClient()
  const metaData = useQueryMetaData()
  const publishUrl = isPublished ? 'unpublished' : 'published'

  const successMessage = isPublished
    ? 'notifications.maskedtool'
    : 'notifications.publishedtool'
  const toast = useToastMessage()

  const { mutate: toggleIsPublished } = useMutation({
    mutationFn(): Promise<Tool.Write> {
      return httpClient.request({
        method: 'put',
        url: `/tools/${publishUrl}/${id}`,
        data: { isPublished: !isPublished },
        headers: metaData?.headers,
      })
    },

    async onSuccess() {
      toast('success', successMessage)
      await queryClient.invalidateQueries()
    },
    onError(error: Error) {
      toast('error', error.message)
    },
  })

  return (
    <HStack
      gap="10px"
      width="full"
      height="full"
      alignItems={'center'}
      justifyContent="flex-end"
    >
      <Button
        variant="circleList"
        iconColor={isPublished ? undefined : 'error.dark'}
        leftIcon={isPublished ? IconEyeHide : IconEyeShow}
        onClick={() => {
          toggleIsPublished()
        }}
      />
      <Button
        variant="circleList"
        leftIcon={IconRecherche}
        to={urls.aubade().toolsEdit(id)}
      />
    </HStack>
  )
}
