import { useQueryMetaData } from '@nartex/api-platform'
import type { BaseRecord } from '@pankod/refine-core'
import { useDataProvider } from '@pankod/refine-core'
import type { UseInfiniteQueryResult } from '@tanstack/react-query'
import { useInfiniteQuery } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import type { DeepPartial } from 'react-hook-form'

export function useInfiniteList<T extends BaseRecord, U>(
  resource: string,
  filters?: DeepPartial<U>,

  pageSize: number = 20,
) {
  const dataProvider = useDataProvider()()
  const queryMetaData = useQueryMetaData()

  const query = useInfiniteQuery(
    ['list', { resource, filters, pageSize, queryMetaData }] as const,
    async (queryVariables) => {
      const { queryKey, pageParam = 1 } = queryVariables
      const [
        _,
        {
          resource: queryResource,
          filters: queryFilters,
          pageSize: queryPageSize,
        },
      ] = queryKey

      const listResponse = await dataProvider.getList<T>({
        resource: queryResource,
        dataProviderName: 'default',
        pagination: {
          pageSize: queryPageSize,
          current: pageParam,
        },
        // @ts-ignore
        filters: queryFilters,
        metaData: queryMetaData,
      })
      return {
        ...listResponse,
        currentPage: pageParam,
      }
    },
    {
      getNextPageParam(lastPage) {
        if (lastPage.total === 0) return undefined
        return lastPage.currentPage + 1
      },
      suspense: false,
    },
  )

  return query as UseInfiniteQueryResult<
    {
      currentPage: any
      data: T[]
      total: number
    },
    AxiosError
  >
}
