import { isTruthy } from 'remeda'

import type { Falsy } from './truthy'

export function filterKeys<T extends Record<string, any>, U extends Partial<T>>(
  object: T,
  predicate: (key: keyof T, value: T[keyof T]) => boolean,
): U {
  const result = {} as U

  for (const key in object) {
    const value = object[key]

    const pass = predicate(key, value)
    if (pass) {
      ;(result as any)[key] = value
    }
  }

  return result
}

export function filterValues<
  T extends Record<string, any>,
  U extends Partial<T>,
>(object: T, predicate: (value: T[keyof T], key: keyof T) => boolean): U {
  const result = {} as U

  for (const key in object) {
    const value = object[key]
    if ((value as any) instanceof Date) {
      const pass = predicate(value.getTime(), key)
      if (pass) {
        ;(result as any)[key] = value.toString()
      }
    }
    if (typeof value === 'object') {
      for (const objectKey in value) {
        const val = value[objectKey]
        const pass = predicate(val, objectKey)
        if (pass) {
          ;(result as any)[key] = value
        }
      }
    } else {
      const pass = predicate(value, key)
      if (pass) {
        ;(result as any)[key] = value
      }
    }
  }

  return result
}

/** filters out falsy values from object */
export function compactObj<T extends Record<string, any>>(
  object: T,
): { [K in keyof T]: T[K] extends Falsy ? never : T[K] } {
  return filterValues(object, isTruthy)
}
