import {
  useForm,
  Form,
  Button,
  useToastMessage,
  unit,
  ButtonWithConfirm,
  Paragraph,
} from '@aubade/core/ui-kit'
import { DrawerContent } from '@aubade/domain/components'
import { HStack } from '@chakra-ui/react'
import { cleanHydraId, useDelete } from '@nartex/api-platform'
import { useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEnv } from 'src/adapters/Env'
import { useUrls } from 'src/App'

import { useTranslate } from '../../../../translation/src'
import type { ExtendedUser } from '../../../../types/src'
import { isAgency, useGetUser } from '../../components'

import {
  FormContent,
  FormHeader,
  FormProfile,
  FormUserList,
} from './Components'

type FormProps = {
  id: string
  profile: 'customer' | 'collaborater'
}
export function FormEdit(props: FormProps) {
  const { id, profile } = props
  const { id: me, role } = useGetUser()
  const urls = useUrls()
  const translate = useTranslate()

  const isMyProfile = cleanHydraId(id) === cleanHydraId(me)

  const { saveButtonProps, ...methods } = useForm<ExtendedUser>({
    mode: 'update',
    resource: 'users',
    id: id,
    hookFormOptions: {
      defaultValues: {},
    },
    redirect() {
      return urls.aubade().collaboraters()
    },
    blockNavigation: false,
  })

  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const toast = useToastMessage()

  const { mutate: mutateDelete } = useDelete()
  function onDelete() {
    mutateDelete(
      {
        resource: 'users',
        id: id,
      },
      {
        async onSuccess() {
          navigate(urls.aubade().customers())
          await queryClient.invalidateQueries()
          toast('success', 'notifications.deletedUser')
        },
      },
    )
  }

  const { firstname, lastname, acRoles } = methods.getValues()
  const userRole = useMemo(() => acRoles?.AC, [acRoles?.AC])
  const fullname = [firstname, lastname].filter(Boolean).join(' ')

  const actualUrl = window.location.href
  const changePasswordUrl = useGetKeycloakUrlAction(
    actualUrl,
    'UPDATE_PASSWORD',
  )

  const confirmText =
    userRole === 'CRAFTER_ADMIN'
      ? 'users.delete.confirmAdminText'
      : 'users.delete.confirmText'

  return (
    <Form {...methods} bucket={{ type: 'User' }}>
      <DrawerContent>
        <FormHeader profile={profile} />
        <FormContent profile={profile} />
        <FormProfile profile={profile} />
        <HStack gap={unit('30')} paddingBottom={unit('40')}>
          {profile === 'collaborater' ? (
            <Button {...saveButtonProps} variant="primary" />
          ) : (
            isAgency(role) && (
              <ButtonWithConfirm
                dialogProps={{
                  title: 'users.delete.confirmTitle',
                  children: (
                    <Paragraph
                      whiteSpace={'pre-wrap'}
                      size="md"
                      text={translate(confirmText, {
                        name: fullname,
                      })}
                    />
                  ),
                }}
                confirmButtonProps={{
                  isDisabled: false,
                  label: 'users.delete.confirmButton',
                  variant: 'primary',
                  isDangerous: true,
                }}
                buttonProps={{
                  label: 'buttons.users.delete',
                  isDisabled: false,
                  variant: 'text',
                  isDangerous: true,
                }}
                onConfirm={() => onDelete()}
              />
            )
          )}

          {isMyProfile && (
            <Button
              variant="text"
              label="buttons.changePassword"
              to={changePasswordUrl}
              newTab={false}
            />
          )}
        </HStack>
      </DrawerContent>
      <FormUserList profile={profile} />
    </Form>
  )
}

function useGetKeycloakUrlAction(redirectUri: string, action: string) {
  const env = useEnv()
  const { KEYCLOAK_CONFIG } = env
  const authority = `${KEYCLOAK_CONFIG.url}/realms/${KEYCLOAK_CONFIG.realm}`

  const url = `${authority}/protocol/openid-connect/auth?client_id=app-crafter-web&redirect_uri=${redirectUri}&response_type=code&scope=openid&kc_action=${action}`
  return url
}
