export const frenchLocales = {
  // months list by order
  months: [
    'Janvier',
    'Fevrier',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
  ],

  // week days by order
  weekDays: [
    {
      name: 'Lundi', // used for accessibility
      short: 'L', // displayed at the top of days' rows
    },
    {
      name: 'Mardi',
      short: 'Ma',
    },
    {
      name: 'Mercredi',
      short: 'Me',
    },
    {
      name: 'Jeudi',
      short: 'J',
    },
    {
      name: 'Vendredi',
      short: 'V',
    },
    {
      name: 'Samedi',
      short: 'S',
      isWeekend: true,
    },
    {
      name: 'Dimanche',
      short: 'D',
      isWeekend: true,
    },
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 6,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject: any) {
    return gregorainTodayObject
  },

  // return a native JavaScript date here
  toNativeDate(date: any) {
    return new Date(date.year, date.month - 1, date.day)
  },

  // return a number for date's month length
  getMonthLength(date: any) {
    return new Date(date.year, date.month, 0).getDate()
  },

  // return a transformed digit to your locale
  transformDigit(digit: any) {
    return digit
  },

  // texts in the date picker
  nextMonth: 'Prochain mois',
  previousMonth: 'Mois précédent',
  openMonthSelector: 'Open Month Selector',
  openYearSelector: 'Open Year Selector',
  closeMonthSelector: 'Close Month Selector',
  closeYearSelector: 'Close Year Selector',
  defaultPlaceholder: 'Select...',

  // for input range value
  from: 'De',
  to: 'À',
  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false,
}
