import { useOne, type DocCreate, useHTTPClient } from '@aubade/core/adapters'
import { useQueryParamsState, drawerQueryParams } from '@aubade/core/libs'
import type { FormSubmitProps } from '@aubade/core/ui-kit'
import {
  FormDrawer,
  Button,
  Form,
  useForm,
  useDocumentUrl,
  Paragraph,
  useModal,
  ButtonWithConfirm,
  useToastMessage,
  ReadOnlyProvider,
  makeForm,
  unit,
} from '@aubade/core/ui-kit'
import { IconFermer } from '@aubade/design/graphics'
import {
  DrawerContent,
  isAdmin,
  isSociety,
  useGetUser,
} from '@aubade/domain/components'
import { useTranslate } from '@aubade/translation'
import type { Contact } from '@aubade/types'
import type { Document } from '@aubade/types/api'
import {
  AspectRatio,
  Image,
  Icon,
  Box,
  Button as ChakraButton,
  VStack,
  Stack,
  SimpleGrid,
} from '@chakra-ui/react'
import {
  toHydraId,
  useDelete,
  cleanHydraId,
  useQueryMetaData,
} from '@nartex/api-platform'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useUrls } from 'src/App'
import { useMemoOne } from 'use-memo-one'
import { v4 as uuid } from 'uuid'

import { TargetSection, LinkSection, PublicationPreview } from './Components'
import { useFormValidation } from './formUtils/formValidators'
import type { FormType } from './types'
import { PublicationLinkEnum } from './types'
import { useIsBlocked } from './useIsBlocked'

const { Input } = makeForm<FormType>()

export function PublicationEdit() {
  const [modalState] = useQueryParamsState(drawerQueryParams)

  const { page, id } = modalState

  const matchCreate = page === 'create'
  const matchUpdate = page === 'edit' && id
  const match = Boolean(matchCreate || matchUpdate)

  return (
    <FormDrawer isOpenProps={Boolean(match)}>
      <DrawerContent>
        {matchCreate && <FormCreate />}
        {matchUpdate && <FormEdit id={id} />}
      </DrawerContent>
    </FormDrawer>
  )
}

function FormEdit(props: { id: string }) {
  const { id } = props
  const urls = useUrls()
  const { role } = useGetUser()

  const { saveButtonProps, ...methods } = useForm<FormType>({
    mode: 'update',
    resource: 'publications',
    id: id,
    hookFormOptions: {
      defaultValues: {
        publishedAt: new Date() as any,
      },
    },
    redirect() {
      return urls.aubade().publications()
    },
    blockNavigation: false,
  })
  const isDraft = methods.getValues('isDraft')

  const fullAuthor = methods.getValues('fullAuthor')

  const hasEditRights = useMemo(() => {
    if (!fullAuthor) return false
    if (isAdmin(role) && isAdmin(fullAuthor?.acRoles.AC as any)) {
      return true
    }
    if (isSociety(role) && !isAdmin(fullAuthor.acRoles.AC as any)) {
      return true
    }
    return false
  }, [fullAuthor, role])
  const publishedAt = methods.getValues('publishedAt')
  const canEdit =
    isDraft ||
    (hasEditRights && new Date(publishedAt!).getTime() > new Date().getTime())
  const title = isDraft ? 'publications.title.edit' : 'publications.title.show'

  return (
    <>
      <Paragraph fontWeight="bold" size="md" color="black.base" text={title} />
      <Form {...methods} bucket={{ type: 'Publication' }}>
        <ReadOnlyProvider value={!canEdit}>
          <FormContent
            canEdit={canEdit}
            publicationId={id}
            {...saveButtonProps}
          />
        </ReadOnlyProvider>
      </Form>
    </>
  )
}

export function FormCreate() {
  const urls = useUrls()
  const { id: me } = useGetUser()
  const newId = useMemoOne(() => {
    return uuid()
  }, [])

  const { saveButtonProps, ...methods } = useForm<FormType>({
    mode: 'create',
    resource: 'publications/create',
    redirect() {
      return urls.aubade().publications()
    },
    blockNavigation: false,
    hookFormOptions: {
      defaultValues: {
        newId,
        linkType: PublicationLinkEnum['enum./publicationlink/WITHOUT_LINK'],
        author: toHydraId('users', me!),
        isDraft: false,
        isNational: 'true',
        isAgencies: 'true',
        publishedAt: new Date() as any,
      },
    },
  })

  return (
    <>
      <Paragraph
        fontWeight="bold"
        size="lg"
        color="black.base"
        text="publications.title.create"
      />
      <Form {...methods} bucket={{ type: 'Publication' }}>
        <FormContent newPulicationId={newId} {...saveButtonProps} />
      </Form>
    </>
  )
}

type FormContentProps = FormSubmitProps & {
  newPulicationId?: string
  publicationId?: string
  canEdit?: boolean
  standAlone?: boolean
}

export function FormContent(props: FormContentProps) {
  const suite = useFormValidation()
  const {
    newPulicationId,
    canEdit = true,
    publicationId,
    standAlone = false,
    ...saveButtonProps
  } = props
  const { id: me, role } = useGetUser()
  const values = useWatch()
  const fullAuthor = useWatch({ name: 'fullAuthor' })
  const { setValue } = useFormContext()
  const { mutate: mutateDelete } = useDelete()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const urls = useUrls()
  const canPublish = useMemo(() => {
    return suite(values as FormType).isValidByGroup('publish')
  }, [suite, values])
  const canDraft = suite(values as FormType).isValidByGroup('draft')

  const { fields, append, remove } = useFieldArray({
    name: 'documents',
  })

  const canPreview = useMemo(() => {
    if (!fields) return false
    return fields.length > 0
  }, [fields])

  const translate = useTranslate()
  const [modal, openModal] = useModal({
    withCloseButton: true,
  })
  const isDisabled = useMemo(() => {
    return Boolean(!canEdit || !canPublish)
  }, [canEdit, canPublish])

  const saveButtonLabel =
    !values.id || values.isDraft ? 'buttons.publish' : 'buttons.save'

  const showButtonSave = useMemo(() => {
    if (isAdmin(role)) return true
    if (isSociety(role)) {
      if (newPulicationId) {
        return true
      }
      if (!newPulicationId) {
        if (fullAuthor && fullAuthor.isAubade) {
          return false
        } else {
          return true
        }
      }
    }
  }, [fullAuthor, newPulicationId, role])

  const showBottonDraft = Boolean(!values.id || values.isDraft)
  const showButtonHide = useMemo(() => {
    if (!fullAuthor) return false
    if (isSociety(role) && fullAuthor.isAubade === true) {
      return true
    }
    return false
  }, [fullAuthor, role])

  Boolean(values.id && !values.isDraft)
  const showButtonDelete = useMemo(() => {
    if (
      isSociety(role) &&
      values.id &&
      fullAuthor &&
      fullAuthor.isAubade === false
    ) {
      return true
    }
    if (isAdmin(role) && values.id) {
      return true
    }
    return false
  }, [fullAuthor, role, values.id])

  const toast = useToastMessage()

  function onDelete(id: string) {
    mutateDelete(
      {
        resource: 'publications',
        id: id,
      },
      {
        async onSuccess() {
          navigate(urls.aubade().publications())
          await queryClient.invalidateQueries()
          toast('success', 'notifications.deletedPublication')
        },
      },
    )
  }

  const httpClient = useHTTPClient()
  const isBlocked = useIsBlocked(values.blockedBy)

  const blockedUrl = isBlocked ? 'unblocked' : 'blocked'
  const successMessage = isBlocked
    ? 'notifications.unblockedPublication'
    : 'notifications.blockedPublication'
  const metaData = useQueryMetaData()

  const [currentUser] = useOne<Contact>({
    iri: toHydraId('users', me!),
  })

  const { mutate: mutateHide } = useMutation({
    mutationFn(): Promise<any> {
      return httpClient.request({
        method: 'put',
        url: `/posts/${blockedUrl}/${cleanHydraId(
          currentUser!.society!['@id'],
        )}/${values.id}`,
        data: {},
        headers: metaData?.headers,
      })
    },
    async onSuccess() {
      navigate(urls.aubade().publications())
      toast('success', successMessage)
      await queryClient.invalidateQueries()
    },
    onError(error: Error) {
      toast('error', error.message)
    },
  })

  const hideButtonLabel = isBlocked ? 'buttons.unMask' : 'buttons.hide'

  return (
    <>
      <VStack gap={unit('5')} alignItems={'flex-start'}>
        <Paragraph size="2xs" text={'publications.fields.documents'} />
        <SimpleGrid columns={2} spacing={'10px'}>
          {fields &&
            fields.length > 0 &&
            (fields as (Document.Read | DocCreate)[]).map((document, index) => {
              return (
                <Box
                  position="relative"
                  borderRadius={unit('10')}
                  overflow="hidden"
                  key={document['@id']}
                  width={unit('150')}
                  height={unit('150')}
                >
                  <Picture key={document['@id']} document={document} />
                  {canEdit && (
                    <ChakraButton
                      onClick={(event) => {
                        remove(index)
                        event.stopPropagation()
                      }}
                      title={translate('actions.delete')}
                      position="absolute"
                      top={1}
                      right={1}
                      color="darkGrey.500"
                      minWidth={0}
                      backgroundColor="transparent"
                    >
                      <Icon
                        as={IconFermer}
                        width={unit('15')}
                        height={unit('15')}
                      />
                    </ChakraButton>
                  )}
                </Box>
              )
            })}

          <Box maxWidth={unit('200')}>
            <Input.DropZone
              name="documents"
              multiple={true}
              onAppend={append}
              disabled={fields.length >= 6}
              objectId={newPulicationId ?? publicationId}
              boxProps={{
                width: unit('150'),
                height: unit('150'),
                gap: unit('14'),
                flexDirection: 'column-reverse',
                borderRadius: 10,
              }}
            />
          </Box>
        </SimpleGrid>
        {/* </HStack> */}
      </VStack>
      <Input.Text
        name="title"
        label="publications.fields.title"
        maxLength={200}
      />
      <Input.TextArea
        name="content"
        label="publications.fields.description"
        maxLength={500}
      />
      <VStack gap={unit('40')} alignItems={'flex-start'} width="full">
        <TargetSection />
        <LinkSection />
        <Planify />

        <VStack
          alignItems="flex-start"
          justifyContent="flex-start"
          gap={'60px'}
        >
          <Stack
            gap={['40px', '40px', '30px']}
            justifyContent={'flex-start'}
            direction={['column', 'column', 'row']}
          >
            {canEdit && showButtonSave && (
              <Button
                {...saveButtonProps}
                label={saveButtonLabel}
                variant="primary"
                isDisabled={isDisabled}
                onClick={(evt) => {
                  const { publishedAt } = values
                  setValue('isDraft', false)
                  if (!publishedAt || publishedAt === null) {
                    setValue('publishedAt', new Date())
                  }
                  saveButtonProps?.onClick?.(evt)
                }}
              />
            )}
            {showBottonDraft && (
              <Button
                {...saveButtonProps}
                label="buttons.draft"
                variant="text"
                isDisabled={!canDraft}
                onClick={(evt) => {
                  setValue('isDraft', true)
                  setValue('publishedAt', undefined)
                  saveButtonProps?.onClick?.(evt)
                }}
              />
            )}
            {!standAlone && (
              <Button
                label="buttons.preview"
                variant="text"
                isDisabled={!canPreview}
                onClick={async (event) => {
                  event.preventDefault()
                  await openModal(() => {
                    return <PublicationPreview />
                  })
                }}
              />
            )}
            {showButtonHide && (
              <Button
                label={hideButtonLabel}
                variant="text"
                onClick={() => {
                  mutateHide()
                }}
              />
            )}
          </Stack>
          {showButtonDelete && (
            <ButtonWithConfirm
              dialogProps={{
                title: 'publications.confirmTitle',
                children: translate('publications.confirmText'),
              }}
              confirmButtonProps={{
                isDisabled: false,
                label: 'publications.confirmButton',
                variant: 'primary',
              }}
              buttonProps={{
                label: 'buttons.delete',
                isDisabled: false,
                variant: 'text',
                isDangerous: true,
              }}
              onConfirm={() => onDelete(values.id)}
            />
          )}
        </VStack>
      </VStack>
      {modal}
    </>
  )
}

export function Picture(props: { document: Document.Read | DocCreate }) {
  const { document } = props

  const pictureUrl = useDocumentUrl(document, {
    size: { width: 200, height: 200 },
    ratio: 'crop',
  })

  if ('file' in document && document.file.type?.includes('video')) {
    return (
      <AspectRatio ratio={1} width="100%" backgroundColor="white.500">
        <iframe
          src={pictureUrl}
          allow="fullscreen; picture-in-picture"
          title="sample"
          style={{
            width: 'full',
            objectFit: 'contain',
          }}
        />
      </AspectRatio>
    )
  }
  if (document?.playerEmbedUrl) {
    return (
      <AspectRatio ratio={1} width="100%">
        <iframe
          src={document.playerEmbedUrl}
          allow="fullscreen; picture-in-picture"
          title="sample"
          style={{
            width: 'full',
            height: 'full',
            objectFit: 'cover',
          }}
        />
      </AspectRatio>
    )
  }

  return (
    <AspectRatio ratio={1} width="100%" backgroundColor="white.500">
      <Image alt={document.displayName} src={pictureUrl} />
    </AspectRatio>
  )
}

function Planify() {
  const [planify] = useWatch<{ planify?: string }>({
    name: ['planify'],
  })

  return (
    <>
      <Input.Checkbox
        name="planify"
        label="publications.fields.planify"
        inputLabel="publications.title.planify"
      />

      {planify && (
        <Input.DateTime
          name="publishedAt"
          label="publications.fields.publishedAt"
          min
        />
      )}
    </>
  )
}
