// https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md#internal-implementation

import { useRef, useLayoutEffect, useCallback } from 'react'

export function useEvent<T extends (...args: any[]) => any>(handler: T): T {
  const handlerRef = useRef<T>(handler)

  // In a real implementation, this would run before layout effects
  useLayoutEffect(() => {
    handlerRef.current = handler
  })

  const result = useCallback<any>((...args: Parameters<T>): ReturnType<T> => {
    // In a real implementation, this would throw if called during render
    const fn = handlerRef.current
    return fn!(...args)
  }, []) as T

  if (process.env.NODE_ENV === 'development') {
    Object.defineProperty(result, 'name', {
      get() {
        return `useEvent(${handlerRef.current.name ?? 'anonymous'})`
      },
    })
  }

  return result
}
