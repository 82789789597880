import type { Merge } from '@aubade/core/libs'
import { IconDot, IconFermer } from '@aubade/design/graphics'
import type { TranslationKey } from '@aubade/translation'
import { useTranslate } from '@aubade/translation'
import {
  Flex,
  HStack,
  Tag,
  TagLeftIcon,
  TagRightIcon,
  Wrap,
} from '@chakra-ui/react'
import type { FocusEvent } from 'react'
import { useCallback, forwardRef, useId, useReducer } from 'react'

import { Button } from '../Button'

import type { RHFInputProps } from './controller'
import { useInput } from './controller'
import { getSelectOptions } from './getSelectOptions'
import { ReadOnlyInputRender } from './ReadOnlyInputs'

type ChipInputProps<T, Outer> = Merge<
  RHFInputProps<T, Outer | null>,
  {
    label: TranslationKey
    options: Record<string, string> | string[]
    minimumOptions?: Record<string, string> | string[]
    translationKey?: string | ((value: string) => string)
    nullOption?: string
    noWrap?: boolean
  }
>

type InnerChipInputProps<Outer> = {
  max?: number
  isSelectedFn: (
    currentValue: Outer | null | undefined,
    optionValue: string,
  ) => boolean
  onClickOption: (
    optionValue: string,
    isSelected: boolean,
    fieldValue: Outer | null | undefined,
  ) => Outer | null
  canSelectMoreFn?: (currentValue: Outer | null | undefined) => boolean
}

function ChipInput<T, Outer extends string | string[]>(
  props: ChipInputProps<T, Outer> & InnerChipInputProps<Outer>,
) {
  const {
    label,
    nullOption,
    required,
    options,
    minimumOptions,
    translationKey,
    max, // The default value is passed by the MultipleChipInput component
    defaultValue,
    isSelectedFn,
    onClickOption,
    canSelectMoreFn,
    noWrap = false,
  } = props
  const translate = useTranslate()

  const { field, wrap } = useInput<T, Outer | null>({
    ...props,
    defaultValue,
  })
  const { value, onChange } = field

  const [isShowingAll, showAll] = useMinimumOptions(value, minimumOptions)
  const showOnlyMin = minimumOptions && !isShowingAll

  const optionsToRender = showOnlyMin ? minimumOptions : options
  const normalizedOptions = getSelectOptions(optionsToRender)

  const getLabel = useCallback(
    (optionLabel: string) => {
      function getTranslationKey() {
        if (!translationKey) return optionLabel
        if (typeof translationKey === 'function') {
          return translationKey(optionLabel)
        }
        return `${translationKey}.${optionLabel}`
      }
      const labelTranslationKey = getTranslationKey()
      if (labelTranslationKey.startsWith('/enums/')) {
        return translate.enum(labelTranslationKey)
      }
      return translate(labelTranslationKey)
    },
    [translationKey, translate],
  )

  const Container = noWrap ? HStack : Wrap

  return wrap(
    field.readOnly ? (
      <ReadOnlyInputRender {...props} field={field as any} />
    ) : (
      <Container
        role="radiogroup"
        aria-required={required}
        aria-label={translate(label)}
        overflowX={noWrap ? 'auto' : undefined}
        paddingBottom={1}
      >
        {nullOption && (
          <ChipOption
            {...field}
            isSelected={!value}
            onClick={() => onChange(null)}
            label={getLabel(nullOption)}
            multiple={false}
          />
        )}
        {normalizedOptions.map(([optionValue, optionLabel]) => {
          const isSelected = isSelectedFn(value, optionValue)
          return (
            <ChipOption
              {...field}
              key={optionValue}
              isSelected={isSelected}
              multiple={Boolean(max && max > 1)}
              onClick={() => {
                const newValue = onClickOption(optionValue, isSelected, value)
                onChange(newValue)
              }}
              canSelectMore={canSelectMoreFn?.(value)}
              label={getLabel(optionLabel)}
            />
          )
        })}
        {showOnlyMin && (
          <Flex pl={2} alignItems="center">
            <Button
              variant="link"
              onClick={() => showAll?.(true)}
              label="buttons.viewMore"
            />
          </Flex>
        )}
      </Container>
    ),
  )
}

export function SingleChipInput<T>(
  props: Omit<ChipInputProps<T, string>, 'max'>,
) {
  return (
    <ChipInput<T, string>
      {...props}
      isSelectedFn={(currentValue, optionValue) => {
        return currentValue === optionValue
      }}
      onClickOption={(optionValue, isSelected) => {
        if (isSelected && !props.nullOption) {
          return null
        }
        return optionValue
      }}
    />
  )
}

export function MultipleChipInput<T>(
  props: Omit<ChipInputProps<T, string[]>, 'nullOption'> & {
    max?: number
  },
) {
  const { max = Infinity } = props
  return (
    <ChipInput<T, string[]>
      {...props}
      max={max}
      isSelectedFn={(currentValue, optionValue) => {
        return Boolean(currentValue && currentValue.includes(optionValue))
      }}
      onClickOption={(optionValue, isSelected, fieldValue) => {
        if (isSelected) {
          const newValue = fieldValue!.filter((value) => value !== optionValue)
          if (newValue.length > 0) return newValue
          return null
        }
        if (fieldValue) {
          if (fieldValue.length < max) {
            return [...fieldValue, optionValue]
          }
          return fieldValue
        }
        return [optionValue]
      }}
      canSelectMoreFn={(currentValue) => {
        return Boolean((currentValue?.length ?? 0) < max)
      }}
    />
  )
}

type ChipOptionProps = {
  isSelected: boolean
  onClick: () => void
  onBlur: (event: FocusEvent<HTMLElement>) => void
  onFocus: (event: FocusEvent<HTMLElement>) => void
  label: string
  multiple: boolean
  canSelectMore?: boolean
}

const ChipOption = forwardRef<HTMLElement, ChipOptionProps>(function ChipOption(
  props,
  ref,
) {
  const {
    isSelected,
    onClick,
    label,
    onFocus,
    onBlur,
    multiple,
    canSelectMore = true,
  } = props

  function getSelectedStyle() {
    if (isSelected) {
      return {
        color: 'white',
        backgroundColor: 'primary.500',
        borderColor: 'primary.500',
      }
    }
    return {
      color: 'primary.500',
      backgroundColor: 'white',
      borderColor: 'primary.500',
    }
  }

  const id = useId()

  return (
    <Tag
      ref={ref}
      onFocus={onFocus}
      onBlur={onBlur}
      borderRadius="full"
      variant="solid"
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick()
        }
      }}
      size="lg"
      cursor="pointer"
      role="radio"
      aria-checked={isSelected}
      aria-labelledby={id}
      tabIndex={0}
      width="fit-content"
      minWidth="fit-content" // min-width break the tag width
      border="1px solid"
      paddingX="12px"
      paddingY="6px"
      lineHeight={1.5}
      transition={'.2s ease-out'}
      {...getSelectedStyle()}
    >
      {multiple && !isSelected && canSelectMore && <TagLeftIcon as={IconDot} />}

      <label id={id} style={{ cursor: 'pointer', userSelect: 'none' }}>
        {label}
      </label>
      {multiple && isSelected && <TagRightIcon as={IconFermer} />}
    </Tag>
  )
})

function useMinimumOptions(
  value: any,
  minimumOptions?: Record<string, string> | string[],
): [boolean, ((show: boolean) => void) | undefined] {
  const [isShowingAll, showAll] = useReducer(() => true, false)

  if (!minimumOptions) return [true, undefined]

  if (isShowingAll) return [isShowingAll, showAll]

  if (value) {
    const minimumValues = getSelectOptions(minimumOptions).map(
      ([optionValue]) => optionValue,
    )

    if (!minimumValues.includes(value)) return [true, showAll]
  }

  return [isShowingAll, showAll]
}
