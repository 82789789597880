import { ResourcePageLayout } from 'src/components/ResourcePageLayout'

import { FlowMessages } from './Components'
import { ConversationList } from './Components/ConversationList'

export function List() {
  return (
    <ResourcePageLayout>
      <ConversationList />
      <FlowMessages />
    </ResourcePageLayout>
  )
}
