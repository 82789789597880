import { useToastMessage } from '@aubade/core/ui-kit'
import type { Api } from '@aubade/types/api'
import { cleanHydraId, useUpdate } from '@nartex/api-platform'
import type { DataProvider, HttpError } from '@pankod/refine-core'
import { useQueryClient } from '@tanstack/react-query'

type SingularToPluralMap = typeof Api.singularToPluralMap

export type BaseAction<
  Read extends Api.Resource,
  Write extends Api.Resource,
> = {
  resource: Write['@type'] extends keyof SingularToPluralMap
    ? SingularToPluralMap[Write['@type']]
    : string
  id: Api.IriReference // this may be optional some day
  type: string
  payload?: Record<string, any>
  optimisticResult?: Partial<Read>
}

export type ExtractActionType<
  T extends Record<string, (...args: any[]) => BaseAction<any, any>>,
> = ReturnType<T[keyof T]>

export function useResourceAction<
  Write extends Api.Resource,
  Read extends Api.Resource,
>() {
  const update = useUpdate<Read, HttpError, Partial<Read | undefined>>()
  const queryClient = useQueryClient()
  const toastMessage = useToastMessage()

  return {
    ...update,
    mutate<Action extends BaseAction<Read, Write>>(
      action: Action,
      options?: Parameters<(typeof update)['mutate']>[1],
    ) {
      const [params, mutateOptions] = actionMutateParams(action, options)
      update.mutate(params, {
        ...mutateOptions,
        async onSettled(...args) {
          await mutateOptions?.onSettled?.(...args)
          await queryClient.invalidateQueries()
        },

        async onError(...args) {
          if (mutateOptions?.onError) {
            await mutateOptions?.onError?.(...args)
            return
          }

          toastMessage('error', 'notifications.actionError')
        },
        async onSuccess(...args) {
          if (mutateOptions?.onSuccess) {
            await mutateOptions?.onSuccess?.(...args)
            return
          }

          toastMessage('success', 'notifications.actionSuccess')
        },
      })
    },
  }
}

export function actionMutateParams<
  Write extends Api.Resource,
  Read extends Api.Resource,
  Action extends BaseAction<Read, Write>,
>(
  action: Action,
  options?: Parameters<
    ReturnType<
      typeof useUpdate<Read, HttpError, Partial<Write | undefined>>
    >['mutate']
  >[1],
) {
  const { resource, id, optimisticResult } = action

  return [
    {
      resource,
      id,
      values: optimisticResult,
      metaData: {
        action,
      },
      mutationMode: optimisticResult ? 'optimistic' : 'pessimistic',
    },
    options,
  ] as const
}

export type ActionHandler<
  ActionType extends BaseAction<any, any> = BaseAction<any, any>,
  MetaData extends Record<string, any> = {},
> = (
  dataProvider: DataProvider,
  action: ActionType,
  metaData: Partial<MetaData>,
) => Promise<unknown>

export function actionsProxy<
  ActionType extends BaseAction<any, any> = BaseAction<any, any>,
  MetaData extends Record<string, any> = {},
>(
  dataProvider: DataProvider,
  actionHandler: ActionHandler<ActionType, MetaData>,
): DataProvider {
  return {
    ...dataProvider,
    async update(params) {
      const { metaData } = params
      const action: ActionType | undefined = params.metaData?.action
      if (!action) {
        return dataProvider.update(params)
      }

      const result = await actionHandler(
        dataProvider,
        action,
        (metaData ?? {}) as Partial<MetaData>,
      )
      return { data: result as any }
    },
  }
}

export async function defaultActionRequest(
  dataProvider: DataProvider,
  action: BaseAction<any, any>,
  metaData: Record<string, any>,
) {
  const { resource, id, type, payload } = action
  const { data } = await dataProvider.custom!({
    method: 'put',
    url: `/${resource}/${cleanHydraId(String(id))}/${type}`,
    metaData,
    payload,
  })
  return data
}
