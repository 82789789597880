import { IconArrowRight, IconArrowLeft } from '@aubade/design/graphics'
import type { ButtonProps as ChakraButtonProps } from '@chakra-ui/react'
import { Button, Center, Icon } from '@chakra-ui/react'

import type { ButtonProps } from '../Button'
import { getButtonBehavior as getCoreButtonBehavior } from '../Button'
import { Paragraph } from '../Paragraph'
import { unit } from '../utils'

export type PaginationResult = {
  page: number
  perPage: number
  nbPages: number
  total: number
}

const PageButtonStyle: ChakraButtonProps = {
  iconSpacing: 0,
  padding: unit('10'),
  height: unit('40'),
  width: unit('40'),
  borderRadius: '100px',
  background: 'transparent',
}

export type PaginationProps = {
  pagination: PaginationResult
  getButtonBehavior: (page: number) => Pick<ButtonProps, 'onClick' | 'to'>
}
export function Pagination(props: PaginationProps) {
  const { pagination, getButtonBehavior } = props
  const { page, nbPages } = pagination

  if (nbPages === 0) return null

  const isFirstPage = page === 1
  const isLastPage = page === nbPages

  function getAdjacentPages() {
    if (nbPages < 3)
      return new Array(nbPages).fill('').map((_, index) => index + 1)
    if (isFirstPage) {
      return [1, 2, 3]
    }
    if (isLastPage) {
      return [nbPages - 2, nbPages - 1, nbPages]
    }
    return [page - 1, page, page + 1]
  }

  return (
    <Center width="full" gap={4}>
      {!isFirstPage && (
        <Button
          {...PageButtonStyle}
          {...getCoreButtonBehavior(getButtonBehavior(page - 1))}
          leftIcon={
            <Icon height={6} width={6} as={IconArrowLeft} color="primary.500" />
          }
        />
      )}
      {getAdjacentPages().map((value) => {
        const isActive = page === value

        return (
          <Button
            key={value}
            {...PageButtonStyle}
            background={isActive ? 'primary.500' : 'primary.100'}
            {...getCoreButtonBehavior(getButtonBehavior(value))}
          >
            <Paragraph
              fontWeight="bold"
              color={isActive ? 'white' : 'primary.500'}
            >
              {value}
            </Paragraph>
          </Button>
        )
      })}
      {!isLastPage && (
        <Button
          {...PageButtonStyle}
          {...getCoreButtonBehavior(getButtonBehavior(page + 1))}
          leftIcon={
            <Icon
              height={6}
              width={6}
              as={IconArrowRight}
              color="primary.500"
            />
          }
        />
      )}
    </Center>
  )
}
