import { useGetUser } from '@aubade/domain/components'

export function useIsBlocked(blockedBy?: string[]) {
  const { society, role } = useGetUser()

  if (role !== 'ROLE_APP_CRAFTER_COMPANY') return false
  if (!blockedBy || blockedBy.length === 0) return false
  if (blockedBy.includes(society?.id)) return true
  return false
}
