import { useSearchParamsState } from '@aubade/core/libs'
import { useMemo } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import type {
  BaseRecord,
  GetListQueryParams,
  SearchFilter,
} from '../../adapters'
import { useList } from '../../adapters'

import { Pagination } from '../Table'
import type { PaginationResult } from '../Table'

import { PAGINATION_SEARCH_PARAM, PAGE_SEARCH_PARAM } from '.'

export type UseResourceListParams<T extends BaseRecord> = {
  scope: string
  paginationDefaults?: { perPage: number }
} & Pick<
  GetListQueryParams<T>,
  'resource' | 'filters' | 'sort' | 'dataProviderName' | 'hasPagination'
>

export function useResourceList<T extends BaseRecord>(
  props: UseResourceListParams<T>,
) {
  const {
    resource,
    filters,
    sort,
    scope,
    paginationDefaults,
    dataProviderName,
    hasPagination,
  } = props
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const scopedPageParam = `${scope}.${PAGINATION_SEARCH_PARAM}.${PAGE_SEARCH_PARAM}`

  const [searchParamsState] = useSearchParamsState({
    scope,
    defaultState: {
      [PAGINATION_SEARCH_PARAM]: {
        [PAGE_SEARCH_PARAM]: 1,
        perPage: 100,
        ...paginationDefaults,
      },
    },
  })

  const pagination: Pick<PaginationResult, 'page' | 'perPage'> = useMemo(() => {
    return {
      page: searchParamsState[PAGINATION_SEARCH_PARAM]?.[PAGE_SEARCH_PARAM]!,
      perPage: searchParamsState[PAGINATION_SEARCH_PARAM]?.perPage!,
    }
  }, [searchParamsState])

  const listFilters = useMemo(() => {
    const search = searchParams.get(`${scope}.q`)
    if (search) {
      const qFilter: SearchFilter = { q: search }
      if (filters) {
        return [qFilter, ...filters]
      }
      return [qFilter]
    }
    return filters
  }, [scope, searchParams, filters])

  const listParams = useMemo(() => {
    return {
      resource,
      filters: listFilters,
      sort,
      hasPagination: hasPagination ?? true,
      pagination: {
        current: pagination.page,
        pageSize: pagination.perPage,
      },
      dataProviderName,
    }
  }, [
    dataProviderName,
    hasPagination,
    listFilters,
    pagination.page,
    pagination.perPage,
    resource,
    sort,
  ])

  const [data, results] = useList<T>(listParams, { keepPreviousData: true })

  const paginationResult: PaginationResult = useMemo(() => {
    return {
      ...pagination,
      nbPages: results.data?.total
        ? Math.ceil(results.data.total / pagination.perPage)
        : 0,
      total: results.data?.total ?? 0,
    }
  }, [pagination, results.data?.total])

  return useMemo(
    () =>
      [
        [data, results, listParams],
        <Pagination
          key="pagination"
          pagination={paginationResult}
          getButtonBehavior={(newPage) => {
            const newSearchParams = new URLSearchParams(searchParams)

            if (newPage === 1) {
              newSearchParams.delete(scopedPageParam)
            } else {
              newSearchParams.set(scopedPageParam, String(newPage))
            }

            return {
              to: {
                pathname: location.pathname,
                search: newSearchParams.toString(),
              },
            }
          }}
        />,
      ] as const,
    [
      data,
      location.pathname,
      paginationResult,
      results,
      scopedPageParam,
      searchParams,
      listParams,
    ],
  )
}
