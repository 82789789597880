import type { SearchFilter } from '@aubade/core/adapters'
import { useOne, useList } from '@aubade/core/adapters'
import { Boundary, SearchBar, makeForm } from '@aubade/core/ui-kit'
import { useGetUser } from '@aubade/domain/components'
import type { Contact } from '@aubade/types'
import type { Typesense } from '@aubade/types/typesense'
import { Box } from '@chakra-ui/react'
import { toHydraId } from '@nartex/api-platform'
import type { BaseSyntheticEvent, ForwardedRef } from 'react'
import { useMemo, forwardRef, memo, Fragment } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useScope } from '../ScopeProvider'

const { Input } = makeForm()

type AgencyFilterProps = {
  light?: boolean
  societyId?: string
  onFilterChange?: (
    e?: BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>
}

/**
 * Component for filters and form, for agencies, name agencies
 * @param {string} societyId - the id of a single society
 * @param {function} onFilterChange - callback onFilterStateChange from useFilter, for filters only
 */
export const AgencyFilter = memo(
  forwardRef(function (
    props: AgencyFilterProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    const { onFilterChange, societyId, light = false } = props
    const { id: me } = useGetUser()
    const [actualUser] = useOne<Contact>({ iri: toHydraId('users', me!) })
    const { societyAgency, society } = actualUser!

    const { agencies } = useScope()

    const [searchParams] = useSearchParams({ scope: agencies })
    const listFilters = useMemo(() => {
      const search = searchParams.get(`agencies.q`)
      if (search) {
        const qFilter: SearchFilter = { q: search }
        return [qFilter]
      }
      return []
    }, [searchParams])

    const [fullAgencies] = useList<Typesense.Agencies>({
      resource: 'agencies',
      dataProviderName: 'default',
      hasPagination: true,
      pagination: { pageSize: 250 },
      filters: [
        ...listFilters,
        {
          field: 'society.id',
          operator: 'eq',
          value: societyId ?? society?.id ?? societyAgency?.id,
        },
      ],
    })

    const lightStyle = getLightStyle(light)

    if (!fullAgencies) return <></>
    return (
      <Box width="full" ref={ref}>
        {!light && <SearchBar scope="agencies" />}
        <Box padding={2} width="full" {...lightStyle}>
          <Boundary>
            {fullAgencies.map((agency) => {
              return (
                <Input.Checkbox
                  key={agency.id}
                  name={`agencies.${agency.id}`}
                  label={agency.name ?? ' '}
                  onChange={() => onFilterChange?.()}
                />
              )
            })}
          </Boundary>
          <Box height="16px" />
        </Box>
      </Box>
    )
  }),
)

function getLightStyle(light: boolean) {
  if (!light)
    return {
      maxHeight: '350px',
      overflow: 'auto',
    }
  return {}
}
