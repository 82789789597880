import { useCreate, useUpdate, useOne } from '@nartex/api-platform'
import type {
  BaseRecord,
  CreateResponse,
  GetOneResponse,
  HttpError,
  UpdateResponse,
} from '@pankod/refine-core'
import * as Sentry from '@sentry/browser'
import type { UseQueryOptions, MutateOptions } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useMemo, useSyncExternalStore } from 'react'
import { useForm as useHookForm } from 'react-hook-form'
import type {
  UseFormProps as UseHookFormProps,
  SubmitHandler,
  SubmitErrorHandler,
  UseFormReturn,
} from 'react-hook-form'
import { useNavigate } from 'react-router'

import { useEvent } from '../../libs'
import { useBusyContext } from '../useIsBusy'
import { useToastMessage } from '../useToast'

import { useBlockNavigation } from './useBlockNavigation'

import type { FormSubmitProps } from '.'

export type UseFormProps<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TContext extends object = {},
> = (
  | {
      mode: 'create'
      resource: string
      id?: string | number
      sendOnlyDirtyValues?: boolean
    }
  | {
      mode: 'update'
      resource: string
      id: string | number
      sendOnlyDirtyValues?: boolean
    }
) & {
  queryOptions?: UseQueryOptions<GetOneResponse<TData>, TError>
  mutationOptions?: MutateOptions<
    CreateResponse<TData> | UpdateResponse<TData>,
    TError,
    TData,
    TContext
  >
  redirect: (
    data: CreateResponse<TData> | UpdateResponse<TData>,
  ) => string | false
  blockNavigation?: boolean
} & Omit<UseFormControllerParams<TData, TContext>, 'onValid' | 'onError'>
export function useForm<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TContext extends object = {},
>(params: UseFormProps<TData, TError, TContext>) {
  const {
    resource,
    id,
    mode,
    redirect,
    queryOptions,
    mutationOptions,
    blockNavigation = true,
    sendOnlyDirtyValues = false,
    hookFormOptions,
  } = params

  const queryResult = useOne<TData, TError>({
    resource,
    id: id!,
    queryOptions: {
      enabled: mode === 'update',
      suspense: true,
      ...queryOptions,
    },
  })

  const mutationUpdate = useUpdate<TData, TError, TData>()
  const mutationCreate = useCreate<TData, TError, TData>()

  const navigate = useNavigate()
  const toastMessage = useToastMessage()
  const queryClient = useQueryClient()
  async function onValid() {
    const mutation = mode === 'create' ? mutationCreate : mutationUpdate

    const rawValues = sendOnlyDirtyValues
      ? getDirtyValues(formState.dirtyFields, getValues())
      : getValues()
    const values = nullifyEmptyString(rawValues) as any

    const successBaseMessage =
      mode === 'create'
        ? 'notifications.createSuccess'
        : 'notifications.editSuccess'
    const errorBaseMessage =
      mode === 'create'
        ? 'notifications.createError'
        : 'notifications.editError'

    await mutation.mutateAsync(
      { id: id!, resource, values },
      {
        async onSuccess(
          response: CreateResponse<TData> | UpdateResponse<TData>,
        ) {
          await queryClient.invalidateQueries()
          const destination = redirect(response)
          if (!(mutationOptions as any)?.onSuccess) {
            toastMessage('success', successBaseMessage)
          }

          if (destination !== false) {
            unblock()
            navigate(destination)
          }
          reset(getValues(), { keepDirtyValues: false })
          ;(mutationOptions as any)?.onSuccess?.(response)
        },
        onError(error: TError) {
          Sentry.captureException(error)
          if (error.response?.status === 422) {
            // TODO Gérer les messages d'erreurs différemment avec l'API (ticket)
            if (!(mutationOptions as any)?.onError) {
              toastMessage('error', 'errorDuplicate')
            }
          } else {
            if (!(mutationOptions as any)?.onError) {
              toastMessage('error', errorBaseMessage)
            }
          }

          ;(mutationOptions as any)?.onError?.(error)
        },
        onSettled(data, error) {
          ;(mutationOptions as any)?.onSettled?.(data, error)
        },
      },
    )
  }

  // useOne is marked as loading when not enabled
  const isLoading =
    (mode === 'update' && queryResult.isLoading) ||
    mutationUpdate.isLoading ||
    mutationCreate.isLoading

  const formControls = useFormController<TData, TContext>({
    onValid,
    isLoading,
    hookFormOptions: {
      defaultValues: {
        ...hookFormOptions?.defaultValues,
        ...(queryResult.data?.data as any),
      },
      ...hookFormOptions,
    },
  })
  const { reset, getValues, formState, control } = formControls

  const isDirty = useIsDirty(control)

  const queryData = queryResult?.data?.data
  useEffect(() => {
    if (!queryData) return
    reset(queryData, { keepDirtyValues: true })
  }, [queryData, reset])

  const unblock = useBlockNavigation(blockNavigation && isDirty)

  const saveButtonProps = useMemo(() => {
    return {
      ...formControls.saveButtonProps,
      label: mode === 'create' ? 'buttons.create' : 'buttons.save',
    }
  }, [formControls.saveButtonProps, mode])

  return useMemo(() => {
    return {
      ...formControls,
      saveButtonProps,
    }
  }, [formControls, saveButtonProps])
}

type UseFormControllerParams<
  TFieldValues extends Record<string, any>,
  TContext extends object = {},
> = {
  hookFormOptions?: UseHookFormProps<TFieldValues, TContext>
  readOnly?: boolean
  isLoading?: boolean
  onValid: SubmitHandler<TFieldValues>
  onError?: SubmitErrorHandler<TFieldValues>
}

export function useFormController<
  TFieldValues extends Record<string, any>,
  TContext extends object = {},
>(params: UseFormControllerParams<TFieldValues, TContext>) {
  const {
    hookFormOptions,
    readOnly,
    isLoading,
    onValid,
    onError = defaultOnError,
  } = params

  const toast = useToastMessage()
  function defaultOnError() {
    toast('warning', 'notifications.invalid')
  }

  const useHookFormResult = useHookForm(hookFormOptions)

  const { handleSubmit } = useHookFormResult
  const onSubmit = useEvent(handleSubmit(onValid, onError))
  const [isBusy, BusyProvider] = useBusyContext()

  const saveButtonProps: FormSubmitProps = useMemo(() => {
    return {
      isDisabled: isLoading || isBusy || readOnly,
      onClick: onSubmit,
      isLoading: isLoading || isBusy,
      label: 'buttons.validate',
    }
  }, [isBusy, isLoading, onSubmit, readOnly])

  return useMemo(() => {
    return {
      ...useHookFormResult,
      onSubmit,
      saveButtonProps,
      readOnly,
      BusyProvider,
    }
  }, [BusyProvider, onSubmit, readOnly, saveButtonProps, useHookFormResult])
}

type WithNullifiedStrings<T extends Record<string, any>> = {
  [K in keyof T]: T[K] extends string ? string | null : T[K]
}
function nullifyEmptyString<T extends Record<string, any>>(
  data: T,
): WithNullifiedStrings<T> {
  const res: Record<string, any> = {}

  Object.entries(data).forEach(([key, value]) => {
    if (value === '') {
      return (res[key] = null)
    }
    return (res[key] = value)
  })
  return res as WithNullifiedStrings<T>
}

function getDirtyValues<
  TDirtyFields extends Record<string, unknown>,
  TValues extends BaseRecord = BaseRecord,
>(dirtyFields: TDirtyFields, values: TValues): Partial<typeof values> {
  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RFH sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) return prev

    return {
      ...prev,
      [key]:
        typeof dirtyFields[key] === 'object'
          ? getDirtyValues(
              dirtyFields[key] as TDirtyFields,
              values[key] as TValues,
            )
          : values[key],
    }
  }, {})

  return dirtyValues
}

// using this methods in place of the traditionnal "formState.isDirty" prevents unnecessary rerenders
function useIsDirty(control: UseFormReturn<any>['control']) {
  return useSyncExternalStore(
    useCallback(
      (notify) => {
        const { unsubscribe } = control._subjects.state.subscribe({
          next: notify,
        })
        return unsubscribe
      },
      [control],
    ),
    useCallback(() => {
      return control._formState.isDirty
    }, [control]),
  )
}
