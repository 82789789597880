import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'

type Props = {
  color?: BoxProps['color']
  paddingX?: BoxProps['paddingX']
}
export function Separator(props: Props) {
  const { color = 'lightGrey.500', paddingX } = props
  if (paddingX) {
    return (
      <Box width="full" paddingX={paddingX}>
        <Box width="full" borderTop="1px" borderColor={color} />
      </Box>
    )
  }
  return <Box width="full" borderTop="1px" borderColor={color} />
}
