import type { TranslationKey } from '@aubade/translation'

import type {
  HeadingProps as ChakraHeadingProps,
  TypographyProps,
  Theme,
} from '@chakra-ui/react'
import { Heading, HStack } from '@chakra-ui/react'
import type { PropsWithChildren, ReactNode } from 'react'
import { useEffect } from 'react'

import { useTranslatable } from '../useTranslatable'

type TitleVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
type FontWeight = keyof Pick<Theme['fontWeights'], 'bold' | 'normal'>

const SizeStyleMap: Record<TitleVariant, ChakraHeadingProps['fontSize']> = {
  h1: 'xs',
  h2: 'sm',
  h3: 'md',
  h4: 'lg',
  h5: 'xl',
  h6: '2xl',
}

export function getTypographyStyle(
  variant: TitleVariant,
  weight: FontWeight,
): TypographyProps {
  return {
    fontSize: SizeStyleMap[variant],
    fontWeight: weight,
    lineHeight: '150%',
  }
}

type BaseTitleProps = PropsWithChildren<{
  text?: TranslationKey
  variant?: TitleVariant
  fontWeight?: FontWeight
  actions?: ReactNode
  isDangerous?: boolean
  id?: string
}> &
  Pick<ChakraHeadingProps, 'textTransform' | 'color' | 'as' | 'textAlign'>

function BaseTitle(props: BaseTitleProps) {
  const {
    variant = 'h1',
    fontWeight = 'normal',
    children,
    actions,
    isDangerous,
    id,
    color = 'inherit',
    as,
    ...headingProps
  } = useTranslatable(props)

  useEffect(() => {
    if (variant === 'h1' && typeof children === 'string') {
      const previous = document.title
      document.title = children

      return () => {
        document.title = previous
      }
    }
  }, [variant, children])

  const heading = (
    <Heading
      id={id}
      color={isDangerous ? 'error.dark' : color}
      as={as ?? variant}
      {...getTypographyStyle(variant, fontWeight)}
      {...headingProps}
    >
      {children}
    </Heading>
  )

  if (actions) {
    return (
      <HStack justifyContent="space-between" width="100%">
        {heading}
        {actions}
      </HStack>
    )
  }

  return heading
}

type TitleProps = Omit<BaseTitleProps, 'variant'>
export const Title = {
  H1: function (props: TitleProps) {
    return <BaseTitle variant="h1" {...props} />
  },
  H2: function (props: TitleProps) {
    return <BaseTitle variant="h2" {...props} />
  },
  H3: function (props: TitleProps) {
    return <BaseTitle variant="h3" {...props} />
  },
  H4: function (props: TitleProps) {
    return <BaseTitle variant="h4" {...props} />
  },
  H5: function (props: TitleProps) {
    return <BaseTitle variant="h5" {...props} />
  },
  H6: function (props: TitleProps) {
    return <BaseTitle variant="h6" {...props} />
  },
}
