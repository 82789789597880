import type { ReactNode } from 'react'
import { useMemo, useCallback, useState, useRef } from 'react'

import { Modal } from '.'

type ShowModalFunction = (
  renderFn: (onClose: () => void) => ReactNode,
) => Promise<void>

type UseModalParams = {
  closeOnlyWithButton?: boolean
  withCloseButton?: boolean
  actions?: ReactNode
}
export function useModal(params?: UseModalParams) {
  const {
    closeOnlyWithButton = false,
    actions,
    withCloseButton = true,
  } = params ?? {}
  const [modalContent, setModalContent] = useState<ReactNode>()
  const resolveFnRef = useRef<Function>()

  const onClose = useCallback(() => {
    if (resolveFnRef.current) {
      resolveFnRef.current()
    }
    resolveFnRef.current = undefined
    setModalContent(undefined)
  }, [])

  const showModal = useCallback<ShowModalFunction>(
    (renderFn) => {
      setModalContent(renderFn(onClose))

      return new Promise<void>(function (resolve) {
        resolveFnRef.current = resolve
      })
    },
    [setModalContent, onClose],
  )

  const body = useMemo(() => {
    return (
      <Modal
        isOpen={Boolean(modalContent)}
        onClose={onClose}
        isCentered
        size="sm"
        closeOnEsc={!closeOnlyWithButton}
        closeOnOverlayClick={!closeOnlyWithButton}
        actions={actions}
        withCloseButton={withCloseButton}
      >
        {modalContent}
      </Modal>
    )
  }, [modalContent, onClose, closeOnlyWithButton, actions, withCloseButton])

  return [body, showModal] as const
}
