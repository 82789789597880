// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconAppareilPhoto = (
  props: SVGProps<SVGSVGElement> & { slot?: string },
) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M42.7662 6.66726H37.7663L36.0996 3.33396C35.6034 2.32016 34.8288 1.46865 33.8668 0.878274C32.9047 0.288252 31.795 -0.0164308 30.6664 0.000683322H19.3331C18.2186 -0.000432762 17.1263 0.311318 16.1806 0.900598C15.2345 1.48988 14.4734 2.33325 13.9831 3.334L12.3164 6.6673H7.3165C5.38499 6.64944 3.52635 7.40316 2.15284 8.76099C0.779326 10.1193 0.00438091 11.969 0 13.9005V42.7668C0.00446424 44.6839 0.767849 46.5209 2.1235 47.8765C3.47915 49.2321 5.31614 49.9955 7.23316 50H42.7661C44.6832 49.9955 46.5202 49.2322 47.8758 47.8765C49.2314 46.5208 49.9948 44.6839 49.9993 42.7668V13.9005C49.9948 11.9834 49.2314 10.1464 47.8758 8.7908C46.5201 7.4352 44.6832 6.67178 42.7661 6.6673L42.7662 6.66726ZM24.9997 39.1669C21.9057 39.1669 18.9381 37.9378 16.7502 35.7499C14.5623 33.562 13.3332 30.5944 13.3332 27.5004C13.3332 24.4063 14.5623 21.4387 16.7502 19.2508C18.9381 17.063 21.9057 15.8338 24.9997 15.8338C28.0938 15.8338 31.0614 17.063 33.2493 19.2508C35.4372 21.4387 36.6663 24.4063 36.6663 27.5004C36.6663 30.5944 35.4372 33.562 33.2493 35.7499C31.0614 37.9378 28.0938 39.1669 24.9997 39.1669Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconAppareilPhoto)
export default Memo
