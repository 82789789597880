import { useHTTPClient, useList } from '@aubade/core/adapters'
import {
  fromS3ToResizedUrl,
  useDateFnsLocale,
  useQueryParamState,
  useQueryParamsState,
  drawerQueryParams,
} from '@aubade/core/libs'
import type { FormSubmitProps } from '@aubade/core/ui-kit'
import {
  Button,
  Form,
  FormDrawer,
  Paragraph,
  QueryOne,
  Surface,
  unit,
  makeInputs,
  useForm,
  useToastMessage,
  ButtonWithConfirm,
  useDocumentUrl,
} from '@aubade/core/ui-kit'
import {
  IconArrowLeft,
  IconSend,
  IconDelete,
  IconOutilsCouleurs,
  IconForbidden,
} from '@aubade/design/graphics'
import {
  DrawerContent,
  ProfileCard,
  useGetUser,
} from '@aubade/domain/components'
import { useTranslate } from '@aubade/translation'

import type {
  ExtendedPostMessage,
  ExtendedConversationWrite,
  Contact,
} from '@aubade/types'
import type { CraftCompany } from '@aubade/types/api'
import { type Message } from '@aubade/types/api'
import type {
  ExtendedOneConversation,
  ExtendedUserConversation,
} from '@aubade/types/index'
import {
  VStack,
  HStack,
  Stack,
  Box,
  Image,
  Icon,
  Spinner,
  Grid,
  Center,
} from '@chakra-ui/react'
import {
  cleanHydraId,
  toHydraId,
  useCreate,
  useQueryMetaData,
} from '@nartex/api-platform'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { format } from 'date-fns'
import type { RefObject, SVGProps } from 'react'
import { useEffect, useMemo, useRef, useState, useLayoutEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { useUrls } from 'src/App'
import { StringParam } from 'use-query-params'

import { v4 } from 'uuid'

import { useOne } from '../../../../../../packages/core/src/adapters'
import { ContactPicker } from '../../../components/ContactPicker'

import { useActions } from '../Actions/index'

import { FilterForm } from './FilterForm'
import { PrivateMention, UnavailableUsers } from './PrivateMention'
import { useConversationFilters } from './useConversationFilters'

export function FlowMessages() {
  const [modalState] = useQueryParamsState(drawerQueryParams)
  const { page, id } = modalState

  const matchCreate = page === 'create'
  const match = Boolean(matchCreate || id)

  return (
    <FormDrawer
      isOpenProps={Boolean(match)}
      variant={matchCreate ? 'solid' : 'ghost'}
      size={matchCreate ? 'lg' : 'xl'}
    >
      {matchCreate && (
        <DrawerContent>
          <ConversationCreate />
        </DrawerContent>
      )}
      {id && <Conversation id={id} />}
    </FormDrawer>
  )
}

function ConversationCreate() {
  const newId = useMemo(() => {
    return v4() as string
  }, [])
  const [_searchParams, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const { saveButtonProps, ...createMethods } =
    useForm<ExtendedConversationWrite>({
      mode: 'create',
      resource: 'conversations',
      hookFormOptions: {
        defaultValues: {
          newId,
        },
      },
      redirect() {
        return false
      },
      mutationOptions: {
        async onSuccess(response) {
          await queryClient.invalidateQueries()
          setSearchParams({ id: response.data.id! })
        },
      },
      blockNavigation: false,
    })

  return (
    <Box width={'full'}>
      <Form {...createMethods}>
        <ConversationSettings saveButtonProps={saveButtonProps} />
      </Form>
    </Box>
  )
}

type ConversationProps = {
  id: string
}

function Conversation(props: ConversationProps) {
  const { id } = props

  return (
    <VStack
      gap={unit('10')}
      width="full"
      height="full"
      maxHeight="full"
      justifyContent={'center'}
      alignItems={'center'}
    >
      <QueryOne<ExtendedOneConversation>
        iri={toHydraId('conversations', id)}
        children={(conversation) => {
          return (
            <>
              <ConversationHeader conversation={conversation} />
              <ChatBox conversation={conversation} />
            </>
          )
        }}
      />
    </VStack>
  )
}

type ChatBoxProps = {
  conversation: ExtendedOneConversation
}

function ConversationHeader(props: ChatBoxProps) {
  const { conversation } = props
  const { users } = conversation
  return (
    <Surface
      width={'full'}
      padding={unit('10')}
      justifyContent={'center'}
      position={'relative'}
    >
      <ConversationUsers users={users} />
      <Actions conversation={conversation} />
    </Surface>
  )
}

type ActionsProps = {
  conversation: ExtendedOneConversation
}

function Actions(props: ActionsProps) {
  const { conversation } = props
  const { id: me, agency } = useGetUser()
  const { id, users, blackList } = conversation
  const { deleteConversation, canBlock, isBlocked, toggleBlackList } =
    useActions(id!, users, blackList)
  const translate = useTranslate()

  const otherUsers = useMemo(
    () => users.filter((user) => cleanHydraId(user.id) !== cleanHydraId(me)),
    [me, users],
  )
  const otherUserAgencyId = useGetOtherUserAgency(otherUsers)

  const canBlockUser =
    canBlock &&
    otherUserAgencyId &&
    cleanHydraId(otherUserAgencyId) === cleanHydraId(agency.id)

  return (
    <HStack position="absolute" right={unit('40')} top={0} height="full">
      {canBlockUser && (
        <Button
          variant="circleList"
          leftIcon={IconForbidden}
          iconColor={isBlocked ? 'error.dark' : undefined}
          onClick={() => {
            toggleBlackList()
          }}
        />
      )}
      <ButtonWithConfirm
        dialogProps={{
          title: 'conversation.confirmTitle',
          children: translate('conversation.confirmText'),
        }}
        confirmButtonProps={{
          isDisabled: false,
          label: 'conversation.confirmButton',
          variant: 'primary',
        }}
        buttonProps={{
          isDisabled: false,
          variant: 'circleList',
          leftIcon: IconDelete,
        }}
        onConfirm={() => deleteConversation()}
      />
    </HStack>
  )
}

function ConversationUsers(props: { users: ExtendedUserConversation[] }) {
  const { users } = props
  const { id: me } = useGetUser()
  const translate = useTranslate()

  if (users.length === 1) {
    return (
      <VStack
        width="full"
        height={unit('60')}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Paragraph
          fontWeight="bold"
          text="messages.label.singleUserconversation"
        />
      </VStack>
    )
  }

  if (users.length === 2) {
    const otherUsers = users.filter((usr) => usr.id !== me)
    const name = [otherUsers[0].firstname, otherUsers[0].lastname]
      .filter(Boolean)
      .join(' ')
    return (
      <ProfileCard
        name={name}
        variant="big"
        maxWidth="350px"
        role={otherUsers[0]?.acRoles?.AC && translate(`${users[0].acRoles.AC}`)}
        picture={otherUsers[0].avatar}
        icon={otherUsers[0]?.craftCompany ? IconOutilsCouleurs : undefined}
      />
    )
  }
  return (
    <ProfileCard
      multiple={users.length}
      name={translate('messages.list.group')}
      maxWidth="350px"
      variant="big"
      role={users
        .map((user) =>
          [user.firstname, user.lastname].filter(Boolean).join(' '),
        )
        .join(', ')}
    />
  )
}

function ChatBox(props: ChatBoxProps) {
  const { conversation } = props
  const { users, id } = conversation
  const locale = useDateFnsLocale()

  const scrollRef = useRef<HTMLDivElement>(null)
  const [messages] = useList<Message.Read>({
    dataProviderName: 'default',
    // @ts-expect-error
    resource: `conversations/${id}/messages`,
  })
  const { id: me } = useGetUser()
  const otherUsers = users.filter((user) => user.id !== me)

  useEffect(() => {
    scrollRef?.current?.scrollIntoView({
      block: 'nearest',
    })
  }, [])

  return (
    <Surface
      width={'full'}
      height={`calc(100%)`}
      maxHeight={`calc(100%)`}
      direction={'column'}
      overflow="hidden"
    >
      <Stack
        width="full"
        paddingX={unit('40')}
        gap={'10px'}
        direction="column"
        height="full"
        maxHeight="full"
        overflow="auto"
      >
        <UnavailableUsers contacts={otherUsers} />
        {messages &&
          messages.map((message, index) => {
            const { author, createdAt, document, isRead } = message
            const externalAuthor = me !== cleanHydraId(author!.id!)
            const messageStyle = getMessageStyle(externalAuthor)
            const justifyContent = externalAuthor ? 'start' : 'end'

            const authorName = [author?.firstname, author?.lastname]
              .filter(Boolean)
              .join(' ')

            const date = format(
              new Date(createdAt as string),
              'dd LLLL à k:mm ',
              { locale },
            )

            return (
              <HStack
                width="full"
                key={index}
                p={2}
                justifyContent={justifyContent}
              >
                <VStack
                  {...messageStyle}
                  position="relative"
                  width="fit-content"
                  maxWidth={'400px'}
                  paddingX={unit('20')}
                  paddingY={unit('10')}
                  borderRadius={'md'}
                  alignItems={'flex-start'}
                >
                  <HStack gap={'5px'}>
                    <Paragraph
                      fontWeight="bold"
                      color="inherit"
                      text={authorName}
                    />
                    <Paragraph
                      color={externalAuthor ? 'darkGrey.500' : 'grey.500'}
                      text={`- ${date}`}
                    />
                  </HStack>
                  <VStack width="full" alignItems={'flex-start'} gap={4}>
                    {document.length > 0 && (
                      <MessagePicture url={document[0].url} />
                    )}
                    <ParsedMessage message={message?.content} />
                    {!externalAuthor && (
                      <HStack
                        gap={1}
                        justifyContent={'flex-end'}
                        position="absolute"
                        right={2}
                        bottom={2}
                      >
                        {isRead ? (
                          <Icon
                            as={IconDoubleCheck}
                            color={'blue.500'}
                            width={unit('14')}
                            height={unit('14')}
                          />
                        ) : (
                          <Icon
                            as={IconSingleCheck}
                            color={'white.500'}
                            width={unit('14')}
                            height={unit('14')}
                          />
                        )}
                      </HStack>
                    )}
                  </VStack>
                </VStack>
              </HStack>
            )
          })}
        <span ref={scrollRef}></span>
        <PrivateMention contacts={otherUsers} />
      </Stack>
      {users.length >= 2 && (
        <FieldMessage conversationId={conversation.id!} scroll={scrollRef} />
      )}
    </Surface>
  )
}

function MessagePicture(props: { url: string }) {
  const { url } = props
  const parsedUrl = fromS3ToResizedUrl(url)
  const pictureUrl = useDocumentUrl(parsedUrl)
  return (
    <Box
      cursor={'pointer'}
      onClick={() => {
        window.open(pictureUrl, '_blank')
      }}
    >
      <Image
        src={pictureUrl}
        minWidth="100%"
        minHeight="100%"
        objectFit="cover"
      />
    </Box>
  )
}

const Input = makeInputs<ExtendedPostMessage>()

type FieldMessageProps = {
  conversationId: string
  scroll: RefObject<HTMLDivElement>
}

function FieldMessage(props: FieldMessageProps) {
  const { conversationId, scroll } = props
  const translate = useTranslate()
  const queryClient = useQueryClient()
  const toastMessage = useToastMessage()
  const [newId] = useState(() => v4())

  const { id: me } = useGetUser()
  const [user] = useOne<Contact>({ iri: toHydraId('users', me!) })
  const { isAvailable } = user!

  useEffect(() => {
    async function invalidate() {
      await queryClient.invalidateQueries()
    }
    invalidate().catch(console.error)
  }, [queryClient])

  const { mutate: postPicture, isLoading } = useCreate()

  const { saveButtonProps, ...methods } = useForm<ExtendedPostMessage>({
    mode: 'create',
    resource: 'messages',
    hookFormOptions: {
      defaultValues: {
        newId,
        conversation: conversationId,
      },
    },
    redirect() {
      return false
    },
    mutationOptions: {
      async onSuccess() {
        await queryClient.invalidateQueries()
        methods.setValue('document', undefined)
        methods.setValue('content', undefined)
        scroll?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        })
      },
      onError() {
        toastMessage('error', 'notifications.message.sendError')
      },
    },
    blockNavigation: false,
  })

  const { watch } = methods

  const content = watch('content')

  const httpClient = useHTTPClient()
  const metaData = useQueryMetaData()
  const toast = useToastMessage()

  const { mutate: togglaAvailable } = useMutation({
    mutationFn(): Promise<any> {
      return httpClient.request({
        method: 'put',
        url: `/users/${me}/setAvailability`,
        data: {
          isAvailable: !isAvailable,
        },
        headers: metaData?.headers,
      })
    },

    async onSuccess() {
      await queryClient.invalidateQueries()
      toast('success', 'notifications.statusChanged')
    },
    onError(error: Error) {
      toast('error', error.message)
    },
  })

  if (!isAvailable) {
    return (
      <Box width="full" padding={5}>
        <HStack
          width="full"
          gap={'10px'}
          backgroundColor="lightGrey.500"
          paddingY="10px"
          paddingX="20px"
          borderRadius={'20px'}
        >
          <Icon as={IconDanger} width="23px" height="20px" />
          <Paragraph text="message.user.unavailable" />
          <Button
            variant="text"
            color="darkGrey.500"
            label="message.user.toggleUnavailable"
            onClick={() => togglaAvailable()}
          />
        </HStack>
      </Box>
    )
  }

  return (
    <Box width="full" padding={5}>
      <Form {...methods} bucket={{ type: 'Conversation' }}>
        <HStack
          width="full"
          gap={'10px'}
          height={unit('40')}
          alignItems={'center'}
        >
          <Input.TextArea
            name="content"
            placeholder={translate('message.writeMessage')}
            variant="textAreaMessageStyle"
          />
          {isLoading ? (
            <Spinner />
          ) : content && (content as string).length ? (
            <SendButton {...saveButtonProps} />
          ) : (
            <Input.DropZone
              name="document"
              variant="icon"
              label="publications.fields.documents"
              objectId={conversationId}
              submitOnAccept={(document: any) => {
                postPicture(
                  {
                    resource: 'messages',
                    values: {
                      newId,
                      conversation: conversationId,
                      document: document,
                    },
                  },
                  {
                    async onSuccess() {
                      methods.setValue('document', undefined)
                      methods.setValue('content', undefined)
                      await queryClient.invalidateQueries()
                    },
                  },
                )
              }}
            />
          )}
        </HStack>
      </Form>
    </Box>
  )
}

function SendButton(props: FormSubmitProps) {
  const content = useWatch<{ content?: string }>({ name: 'content' })
  const document = useWatch<{ document?: any }>({ name: 'document' })

  const isDisabled = useMemo(() => {
    if (!document && (!content || content.length === 0)) {
      return true
    }
    if (document) {
      return false
    }
    return false
  }, [content, document])

  return (
    <Button
      variant="circleNegative"
      leftIcon={IconSend}
      {...props}
      isDisabled={isDisabled}
      label=""
    />
  )
}

function getMessageStyle(isOdd: boolean) {
  if (isOdd) {
    return {
      backgroundColor: 'lightGrey.500',
      color: 'black.500',
    }
  } else {
    return {
      backgroundColor: 'darkGrey.500',
      color: 'white.500',
    }
  }
}

function ConversationSettings(props: { saveButtonProps: FormSubmitProps }) {
  const { saveButtonProps } = props
  const urls = useUrls()
  const [conversationId] = useQueryParamState('id', StringParam)
  const [isSubmit, setIsSubmit] = useState<Boolean>(false)

  const { crudFilters, filtersStore } = useConversationFilters('conversations')

  const { multi } = filtersStore.state

  const mainTitle = conversationId
    ? 'message.title.settingEdit'
    : multi
    ? 'message.title.settingCreate.groupe'
    : 'message.title.settingCreate.simple'

  const { setValue } = useFormContext()

  const users = useWatch<{ users?: string[] }>({ name: 'users' })

  const isDisabled = useMemo(() => {
    if (multi && Boolean(!users || users.length < 2)) {
      return true
    }
    if (!multi && Boolean(!users || users.length === 0)) {
      return true
    }
    return false
  }, [multi, users])

  useLayoutEffect(() => {
    if (!multi && users && !isSubmit) {
      // TODO submit form once, change it for a better solution
      // @ts-ignore
      saveButtonProps?.onClick?.()
      setIsSubmit(true)
    }
  }, [multi, saveButtonProps, users, isSubmit, setValue])

  if (isSubmit) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  return (
    <Grid
      width={'full'}
      height="full"
      templateRows={'60px 1fr 60px'}
      paddingX={unit('8')}
      gap={unit('40')}
    >
      <HStack width="full" padding={unit('10')} justifyContent={'flex-start'}>
        {conversationId && (
          <Button
            variant="circleList"
            leftIcon={IconArrowLeft}
            to={urls.aubade().conversationDetail(conversationId)}
          />
        )}
        <Paragraph size="lg" fontWeight="bold" text={mainTitle} />
      </HStack>
      <Box width="full" height="full">
        <ContactPicker
          name="users"
          filters={crudFilters}
          max={multi ? undefined : 1}
          renderFilters={(options) => {
            if (options.length < 2 && options.length > 0) return <></>
            return (
              <FilterForm
                filters={filtersStore.state}
                onChange={filtersStore.setState}
                reset={() => setValue('users', undefined)}
              />
            )
          }}
        />
      </Box>
      <HStack
        width={'100%'}
        justifyContent={'center'}
        position="absolute"
        bottom={unit('40')}
        left={0}
      >
        {multi && !isDisabled && (
          <Button
            {...saveButtonProps}
            onClick={(e) => {
              setIsSubmit(true)
              saveButtonProps?.onClick?.(e)
            }}
            leftIcon={IconSend}
            variant="primary"
            label="buttons.conversation.create"
          />
        )}
      </HStack>
    </Grid>
  )
}

function ParsedMessage(props: { message?: string }) {
  const { message } = props
  if (!message) return <></>
  return (
    <Paragraph
      color={'inherit'}
      whiteSpace={'pre-line'}
      text={message}
      lineHeight={unit('20')}
    />
  )
}

function IconDoubleCheck(props: SVGProps<SVGSVGElement> & { slot?: string }) {
  return (
    <svg
      width={'13px'}
      height={'13px'}
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.21805 1.12043C8.04577 1.12544 7.8822 1.19732 7.76197 1.32078C7.61236 1.47046 6.62186 2.48849 5.59921 3.53881C4.76887 4.3915 4.18387 4.99141 3.85449 5.32841L1.99918 3.91538H1.99925C1.86083 3.79492 1.6786 3.73716 1.49606 3.7559C1.31353 3.77464 1.14686 3.8681 1.03575 4.01416C0.924627 4.16022 0.879051 4.34585 0.909744 4.52677C0.940437 4.70769 1.0447 4.86786 1.1977 4.96911L3.51939 6.73806C3.6471 6.83515 3.80565 6.88273 3.96574 6.87193C4.12576 6.86114 4.27652 6.79273 4.38996 6.67929C4.53957 6.52968 5.53007 5.51496 6.55272 4.46461C7.57539 3.41434 8.63019 2.3285 8.70166 2.25704C8.82937 2.13172 8.90102 1.96021 8.90039 1.78137C8.89985 1.60245 8.82705 1.4314 8.69856 1.30694C8.57008 1.18255 8.39672 1.11523 8.21796 1.12039L8.21805 1.12043Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.23271 4.01734L6.09879 3.91534C5.96036 3.79488 5.77821 3.73712 5.59567 3.75586C5.41314 3.7746 5.24647 3.86807 5.13536 4.01413C5.02424 4.16019 4.97866 4.34582 5.00935 4.52673C5.04005 4.70765 5.14431 4.86782 5.29731 4.96908L5.30168 4.9724C5.54588 4.72213 5.85669 4.40331 6.23271 4.01734ZM6.36652 5.78373C6.64305 5.50041 6.96345 5.17181 7.29784 4.82856L7.9541 5.32837C8.28348 4.99137 8.86848 4.39146 9.69882 3.53878C9.83654 3.39733 9.97367 3.25647 10.1082 3.11824C10.973 2.23001 11.7321 1.45027 11.8616 1.32074C11.9818 1.19728 12.1454 1.12541 12.3177 1.12039C12.4964 1.11523 12.6697 1.18251 12.7982 1.3069C12.9267 1.43136 12.9995 1.60242 13 1.78133C13.0006 1.96017 12.929 2.13168 12.8013 2.257C12.76 2.29829 12.3904 2.67819 11.8885 3.19421C11.5217 3.57131 11.0841 4.02111 10.6523 4.46457C9.62968 5.51492 8.63918 6.52965 8.48957 6.67925C8.37613 6.7927 8.22537 6.8611 8.06535 6.87189C7.90526 6.88269 7.74671 6.83511 7.619 6.73802L6.36652 5.78373Z"
        fill="currentColor"
      />
    </svg>
  )
}
function IconSingleCheck(props: SVGProps<SVGSVGElement> & { slot?: string }) {
  return (
    <svg
      width={'13px'}
      height={'13px'}
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.21805 1.12043C8.04577 1.12544 7.8822 1.19732 7.76197 1.32078C7.61236 1.47046 6.62186 2.48849 5.59921 3.53881C4.76887 4.3915 4.18387 4.99141 3.85449 5.32841L1.99918 3.91538H1.99925C1.86083 3.79492 1.6786 3.73716 1.49606 3.7559C1.31353 3.77464 1.14686 3.8681 1.03575 4.01416C0.924627 4.16022 0.879051 4.34585 0.909744 4.52677C0.940437 4.70769 1.0447 4.86786 1.1977 4.96911L3.51939 6.73806C3.6471 6.83515 3.80565 6.88273 3.96574 6.87193C4.12576 6.86114 4.27652 6.79273 4.38996 6.67929C4.53957 6.52968 5.53007 5.51496 6.55272 4.46461C7.57539 3.41434 8.63019 2.3285 8.70166 2.25704C8.82937 2.13172 8.90102 1.96021 8.90039 1.78137C8.89985 1.60245 8.82705 1.4314 8.69856 1.30694C8.57008 1.18255 8.39672 1.11523 8.21796 1.12039L8.21805 1.12043Z"
        fill="currentColor"
      />
    </svg>
  )
}

function IconDanger(props: SVGProps<SVGSVGElement> & { slot?: string }) {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.2223 1.79208L21.6679 14.1933C22.738 15.9744 22.2124 18.3178 20.4935 19.4267C19.9166 19.8002 19.2441 19.9993 18.5568 20H3.66447C1.64112 20 0 18.3 0 16.2011C0 15.4911 0.192224 14.7966 0.553338 14.1933L8.00006 1.79208C9.06896 0.0109538 11.329 -0.534606 13.0479 0.574292C13.5234 0.880961 13.9257 1.29763 14.2223 1.79208ZM11.1112 15.5555C11.4059 15.5555 11.6885 15.4385 11.8969 15.2301C12.1053 15.0217 12.2223 14.7391 12.2223 14.4444C12.2223 14.1497 12.1053 13.8671 11.8969 13.6587C11.6885 13.4503 11.4059 13.3333 11.1112 13.3333C10.8165 13.3333 10.5339 13.4503 10.3255 13.6587C10.1171 13.8671 10.0001 14.1497 10.0001 14.4444C10.0001 14.7391 10.1171 15.0217 10.3255 15.2301C10.5339 15.4385 10.8165 15.5555 11.1112 15.5555ZM11.1112 5.55544C10.8165 5.55544 10.5339 5.67251 10.3255 5.88088C10.1171 6.08926 10.0001 6.37187 10.0001 6.66656V11.111C10.0001 11.4057 10.1171 11.6883 10.3255 11.8967C10.5339 12.1051 10.8165 12.2222 11.1112 12.2222C11.4059 12.2222 11.6885 12.1051 11.8969 11.8967C12.1053 11.6883 12.2223 11.4057 12.2223 11.111V6.66656C12.2223 6.37187 12.1053 6.08926 11.8969 5.88088C11.6885 5.67251 11.4059 5.55544 11.1112 5.55544Z"
        fill="#CE0E2D"
      />
    </svg>
  )
}

function useGetOtherUserAgency(
  otherUsers: ExtendedUserConversation[],
): string | undefined {
  const [craftCompany] = useOne<CraftCompany.Read>(
    {
      resource: 'craft_companies',
      id: cleanHydraId(otherUsers[0]?.craftCompany?.id),
    },
    {
      enabled: Boolean(otherUsers[0]?.craftCompany?.id),
    },
  )
  if (craftCompany && craftCompany.agency) {
    return craftCompany.agency
  }
  return undefined
}
