import { createContextFunction } from '../../../core/src/libs'

type AgencyProviderValue = {
  agency: string
  changeAgency: (agency: string) => void
}

export const [AgencyProvider, useMaybeSelectedAgency] = createContextFunction<
  AgencyProviderValue | undefined
>('SelectedAgency', () => undefined)
