import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import { useEvent } from '../../../libs'
import { useDefaultValue, useReadOnly } from '../../Form'

import type {
  RHFInputProps,
  UseBaseInputParams,
  BaseInputProps,
  UseBaseInputResult,
  UseRHFInputOptions,
  Transformer,
} from './types'
import { noopTransformer } from './types'
import { useBaseInput } from './useBaseInput'

export function useRHFInput<
  T,
  Outer = string,
  Inner = Outer,
  InnerDeep = Inner,
  Element extends HTMLElement = HTMLElement,
>(
  params: RHFInputProps<T, Outer, Inner, Element>,
  options?: UseRHFInputOptions<Inner, InnerDeep>,
): UseBaseInputParams<Inner, InnerDeep, Element> {
  const {
    rules,
    defaultValue,
    shouldUnregister,
    required,
    name,
    transformer = noopTransformer,
    ...rest
  } = params

  const { field, fieldState } = useController({
    name,
    rules: { ...rules, required },
    defaultValue,
    shouldUnregister,
  })
  const { error } = fieldState

  const baseTransformer = (options?.baseTransformer ??
    noopTransformer) as Transformer<Inner | null | undefined, InnerDeep>

  const transformerRead = transformer.read
  const value = useMemo(() => {
    return transformerRead(field.value) as Inner
  }, [field.value, transformerRead])

  useDefaultValue(
    name,
    defaultValue !== undefined ? transformer.write(defaultValue) : undefined,
  )

  const readOnly = useReadOnly()

  return {
    readOnly,
    ...rest,
    ref: field.ref,
    value,
    name,
    required,
    error,
    onBlur: useEvent((event) => {
      field.onBlur()
      params.onBlur?.(event)
    }),
    onChange: useEvent((newLocalValue) => {
      const newFormValue = transformer.write(newLocalValue) as Outer
      field.onChange(newFormValue)
      params.onChange?.(newFormValue)
    }),
    transformer: baseTransformer,
  }
}

export function connectRHF<Inner, InnerDeep = Inner>(
  BaseComponent: React.FC<BaseInputProps<Inner, InnerDeep>>,
  options?: UseRHFInputOptions<Inner, InnerDeep>,
) {
  function RHFComponent<T, Outer = Inner>(
    props: RHFInputProps<T, Outer, Inner>,
  ) {
    const baseComponentProps = useRHFInput<T, Outer, Inner, InnerDeep>(
      props,
      options,
    )
    return <BaseComponent {...baseComponentProps} />
  }
  RHFComponent.name = `ConnectRHF(${BaseComponent.name})`

  return RHFComponent
}

export function useInput<
  T,
  Outer = string | number,
  Inner = Outer,
  InnerDeep = Inner,
  Element extends HTMLElement = HTMLElement,
>(
  params: RHFInputProps<T, Outer, Inner, Element>,
  options?: UseRHFInputOptions<Inner, InnerDeep>,
): UseBaseInputResult<InnerDeep, Element> {
  const baseInputParams = useRHFInput<T, Outer, Inner, InnerDeep, Element>(
    params,
    options,
  )
  return useBaseInput<Inner, InnerDeep, InnerDeep, Element>(baseInputParams)
}
