import { compactObj, removeEmptyValues } from '@aubade/core/libs'
import { Chip, QueryOne, makeForm } from '@aubade/core/ui-kit'
import { IconFermer } from '@aubade/design/graphics'
import type { Agency } from '@aubade/types/api'
import { HStack } from '@chakra-ui/react'
import { toHydraId } from '@nartex/api-platform'
import { useFormContext } from 'react-hook-form'

import type { ConversationFilterType } from '../types'

const { useWatch } = makeForm<
  ConversationFilterType,
  'agencies' | 'internal' | 'external'
>()

type Props = {
  onChange: () => void
}
export function ActiveFilters(props: Props) {
  const { onChange } = props

  const [agencies, internal, external] = useWatch([
    'agencies',
    'internal',
    'external',
  ])
  const { setValue } = useFormContext()
  const filteredAgencies = compactObj(removeEmptyValues(agencies ?? {}))

  return (
    <HStack width="full" wrap="wrap" gap={2}>
      {filteredAgencies &&
        Object.keys(filteredAgencies).map((target) => {
          return (
            <QueryOne<Agency.Read>
              key={target}
              iri={toHydraId('agencies', target)}
              children={(record) => {
                return (
                  <FilterChip
                    label={record.name}
                    backgroundColor={'white'}
                    onClick={() => {
                      setValue(`agencies.${target}`, false)
                      onChange()
                    }}
                  />
                )
              }}
            />
          )
        })}
      <>
        {internal && (
          <FilterChip
            label="filters.publications.internal"
            backgroundColor={'white'}
            onClick={() => {
              setValue(`internal`, false)
              onChange()
            }}
          />
        )}
        {external && (
          <FilterChip
            label="filters.publications.external"
            backgroundColor={'white'}
            onClick={() => {
              setValue(`external`, false)
              onChange()
            }}
          />
        )}
      </>
    </HStack>
  )
}

type FilterChipProps = {
  label: string
  onClick: () => void
  backgroundColor?: string
}

function FilterChip(props: FilterChipProps) {
  const { label, onClick, backgroundColor } = props
  return (
    <Chip
      size="xsmall"
      label={label}
      iconLeftProps={{
        as: IconFermer,
        color: 'darkGrey.500',
      }}
      textProps={{
        color: 'darkGrey.500',
        fontWeight: 'bold',
      }}
      onClick={onClick}
      backgroundColor={backgroundColor}
    />
  )
}
