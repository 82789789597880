import type { PropsWithChildren } from 'react'
import {
  useMemo,
  useState,
  createContext,
  useContext,
  useId,
  useLayoutEffect,
} from 'react'
import { useDeepCompareMemo } from 'use-deep-compare'
import { useCallbackOne } from 'use-memo-one'

import { useImmediateCallback } from './debounce'

const emptySymbol = Symbol('empty')
type EmptySymbol = typeof emptySymbol

export type CombinedElements<U> = Record<string, U>
type SetChildState<U> = (id: string, ownState: U | EmptySymbol) => void

export function createCombinedStateHook<CombinedState, ChildState>(
  combineState: (elements: CombinedElements<ChildState>) => CombinedState,
) {
  const Context = createContext<SetChildState<ChildState>>(() => {})

  function useSetCombinedState(ownState: ChildState) {
    const id = useId()
    const setChildState = useImmediateCallback(useContext(Context))

    const ownStateMemo = useDeepCompareMemo(() => ownState, [ownState])

    useLayoutEffect(() => {
      setChildState(id, ownStateMemo)

      return () => {
        setChildState(id, emptySymbol)
      }
    }, [setChildState, id, ownStateMemo])
  }

  function useCombinedState() {
    const [combinedElements, setState] = useState<CombinedElements<ChildState>>(
      {},
    )

    const setChildState = useCallbackOne<SetChildState<ChildState>>(
      (id, childState) => {
        setState((prevState) => {
          if (childState === emptySymbol) {
            const result = { ...prevState }
            delete result[id]
            return result
          }

          return {
            ...prevState,
            [id]: childState,
          }
        })
      },
      [],
    )

    const Provider = useCallbackOne(
      (props: PropsWithChildren<{}>) => {
        const { children } = props
        return (
          <Context.Provider value={setChildState}>{children}</Context.Provider>
        )
      },
      [setChildState],
    )

    const state = useMemo(
      () => combineState(combinedElements),
      [combinedElements],
    )

    return [state, Provider] as const
  }

  return [useSetCombinedState, useCombinedState] as const
}
