import type { Filters } from '@aubade/core/ui-kit'
import {
  makeForm,
  Form,
  DropdownMenu,
  unit,
  Paragraph,
} from '@aubade/core/ui-kit'

import { isAgency, useGetUser } from '@aubade/domain/components'
import { HStack, Icon, VStack } from '@chakra-ui/react'
import { useId } from 'react'
import { useForm, useFormContext } from 'react-hook-form'

import { cleanValues } from 'src/libs/cleanValues'

import { IconEmployees3 } from '../../../../../../packages/design/ExtraIcons'
import { AgencyFilter } from '../../../components'

import type { ConversationFilterType } from '../types'

import { ActiveFilters } from './ActiveFilters'

const { Input, useWatch } = makeForm<
  ConversationFilterType,
  'multi' | 'internal' | 'external'
>()

type ContactFilterProps = {
  filters: ConversationFilterType
  onChange: (filters: Filters) => void
  reset: () => void
}

export function FilterForm(props: ContactFilterProps) {
  const { filters, onChange, reset } = props

  const methods = useForm<ConversationFilterType>({
    defaultValues: filters,
  })
  const formId = useId()
  const { handleSubmit } = methods
  const onFilterChange = handleSubmit(() => {
    onChange(cleanValues(methods.getValues()))
  })

  return (
    <Form {...methods} onSubmit={onFilterChange} id={formId}>
      <FormContent onChange={onFilterChange} reset={reset} />
    </Form>
  )
}

type FormProps = { onChange: any; reset: () => void }

function FormContent(props: FormProps) {
  const { onChange, reset } = props
  const { role } = useGetUser()
  const [multi, external] = useWatch(['multi', 'external'])
  const { setValue } = useFormContext()
  return (
    <VStack
      width="full"
      alignItems={'flex-start'}
      gap={unit('20')}
      marginTop={unit('20')}
    >
      <HStack gap={8} alignItems={'center'}>
        {!multi && isAgency(role) && (
          <DropdownMenu title="filters.conversation.profile">
            <Input.Checkbox
              name={'external'}
              label="filters.conversation.profileExternal"
              onChange={() => {
                setValue(`agencies`, {})
                onChange()
              }}
            />
            <Input.Checkbox
              name={'internal'}
              label="filters.conversation.profileInternal"
              onChange={() => {
                onChange()
              }}
            />
          </DropdownMenu>
        )}
        {!external && <AgencyFilters onChange={onChange} light />}
      </HStack>
      <ActiveFilters onChange={() => onChange()} />
      <HStack
        color={multi ? 'white' : 'black.500'}
        onClick={() => {
          if (!multi) {
            setValue('isInternal', true)
          } else {
            setValue('isInternal', undefined)
          }
          setValue('multi', !multi)
          setValue('external', false)
          setValue('internal', false)
          reset()
          onChange()
        }}
        backgroundColor={multi ? 'blue.500' : 'white'}
        cursor={'pointer'}
        width="full"
        borderRadius={'20px'}
        gap={unit('10')}
        padding={unit('20')}
        border="1px solid"
        borderColor="transparent"
        _hover={{ borderColor: 'blue.500' }}
      >
        <Icon
          as={IconEmployees3}
          width={unit('40')}
          padding="9px"
          height={unit('40')}
          backgroundColor="lightGrey.500"
          borderRadius="50%"
        />
        <Paragraph
          fontWeight="bold"
          text="filters.conversation.group"
          color="inherit"
        />
      </HStack>
    </VStack>
  )
}

export function ConversationListFilter(
  props: Omit<ContactFilterProps, 'reset'>,
) {
  const { filters, onChange } = props

  const methods = useForm<ConversationFilterType>({
    defaultValues: filters,
  })
  const formId = useId()
  const { handleSubmit } = methods
  const onFilterChange = handleSubmit(() => {
    onChange(cleanValues(methods.getValues()))
  })

  return (
    <Form {...methods} onSubmit={onFilterChange} id={formId}>
      <AgencyFilters onChange={onFilterChange} withTitle />
      <ActiveFilters onChange={onFilterChange} />
    </Form>
  )
}

function AgencyFilters(props: {
  onChange: any
  withTitle?: boolean
  light?: boolean
}) {
  const { onChange, withTitle = false, light = false } = props
  return (
    <HStack gap={unit('20')} alignItems="flex-start">
      {withTitle && <Paragraph size="sm" text="filters.conversations.title" />}
      <DropdownMenu title="filters.conversations.target" light={light}>
        <AgencyFilter onFilterChange={onChange} light={light} />
      </DropdownMenu>
    </HStack>
  )
}
