import { useList } from '@aubade/core/adapters'
import { unit } from '@aubade/core/ui-kit'
import { HStack } from '@chakra-ui/react'

import { ExportCard } from '../Components'
import type { TemporaryStatSummary } from '../types'

export function ExportSection() {
  // const { stats } = props
  const [stats] = useList<TemporaryStatSummary>(
    {
      dataProviderName: 'default',
      // @ts-expect-errors
      resource: 'countStatistics',

      filters: [
        {
          field: 'startDate',
          operator: 'eq',
          value: new Date().getTime(),
        },
        {
          field: 'endDate',
          operator: 'eq',
          value: new Date().getTime(),
        },
        {
          field: 'displayType',
          operator: 'eq',
          value: 'day',
        },
      ],
    },
    {
      queryKey: ['export'],
    },
  )
  if (!stats) return <></>
  const { nbAssociates, nbClients } = stats[0]
  return (
    <HStack width="full" paddingY={unit('10')} gap={unit('10')}>
      <ExportCard resource="associates" count={nbAssociates} />
      <ExportCard resource="customers" count={nbClients} />
    </HStack>
  )
}
