import type { TranslationKey } from '@aubade/translation'
import { Flex } from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import { useWatch, useFormContext } from 'react-hook-form'

import type { RHFInputProps } from '../controller'
import { useRHFInput } from '../controller'

import type { CheckboxProps } from './Checkbox'
import { CheckboxInput } from './Checkbox'

export {
  CheckboxInput,
  useCheckboxGroup,
  BaseCheckboxesGroup,
} from './Checkbox'
export type { CheckboxProps as BaseCheckboxProps } from './Checkbox'

type CheckBoxProps<T, Out = boolean> = Omit<
  RHFInputProps<T, Out, boolean>,
  'label'
> &
  Pick<
    CheckboxProps,
    'label' | 'inverse' | 'fullWidth' | 'inputLabel' | 'subtext'
  >
export const RHFCheckbox = function <T, Out = boolean>(
  props: CheckBoxProps<T, Out>,
) {
  const innerProps = useRHFInput<T, Out, boolean>(props)

  return <CheckboxInput {...innerProps} label={props.label} />
}

type BaseCheckboxesGroupProps<T, Out = boolean> = {
  label: TranslationKey
  childCheckboxesProps: CheckBoxProps<T, Out>[]
}
export function CheckboxesGroup<T, Out = boolean>(
  props: BaseCheckboxesGroupProps<T, Out>,
) {
  const { label, childCheckboxesProps } = props

  const childCheckboxesValues = useWatch({
    name: childCheckboxesProps.map((checkbox) => checkbox.name),
    defaultValue: Object.fromEntries(
      childCheckboxesProps.map((checkbox) => [
        checkbox.name,
        checkbox.defaultValue,
      ]) as any,
    ),
  }) as boolean[]

  const data = useMemo(() => {
    const isChecked = childCheckboxesValues.every((value) => Boolean(value))
    return {
      isChecked,
      isIndeterminate:
        !isChecked && childCheckboxesValues.some((value) => Boolean(value)),
    }
  }, [childCheckboxesValues])

  const { setValue } = useFormContext<Record<string, any>>()
  const onChange = useCallback(
    (newValue: boolean) => {
      childCheckboxesProps.forEach((checkbox) =>
        setValue(checkbox.name, newValue),
      )
    },
    [childCheckboxesProps, setValue],
  )

  const { isChecked, isIndeterminate } = data

  return (
    <Flex flexDirection="column" gap={2}>
      <CheckboxInput
        label={label}
        isIndeterminate={isIndeterminate}
        value={isChecked}
        onChange={() => onChange(!isChecked)}
      />
      <Flex marginLeft={8} flexDirection="column" gap={2}>
        {childCheckboxesProps.map((childCheckboxProps) => {
          return (
            <RHFCheckbox
              key={childCheckboxProps.label}
              {...childCheckboxProps}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}
