import { removeEmptyValues, compactObj } from '@aubade/core/libs'
import {
  type Filters,
  unit,
  DropdownMenu,
  Form,
  Paragraph,
  makeForm,
} from '@aubade/core/ui-kit'
import { isAdmin, isAgency, useGetUser } from '@aubade/domain/components'
import { UserRole, CustomerRole } from '@aubade/types/index'
import { HStack, VStack } from '@chakra-ui/react'
import { useId } from 'react'
import type { DefaultValues } from 'react-hook-form'
import { useForm } from 'react-hook-form'

import type { ScopesType } from '../../../components/ScopeProvider'
import { SocietiesFilters } from '../../Publications/filters/FilterForm'

import { ActiveFilters } from '../Components/ActiveFilters'
import type { UserFilterType } from '../types'

const { Input } = makeForm<UserFilterType>()

type Props<T> = {
  onChange: (filters: Filters) => void
  filterState: DefaultValues<T>
  scope: ScopesType['collaboraters'] | ScopesType['customers']
}

export function FilterBar<T extends Filters>(props: Props<T>) {
  const { onChange, filterState, scope } = props
  const { role } = useGetUser()
  const methods = useForm<UserFilterType>({
    // @ts-ignore
    defaultValues: filterState,
  })
  const formId = useId()
  const { handleSubmit } = methods
  const onFilterChange = handleSubmit(() => {
    onChange(cleanValues(methods.getValues()))
  })

  const targetTitle = isAdmin(role)
    ? 'filters.collaboraters.target.aubade'
    : 'filters.collaboraters.target'

  return (
    <Form {...methods} onSubmit={onFilterChange} id={formId}>
      <VStack width="full" gap={10}>
        <HStack
          width="full"
          gap={5}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          marginBottom={'-10px'}
        >
          <HStack gap={unit('20')} alignItems="flex-start">
            <Paragraph size="sm" text={`filters.${scope}.title`} />
            <HStack gap={unit('40')}>
              <DropdownMenu title="filters.collaboraters.role">
                <RoleFilters onChange={() => onFilterChange()} scope={scope} />
              </DropdownMenu>
              {!isAgency(role) && (
                <DropdownMenu title={targetTitle}>
                  <SocietiesFilters onChange={() => onFilterChange()} />
                </DropdownMenu>
              )}
            </HStack>
          </HStack>
        </HStack>
        <ActiveFilters onChange={() => onFilterChange()} />
      </VStack>
    </Form>
  )
}

type RoleFiltersProps = {
  onChange: () => void
  scope: ScopesType['collaboraters'] | ScopesType['customers']
}

function RoleFilters(props: RoleFiltersProps) {
  const { onChange, scope } = props
  const { role } = useGetUser()

  const roles =
    scope === 'collaboraters'
      ? getUserRoleOptions(role! as UserRole)
      : CustomerRole

  return (
    <>
      <VStack
        gap={unit('5')}
        width="full"
        borderRadius={unit('10')}
        padding={unit('20')}
        alignItems={'flex-start'}
      >
        {Object.entries(roles).map(([key, value]) => (
          <Input.Checkbox
            key={key}
            name={`roles.${value}`}
            label={value}
            onChange={() => {
              onChange?.()
            }}
          />
        ))}
      </VStack>
    </>
  )
}

function getUserRoleOptions(role: UserRole) {
  if (isAdmin(role)) return UserRole
  return {
    ROLE_APP_CRAFTER_COMPANY: 'ROLE_APP_CRAFTER_COMPANY',
    ROLE_APP_CRAFTER_STORE: 'ROLE_APP_CRAFTER_STORE',
  }
}

function cleanValues(values: UserFilterType) {
  return removeEmptyValues(
    compactObj({
      ...values,
      agencies: compactObj(values?.agencies ?? {}),
      societies: compactObj(values?.societies ?? {}),
      roles: compactObj(values?.roles ?? {}),
    }),
  )
}
