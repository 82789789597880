import { useOne } from '@aubade/core/adapters'
import { Image, useDesignSystemConfig } from '@aubade/core/ui-kit'
import type { Document } from '@aubade/types/api'
import type { ImageProps } from '@chakra-ui/react'
import { Center, Spinner } from '@chakra-ui/react'
import { useQueryMetaData } from '@nartex/api-platform'
import { useCallback } from 'react'

import { memoCreateObjectURL } from '../libs'

type ResizeProps = {
  size: { width: number; height: number }
  ratio: 'crop' | 'fit'
}

type DocumentImageProps = Omit<ImageProps, 'src'> & {
  documentId: Document.Read['@id'] | undefined
  options?: ResizeProps
}
export function DocumentImage(props: DocumentImageProps) {
  const { documentId, options, ...rest } = props

  const enabled = Boolean(documentId)
  const [document, { isLoading }] = useOne<Document.Read>(
    {
      resource: 'documents',
      id: documentId!,
    },
    {
      suspense: false,
      useErrorBoundary: true,
      enabled,
    },
  )
  const url = useDocumentUrl(document, options)

  if (!enabled) {
    return null
  }

  if (isLoading) {
    return (
      <Center {...rest}>
        <Spinner size={'lg'} />
      </Center>
    )
  }

  return <Image src={url} {...rest} background={'greyscale.200'} />
}

export function useDocumentUrl<
  T extends
    | Pick<Document.Read, 'fileName' | 'objectId' | 'objectType'>
    | undefined,
>(
  document: T,
  options?: ResizeProps,
): T extends undefined ? undefined : string {
  const getDocumentUrl = useGetDocumentUrl(options)
  if (document === undefined) {
    return undefined as any
  }

  if (Array.isArray(document)) {
    return getDocumentUrl(document[0]) as any
  }

  return getDocumentUrl(document) as any
}

export function useGetDocumentUrl(options?: ResizeProps) {
  const { s3 } = useDesignSystemConfig()
  const { headers } = (useQueryMetaData() ?? {}) as {
    headers?: Record<string, string>
  }

  return useCallback(
    (
      document?: Pick<Document.Read, 'fileName' | 'objectId' | 'objectType'> & {
        file?: File
      },
    ) => {
      if (!document) return undefined
      const { fileName, objectId, objectType } = document

      if (fileName === 'PLACEKITTEN') {
        const width = Math.round(200 + Math.random() * 200)
        const height = Math.round(200 + Math.random() * 200)
        return `https://placekitten.com/${width}/${height}`
      }

      if ('file' in document) return memoCreateObjectURL(document.file!)

      if (!s3.url) {
        throw Error(
          'Property `s3.url` is required in DesignSystemConfig provider to use this hook',
        )
      }

      if (options) {
        const { size, ratio } = options
        return `${s3.resizeUrl}/${size.width}/${size.height}/${ratio}/aws/${objectType}/${objectId}/${fileName}`
      }

      const inlineHeaders = new URLSearchParams(headers ?? {}).toString()

      return `${s3.url}/presigned_s3_get/${objectType}/${objectId}/${fileName}?${inlineHeaders}`
    },
    [s3.url, s3.resizeUrl, options, headers],
  )
}

export function useGetTypesenseDocumentUrl() {
  const { s3 } = useDesignSystemConfig()
  const { headers } = (useQueryMetaData() ?? {}) as {
    headers?: Record<string, string>
  }

  if (!s3.url) {
    throw Error(
      'Property `s3.url` is required in DesignSystemConfig provider to use this hook',
    )
  }

  return useCallback(
    (url: string) => {
      const inlineHeaders = new URLSearchParams(headers ?? {}).toString()
      return `${s3.url}${url}?${inlineHeaders}`.replace('/s3/s3', '/s3')
    },
    [s3.url, headers],
  )
}
