// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconEyeShow = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 7C12.4771 7 6.61147 13.8344 0.311128 24.9108L0 25.4607L0.311128 26.0892C6.61147 37.0871 12.4771 44 25 44C37.5229 44 43.3885 37.1656 49.6889 26.0892L50 25.4607L49.6889 24.8323C43.3885 13.8344 37.5229 7 25 7ZM10.6103 25.4607C10.6103 17.448 17.0662 10.9278 25 10.9278C32.9338 10.9278 39.3897 17.448 39.3897 25.4607C39.3897 33.4735 32.9338 39.9936 25 39.9936C17.0662 39.9936 10.6103 33.4735 10.6103 25.4607ZM20.9571 16.9767C22.2017 16.3482 23.524 16.034 25.0018 16.034C30.1354 16.034 34.3356 20.276 34.3356 25.4607C34.3356 30.6455 30.1354 34.8875 25.0018 34.8875C19.8682 34.8875 15.668 30.6455 15.668 25.4607C15.668 23.9682 15.9791 22.6327 16.6014 21.3758C17.1458 22.0043 17.9236 22.3185 18.7792 22.3185C20.4905 22.3185 21.8905 20.9045 21.8905 19.1762C21.8905 18.3121 21.5016 17.5266 20.9571 16.9767Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconEyeShow)
export default Memo
