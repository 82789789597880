import { useCallback, useSyncExternalStore } from 'react'
import { useFormContext } from 'react-hook-form'
import type { Control, FieldValues, UseFormReturn } from 'react-hook-form'

import { useStabilizeResult } from '../../../libs'

// same as useFormSelector, but subscribes only to formState
export function useFormStateSelector<TValues extends FieldValues, U>(
  selector: (
    formState: Control<TValues>['_formState'],
    form: UseFormReturn<TValues>,
  ) => U,
  deps?: unknown[],
): U {
  const form = useFormContext<TValues>()
  const { control } = form
  return useSyncExternalStore(
    useCallback(
      (notify) => {
        const { unsubscribe } = control._subjects.state.subscribe({
          next: notify,
        })
        return unsubscribe
      },
      [control],
    ),
    useStabilizeResult<U>(() => {
      return selector(form.control._formState, form)
    }, [selector, form, ...(deps ?? [])]),
  )
}

export function useFormSelector<TValues extends FieldValues, U>(
  selector: (form: UseFormReturn<TValues>) => U,
  deps?: unknown[],
): U {
  const form = useFormContext<TValues>()
  const { control } = form
  return useSyncExternalStore(
    useCallback(
      (notify) => {
        const { unsubscribe: unsubscribeState } =
          control._subjects.state.subscribe({
            next: notify,
          })
        const { unsubscribe: unsubscribeValues } =
          control._subjects.values.subscribe({
            next: notify,
          })
        const { unsubscribe: unsubscribeArray } =
          control._subjects.array.subscribe({
            next: notify,
          })

        return () => {
          unsubscribeState()
          unsubscribeValues()
          unsubscribeArray()
        }
      },
      [control],
    ),
    useStabilizeResult<U>(() => {
      return selector(form)
    }, [selector, form, ...(deps ?? [])]),
  )
}
