import type { SimpleGridProps } from '@chakra-ui/react'
import { SimpleGrid as ChakraSimpleGrid } from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'

export type SmartGridProps = PropsWithChildren<
  Pick<SimpleGridProps, 'maxWidth' | 'alignItems'>
> & {
  columns?: 1 | 2 | 3 | 4
  responsive?: boolean
}

export function SmartGrid(props: SmartGridProps) {
  const { columns = 3, responsive = true, ...rest } = props
  return (
    <ChakraSimpleGrid
      width="full"
      gridColumnGap={6}
      gridRowGap={9}
      alignItems="flex-start"
      columns={responsive ? responsiveColumnsMapping[columns] : columns} // default to a smart enough responsive value
      {...rest}
    />
  )
}

const responsiveColumnsMapping = {
  1: 1,
  2: [1, 2],
  3: [1, 2, 2, 3],
  4: [1, 2, 2, 4],
}
