import { useHTTPClient, useList } from '@aubade/core/adapters'
import { useToastMessage } from '@aubade/core/ui-kit'
import type { BlackList } from '@aubade/types/api'
import { toHydraId, useDelete, useQueryMetaData } from '@nartex/api-platform'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { v4 as uuid } from 'uuid'

export function useActions(
  craftCompany: string,
  isBlocked: boolean,
  blockedBy?: string,
) {
  const httpClient = useHTTPClient()
  const queryClient = useQueryClient()
  const metaData = useQueryMetaData()
  const toast = useToastMessage()

  const [fullBlackList] = useList<BlackList.Read>(
    {
      // @ts-expect-error
      resource: 'black_lists',
      dataProviderName: 'default',
      filters: [
        {
          field: 'craftCompany',
          operator: 'eq',
          value: toHydraId('craft_companies', craftCompany),
        },
        {
          field: 'agency',
          operator: 'eq',
          value: toHydraId('agencies', blockedBy ?? ''),
        },
      ],
    },
    {
      enabled: Boolean(blockedBy),
    },
  )

  const { mutate: blockCrafter } = useMutation({
    mutationFn(): Promise<any> {
      return httpClient.request({
        method: 'post',
        url: `/black_lists`,
        data: {
          newId: uuid(),
          craftCompany,
        },
        headers: metaData?.headers,
      })
    },

    async onSuccess() {
      await queryClient.invalidateQueries()
      toast('success', 'notifications.blockCrafter')
    },
    onError(error: Error) {
      toast('error', error.message)
    },
  })

  const { mutate: unblockCrafter } = useDelete()

  function toggleBlockCrafter() {
    if (isBlocked === true && fullBlackList && fullBlackList[0]) {
      unblockCrafter(
        {
          resource: 'black_lists',
          id: fullBlackList[0].id,
        },
        {
          async onSuccess() {
            await queryClient.invalidateQueries()
            toast('success', 'notifications.unblockCrafter')
          },
        },
      )
    } else {
      blockCrafter()
    }
  }

  return {
    toggleBlockCrafter,
  }
}
