import type { TranslationKey } from '@aubade/translation'
import { useTranslate } from '@aubade/translation'
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons'
import type { FlexProps, IconProps } from '@chakra-ui/react'
import { Box, Flex, Icon, Link, List, ListItem, Spacer } from '@chakra-ui/react'
import type { ElementType, PropsWithChildren, ReactNode } from 'react'
import { useMemo } from 'react'
import type { LinkProps, NavLinkProps, To } from 'react-router-dom'
import {
  NavLink,
  useLocation,
  useResolvedPath,
  Link as RouterLink,
} from 'react-router-dom'

import { Paragraph } from '../Paragraph'
import { useTranslatable } from '../useTranslatable'

function getMenuIconStyle(isActive?: boolean): IconProps {
  return {
    width: 6,
    height: 6,
    color: isActive ? 'blue.500' : 'darkGrey.500',
    _groupHover: {
      color: 'blue.500',
    },
  }
}

function getNavItemStyle(isActive?: boolean): FlexProps {
  return {
    align: 'center',
    role: 'group',
    cursor: 'pointer',
    color: isActive ? 'blue.500' : 'darkGrey.500',

    _hover: {
      color: 'blue.500',
    },
  }
}

type NavItemProps = Omit<NavComponentProps, 'isActive'> &
  NavLinkProps & { label?: TranslationKey }
export function NavItem(props: NavItemProps) {
  const { icon, children, ...linkProps } = useTranslatable(props)

  const location = useLocation()

  if (location.pathname === linkProps.to) {
    return (
      <NavComponent icon={icon} isActive={true}>
        <Paragraph color="inherit" fontWeight="bold">
          {children}
        </Paragraph>
      </NavComponent>
    )
  }

  return (
    <NavLink {...linkProps} style={{ width: '100%' }}>
      {(linkState) => {
        const { isActive } = linkState
        if (isActive) {
          return (
            <Link
              style={{ textDecoration: 'none' }}
              _focus={{ boxShadow: 'none' }}
              as={Flex}
              width="full"
            >
              <NavComponent icon={icon} isActive={isActive}>
                <Paragraph color="inherit" fontWeight="bold">
                  {children}
                </Paragraph>
              </NavComponent>
            </Link>
          )
        }
        return (
          <Link
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}
            as={Flex}
            width="full"
          >
            <NavComponent icon={icon} isActive={isActive}>
              <Paragraph color="inherit" fontWeight="bold">
                {children}
              </Paragraph>
            </NavComponent>
          </Link>
        )
      }}
    </NavLink>
  )
}

type NavComponentProps = {
  icon?: ElementType<any>
  children?: ReactNode
  isActive?: boolean
}
function NavComponent(props: NavComponentProps) {
  const { icon, children, isActive } = useTranslatable(props)
  return (
    <Flex
      {...getNavItemStyle(isActive)}
      width="full"
      gap={4}
      position="relative"
    >
      {icon && (
        <Icon
          {...getMenuIconStyle(isActive)}
          as={icon}
          width="18px"
          height="18px"
        />
      )}
      {children}
    </Flex>
  )
}

type NavGroupProps = LinkProps &
  PropsWithChildren<{
    icon?: ElementType<any>
    label: TranslationKey
  }>

export function NavGroup(props: NavGroupProps) {
  const { label, icon, children, ...linkProps } = props
  const translate = useTranslate()

  const isActive = useLinkIsActive(linkProps.to)
  return (
    <Box width="full">
      <RouterLink {...linkProps}>
        <NavComponent isActive={isActive} icon={icon}>
          <Flex alignItems="center" width="full">
            <Paragraph color="inherit" fontWeight="bold">
              {translate(label)}
            </Paragraph>
            <Spacer />
            <Icon
              {...getMenuIconStyle(isActive)}
              as={isActive ? ChevronDownIcon : ChevronRightIcon}
            />
          </Flex>
        </NavComponent>
      </RouterLink>

      {isActive && (
        <Box
          display="flex"
          width="full"
          paddingLeft={6}
          _before={{
            content: `''`,
            borderLeft: '1px',
            borderColor: 'primary.500',
            marginBottom: '24px',
          }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          cursor="default"
        >
          <List width="full">{children}</List>
        </Box>
      )}
    </Box>
  )
}

NavGroup.Item = function NavGroupItem(
  props: Omit<NavItemProps, 'onClick' | 'icon'>,
) {
  const { children, replace, state, reloadDocument, to } =
    useTranslatable(props)
  return (
    <ListItem
      display="flex"
      alignItems="center"
      width="full"
      _before={{
        content: `''`,
        width: '18px',
        height: '8px',
        borderBottomLeftRadius: '6px',
        borderBottom: '1px',
        borderLeft: '1px',
        marginLeft: '-1px',
        marginBottom: '4px',
        borderColor: 'primary.500',
      }}
    >
      <NavLink
        to={to}
        replace={replace}
        state={state}
        reloadDocument={reloadDocument}
        style={{
          width: 'calc(100% - 18px)',
        }}
      >
        {(linkState) => {
          const { isActive } = linkState
          return (
            <Flex
              style={{ textDecoration: 'none' }}
              _focus={{ boxShadow: 'none' }}
              padding={2}
              {...getNavItemStyle()}
              gap={1.5}
              width="full"
            >
              {isActive && (
                <Icon
                  viewBox="0 0 100 100"
                  {...getMenuIconStyle()}
                  height={2}
                  width={2}
                >
                  <circle fill="currentColor" cx="50" cy="50" r="50" />
                </Icon>
              )}
              <Paragraph fontWeight={isActive ? 'bold' : 'normal'} ellipsis>
                {children}
              </Paragraph>
            </Flex>
          )
        }}
      </NavLink>
    </ListItem>
  )
}

function useLinkIsActive(to: To) {
  // NavLink implementation from react-router : https://github.com/remix-run/react-router/blob/main/packages/react-router-dom/index.tsx#L514
  // Can't use NavLink directly because of <a> html element nesting
  const path = useResolvedPath(to)
  const location = useLocation()
  const isActive = useMemo(() => {
    const toPathname = path.pathname
    const locationPathname = location.pathname

    return (
      locationPathname === toPathname ||
      (locationPathname.startsWith(toPathname) &&
        locationPathname.charAt(toPathname.length) === '/')
    )
  }, [path, location])
  return isActive
}
