import { unit } from '@aubade/core/ui-kit'
import type { SystemStyleObject } from '@chakra-ui/react'
import {
  Td,
  Tr,
  Table as ChakraTable,
  Tbody,
  Box,
  Grid,
  TableContainer,
} from '@chakra-ui/react'

import { flexRender } from '@tanstack/react-table'
import type { Table as ReactTable, Row } from '@tanstack/react-table'
import type { PropsWithChildren, MouseEventHandler } from 'react'
import { useCallback } from 'react'
import type { To } from 'react-router-dom'

import { useEvent } from '../../libs'
import { getButtonBehavior } from '../Button'
import { Paragraph, getParagraphStyle } from '../Paragraph'

type RowProps = {
  to?: To
  onClick?: () => void
  isDisabled?: boolean
  isSelectionDisabled?: boolean
}

export type GetRowProps<T> = (row: T) => RowProps

export type BaseTableProps<T> = {
  table: ReactTable<T>
  getRowProps?: GetRowProps<T>
  noDataLabel?: string
  templateColumns: any
}
export function BaseTable<T>(props: BaseTableProps<T>) {
  const { table, getRowProps, noDataLabel, templateColumns } = props

  const onCellClick = useEvent<MouseEventHandler<HTMLElement>>((event) => {
    if (!event.target) return
    const interactiveElement = hasClickedAnInteractiveChildren(
      event.target as HTMLElement,
      event.currentTarget as HTMLElement,
    )
    if (interactiveElement) {
      if (interactiveElement.tagName === 'LABEL') {
        event.stopPropagation()
      } else {
        event.preventDefault()
      }
    }
  })

  const tableBody = useCallback(() => {
    const { rows } = table.getRowModel()

    if (rows.length === 0) {
      return (
        <Tr
          _hover={undefined}
          display="table-row"
          role="row"
          borderColor="transparent"
        >
          <Grid
            width="100%"
            templateColumns={'1fr'}
            gap={'20px'}
            paddingY={unit('15')}
            paddingX={unit('50')}
          >
            <Td
              borderColor="transparent"
              as={'object'}
              role="gridcell"
              display={'table-cell'}
              paddingLeft={0}
            >
              <Paragraph size="sm" text={noDataLabel ?? 'table.noData'} />
            </Td>
          </Grid>
        </Tr>
      )
    }

    return rows.map((row) => {
      const { isDisabled } = getRowProps?.(row.original) ?? {}
      return (
        <RowWrapper<T>
          key={row.id}
          row={row}
          getRowProps={getRowProps}
          templateColumns={templateColumns}
        >
          {row.getVisibleCells().map((cell) => (
            <Td
              // https://kizu.dev/nested-links/
              as={'object'}
              key={cell.id}
              onClick={onCellClick}
              role="gridcell"
              display={'table-cell'}
              {...getParagraphStyle({
                color: isDisabled ? 'greyscale.400' : 'greyscale.900',
              })}
              // padding must not be set on the `<object />` tag, otherwise it will crop its children !
              verticalAlign="middle"
              overflow={'visible !important'}
              style={{ overflowClipMargin: 'border-box !important' }}
              border="none"
              padding={0}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          ))}
        </RowWrapper>
      )
    })
  }, [table, noDataLabel, getRowProps, templateColumns, onCellClick])

  return (
    <TableContainer width={'full'} overflowX="visible" overflowY="visible">
      <Box as={ChakraTable} maxWidth={'100%'}>
        <Tbody>{tableBody()}</Tbody>
      </Box>
    </TableContainer>
  )
}

type RowWrapperProps<T> = Omit<BaseTableProps<T>, 'table'> & {
  row: Row<T>
}
function RowWrapper<T>(props: PropsWithChildren<RowWrapperProps<T>>) {
  const { row, getRowProps, templateColumns, children } = props

  const rowProps = getRowProps?.(row.original)
  const buttonBehavior = getButtonBehavior(rowProps ?? {})

  if (buttonBehavior.as) {
    return (
      <Tr
        {...(buttonBehavior as any)}
        display="table-row"
        role="row"
        borderColor="transparent"
      >
        <Grid
          paddingY={unit('15')}
          paddingX={unit('50')}
          templateColumns={templateColumns}
          border="1px solid transparent"
          gap={'20px'}
          {...rowStyle}
        >
          {children}
        </Grid>
      </Tr>
    )
  }

  return (
    <Tr
      _hover={undefined}
      display="table-row"
      role="row"
      borderColor="transparent"
    >
      <Grid
        width="100%"
        templateColumns={templateColumns}
        gap={'20px'}
        paddingY={unit('15')}
        paddingX={unit('50')}
      >
        {children}
      </Grid>
    </Tr>
  )
}

// check onClick

const interactiveTagNames = [
  'BUTTON',
  'A',
  'INPUT',
  'LABEL',
  'TEXTAREA',
  'SELECT',
]
const interactiveRoles = ['button', 'link', 'textbox', 'input']
function isInteractiveElement(element: HTMLElement) {
  if (interactiveTagNames.includes(element.tagName)) return true
  if (interactiveRoles.includes(element.role ?? '')) return true
  return false
}

function hasClickedAnInteractiveChildren(
  clickTarget: HTMLElement,
  currentTarget: HTMLElement,
) {
  let current = clickTarget as HTMLElement
  while (current) {
    if (current === currentTarget) return false
    if (isInteractiveElement(current)) return current
    if (!current.parentElement) return false
    current = current.parentElement
  }
  return false
}

// row styles

const rowHoverStyle = {
  background: 'white',
  boxShadow: '0px 0px 20px 3px rgba(0, 0, 0, 0.05)',
  transitionDuration: '.3s',
  transitionTimingFunction: 'ease-in',
} satisfies SystemStyleObject

const rowStyle = {
  borderRadius: '10px',
  _hover: rowHoverStyle,
} satisfies SystemStyleObject
