import { Button, unit } from '@aubade/core/ui-kit'
import { IconFermer } from '@aubade/design/graphics'
import type { ModalProps as ChakraModalProps } from '@chakra-ui/react'
import {
  VStack,
  Modal as ChakraModal,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { useLayoutEffect, useRef } from 'react'

import { useOnClickOutside } from '../../libs'

export * from './useModal'

type ModalProps = ChakraModalProps & {
  actions?: ReactNode
  withCloseButton?: boolean
}

export function Modal(props: ModalProps) {
  const {
    onClose,
    children,
    size = '6xl',
    actions,
    withCloseButton = true,
    ...rest
  } = props

  const [{ zIndex }, withClickOutsideListener] = useOnClickOutside(onClose)

  // The zIndex property is set on the ModalContent parent element, which we don't have a direct access to
  // This hack circumvents this limitation
  const modalContentRef = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    modalContentRef.current?.parentElement?.style.setProperty(
      'z-index',
      String(zIndex + 1000),
    )
  }, [zIndex])

  return (
    <ChakraModal {...rest} size={size} onClose={onClose}>
      <ModalOverlay />
      {withClickOutsideListener(
        <ModalContent ref={modalContentRef} position={'relative'} padding={0}>
          <VStack
            gap={unit('10')}
            position="absolute"
            top={0}
            right={unit('-50')}
          >
            {withCloseButton && (
              <Button
                variant="circleList"
                rightIcon={IconFermer}
                onClick={onClose}
              />
            )}
            {actions}
          </VStack>
          {children}
        </ModalContent>,
      )}
    </ChakraModal>
  )
}
