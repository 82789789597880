import { isValidPhoneNumber } from 'libphonenumber-js'

import { useRHFInput } from '../controller'
import type { RHFInputProps, Transformer } from '../controller'

import type { TextInputProps, TextInputType } from './TextInput'
import { TextInput } from './TextInput'

export { isTextInputType, TextInput } from './TextInput'
export type { TextInputType } from './TextInput'

export const RHFTextInput = function <T, Outer = string>(
  props: RHFInputProps<T, Outer, string, HTMLInputElement> &
    Omit<TextInputProps, 'onKeyDown'>,
) {
  const { type = 'text', suggestions, rules, ...rest } = props
  const innerProps = useRHFInput<T, Outer, string, string, HTMLInputElement>({
    ...rest,
    rules: {
      ...rules,
      validate: function (value, formValues) {
        if (rules?.validate) {
          if (typeof rules.validate === 'function') {
            const result = rules.validate(value, formValues)
            if (result) return result
          } else {
            Object.values(rules.validate).forEach((validateFn) => {
              const result = validateFn(value, formValues)
              if (result) return result
            })
          }
        }
        return isValidTextInput({ value, type })
      },
    },
  })

  return <TextInput {...innerProps} type={type} suggestions={suggestions} />
}

export const textTransformer: Transformer<string | null | undefined, string> = {
  read(value: string | null): string {
    if (!value) return ''
    return value
  },
  write(value: string): string | null {
    value = value?.trim()
    if (!value) return null
    return value
  },
}

type IsValidInputParams = {
  value: string
  type: TextInputType
  maxLength?: number
}
export function isValidTextInput(params: IsValidInputParams) {
  const { value, type, maxLength } = params
  const mailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  if (type === 'email') {
    if (!value) return
    if (!mailRegex.test(value)) return 'errors.invalidEmail'
  }
  if (type === 'tel') {
    if (!value) return
    if (!isValidPhoneNumber(value, 'FR')) return 'errors.invalidPhone'
  }
  if (type === 'url') {
    const urlRegex = /^(http:\/\/|https:\/\/)/
    if (!value) return
    if (!urlRegex.test(value)) return 'errors.invalidUrl'
  }
  if (maxLength) {
    if (!value) return
    if (value.length > maxLength) return 'errors.tooLongText'
  }
}
