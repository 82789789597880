import { useList, useOne } from '@aubade/core/adapters'
import type { SearchFilter } from '@aubade/core/adapters'
import { useQueryParamState } from '@aubade/core/libs'
import type { Filters } from '@aubade/core/ui-kit'
import {
  unit,
  DropdownMenu,
  Form,
  makeInputs,
  Paragraph,
  Accordion,
} from '@aubade/core/ui-kit'
import { isAdmin, isAgency, useGetUser } from '@aubade/domain/components'
import type { Contact } from '@aubade/types'
import { type Typesense } from '@aubade/types/typesense'
import { Box, HStack, VStack } from '@chakra-ui/react'

import { toHydraId } from '@nartex/api-platform'
import type { BaseSyntheticEvent } from 'react'
import { useId, useMemo } from 'react'
import type { DefaultValues } from 'react-hook-form'
import { useForm } from 'react-hook-form'

import { AgencyFilter, SocietyAgencyFilter } from 'src/components/Filters'

import { cleanValues } from 'src/libs/cleanValues'
import { StringParam } from 'use-query-params'

import { ActiveFilters } from '../Components/ActiveFliters'

import type { PublicationFilterType } from './types'

const Input = makeInputs()

type Props<T> = {
  onChange: (filters: Filters) => void
  filterState: DefaultValues<T>
  resource: 'notifications' | 'publications'
  context: 'feed' | 'publications'
}

export function FilterBar<T extends Filters>(props: Props<T>) {
  const { onChange, filterState, resource, context } = props
  const { role } = useGetUser()
  const methods = useForm<PublicationFilterType>({
    // @ts-ignore
    defaultValues: filterState,
  })
  const formId = useId()
  const { handleSubmit } = methods
  const onFilterChange = handleSubmit(() => {
    onChange(cleanValues(methods.getValues()))
  })

  const hideToggle = Boolean(context === 'feed' && isAgency(role))

  return (
    <Form {...methods} onSubmit={onFilterChange} id={formId}>
      <VStack width="full">
        <HStack
          width="full"
          gap={5}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          marginBottom={'-10px'}
        >
          <HStack gap={unit('20')} alignItems="flex-start">
            <Paragraph size="sm" text="filters.publications.title" />
            <DropdownMenu title="filters.publications.target">
              <SocietiesFilters onChange={() => onFilterChange()} />
            </DropdownMenu>
          </HStack>
          {!hideToggle && (
            <Box maxWidth="300px">
              <Input.Switch
                label={`filters.${resource}.filterMe`}
                name="me"
                onChange={() => onFilterChange()}
              />
            </Box>
          )}
        </HStack>
        <ActiveFilters onChange={onFilterChange} scope={resource} />
      </VStack>
    </Form>
  )
}

export function SocietiesFilters(props: {
  onChange: (
    e?: BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>
}) {
  const { onChange } = props
  const { id: me } = useGetUser()
  const paramName = 'societies.q'
  const [queryValue] = useQueryParamState(paramName, StringParam)

  const listFilters = useMemo(() => {
    const search = queryValue
    if (search) {
      const qFilter: SearchFilter = { q: search }

      return [qFilter]
    }
  }, [queryValue])

  const [currentUser] = useOne<Contact>({
    iri: toHydraId('users', me!),
  })

  const [societies] = useList<Typesense.Societies>({
    resource: 'societies',
    dataProviderName: 'default',
    filters: listFilters,
    hasPagination: true,
    pagination: { pageSize: 250 },
  })

  if (!societies || !currentUser) return <></>
  const filterType = isAdmin(currentUser!.acRoles.AC)
    ? 'societyAgency'
    : 'agency'
  return (
    <VStack
      gap={unit('5')}
      maxHeight={unit('500')}
      width="full"
      borderRadius={unit('10')}
      padding={unit('20')}
    >
      <Accordion>
        {filterType === 'societyAgency' ? (
          <SocietyAgencyFilter onFilterChange={onChange} />
        ) : (
          <AgencyFilter onFilterChange={onChange} />
        )}
      </Accordion>
    </VStack>
  )
}
