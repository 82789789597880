import type { Option } from '@aubade/core/ui-kit/DataPicker/types'
import { Flex, useMergeRefs } from '@chakra-ui/react'
import type { ForwardedRef, ReactNode, RefCallback } from 'react'
import { useRef, useMemo, useCallback, forwardRef } from 'react'

import type { ImmutableSet, Merge } from '../../../libs'
import { createImmutableSet, useEvent } from '../../../libs'
import type { BaseInputProps, RHFInputProps, Transformer } from '../../Inputs'
import { useBaseInput, useRHFInput } from '../../Inputs'
import type { SmartGridProps } from '../../SmartGrid'
import { SmartGrid } from '../../SmartGrid'
import { Suggest } from '../Suggest'

import type { SuggestProps, SuggestRenderOptionsProps } from '../Suggest'

export type PickerRenderOptionsProps<T> = SuggestRenderOptionsProps & {
  values: ImmutableSet<T>
  onAdd: (addedValue: T) => void
  onRemove: (removedValue: T) => void
  withoutEmptyText?: boolean
}

export type PickerRenderValuesProps<T> = {
  values: ImmutableSet<T>
  onAdd: (addedValue: T) => void
  onRemove: (removedValue: T) => void
  readOnly?: boolean
}

type BasePickerProps<T> = Merge<
  SuggestProps,
  {
    max?: number
    renderOptions: (renderProps: PickerRenderOptionsProps<T>) => ReactNode
    renderValues: (renderProps: PickerRenderValuesProps<T>) => ReactNode
    renderFooter?: (renderProps: PickerRenderOptionsProps<T>) => ReactNode
    renderFilters?: (options: Option[]) => ReactNode
    hideFilters?: boolean
    subGrid?: SmartGridProps['columns']
  }
>
export type PickerProps<T> = BaseInputProps<T[]> & BasePickerProps<T>

const PickerImpl = forwardRef(function Picker<T>(
  props: PickerProps<T>,
  ref?: ForwardedRef<HTMLInputElement>,
) {
  const {
    label,
    renderOptions,
    renderValues,
    renderFooter,
    renderFilters,
    max = Infinity,
    placeholder,
    disabled,
    readOnly,
    subGrid = 1,
    hideFilters,
    ...inputProps
  } = props
  const { field, wrap } = useBaseInput(inputProps, {
    baseTransformer: immutableSetTransformer as SetTransformer<T>,
    noLocalValue: true,
  })

  const containerRef = useRef<HTMLDivElement>(null)
  const textInputRef = useRef<HTMLInputElement>(null)
  const suggestRef = useMergeRefs(field.ref, textInputRef, ref)

  const values = useMemo(
    () => field.value ?? createImmutableSet<T>(),
    [field.value],
  )

  const onAdd = useEvent((addedValue: T) => {
    field.onChange(values.withAdded(addedValue))
    // Temporary remove autofocus
    // setTimeout(() => focusFirstChild(containerRef.current), 50)
  })

  const onRemove = useEvent((removedValue: T) => {
    field.onChange(values.withDeleted(removedValue))
    // Temporary remove autofocus
    // setTimeout(() => textInputRef.current?.focus(), 50)
  })

  const renderSuggestOptions = useCallback<SuggestProps['renderOptions']>(
    (renderProps) => {
      return renderOptions({ ...renderProps, values, onAdd, onRemove })
    },
    [onAdd, onRemove, renderOptions, values],
  )
  const innerRenderFooter = useCallback<Required<SuggestProps>['renderFooter']>(
    (renderProps) => {
      return renderFooter?.({ ...renderProps, values, onAdd, onRemove })
    },
    [onAdd, onRemove, renderFooter, values],
  )

  const canAddValues = values.size < max && !readOnly

  const renderedValues = useMemo(() => {
    return renderValues({
      values: values.chain((vals) => vals.reverse()),
      onAdd,
      onRemove,
      readOnly,
    })
  }, [onAdd, onRemove, renderValues, values, readOnly])

  const body = (
    <Flex
      gap={3}
      ref={containerRef}
      alignItems={'flex-start'}
      direction={'column'}
      width={'full'}
      height="full"
    >
      <SmartGrid columns={subGrid}>
        <Suggest
          placeholder={placeholder}
          {...field}
          hideFilters={hideFilters}
          ref={suggestRef}
          disabled={disabled ?? !canAddValues}
          label={label}
          renderOptions={renderSuggestOptions}
          renderFooter={renderFooter && innerRenderFooter}
          renderValues={renderedValues}
          renderFilters={renderFilters}
        />
      </SmartGrid>
    </Flex>
  )

  // prevents double error message
  if (canAddValues) {
    return body
  } else {
    return wrap(body)
  }
})

export const Picker = Object.assign(
  PickerImpl as <T>(props: PickerProps<T>) => ReactNode,
  {
    Option: Suggest.Option,
  },
)

export type SinglePickerProps<T> = Omit<
  BaseInputProps<T> & BasePickerProps<T>,
  'transfomer' | 'max'
>
const SinglePickerImpl = forwardRef(function SinglePickerImpl<T>(
  props: SinglePickerProps<T>,
  ref?: ForwardedRef<HTMLInputElement>,
) {
  const { value, onChange } = props

  const valueMemo = useMemo(() => {
    if (value == null) return []
    else return [value]
  }, [value])

  return (
    // @ts-ignore
    <Picker<T>
      {...props}
      ref={ref as RefCallback<HTMLInputElement>}
      max={1}
      // transfomer was too hard to type
      transformer={undefined}
      value={valueMemo}
      onChange={useEvent((newValue) => {
        onChange?.(newValue?.at(-1) ?? null)
      })}
    />
  )
})

export const SinglePicker = Object.assign(
  SinglePickerImpl as <T>(props: SinglePickerProps<T>) => ReactNode,
  {
    Option: Suggest.Option,
  },
)

type SetTransformer<T> = Transformer<T[] | null | undefined, ImmutableSet<T>>
const immutableSetTransformer: SetTransformer<unknown> = {
  read(vals) {
    return createImmutableSet(vals ?? [])
  },
  write(set) {
    return Array.from(set)
  },
}

type RHFSinglePickerProps<Form, T> = Omit<
  Merge<SinglePickerProps<T>, RHFInputProps<Form, T>>,
  'value' | 'transformer'
>
export function RHFSinglePicker<Form, T>(props: RHFSinglePickerProps<Form, T>) {
  const { renderOptions, renderValues, renderFooter, ...rest } = props

  const rhfInputProps = useRHFInput<Form, T>({
    ...rest,
  })
  return (
    // @ts-ignore
    <SinglePicker
      {...rhfInputProps}
      renderOptions={renderOptions}
      renderValues={renderValues}
      renderFooter={renderFooter}
    />
  )
}
