import type { AutoCompleteString } from '@aubade/core/libs'
import type { TranslationKey } from '@aubade/translation'
import { useTranslate } from '@aubade/translation'
import { Search2Icon, CloseIcon } from '@chakra-ui/icons'
import {
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
} from '@chakra-ui/react'

import { useInput } from './controller'

type SearchInputProps<T> = {
  label?: TranslationKey
  name: AutoCompleteString<keyof T & string>
  onFocus?: () => void
  onBlur?: () => any
}
export function SearchInput<T>(props: SearchInputProps<T>) {
  const { label } = props
  const { field, wrap } = useInput<T>(props)
  const translate = useTranslate()

  const placeholder = translate(label || 'actions.search')
  return wrap(
    <InputGroup>
      <InputLeftElement pointerEvents="none" right="auto" left={4}>
        <Search2Icon color="primary.400" />
      </InputLeftElement>
      <Input
        {...field}
        value={field.value ?? ''}
        placeholder={placeholder}
        type="search"
        bg="white"
        aria-label={label ? undefined : placeholder}
      />
      <InputRightElement onClick={() => field.onChange('')} cursor="pointer">
        <CloseIcon />
      </InputRightElement>
    </InputGroup>,
  )
}
