import { unit, makeForm, CheckboxInput } from '@aubade/core/ui-kit'
import { isAdmin, useGetUser } from '@aubade/domain/components'
import type { ExtendedUser } from '@aubade/types'
import { VStack } from '@chakra-ui/react'
import { cleanHydraId } from '@nartex/api-platform'

const { Input, useWatch } = makeForm<
  ExtendedUser,
  'isNotAvailable' | 'id' | 'acRoles'
>()

type FormProfileProps = {
  profile: 'customer' | 'collaborater'
}
export function FormProfile(props: FormProfileProps) {
  const { profile } = props
  const { id: me } = useGetUser()
  const [id, acRoles] = useWatch(['id', 'acRoles'])
  const isAubade = Boolean(acRoles && isAdmin(acRoles.AC as any))

  const isMyProfile = cleanHydraId(me) === cleanHydraId(id!)
  const showInputs = isMyProfile && !isAubade && profile === 'collaborater'

  if (!showInputs) return <></>

  return (
    <VStack gap={unit('20')} width="full" alignItems={'flex-start'}>
      <CheckboxInput
        label="users.fields.acceptNotifications"
        disabled
        value={Notification.permission === 'granted'}
      />
      <VStack gap={0}>
        <Input.Checkbox
          label="users.fields.available"
          name="isNotAvailable"
          subtext="users.fields.availableDescription"
        />
      </VStack>
    </VStack>
  )
}
