import { useEvent } from '@aubade/core/libs'
import type { TranslationKey } from '@aubade/translation'
import type { AlertStatus, UseToastOptions } from '@chakra-ui/react'
import {
  useToast,
  Alert as ChakraAlert,
  Flex,
  CloseButton,
  Spacer,
} from '@chakra-ui/react'

import { Paragraph } from './Paragraph'

export function useToastMessage() {
  const chakraToast = useToast()
  function toast(
    status: AlertStatus,
    message: TranslationKey,
    position: UseToastOptions['position'] = 'top',
    duration: number = 4000,
    isClosable: boolean = true,
  ) {
    return chakraToast({
      isClosable,
      duration,
      position,
      render(props) {
        const { onClose } = props
        return (
          <Alert
            title={message}
            variant={status}
            hasIcon
            onClose={isClosable ? onClose : undefined}
          />
        )
      },
    })
  }

  return useEvent(toast)
}
type AlertVariant =
  | 'default'
  | 'info'
  | 'warning'
  | 'success'
  | 'error'
  | 'loading'
const alertStyleMap: Record<
  AlertVariant,
  { color: string } // icon: As;
> = {
  default: {
    color: 'greyscale.900',
  },
  info: {
    color: 'primary.500',
  },
  loading: {
    color: 'primary.500',
  },
  warning: {
    color: 'warning.dark',
  },
  success: {
    color: 'success.dark',
  },
  error: {
    color: 'error.dark',
  },
}

type AlertProps = {
  hasIcon?: boolean
  title: TranslationKey
  text?: TranslationKey
  variant?: AlertVariant
  onClose?: () => void
}

function Alert(props: AlertProps) {
  const { variant = 'default', title, text, onClose } = props

  const alertStyle = alertStyleMap[variant]
  return (
    <ChakraAlert
      gap={2}
      padding={4}
      backgroundColor={'white.500'}
      alignItems="center"
      borderRadius={10}
      borderLeft="5px solid"
      border="1px solid"
      borderColor="lightgrey.500"
    >
      <Flex gap={2} alignItems="baseline">
        <Paragraph text={title} fontWeight="bold" color={alertStyle.color} />
        {text && <Paragraph size="md" text={text} color="darkgrey.500" />}
      </Flex>
      <Spacer />
      {onClose && <CloseButton onClick={onClose} />}
    </ChakraAlert>
  )
}
