// @ts-nocheck
/*
 * Beware, this code is AUTO-GENERATED
 * Any manual changes on this file would be eaten by the VØ|D
 */

type Integer = number

type Year = Integer
type Month = Integer
type Day = Integer
type Hour = Integer
type Minute = Integer
type Second = Integer
type HoursOffset = Integer
type SecondsOffset = Integer
export type DateTimeString =
  `${Year}-${Month}-${Day}T${Hour}:${Minute}:${Second}+${HoursOffset}:${SecondsOffset}`

export type UUID = string
export type Email = string

export namespace Api {
  export type ResourcePath =
    | '/agencies'
    | '/black_lists'
    | '/conversations'
    | '/conversations/leave/{id}/users/{uId}'
    | '/conversations/{id}/messages'
    | '/conversations/{id}/users/add'
    | '/conversations/{id}/users/{uId}'
    | '/craft_companies'
    | '/craft_companies/admin/register'
    | '/craft_companies/admin/search'
    | '/craft_companies/users'
    | '/craft_companies/users/add'
    | '/craft_companies/{id}/agencies'
    | '/craft_companies/{id}/favorites'
    | '/craft_companies/{id}/full'
    | '/documents'
    | '/enums/event_keys'
    | '/enums/tool_categories'
    | '/enums/tool_names'
    | '/enums/user_jobs'
    | '/folders'
    | '/images'
    | '/images/avatar'
    | '/images/send_guide'
    | '/login'
    | '/messages'
    | '/notifications'
    | '/notifications/create'
    | '/notifications/{id}/update'
    | '/posts/published'
    | '/public/craft_companies/register'
    | '/public/societies'
    | '/publications'
    | '/publications/create'
    | '/publications/{id}/update'
    | '/realisations'
    | '/statistics'
    | '/summary/conversations'
    | '/summary/gallery'
    | '/tools'
    | '/tools/create'
    | '/tools/{id}/update'
    | '/users'
    | '/users/invite'
    | '/users/me'
    | '/users/me/avatar'
    | '/users/me/colleagues'
    | '/users/me/has_seen_onboarding'
    | '/users/me/info'
    | '/users/me/update-password'
    | '/users/reset-credentials'
    | '/users/{id}/crafter_user'
    | '/users/{id}/setAvailability'
    | '/users/{id}/setRegistrationTokens/{platform}'
    | '/{type}/export'
    | '/roles'
    | '/users/{uuid}/role'
    | '/impersonate'
    | '/s3/presigned_s3_put/{objectType}/{objectId}/{filename}'
    | '/s3/presigned_s3_get/{objectType}/{objectId}/{filename}'
    | '/s3/presigned_url_get/batch'
    | '/typesense/spec.json'
    | '/audit/events'
    | '/audit/events/{objectName}/object/{objectId}'

  export type ResourcePluralName =
    | 'agencies'
    | 'conversations'
    | 'blocked_bies'
    | 'conversations'
    | 'craft_companies'
    | 'documents'
    | 'messages'
    | 'notifications'
    | 'publications'
    | 'realisations'
    | 'societies'
    | 'statistics'
    | 'tools'
    | 'users'

  export type IriReference = `/${ResourcePluralName}/${UUID}`

  export type ResourceName =
    | 'AddUsersOnConversationEventAddUsersOnConversationRequest'
    | 'AddUsersOnConversationEventConversation'
    | 'Agency'
    | 'AvailabilityMessagingEventAvailabilityMessagingRequest'
    | 'AvailabilityMessagingEventUser'
    | 'BlackList'
    | 'BlackListEventBlackList'
    | 'BlackListEventPostBlackListRequest'
    | 'BlockedBy'
    | 'Collection'
    | 'Conversation'
    | 'CraftCompany'
    | 'CraftCompanyEventCraftCompany'
    | 'CraftCompanyEventCraftCompanyAgencies'
    | 'CraftCompanyEventCraftCompanyFavoritesRequest'
    | 'CraftCompanyEventPublicCrafterRegisterRequest'
    | 'CraftCompanyEvent'
    | 'CrafterEventCrafterRegisterRequest'
    | 'CrafterEventCrafterSearchRequest'
    | 'CrafterEventCrafterUser'
    | 'CrafterEventCrafterUserAddRequest'
    | 'CrafterEventUser'
    | 'Document'
    | 'DocumentPart'
    | 'ExportUsersEventExportUser'
    | 'Folder'
    | 'FolderEventFolder'
    | 'FolderEventPostFolderRequest'
    | 'FolderEventPutFolderRequest'
    | 'GetSummaryConversationSummaryConversation'
    | 'GetSummaryGallerySummaryGallery'
    | 'ImageEventDocument'
    | 'ImageEventPostAvatarRequest'
    | 'ImageEventPostImageRequest'
    | 'ImageEventPostImageSendGuideRequest'
    | 'ImageEventPutImageRequest'
    | 'ImageEvent'
    | 'Message'
    | 'Notification'
    | 'PostConversationConversation'
    | 'PostConversationPostConversationRequest'
    | 'PostMessageEventMessage'
    | 'PostMessageEventPostMessageRequest'
    | 'Publication'
    | 'PublicationEvent'
    | 'PublicationSociety'
    | 'Realisation'
    | 'RealisationEventPostRealisationRequest'
    | 'RealisationEventPutRealisationRequest'
    | 'RealisationEventRealisation'
    | 'RegistrationTokenEventRegistrationTokenRequest'
    | 'RegistrationTokenEventUser'
    | 'Society'
    | 'SocietyEvent'
    | 'Statistic'
    | 'StatisticEventStatisticRequest'
    | 'StatisticEvent'
    | 'Tool'
    | 'UpsertNotificationEventNotification'
    | 'UpsertNotificationEventUpsertNotificationRequest'
    | 'UpsertPublicationEventPublicationRepresentation'
    | 'UpsertPublicationEventUpsertPublicationRequest'
    | 'UpsertToolEventTool'
    | 'UpsertToolEventUpsertToolRequest'
    | 'User'
    | 'UserEventAvatarUserRequest'
    | 'UserEventCrafterUserInfoRequest'
    | 'UserEventCrafterUserUpdateRequest'
    | 'UserEventEditUserRequest'
    | 'UserEventHasSeenOnboardingRequest'
    | 'UserEventInviteUserRequest'
    | 'UserEventResetCredentialsRequest'
    | 'UserEventUpdatePasswordRequest'
    | 'UserEventUser'
    | 'UserEvent'

  export type Resource =
    | AddUsersOnConversationEventAddUsersOnConversationRequest.Read
    | AddUsersOnConversationEventAddUsersOnConversationRequest.Write
    | AddUsersOnConversationEventAddUsersOnConversationRequest.ConversationWrite
    | AddUsersOnConversationEventConversation.Read
    | AddUsersOnConversationEventConversation.Write
    | AddUsersOnConversationEventConversation.ConversationRead
    | Agency.Read
    | Agency.Write
    | AvailabilityMessagingEventAvailabilityMessagingRequest.Read
    | AvailabilityMessagingEventAvailabilityMessagingRequest.Write
    | AvailabilityMessagingEventAvailabilityMessagingRequest.UserWrite
    | AvailabilityMessagingEventUser.Read
    | AvailabilityMessagingEventUser.Write
    | AvailabilityMessagingEventUser.AvailabilityMessagingEventUser
    | BlackList.Read
    | BlackList.Write
    | BlackList.BlacklistRead
    | BlackList.SummaryConversationRead
    | BlackListEventBlackList.Read
    | BlackListEventBlackList.Write
    | BlackListEventBlackList.BlacklistRead
    | BlackListEventPostBlackListRequest.Read
    | BlackListEventPostBlackListRequest.Write
    | BlackListEventPostBlackListRequest.BlacklistWrite
    | BlockedBy.Read
    | BlockedBy.Write
    | BlockedBy.BlockedBy
    | BlockedBy.PublicationRead
    | Collection.Read
    | Collection.Write
    | Collection.SummaryConversationRead
    | Conversation.Read
    | Conversation.Write
    | CraftCompany.Read
    | CraftCompany.Write
    | CraftCompany.ReadFullRead
    | CraftCompanyEventCraftCompany.Read
    | CraftCompanyEventCraftCompany.Write
    | CraftCompanyEventCraftCompany.CraftCompanyRead
    | CraftCompanyEventCraftCompanyAgencies.Read
    | CraftCompanyEventCraftCompanyAgencies.Write
    | CraftCompanyEventCraftCompanyAgencies.CraftCompanyEventCraftCompanyAgencies
    | CraftCompanyEventCraftCompanyFavoritesRequest.Read
    | CraftCompanyEventCraftCompanyFavoritesRequest.Write
    | CraftCompanyEventCraftCompanyFavoritesRequest.FavoritesWrite
    | CraftCompanyEventPublicCrafterRegisterRequest.Read
    | CraftCompanyEventPublicCrafterRegisterRequest.Write
    | CraftCompanyEventPublicCrafterRegisterRequest.CraftCompanyEventPublicCrafterRegisterRequest
    | CraftCompanyEvent.Read
    | CraftCompanyEvent.Write
    | CraftCompanyEvent.CraftCompanyEvent
    | CrafterEventCrafterRegisterRequest.Read
    | CrafterEventCrafterRegisterRequest.Write
    | CrafterEventCrafterRegisterRequest.CrafterEventCrafterRegisterRequest
    | CrafterEventCrafterSearchRequest.Read
    | CrafterEventCrafterSearchRequest.Write
    | CrafterEventCrafterSearchRequest.CrafterEventCrafterSearchRequest
    | CrafterEventCrafterUser.Read
    | CrafterEventCrafterUser.Write
    | CrafterEventCrafterUser.CrafterEventCrafterUser
    | CrafterEventCrafterUserAddRequest.Read
    | CrafterEventCrafterUserAddRequest.Write
    | CrafterEventCrafterUserAddRequest.CrafterEventCrafterUserAddRequest
    | CrafterEventUser.Read
    | CrafterEventUser.Write
    | CrafterEventUser.CrafterEventUser
    | Document.Read
    | Document.Write
    | Document.FeedRead
    | Document.MessageRead
    | Document.ToolRead
    | DocumentPart.Read
    | DocumentPart.Write
    | DocumentPart.MessageWrite
    | DocumentPart.PublicationWrite
    | DocumentPart.SummaryGalleryRead
    | DocumentPart.ToolWrite
    | DocumentPart.UserWrite
    | ExportUsersEventExportUser.Read
    | ExportUsersEventExportUser.Write
    | ExportUsersEventExportUser.ExportUserRead
    | Folder.Read
    | Folder.Write
    | FolderEventFolder.Read
    | FolderEventFolder.Write
    | FolderEventFolder.FolderRead
    | FolderEventPostFolderRequest.Read
    | FolderEventPostFolderRequest.Write
    | FolderEventPostFolderRequest.FolderWrite
    | FolderEventPutFolderRequest.Read
    | FolderEventPutFolderRequest.Write
    | FolderEventPutFolderRequest.FolderWrite
    | GetSummaryConversationSummaryConversation.Read
    | GetSummaryConversationSummaryConversation.Write
    | GetSummaryConversationSummaryConversation.SummaryConversationRead
    | GetSummaryGallerySummaryGallery.Read
    | GetSummaryGallerySummaryGallery.Write
    | GetSummaryGallerySummaryGallery.SummaryGalleryRead
    | ImageEventDocument.Read
    | ImageEventDocument.Write
    | ImageEventDocument.ImageRead
    | ImageEventPostAvatarRequest.Read
    | ImageEventPostAvatarRequest.Write
    | ImageEventPostAvatarRequest.ImageWrite
    | ImageEventPostImageRequest.Read
    | ImageEventPostImageRequest.Write
    | ImageEventPostImageRequest.ImageWrite
    | ImageEventPostImageSendGuideRequest.Read
    | ImageEventPostImageSendGuideRequest.Write
    | ImageEventPostImageSendGuideRequest.ImageEventPostImageSendGuideRequest
    | ImageEventPutImageRequest.Read
    | ImageEventPutImageRequest.Write
    | ImageEventPutImageRequest.ImageWrite
    | ImageEvent.Read
    | ImageEvent.Write
    | ImageEvent.ImageEvent
    | Message.Read
    | Message.Write
    | Message.SummaryConversationRead
    | Notification.Read
    | Notification.Write
    | PostConversationConversation.Read
    | PostConversationConversation.Write
    | PostConversationConversation.ConversationRead
    | PostConversationPostConversationRequest.Read
    | PostConversationPostConversationRequest.Write
    | PostConversationPostConversationRequest.ConversationWrite
    | PostMessageEventMessage.Read
    | PostMessageEventMessage.Write
    | PostMessageEventMessage.MessageRead
    | PostMessageEventPostMessageRequest.Read
    | PostMessageEventPostMessageRequest.Write
    | PostMessageEventPostMessageRequest.MessageWrite
    | Publication.Read
    | Publication.Write
    | PublicationEvent.Read
    | PublicationEvent.Write
    | PublicationEvent.FeedRead
    | PublicationSociety.Read
    | PublicationSociety.Write
    | PublicationSociety.PublicationSociety
    | Realisation.Read
    | Realisation.Write
    | RealisationEventPostRealisationRequest.Read
    | RealisationEventPostRealisationRequest.Write
    | RealisationEventPostRealisationRequest.RealisationWrite
    | RealisationEventPutRealisationRequest.Read
    | RealisationEventPutRealisationRequest.Write
    | RealisationEventPutRealisationRequest.RealisationWrite
    | RealisationEventRealisation.Read
    | RealisationEventRealisation.Write
    | RealisationEventRealisation.RealisationRead
    | RegistrationTokenEventRegistrationTokenRequest.Read
    | RegistrationTokenEventRegistrationTokenRequest.Write
    | RegistrationTokenEventRegistrationTokenRequest.UserWrite
    | RegistrationTokenEventUser.Read
    | RegistrationTokenEventUser.Write
    | RegistrationTokenEventUser.RegistrationTokenEventUser
    | Society.Read
    | Society.Write
    | SocietyEvent.Read
    | SocietyEvent.Write
    | SocietyEvent.SocietyEvent
    | Statistic.Read
    | Statistic.Write
    | StatisticEventStatisticRequest.Read
    | StatisticEventStatisticRequest.Write
    | StatisticEventStatisticRequest.StatisticWrite
    | StatisticEvent.Read
    | StatisticEvent.Write
    | StatisticEvent.StatisticEvent
    | Tool.Read
    | Tool.Write
    | UpsertNotificationEventNotification.Read
    | UpsertNotificationEventNotification.Write
    | UpsertNotificationEventNotification.NotificationRead
    | UpsertNotificationEventUpsertNotificationRequest.Read
    | UpsertNotificationEventUpsertNotificationRequest.Write
    | UpsertNotificationEventUpsertNotificationRequest.NotificationWrite
    | UpsertPublicationEventPublicationRepresentation.Read
    | UpsertPublicationEventPublicationRepresentation.Write
    | UpsertPublicationEventPublicationRepresentation.UpsertPublicationEventPublicationRepresentation
    | UpsertPublicationEventUpsertPublicationRequest.Read
    | UpsertPublicationEventUpsertPublicationRequest.Write
    | UpsertPublicationEventUpsertPublicationRequest.PublicationWrite
    | UpsertToolEventTool.Read
    | UpsertToolEventTool.Write
    | UpsertToolEventTool.UpsertToolEventTool
    | UpsertToolEventUpsertToolRequest.Read
    | UpsertToolEventUpsertToolRequest.Write
    | UpsertToolEventUpsertToolRequest.ToolWrite
    | User.Read
    | User.Write
    | User.CraftCompanyReadCraftCompanyFullRead
    | User.MessageRead
    | UserEventAvatarUserRequest.Read
    | UserEventAvatarUserRequest.Write
    | UserEventAvatarUserRequest.UserWrite
    | UserEventCrafterUserInfoRequest.Read
    | UserEventCrafterUserInfoRequest.Write
    | UserEventCrafterUserInfoRequest.UserWrite
    | UserEventCrafterUserUpdateRequest.Read
    | UserEventCrafterUserUpdateRequest.Write
    | UserEventCrafterUserUpdateRequest.UserEventCrafterUserUpdateRequest
    | UserEventEditUserRequest.Read
    | UserEventEditUserRequest.Write
    | UserEventEditUserRequest.UserWrite
    | UserEventHasSeenOnboardingRequest.Read
    | UserEventHasSeenOnboardingRequest.Write
    | UserEventHasSeenOnboardingRequest.UserEventHasSeenOnboardingRequest
    | UserEventInviteUserRequest.Read
    | UserEventInviteUserRequest.Write
    | UserEventInviteUserRequest.UserEventInviteUserRequest
    | UserEventResetCredentialsRequest.Read
    | UserEventResetCredentialsRequest.Write
    | UserEventResetCredentialsRequest.UserEventResetCredentialsRequest
    | UserEventUpdatePasswordRequest.Read
    | UserEventUpdatePasswordRequest.Write
    | UserEventUpdatePasswordRequest.UserEventUpdatePasswordRequest
    | UserEventUser.Read
    | UserEventUser.Write
    | UserEventUser.UserEventUser
    | UserEventUser.UserRead
    | UserEvent.Read
    | UserEvent.Write
    | UserEvent.UserEvent

  export const singularToPluralMap = {
    Agency: 'agencies',
    BlackList: 'conversations',
    BlockedBy: 'blocked_bies',
    Conversation: 'conversations',
    CraftCompany: 'craft_companies',
    Document: 'documents',
    Message: 'messages',
    Notification: 'notifications',
    Publication: 'publications',
    Realisation: 'realisations',
    Society: 'societies',
    Statistic: 'statistics',
    Tool: 'tools',
    User: 'users',
  } as const
}

export enum ToolCategoriesEnum {
  'OUTILS_GENERIQUES' = '/enums/tool_categories/OUTILS_GENERIQUES',
  'SDB_CARRELAGE' = '/enums/tool_categories/SDB_CARRELAGE',
  'WARM_CLIM_ENERGY' = '/enums/tool_categories/WARM_CLIM_ENERGY',
}

export namespace AddUsersOnConversationEventAddUsersOnConversationRequest {
  export type Read = {
    readonly '@type': 'AddUsersOnConversationEventAddUsersOnConversationRequest'
    readonly '@id': `/${string}/${UUID}`
    users: string[]
  }

  export type Write = Read

  export type ConversationWrite = Read
}

export namespace AddUsersOnConversationEventConversation {
  export type Read = {
    readonly '@type': 'AddUsersOnConversationEventConversation'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    users: Api.IriReference[]
    isInternal: boolean
  }

  export type Write = {
    readonly '@type': 'AddUsersOnConversationEventConversation'
    readonly '@id': `/${string}/${UUID}`
    users: Api.IriReference[]
    isInternal: boolean
  }

  export type ConversationRead = Read
}

export namespace Agency {
  export type Read = {
    readonly '@type': 'Agency'
    readonly '@id': `/agencies/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    society: Api.IriReference
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    code: any
    name: string
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    phone: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    users: Api.IriReference[]
  }

  export type Write = {
    readonly '@type': 'Agency'
    readonly '@id': `/agencies/${UUID}`
    society: Api.IriReference
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    code: any
    name: string
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    phone: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    users: Api.IriReference[]
  }
}

export namespace AvailabilityMessagingEventAvailabilityMessagingRequest {
  export type Read = {
    readonly '@type': 'AvailabilityMessagingEventAvailabilityMessagingRequest'
    readonly '@id': `/${string}/${UUID}`
    isAvailable: boolean
  }

  export type Write = Read

  export type UserWrite = Read
}

export namespace AvailabilityMessagingEventUser {
  export type Read = {
    readonly '@type': 'AvailabilityMessagingEventUser'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    username: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    firstname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lastname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    job: any
    isAvailable: boolean
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    agency: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    society: any
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    avatar: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    craftCompany: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensBackOffice: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensIos: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensAndroid: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acRoles: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acAccesses: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiAccessToken: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiRefreshToken: any
    hasSeenOnboarding: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastLoginAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastInvitationAt: any
    favouriteAgencies: Api.IriReference[]
    conversations: Api.IriReference[]
    messages: Api.IriReference[]
    documents: Api.IriReference[]
    folders: Api.IriReference[]
    realisations: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    currentAgency: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    updatedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    deletedAt: any
    readonly aubade: boolean
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly societyAgency: any
    readonly fullName: string
    readonly sortValue: string
    readonly roles: string[]
    readonly userIdentifier: string
    readonly isCrafterUser: boolean
    readonly isCrafterAdmin: boolean
    readonly isCrafterManager: boolean
    readonly isCrafterEmployee: boolean
    readonly isAubadeAdmin: boolean
    readonly isSocietyUser: boolean
    readonly isAgencyUser: boolean
    ebAccessEnabled: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    ebRole: any
    acAccessEnabled: boolean
    acRole: string
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly agencyId: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly agencyName: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly societyId: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly societyName: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly craftCompanyId: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly craftCompanyName: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly avatarUrl: any
    readonly agenciesBlackListed: string[]
  }

  export type Write = {
    readonly '@type': 'AvailabilityMessagingEventUser'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    username: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    firstname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lastname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    job: any
    isAvailable: boolean
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    agency: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    society: any
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    avatar: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    craftCompany: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensBackOffice: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensIos: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensAndroid: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acRoles: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acAccesses: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiAccessToken: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiRefreshToken: any
    hasSeenOnboarding: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastLoginAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastInvitationAt: any
    favouriteAgencies: Api.IriReference[]
    conversations: Api.IriReference[]
    messages: Api.IriReference[]
    documents: Api.IriReference[]
    folders: Api.IriReference[]
    realisations: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    currentAgency: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    updatedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    deletedAt: any
    ebAccessEnabled: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    ebRole: any
    acAccessEnabled: boolean
    acRole: string
  }

  export type AvailabilityMessagingEventUser = Read
}

export namespace BlackList {
  export type Read = {
    readonly '@type': 'BlackList'
    readonly '@id': `/conversations/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    agency: Api.IriReference
    craftCompany: Api.IriReference
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    reason: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    updatedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    deletedAt: any
  }

  export type Write = {
    readonly '@type': 'BlackList'
    readonly '@id': `/conversations/${UUID}`
    agency: Api.IriReference
    craftCompany: Api.IriReference
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    reason: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    updatedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    deletedAt: any
  }

  export type BlacklistRead = Read

  export type SummaryConversationRead = {
    readonly '@type': 'BlackList'
    readonly '@id': `/conversations/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    reason: any
  }
}

export namespace BlackListEventBlackList {
  export type Read = {
    readonly '@type': 'BlackListEventBlackList'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    agency: Api.IriReference
    craftCompany: Api.IriReference
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    reason: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    updatedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    deletedAt: any
  }

  export type Write = {
    readonly '@type': 'BlackListEventBlackList'
    readonly '@id': `/${string}/${UUID}`
    agency: Api.IriReference
    craftCompany: Api.IriReference
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    reason: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    updatedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    deletedAt: any
  }

  export type BlacklistRead = Read
}

export namespace BlackListEventPostBlackListRequest {
  export type Read = {
    readonly '@type': 'BlackListEventPostBlackListRequest'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    newId: any
    craftCompany: string
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    reason: any
  }

  export type Write = Read

  export type BlacklistWrite = Read
}

export namespace BlockedBy {
  export type Read = {
    readonly '@type': 'BlockedBy'
    readonly '@id': `/blocked_bies/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    publication: Api.IriReference
    society: Api.IriReference
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    newId: any
  }

  export type Write = {
    readonly '@type': 'BlockedBy'
    readonly '@id': `/blocked_bies/${UUID}`
    publication: Api.IriReference
    society: Api.IriReference
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    newId: any
  }

  export type BlockedBy = Read

  export type PublicationRead = {
    readonly '@type': 'BlockedBy'
    readonly '@id': `/blocked_bies/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    society: Api.IriReference
  }
}

export namespace Collection {
  export type Read = {
    readonly '@type': 'Collection'
    readonly '@id': `/${string}/${UUID}`
  }

  export type Write = Read

  export type SummaryConversationRead = Read
}

export namespace Conversation {
  export type Read = {
    readonly '@type': 'Conversation'
    readonly '@id': `/conversations/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    users: Api.IriReference[]
    isInternal: boolean
  }

  export type Write = {
    readonly '@type': 'Conversation'
    readonly '@id': `/conversations/${UUID}`
    users: Api.IriReference[]
    isInternal: boolean
  }
}

export namespace CraftCompany {
  export type Read = {
    readonly '@type': 'CraftCompany'
    readonly '@id': `/craft_companies/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    code: any
    name: string
    users: Api.IriReference[]
    favoriteAgencies: Api.IriReference[]
    society: Api.IriReference
    blackLists: Api.IriReference[]
    agency: Api.IriReference
  }

  export type Write = {
    readonly '@type': 'CraftCompany'
    readonly '@id': `/craft_companies/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    code: any
    name: string
    users: Api.IriReference[]
    favoriteAgencies: Api.IriReference[]
    society: Api.IriReference
    blackLists: Api.IriReference[]
    agency: Api.IriReference
  }

  export type ReadFullRead = {
    readonly '@type': 'CraftCompany'
    readonly '@id': `/craft_companies/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    code: any
    name: string
    users: User.CraftCompanyReadCraftCompanyFullRead[]
    favoriteAgencies: Api.IriReference[]
    society: Api.IriReference
    blackLists: Api.IriReference[]
    agency: Api.IriReference
  }
}

export namespace CraftCompanyEventCraftCompany {
  export type Read = {
    readonly '@type': 'CraftCompanyEventCraftCompany'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    code: any
    name: string
    users: Api.IriReference[]
    favoriteAgencies: Api.IriReference[]
    society: Api.IriReference
    blackLists: Api.IriReference[]
    agency: Api.IriReference
  }

  export type Write = {
    readonly '@type': 'CraftCompanyEventCraftCompany'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    code: any
    name: string
    users: Api.IriReference[]
    favoriteAgencies: Api.IriReference[]
    society: Api.IriReference
    blackLists: Api.IriReference[]
    agency: Api.IriReference
  }

  export type CraftCompanyRead = Read
}

export namespace CraftCompanyEventCraftCompanyAgencies {
  export type Read = {
    readonly '@type': 'CraftCompanyEventCraftCompanyAgencies'
    readonly '@id': `/${string}/${UUID}`
    agencies: string[]
  }

  export type Write = Read

  export type CraftCompanyEventCraftCompanyAgencies = Read
}

export namespace CraftCompanyEventCraftCompanyFavoritesRequest {
  export type Read = {
    readonly '@type': 'CraftCompanyEventCraftCompanyFavoritesRequest'
    readonly '@id': `/${string}/${UUID}`
    agencies: string[]
  }

  export type Write = Read

  export type FavoritesWrite = Read
}

export namespace CraftCompanyEventPublicCrafterRegisterRequest {
  export type Read = {
    readonly '@type': 'CraftCompanyEventPublicCrafterRegisterRequest'
    readonly '@id': `/${string}/${UUID}`
    societyId: UUID
    crafterCompanyName: string
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterCompanyCode: any
    firstname: string
    lastname: string
    email: string
  }

  export type Write = Read

  export type CraftCompanyEventPublicCrafterRegisterRequest = Read
}

export namespace CraftCompanyEvent {
  export type Read = {
    readonly '@type': 'CraftCompanyEvent'
    readonly '@id': `/${string}/${UUID}`
  }

  export type Write = Read

  export type CraftCompanyEvent = Read
}

export namespace CrafterEventCrafterRegisterRequest {
  export type Read = {
    readonly '@type': 'CrafterEventCrafterRegisterRequest'
    readonly '@id': `/${string}/${UUID}`
    crafterCode: string
    firstname: string
    lastname: string
    username: string
    email: string
  }

  export type Write = Read

  export type CrafterEventCrafterRegisterRequest = Read
}

export namespace CrafterEventCrafterSearchRequest {
  export type Read = {
    readonly '@type': 'CrafterEventCrafterSearchRequest'
    readonly '@id': `/${string}/${UUID}`
    code: string
  }

  export type Write = Read

  export type CrafterEventCrafterSearchRequest = Read
}

export namespace CrafterEventCrafterUser {
  export type Read = {
    readonly '@type': 'CrafterEventCrafterUser'
    readonly '@id': `/${string}/${UUID}`
    crafterCode: string
    crafterName: string
    username: string
    isAdmin: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    firstname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lastname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    hasAuthentification: boolean
    accesses: string[]
    roles: string[]
    agency: Api.IriReference
    readonly admin: boolean
    readonly aCAccess: boolean
  }

  export type Write = {
    readonly '@type': 'CrafterEventCrafterUser'
    readonly '@id': `/${string}/${UUID}`
    crafterCode: string
    crafterName: string
    username: string
    isAdmin: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    firstname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lastname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    hasAuthentification: boolean
    accesses: string[]
    roles: string[]
    agency: Api.IriReference
  }

  export type CrafterEventCrafterUser = Read
}

export namespace CrafterEventCrafterUserAddRequest {
  export type Read = {
    readonly '@type': 'CrafterEventCrafterUserAddRequest'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    username: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    password: any
    lastName: string
    firstName: string
    email: string
    accesses: string[]
    roles: string[]
  }

  export type Write = Read

  export type CrafterEventCrafterUserAddRequest = Read
}

export namespace CrafterEventUser {
  export type Read = {
    readonly '@type': 'CrafterEventUser'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    username: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    firstname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lastname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    job: any
    isAvailable: boolean
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    agency: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    society: any
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    avatar: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    craftCompany: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensBackOffice: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensIos: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensAndroid: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acRoles: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acAccesses: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiAccessToken: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiRefreshToken: any
    hasSeenOnboarding: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastLoginAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastInvitationAt: any
    favouriteAgencies: Api.IriReference[]
    conversations: Api.IriReference[]
    messages: Api.IriReference[]
    documents: Api.IriReference[]
    folders: Api.IriReference[]
    realisations: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    currentAgency: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    updatedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    deletedAt: any
    readonly aubade: boolean
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly societyAgency: any
    readonly fullName: string
    readonly sortValue: string
    readonly roles: string[]
    readonly userIdentifier: string
    readonly isCrafterUser: boolean
    readonly isCrafterAdmin: boolean
    readonly isCrafterManager: boolean
    readonly isCrafterEmployee: boolean
    readonly isAubadeAdmin: boolean
    readonly isSocietyUser: boolean
    readonly isAgencyUser: boolean
    ebAccessEnabled: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    ebRole: any
    acAccessEnabled: boolean
    acRole: string
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly agencyId: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly agencyName: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly societyId: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly societyName: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly craftCompanyId: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly craftCompanyName: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly avatarUrl: any
    readonly agenciesBlackListed: string[]
  }

  export type Write = {
    readonly '@type': 'CrafterEventUser'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    username: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    firstname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lastname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    job: any
    isAvailable: boolean
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    agency: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    society: any
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    avatar: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    craftCompany: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensBackOffice: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensIos: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensAndroid: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acRoles: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acAccesses: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiAccessToken: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiRefreshToken: any
    hasSeenOnboarding: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastLoginAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastInvitationAt: any
    favouriteAgencies: Api.IriReference[]
    conversations: Api.IriReference[]
    messages: Api.IriReference[]
    documents: Api.IriReference[]
    folders: Api.IriReference[]
    realisations: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    currentAgency: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    updatedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    deletedAt: any
    ebAccessEnabled: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    ebRole: any
    acAccessEnabled: boolean
    acRole: string
  }

  export type CrafterEventUser = Read
}

export namespace Document {
  export type Read = {
    readonly '@type': 'Document'
    readonly '@id': `/documents/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    displayName: string
    fileName: string
    size: Integer
    mimeType: string
    extension: string
    objectId: string
    objectType: string
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    category: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    publication: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    pictureLink: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    playerEmbedUrl: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    folder: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    owner: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    description: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    realisationBefore: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    realisationAfter: any
  }

  export type Write = {
    readonly '@type': 'Document'
    readonly '@id': `/documents/${UUID}`
    displayName: string
    fileName: string
    size: Integer
    mimeType: string
    extension: string
    objectId: string
    objectType: string
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    category: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    publication: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    pictureLink: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    playerEmbedUrl: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    folder: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    owner: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    description: any
  }

  export type FeedRead = {
    readonly '@type': 'Document'
    readonly '@id': `/documents/${UUID}`
    mimeType: string
    readonly url: string
  }

  export type MessageRead = {
    readonly '@type': 'Document'
    readonly '@id': `/documents/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    mimeType: string
    readonly url: string
  }

  export type ToolRead = MessageRead
}

export namespace DocumentPart {
  export type Read = {
    readonly '@type': 'DocumentPart'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    id: any
    displayName: string
    extension: string
    fileName: string
    size: Integer
    mimeType: string
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    objectId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    objectType: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    pictureLink: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    playerEmbedUrl: any
  }

  export type Write = Read

  export type MessageWrite = Read

  export type PublicationWrite = Read

  export type SummaryGalleryRead = {
    readonly '@type': 'DocumentPart'
    readonly '@id': `/${string}/${UUID}`
    url: string
    mimeType: string
  }

  export type ToolWrite = {
    readonly '@type': 'DocumentPart'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    id: any
    displayName: string
    extension: string
    fileName: string
    size: Integer
    mimeType: string
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    objectId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    objectType: any
  }

  export type UserWrite = {
    readonly '@type': 'DocumentPart'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    id: any
    displayName: string
    extension: string
    fileName: string
    size: Integer
    mimeType: string
  }
}

export namespace ExportUsersEventExportUser {
  export type Read = {
    readonly '@type': 'ExportUsersEventExportUser'
    readonly '@id': `/${string}/${UUID}`
    id: string
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    firstname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lastname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    roles: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    society: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    agency: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    craftCompany: any
  }

  export type Write = Read

  export type ExportUserRead = Read
}

export namespace Folder {
  export type Read = {
    readonly '@type': 'Folder'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    title: string
    owner: Api.IriReference
    parent: Folder.Read | any
    folders: Folder.Read[]
    craftCompany: Api.IriReference
    realisations: Api.IriReference[]
    documents: Api.IriReference[]
  }

  export type Write = {
    readonly '@type': 'Folder'
    readonly '@id': `/${string}/${UUID}`
    title: string
    owner: Api.IriReference
    parent: Folder.Read | any
    folders: Folder.Read[]
    craftCompany: Api.IriReference
    realisations: Api.IriReference[]
    documents: Api.IriReference[]
  }
}

export namespace FolderEventFolder {
  export type Read = {
    readonly '@type': 'FolderEventFolder'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    title: string
    owner: Api.IriReference
    parent: Folder.Read | any
    folders: Folder.Read[]
    craftCompany: Api.IriReference
    realisations: Api.IriReference[]
    documents: Api.IriReference[]
  }

  export type Write = {
    readonly '@type': 'FolderEventFolder'
    readonly '@id': `/${string}/${UUID}`
    title: string
    owner: Api.IriReference
    parent: Folder.Read | any
    folders: Folder.Read[]
    craftCompany: Api.IriReference
    realisations: Api.IriReference[]
    documents: Api.IriReference[]
  }

  export type FolderRead = Read
}

export namespace FolderEventPostFolderRequest {
  export type Read = {
    readonly '@type': 'FolderEventPostFolderRequest'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    parent: any
    title: string
  }

  export type Write = Read

  export type FolderWrite = Read
}

export namespace FolderEventPutFolderRequest {
  export type Read = {
    readonly '@type': 'FolderEventPutFolderRequest'
    readonly '@id': `/${string}/${UUID}`
    title: string
  }

  export type Write = Read

  export type FolderWrite = Read
}

export namespace GetSummaryConversationSummaryConversation {
  export type Read = {
    readonly '@type': 'GetSummaryConversationSummaryConversation'
    readonly '@id': `/${string}/${UUID}`
    id: UUID
    isInternal: boolean
    users: Collection.SummaryConversationRead
    nbUnreadMessage: Integer
    blackList: BlackList.SummaryConversationRead | any
    lastMessage: Message.SummaryConversationRead | any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "integer",
    "null"
  ]
} */
    sortValue: any
  }

  export type Write = Read

  export type SummaryConversationRead = Read
}

export namespace GetSummaryGallerySummaryGallery {
  export type Read = {
    readonly '@type': 'GetSummaryGallerySummaryGallery'
    readonly '@id': `/${string}/${UUID}`
    id: UUID
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    description: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    parent: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "integer",
    "null"
  ]
} */
    nbElements: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    url: any
    before: DocumentPart.SummaryGalleryRead | any
    after: DocumentPart.SummaryGalleryRead | any
    isUpdatable: boolean
    isDeletable: boolean
    createdAt: string
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    updatedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    deletedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    owner: any
  }

  export type Write = Read

  export type SummaryGalleryRead = Read
}

export namespace ImageEventDocument {
  export type Read = {
    readonly '@type': 'ImageEventDocument'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    displayName: string
    fileName: string
    size: Integer
    mimeType: string
    extension: string
    objectId: string
    objectType: string
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    category: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    publication: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    folder: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    owner: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    description: any
  }

  export type Write = {
    readonly '@type': 'ImageEventDocument'
    readonly '@id': `/${string}/${UUID}`
    displayName: string
    fileName: string
    size: Integer
    mimeType: string
    extension: string
    objectId: string
    objectType: string
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    category: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    publication: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    folder: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    owner: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    description: any
  }

  export type ImageRead = Read
}

export namespace ImageEventPostAvatarRequest {
  export type Read = {
    readonly '@type': 'ImageEventPostAvatarRequest'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    id: any
    displayName: string
    extension: string
    fileName: string
    size: Integer
    mimeType: string
  }

  export type Write = Read

  export type ImageWrite = Read
}

export namespace ImageEventPostImageRequest {
  export type Read = {
    readonly '@type': 'ImageEventPostImageRequest'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    id: any
    displayName: string
    extension: string
    fileName: string
    size: Integer
    mimeType: string
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    description: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    folder: any
  }

  export type Write = Read

  export type ImageWrite = Read
}

export namespace ImageEventPostImageSendGuideRequest {
  export type Read = {
    readonly '@type': 'ImageEventPostImageSendGuideRequest'
    readonly '@id': `/${string}/${UUID}`
    images: string[]
    realisations: string[]
  }

  export type Write = Read

  export type ImageEventPostImageSendGuideRequest = Read
}

export namespace ImageEventPutImageRequest {
  export type Read = {
    readonly '@type': 'ImageEventPutImageRequest'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    description: any
  }

  export type Write = Read

  export type ImageWrite = Read
}

export namespace ImageEvent {
  export type Read = {
    readonly '@type': 'ImageEvent'
    readonly '@id': `/${string}/${UUID}`
  }

  export type Write = Read

  export type ImageEvent = Read
}

export namespace Message {
  export type Read = {
    readonly '@type': 'Message'
    readonly '@id': `/messages/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    content: any
    document: Document.MessageRead[]
    author: User.MessageRead | any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    readonly isRead: boolean
  }

  export type Write = {
    readonly '@type': 'Message'
    readonly '@id': `/messages/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    content: any
    document: Document.MessageRead[]
    author: User.MessageRead | any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
  }

  export type SummaryConversationRead = {
    readonly '@type': 'Message'
    readonly '@id': `/messages/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    content: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
  }
}

export namespace Notification {
  export type Read = {
    readonly '@type': 'Notification'
    readonly '@id': `/notifications/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    content: any
    isDraft: boolean
    isNational: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    publishedAt: any
    targetSocieties: Api.IriReference[]
    targetAgencies: Api.IriReference[]
    author: Api.IriReference
  }

  export type Write = Read
}

export namespace PostConversationConversation {
  export type Read = {
    readonly '@type': 'PostConversationConversation'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    users: Api.IriReference[]
    isInternal: boolean
  }

  export type Write = {
    readonly '@type': 'PostConversationConversation'
    readonly '@id': `/${string}/${UUID}`
    users: Api.IriReference[]
    isInternal: boolean
  }

  export type ConversationRead = Read
}

export namespace PostConversationPostConversationRequest {
  export type Read = {
    readonly '@type': 'PostConversationPostConversationRequest'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    newId: any
    users: string[]
  }

  export type Write = Read

  export type ConversationWrite = Read
}

export namespace PostMessageEventMessage {
  export type Read = {
    readonly '@type': 'PostMessageEventMessage'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    content: any
    document: Document.MessageRead[]
    author: User.MessageRead | any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    readonly isRead: boolean
  }

  export type Write = {
    readonly '@type': 'PostMessageEventMessage'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    content: any
    document: Document.MessageRead[]
    author: User.MessageRead | any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
  }

  export type MessageRead = Read
}

export namespace PostMessageEventPostMessageRequest {
  export type Read = {
    readonly '@type': 'PostMessageEventPostMessageRequest'
    readonly '@id': `/${string}/${UUID}`
    conversation: string
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    content: any
    document: DocumentPart.MessageWrite | any
  }

  export type Write = Read

  export type MessageWrite = Read
}

export namespace Publication {
  export type Read = {
    readonly '@type': 'Publication'
    readonly '@id': `/publications/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    content: any
    isDraft: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    publishedAt: any
    author: Api.IriReference
    documents: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    url: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    urlLabel: any
    targetSocieties: Api.IriReference[]
    blockedBy: BlockedBy.PublicationRead[]
  }

  export type Write = {
    readonly '@type': 'Publication'
    readonly '@id': `/publications/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    content: any
    isDraft: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    publishedAt: any
    author: Api.IriReference
    documents: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    url: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    urlLabel: any
    targetSocieties: Api.IriReference[]
    blockedBy: BlockedBy.PublicationRead[]
  }
}

export namespace PublicationEvent {
  export type Read = {
    readonly '@type': 'PublicationEvent'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    content: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    publishedAt: any
    documents: Document.FeedRead[]
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    url: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    urlLabel: any
    readonly authorFullName: string
    readonly isAubade: boolean
  }

  export type Write = {
    readonly '@type': 'PublicationEvent'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    content: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    publishedAt: any
    documents: Document.FeedRead[]
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    url: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    urlLabel: any
  }

  export type FeedRead = Read
}

export namespace PublicationSociety {
  export type Read = {
    readonly '@type': 'PublicationSociety'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    society: Api.IriReference
    publication: Api.IriReference
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    newId: any
  }

  export type Write = {
    readonly '@type': 'PublicationSociety'
    readonly '@id': `/${string}/${UUID}`
    society: Api.IriReference
    publication: Api.IriReference
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    newId: any
  }

  export type PublicationSociety = Read
}

export namespace Realisation {
  export type Read = {
    readonly '@type': 'Realisation'
    readonly '@id': `/realisations/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    before: Api.IriReference
    after: Api.IriReference
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    folder: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    description: any
    owner: Api.IriReference
    craftCompany: Api.IriReference
  }

  export type Write = {
    readonly '@type': 'Realisation'
    readonly '@id': `/realisations/${UUID}`
    before: Api.IriReference
    after: Api.IriReference
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    folder: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    description: any
    owner: Api.IriReference
    craftCompany: Api.IriReference
  }
}

export namespace RealisationEventPostRealisationRequest {
  export type Read = {
    readonly '@type': 'RealisationEventPostRealisationRequest'
    readonly '@id': `/${string}/${UUID}`
    newId: UUID
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    folder: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    description: any
    before: string
    after: string
  }

  export type Write = Read

  export type RealisationWrite = Read
}

export namespace RealisationEventPutRealisationRequest {
  export type Read = {
    readonly '@type': 'RealisationEventPutRealisationRequest'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    description: any
  }

  export type Write = Read

  export type RealisationWrite = Read
}

export namespace RealisationEventRealisation {
  export type Read = {
    readonly '@type': 'RealisationEventRealisation'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    before: Api.IriReference
    after: Api.IriReference
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    folder: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    description: any
    owner: Api.IriReference
    craftCompany: Api.IriReference
  }

  export type Write = {
    readonly '@type': 'RealisationEventRealisation'
    readonly '@id': `/${string}/${UUID}`
    before: Api.IriReference
    after: Api.IriReference
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    folder: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    description: any
    owner: Api.IriReference
    craftCompany: Api.IriReference
  }

  export type RealisationRead = Read
}

export namespace RegistrationTokenEventRegistrationTokenRequest {
  export type Read = {
    readonly '@type': 'RegistrationTokenEventRegistrationTokenRequest'
    readonly '@id': `/${string}/${UUID}`
    registrationTokens: string[]
  }

  export type Write = Read

  export type UserWrite = Read
}

export namespace RegistrationTokenEventUser {
  export type Read = {
    readonly '@type': 'RegistrationTokenEventUser'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    username: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    firstname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lastname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    job: any
    isAvailable: boolean
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    agency: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    society: any
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    avatar: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    craftCompany: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensBackOffice: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensIos: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensAndroid: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acRoles: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acAccesses: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiAccessToken: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiRefreshToken: any
    hasSeenOnboarding: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastLoginAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastInvitationAt: any
    favouriteAgencies: Api.IriReference[]
    conversations: Api.IriReference[]
    messages: Api.IriReference[]
    documents: Api.IriReference[]
    folders: Api.IriReference[]
    realisations: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    currentAgency: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    updatedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    deletedAt: any
    readonly aubade: boolean
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly societyAgency: any
    readonly fullName: string
    readonly sortValue: string
    readonly roles: string[]
    readonly userIdentifier: string
    readonly isCrafterUser: boolean
    readonly isCrafterAdmin: boolean
    readonly isCrafterManager: boolean
    readonly isCrafterEmployee: boolean
    readonly isAubadeAdmin: boolean
    readonly isSocietyUser: boolean
    readonly isAgencyUser: boolean
    ebAccessEnabled: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    ebRole: any
    acAccessEnabled: boolean
    acRole: string
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly agencyId: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly agencyName: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly societyId: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly societyName: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly craftCompanyId: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly craftCompanyName: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly avatarUrl: any
    readonly agenciesBlackListed: string[]
  }

  export type Write = {
    readonly '@type': 'RegistrationTokenEventUser'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    username: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    firstname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lastname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    job: any
    isAvailable: boolean
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    agency: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    society: any
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    avatar: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    craftCompany: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensBackOffice: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensIos: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensAndroid: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acRoles: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acAccesses: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiAccessToken: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiRefreshToken: any
    hasSeenOnboarding: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastLoginAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastInvitationAt: any
    favouriteAgencies: Api.IriReference[]
    conversations: Api.IriReference[]
    messages: Api.IriReference[]
    documents: Api.IriReference[]
    folders: Api.IriReference[]
    realisations: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    currentAgency: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    updatedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    deletedAt: any
    ebAccessEnabled: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    ebRole: any
    acAccessEnabled: boolean
    acRole: string
  }

  export type RegistrationTokenEventUser = Read
}

export namespace Society {
  export type Read = {
    readonly '@type': 'Society'
    readonly '@id': `/societies/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    code: any
    agencies: Api.IriReference[]
    name: string
  }

  export type Write = {
    readonly '@type': 'Society'
    readonly '@id': `/societies/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    code: any
    agencies: Api.IriReference[]
    name: string
  }
}

export namespace SocietyEvent {
  export type Read = {
    readonly '@type': 'SocietyEvent'
    readonly '@id': `/${string}/${UUID}`
  }

  export type Write = Read

  export type SocietyEvent = Read
}

export namespace Statistic {
  export type Read = {
    readonly '@type': 'Statistic'
    readonly '@id': `/statistics/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
  }

  export type Write = {
    readonly '@type': 'Statistic'
    readonly '@id': `/statistics/${UUID}`
  }
}

export namespace StatisticEventStatisticRequest {
  export type Read = {
    readonly '@type': 'StatisticEventStatisticRequest'
    readonly '@id': `/${string}/${UUID}`
    eventKey: string
    toolName: string
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    recordedAt: any
  }

  export type Write = Read

  export type StatisticWrite = Read
}

export namespace StatisticEvent {
  export type Read = {
    readonly '@type': 'StatisticEvent'
    readonly '@id': `/${string}/${UUID}`
  }

  export type Write = Read

  export type StatisticEvent = Read
}

export namespace Tool {
  export type Read = {
    readonly '@type': 'Tool'
    readonly '@id': `/tools/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    name: string
    url: string
    isPublished: boolean
    backgroundColor: string
    document: Document.ToolRead | any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lineOne: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lineTwo: any
    toolCategory: ToolCategoriesEnum
    isNotEditable: boolean
    isScanBat: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    updatedAt: any
  }

  export type Write = {
    readonly '@type': 'Tool'
    readonly '@id': `/tools/${UUID}`
    name: string
    url: string
    isPublished: boolean
    backgroundColor: string
    document: Document.ToolRead | any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lineOne: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lineTwo: any
    toolCategory: ToolCategoriesEnum
    isNotEditable: boolean
    isScanBat: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    updatedAt: any
  }
}

export namespace UpsertNotificationEventNotification {
  export type Read = {
    readonly '@type': 'UpsertNotificationEventNotification'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    content: any
    isDraft: boolean
    isNational: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    publishedAt: any
    targetSocieties: Api.IriReference[]
    targetAgencies: Api.IriReference[]
    author: Api.IriReference
  }

  export type Write = Read

  export type NotificationRead = Read
}

export namespace UpsertNotificationEventUpsertNotificationRequest {
  export type Read = {
    readonly '@type': 'UpsertNotificationEventUpsertNotificationRequest'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    content: any
    isDraft: boolean
    isNational: boolean
    targetSocieties: string[]
    targetAgencies: string[]
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    publishedAt: any
    author: string
  }

  export type Write = Read

  export type NotificationWrite = Read
}

export namespace UpsertPublicationEventPublicationRepresentation {
  export type Read = {
    readonly '@type': 'UpsertPublicationEventPublicationRepresentation'
    readonly '@id': `/${string}/${UUID}`
    id: string
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    content: any
    author: string
    documents: string[]
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    url: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    urlLabel: any
    isDraft: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "boolean",
    "null"
  ]
} */
    isNational: any
    targetSocieties: string[]
    targetAgencies: string[]
    societyId: string
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    publishedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    toolId: any
    blockedBy: string[]
  }

  export type Write = Read

  export type UpsertPublicationEventPublicationRepresentation = Read
}

export namespace UpsertPublicationEventUpsertPublicationRequest {
  export type Read = {
    readonly '@type': 'UpsertPublicationEventUpsertPublicationRequest'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    title: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    content: any
    author: string
    documents: DocumentPart.PublicationWrite[]
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    url: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    urlLabel: any
    isDraft: boolean
    isNational: boolean
    targetSocieties: string[]
    targetAgencies: string[]
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    societyId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    publishedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    toolId: any
  }

  export type Write = Read

  export type PublicationWrite = Read
}

export namespace UpsertToolEventTool {
  export type Read = {
    readonly '@type': 'UpsertToolEventTool'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    name: string
    url: string
    isPublished: boolean
    backgroundColor: string
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    document: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lineOne: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lineTwo: any
    toolCategory: Api.IriReference
    publications: Api.IriReference[]
    isNotEditable: boolean
    isScanBat: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    updatedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    deletedAt: any
    readonly sortValue: Integer
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly photo: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "boolean",
    "null"
  ]
} */
    readonly scanBat: any
  }

  export type Write = {
    readonly '@type': 'UpsertToolEventTool'
    readonly '@id': `/${string}/${UUID}`
    name: string
    url: string
    isPublished: boolean
    backgroundColor: string
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    document: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lineOne: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lineTwo: any
    toolCategory: Api.IriReference
    publications: Api.IriReference[]
    isNotEditable: boolean
    isScanBat: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    updatedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    deletedAt: any
  }

  export type UpsertToolEventTool = Read
}

export namespace UpsertToolEventUpsertToolRequest {
  export type Read = {
    readonly '@type': 'UpsertToolEventUpsertToolRequest'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    newId: any
    document: DocumentPart.ToolWrite | any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    url: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    name: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "boolean",
    "null"
  ]
} */
    isPublished: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    backgroundColor: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lineOne: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lineTwo: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    toolCategory: any
  }

  export type Write = Read

  export type ToolWrite = Read
}

export namespace User {
  export type Read = {
    readonly '@type': 'User'
    readonly '@id': `/users/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    username: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    firstname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lastname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/",
  "enum": [
    "/enums/user_jobs/JOB_COMMERCIAL",
    "/enums/user_jobs/JOB_RESPONSABLE",
    "/enums/user_jobs/JOB_MEMBER",
    "/enums/user_jobs/JOB_ATC",
    "/enums/user_jobs/JOB_SALESOFFICE",
    "/enums/user_jobs/JOB_SALESSHOWROOM",
    "/enums/user_jobs/JOB_MAILER",
    "/enums/user_jobs/JOB_TRAVELING",
    "/enums/user_jobs/JOB_AGENCYMANAGER",
    "/enums/user_jobs/JOB_ADVISESHOWROOM",
    "/enums/user_jobs/JOB_SAVMANAGER",
    "/enums/user_jobs/JOB_SELF"
  ]
} */
    job: any
    isAvailable: boolean
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    agency: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    society: any
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    avatar: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    craftCompany: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensBackOffice: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensIos: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensAndroid: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acRoles: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acAccesses: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiAccessToken: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiRefreshToken: any
    hasSeenOnboarding: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastLoginAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastInvitationAt: any
    favouriteAgencies: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    readonly aubade: boolean
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly societyAgency: any
    readonly isCrafterUser: boolean
    readonly isCrafterAdmin: boolean
    readonly isCrafterManager: boolean
    readonly isCrafterEmployee: boolean
    readonly isAubadeAdmin: boolean
    readonly isSocietyUser: boolean
    readonly isAgencyUser: boolean
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly avatarUrl: any
  }

  export type Write = {
    readonly '@type': 'User'
    readonly '@id': `/users/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    username: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    firstname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lastname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/",
  "enum": [
    "/enums/user_jobs/JOB_COMMERCIAL",
    "/enums/user_jobs/JOB_RESPONSABLE",
    "/enums/user_jobs/JOB_MEMBER",
    "/enums/user_jobs/JOB_ATC",
    "/enums/user_jobs/JOB_SALESOFFICE",
    "/enums/user_jobs/JOB_SALESSHOWROOM",
    "/enums/user_jobs/JOB_MAILER",
    "/enums/user_jobs/JOB_TRAVELING",
    "/enums/user_jobs/JOB_AGENCYMANAGER",
    "/enums/user_jobs/JOB_ADVISESHOWROOM",
    "/enums/user_jobs/JOB_SAVMANAGER",
    "/enums/user_jobs/JOB_SELF"
  ]
} */
    job: any
    isAvailable: boolean
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    avatar: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acRoles: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acAccesses: any
    hasSeenOnboarding: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastLoginAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastInvitationAt: any
  }

  export type CraftCompanyReadCraftCompanyFullRead = {
    readonly '@type': 'User'
    readonly '@id': `/users/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    username: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    firstname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lastname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/",
  "enum": [
    "/enums/user_jobs/JOB_COMMERCIAL",
    "/enums/user_jobs/JOB_RESPONSABLE",
    "/enums/user_jobs/JOB_MEMBER",
    "/enums/user_jobs/JOB_ATC",
    "/enums/user_jobs/JOB_SALESOFFICE",
    "/enums/user_jobs/JOB_SALESSHOWROOM",
    "/enums/user_jobs/JOB_MAILER",
    "/enums/user_jobs/JOB_TRAVELING",
    "/enums/user_jobs/JOB_AGENCYMANAGER",
    "/enums/user_jobs/JOB_ADVISESHOWROOM",
    "/enums/user_jobs/JOB_SAVMANAGER",
    "/enums/user_jobs/JOB_SELF"
  ]
} */
    job: any
    isAvailable: boolean
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    avatar: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acRoles: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acAccesses: any
    hasSeenOnboarding: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastLoginAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastInvitationAt: any
  }

  export type MessageRead = {
    readonly '@type': 'User'
    readonly '@id': `/users/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    username: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    firstname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lastname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/",
  "enum": [
    "/enums/user_jobs/JOB_COMMERCIAL",
    "/enums/user_jobs/JOB_RESPONSABLE",
    "/enums/user_jobs/JOB_MEMBER",
    "/enums/user_jobs/JOB_ATC",
    "/enums/user_jobs/JOB_SALESOFFICE",
    "/enums/user_jobs/JOB_SALESSHOWROOM",
    "/enums/user_jobs/JOB_MAILER",
    "/enums/user_jobs/JOB_TRAVELING",
    "/enums/user_jobs/JOB_AGENCYMANAGER",
    "/enums/user_jobs/JOB_ADVISESHOWROOM",
    "/enums/user_jobs/JOB_SAVMANAGER",
    "/enums/user_jobs/JOB_SELF"
  ]
} */
    job: any
    isAvailable: boolean
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    agency: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    society: any
    avatar: Document.MessageRead | any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    craftCompany: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensBackOffice: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensIos: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensAndroid: any
    readonly aubade: boolean
  }
}

export namespace UserEventAvatarUserRequest {
  export type Read = {
    readonly '@type': 'UserEventAvatarUserRequest'
    readonly '@id': `/${string}/${UUID}`
    avatar: string
  }

  export type Write = Read

  export type UserWrite = Read
}

export namespace UserEventCrafterUserInfoRequest {
  export type Read = {
    readonly '@type': 'UserEventCrafterUserInfoRequest'
    readonly '@id': `/${string}/${UUID}`
    firstname: string
    lastname: string
    email: string
  }

  export type Write = Read

  export type UserWrite = Read
}

export namespace UserEventCrafterUserUpdateRequest {
  export type Read = {
    readonly '@type': 'UserEventCrafterUserUpdateRequest'
    readonly '@id': `/${string}/${UUID}`
    lastName: string
    firstName: string
    email: string
    ebAccessEnabled: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    ebRole: any
    acRole: string
  }

  export type Write = Read

  export type UserEventCrafterUserUpdateRequest = Read
}

export namespace UserEventEditUserRequest {
  export type Read = {
    readonly '@type': 'UserEventEditUserRequest'
    readonly '@id': `/${string}/${UUID}`
    avatar: DocumentPart.UserWrite[]
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    job: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "boolean",
    "null"
  ]
} */
    isAvailable: any
  }

  export type Write = Read

  export type UserWrite = Read
}

export namespace UserEventHasSeenOnboardingRequest {
  export type Read = {
    readonly '@type': 'UserEventHasSeenOnboardingRequest'
    readonly '@id': `/${string}/${UUID}`
    hasSeenOnboarding: boolean
  }

  export type Write = Read

  export type UserEventHasSeenOnboardingRequest = Read
}

export namespace UserEventInviteUserRequest {
  export type Read = {
    readonly '@type': 'UserEventInviteUserRequest'
    readonly '@id': `/${string}/${UUID}`
    userId: string
  }

  export type Write = Read

  export type UserEventInviteUserRequest = Read
}

export namespace UserEventResetCredentialsRequest {
  export type Read = {
    readonly '@type': 'UserEventResetCredentialsRequest'
    readonly '@id': `/${string}/${UUID}`
    username: string
  }

  export type Write = Read

  export type UserEventResetCredentialsRequest = Read
}

export namespace UserEventUpdatePasswordRequest {
  export type Read = {
    readonly '@type': 'UserEventUpdatePasswordRequest'
    readonly '@id': `/${string}/${UUID}`
    oldPassword: string
    password: string
  }

  export type Write = Read

  export type UserEventUpdatePasswordRequest = Read
}

export namespace UserEventUser {
  export type Read = {
    readonly '@type': 'UserEventUser'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    username: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    firstname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lastname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    job: any
    isAvailable: boolean
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    agency: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    society: any
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    avatar: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    craftCompany: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensBackOffice: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensIos: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensAndroid: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acRoles: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acAccesses: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiAccessToken: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiRefreshToken: any
    hasSeenOnboarding: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastLoginAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastInvitationAt: any
    favouriteAgencies: Api.IriReference[]
    conversations: Api.IriReference[]
    messages: Api.IriReference[]
    documents: Api.IriReference[]
    folders: Api.IriReference[]
    realisations: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    currentAgency: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    updatedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    deletedAt: any
    readonly aubade: boolean
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly societyAgency: any
    readonly fullName: string
    readonly sortValue: string
    readonly roles: string[]
    readonly userIdentifier: string
    readonly isCrafterUser: boolean
    readonly isCrafterAdmin: boolean
    readonly isCrafterManager: boolean
    readonly isCrafterEmployee: boolean
    readonly isAubadeAdmin: boolean
    readonly isSocietyUser: boolean
    readonly isAgencyUser: boolean
    ebAccessEnabled: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    ebRole: any
    acAccessEnabled: boolean
    acRole: string
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly agencyId: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly agencyName: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly societyId: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly societyName: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly craftCompanyId: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly craftCompanyName: any
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly avatarUrl: any
    readonly agenciesBlackListed: string[]
  }

  export type Write = {
    readonly '@type': 'UserEventUser'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    username: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    firstname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lastname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    job: any
    isAvailable: boolean
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    agency: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    society: any
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    avatar: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    craftCompany: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensBackOffice: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensIos: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensAndroid: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acRoles: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acAccesses: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiAccessToken: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiRefreshToken: any
    hasSeenOnboarding: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastLoginAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastInvitationAt: any
    favouriteAgencies: Api.IriReference[]
    conversations: Api.IriReference[]
    messages: Api.IriReference[]
    documents: Api.IriReference[]
    folders: Api.IriReference[]
    realisations: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    currentAgency: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    newId: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    createdAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    updatedAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    deletedAt: any
    ebAccessEnabled: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    ebRole: any
    acAccessEnabled: boolean
    acRole: string
  }

  export type UserEventUser = Read

  export type UserRead = {
    readonly '@type': 'UserEventUser'
    readonly '@id': `/${string}/${UUID}`
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ],
  "format": "uuid"
} */
    readonly id: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    username: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    firstname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    lastname: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    email: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    job: any
    isAvailable: boolean
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    agency: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    society: any
    publications: Api.IriReference[]
    notifications: Api.IriReference[]
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    avatar: any
    /** CodegenError: Cannot generate property type : {
  "owl:maxCardinality": 1,
  "type": [
    "string",
    "null"
  ],
  "format": "iri-reference",
  "example": "https://example.com/"
} */
    craftCompany: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensBackOffice: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensIos: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    registrationTokensAndroid: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acRoles: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "array",
    "null"
  ],
  "items": {
    "type": "string"
  }
} */
    acAccesses: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiAccessToken: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ]
} */
    crafterApiRefreshToken: any
    hasSeenOnboarding: boolean
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastLoginAt: any
    /** CodegenError: Cannot generate property type : {
  "type": [
    "string",
    "null"
  ],
  "format": "date-time"
} */
    lastInvitationAt: any
    favouriteAgencies: Api.IriReference[]
    authorizeAgencies: Api.IriReference[]
    readonly aubade: boolean
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly societyAgency: any
    readonly isCrafterUser: boolean
    readonly isCrafterAdmin: boolean
    readonly isCrafterManager: boolean
    readonly isCrafterEmployee: boolean
    readonly isAubadeAdmin: boolean
    readonly isSocietyUser: boolean
    readonly isAgencyUser: boolean
    /** CodegenError: Cannot generate property type : {
  "readOnly": true,
  "type": [
    "string",
    "null"
  ]
} */
    readonly avatarUrl: any
  }
}

export namespace UserEvent {
  export type Read = {
    readonly '@type': 'UserEvent'
    readonly '@id': `/${string}/${UUID}`
  }

  export type Write = Read

  export type UserEvent = Read
}
