import { compactObj, removeEmptyValues } from '@aubade/core/libs'
import { QueryOne, makeForm, Chip } from '@aubade/core/ui-kit'
import { IconFermer } from '@aubade/design/graphics'
import type { Society, Agency } from '@aubade/types/api'

import { HStack } from '@chakra-ui/react'
import { toHydraId } from '@nartex/api-platform'
import { useFormContext } from 'react-hook-form'

import type { UserFilterType } from '../types'

const { useWatch } = makeForm<
  UserFilterType,
  'societies' | 'agencies' | 'roles'
>()

type Props = {
  onChange: () => void
}
export function ActiveFilters(props: Props) {
  const { onChange } = props

  const [societies, agencies] = useWatch(['societies', 'agencies'])
  const roles = useWatch('roles')
  const { setValue } = useFormContext()
  const filteredSocieties = compactObj(removeEmptyValues(societies ?? {}))
  const filteredAgencies = compactObj(removeEmptyValues(agencies ?? {}))

  const filteredRoles = compactObj(removeEmptyValues(roles ?? {}))

  return (
    <HStack width="full" wrap="wrap" gap={2}>
      {filteredSocieties &&
        Object.keys(filteredSocieties).map((singleSociety) => {
          return (
            <QueryOne<Society.Read>
              key={`society - ${singleSociety}`}
              iri={toHydraId('societies', singleSociety)}
              children={(record) => {
                return (
                  <FilterChip
                    label={record.name}
                    onClick={() => {
                      setValue(`societies.${singleSociety}`, undefined)
                      onChange()
                    }}
                  />
                )
              }}
            />
          )
        })}
      {filteredAgencies &&
        Object.keys(filteredAgencies).map((singleAgency) => {
          return (
            <QueryOne<Agency.Read>
              key={`agency - ${singleAgency}`}
              // @ts-ignore
              iri={toHydraId('agencies', singleAgency)}
              children={(record) => {
                return (
                  <QueryOne<Society.Read>
                    // @ts-ignore
                    iri={record.society['@id']}
                    children={(society) => {
                      const label = [society.name, record.name]
                        .filter(Boolean)
                        .join(': ')
                      return (
                        <FilterChip
                          label={label}
                          onClick={() => {
                            setValue(`agencies.${singleAgency}`, undefined)
                            onChange()
                          }}
                        />
                      )
                    }}
                  />
                )
              }}
            />
          )
        })}

      <>
        {filteredRoles &&
          Object.keys(filteredRoles).map((role) => {
            return (
              <FilterChip
                key={role}
                label={role}
                onClick={() => {
                  setValue(`roles.${role}`, undefined)
                  onChange()
                }}
              />
            )
          })}
      </>
    </HStack>
  )
}

type FilterChipProps = {
  label: string
  onClick: () => void
}

function FilterChip(props: FilterChipProps) {
  const { label, onClick } = props
  return (
    <Chip
      size="xsmall"
      label={label}
      iconLeftProps={{
        as: IconFermer,
        color: 'darkGrey.500',
      }}
      textProps={{
        color: 'darkGrey.500',
        fontWeight: 'bold',
      }}
      onClick={onClick}
    />
  )
}
