import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import type { Active } from '@dnd-kit/core'
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import type { PropsWithChildren } from 'react'
import React, { useMemo, useState, isValidElement } from 'react'
import flattenChildren from 'react-flatten-children'

import { useDeepCompareMemo } from 'use-deep-compare'

import { DragHandle, SortableItem, SortableOverlay } from './components'

type SortableListProps = {
  onDragEnd: (oldIndex: number, newIndex: number) => void
}

export const SortableList = Object.assign(
  function SortableList(props: PropsWithChildren<SortableListProps>) {
    const { onDragEnd, children } = props

    const childrenList = flattenChildren(children)
      .filter(isValidElement)
      .filter((child) => {
        return child.type === SortableItem
      })

    const items_ = childrenList.map((child) => {
      return { id: (child.props as any).id as string }
    })
    const items = useDeepCompareMemo(() => items_, [items_])

    const [activeObj, setActiveObj] = useState<Active | null>(null)
    const activeChild = useMemo(
      () =>
        childrenList.find((child) => (child.props as any).id === activeObj?.id),
      [activeObj?.id, childrenList],
    )

    const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: {
          distance: 5,
        },
      }),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      }),
    )

    return (
      <DndContext
        sensors={sensors}
        onDragStart={(dragStartProps) => {
          const { active } = dragStartProps
          setActiveObj(active)
        }}
        onDragEnd={(dragEndProps) => {
          const { active, over } = dragEndProps
          if (over && active.id !== over?.id) {
            const activeIndex = items.findIndex((item) => item.id === active.id)
            const overIndex = items.findIndex((item) => item.id === over.id)
            onDragEnd(activeIndex, overIndex)
          }
          setActiveObj(null)
        }}
        onDragCancel={() => {
          setActiveObj(null)
        }}
      >
        <SortableContext items={items}>{children}</SortableContext>
        <SortableOverlay>
          {activeChild ? React.cloneElement(activeChild) : null}
        </SortableOverlay>
      </DndContext>
    )
  },
  { Item: SortableItem, DragHandle: DragHandle },
)
