import { getParagraphStyle, unit } from '@aubade/core/ui-kit'
import { IconDelete } from '@aubade/design/graphics'
import type { TranslationKey } from '@aubade/translation'
import { useTranslate } from '@aubade/translation'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button as ChakraButton,
  HStack,
  Icon,
  Tooltip,
  useDisclosure,
  useMergeRefs,
} from '@chakra-ui/react'
import type { As, IconProps } from '@chakra-ui/react'
import type { HydraId } from '@nartex/api-platform'
import { getResourceType, useDeleteMany } from '@nartex/api-platform'

import { useQueryClient } from '@tanstack/react-query'
import type { To } from 'history'
import type {
  AnchorHTMLAttributes,
  PropsWithChildren,
  ReactNode,
  ComponentProps,
  MouseEventHandler,
} from 'react'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { groupBy } from 'remeda'

import { useTranslatable } from '../useTranslatable'

type ChakraButtonProps = ComponentProps<typeof ChakraButton>

type Tint = 'white' | 'lightGrey'

export type ButtonProps = {
  label?: TranslationKey
  'aria-label'?: TranslationKey
  iconColor?: string
  variant: Variant
  newTab?: boolean
  tint?: Tint
  to?: To
  leftIcon?: As
  rightIcon?: As
  isDangerous?: boolean
  extraIconProps?: IconProps
} & Pick<
  ChakraButtonProps,
  | 'isDisabled'
  | 'isLoading'
  | 'type'
  | 'id'
  | 'form'
  | 'autoFocus'
  | 'isActive'
  | 'onClick'
  | 'color'
> &
  Pick<AnchorHTMLAttributes<any>, 'download'>

const COMMON_BUTTON_STYLE: ChakraButtonProps = {
  fontWeight: '600',
  borderRadius: '16px',
  color: 'white',
}

type Variant =
  | 'primary'
  | 'secondary'
  | 'link'
  | 'text'
  | 'circle'
  | 'circleNegative'
  | 'circleList'

function iconVariantStyleMap(color?: string): Record<Variant, IconProps> {
  return {
    circle: {
      color: color ?? 'darkGrey.500',
      backgroundColor: 'lightGrey.500',
    },
    circleList: {
      color: color ?? 'darkGrey.500',
      backgroundColor: 'lightGrey.500',
    },
    circleNegative: {
      backgroundColor: 'darkGrey.500',
      color: color ?? 'lightGrey.500',
    },
    primary: {
      color: color ?? 'white.500',
    },
    secondary: {
      color: color ?? 'blue.500',
    },
    link: {
      color: color ?? 'darkGrey.500',
    },
    text: {
      color: color ?? 'darkGrey.500',
    },
  }
}

function getVariantStyleMap(
  tint: Tint = 'lightGrey',
): Record<Variant, ChakraButtonProps> {
  return {
    primary: {
      height: '50px',
      borderRadius: '25px',
      fontSize: 'sm',
      fontWeight: 'bold',
      lineHeight: '20px',
      paddingX: '20px',
      paddingY: '13px',
      iconSpacing: '8px',
      minWidth: 'fit-content',
      background: 'blue.500',
    },
    secondary: {
      height: '50px',
      borderRadius: '25px',
      fontSize: 'sm',
      fontWeight: 'bold',
      lineHeight: '20px',
      paddingX: 0,
      paddingY: 0,
      iconSpacing: '8px',
      minWidth: 'fit-content',
      background: 'white',
      color: 'blue.500',
    },
    circle: {
      borderRadius: '20px',
      width: '40px',
      height: '40px',
      padding: 0,
      background: `${tint}.500`,
    },
    circleList: {
      borderRadius: '20px',
      width: '40px',
      height: '40px',
      padding: 0,
      background: 'lightGrey.500',
    },
    circleNegative: {
      borderRadius: '20px',
      width: '40px',
      height: '40px',
      padding: 0,
      background: 'darkGrey.500',
    },
    link: {
      variant: 'link',
      color: 'darkGrey.500',
      fontSize: 'sm',
      fontWeight: 'bold',
      paddingX: 0,
      paddingY: 0,
      height: 'fit-content',
      textDecoration: 'underline',
      _hover: {
        color: 'primary.500',
      },
      _active: {
        color: 'primary.500',
      },
    },
    text: {
      variant: 'link',
      background: 'transparent',
      paddingX: 0,
      paddingY: 0,
      height: 'auto',
      ...getParagraphStyle({
        color: 'blue.500',
        fontWeight: 'bold',
        size: 'sm',
      }),
      _hover: {
        textDecoration: 'underline',
      },
      _active: {
        textDecoration: 'underline',
      },
    },
  } satisfies Record<Variant, ChakraButtonProps>
}

function getIsDangerousStyleMap(): Record<Variant, ChakraButtonProps> {
  return {
    primary: {
      background: 'error.dark',
      _hover: {
        background: 'error.medium',
      },
      _active: {
        background: 'error.dark',
      },
    },
    secondary: {
      background: 'white',
      color: 'error.dark',
    },

    circle: {
      background: 'error.dark',
      _hover: {
        background: 'error.medium',
      },
      _active: {
        background: 'error.dark',
      },
    },
    circleList: {
      background: 'error.dark',
      _hover: {
        background: 'error.medium',
      },
      _active: {
        background: 'error.dark',
      },
    },
    circleNegative: {
      background: 'error.dark',
      _hover: {
        background: 'error.medium',
      },
      _active: {
        background: 'error.dark',
      },
    },

    link: {
      variant: 'link',
      color: 'error.dark',
      _active: {
        color: 'error.medium',
      },
      _hover: {
        color: 'error.medium',
      },
    },
    text: {
      ...getVariantStyleMap().text,
      color: 'error.medium',
      _active: {
        color: 'error.medium',
      },
    },
  }
}

export const Button = React.forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(function Button(props, ref) {
  const {
    to,
    newTab,
    variant = 'primary',
    leftIcon,
    rightIcon,
    children,
    onClick,
    isDisabled,
    autoFocus,
    isActive,
    type,
    'aria-label': ariaLabel,
    isDangerous,
    iconColor,
    tint = 'lightGrey',
    extraIconProps,
    ...rest
  } = useTranslatable(props)

  const translate = useTranslate()

  const variantStyle = getVariantStyleMap(tint)[variant]

  const iconVariantStyle = iconVariantStyleMap(iconColor)[variant]

  const buttonStyles = {
    ...COMMON_BUTTON_STYLE,
    ...variantStyle,
    leftIcon: leftIcon ? (
      <Icon
        height={'15px'}
        width={'15px'}
        as={leftIcon}
        {...iconVariantStyle}
        {...extraIconProps}
      />
    ) : undefined,
    rightIcon: rightIcon ? (
      <Icon
        height={'15px'}
        width={'15px'}
        as={rightIcon}
        color="darkGrey.500"
      />
    ) : undefined,
    iconSpacing: variant.includes('circle') ? 0 : '8px',
    ...(isDangerous ? getIsDangerousStyleMap()[variant] : {}),
    isDisabled,
    title:
      variant === 'circle'
        ? undefined
        : typeof children === 'string'
        ? children
        : undefined,
    maxWidth: 'full',
  } satisfies ChakraButtonProps

  const isActiveStyle = {
    ...buttonStyles._focus,
    boxShadow: 'var(--chakra-shadows-outline)',
  } as ChakraButtonProps

  const buttonProps: ChakraButtonProps = {
    ...rest,
    'aria-label': ariaLabel ? translate(ariaLabel) : undefined,
    ...getButtonBehavior({ to, onClick, isDisabled, newTab }),
    type,
    _focus: autoFocus ? isActiveStyle : {},
    ...(isActive ? isActiveStyle : {}),
  }

  // Can't use the native autoFocus feature, because it fires too early for useOnClickOutside
  const innerRef = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    if (autoFocus) {
      requestAnimationFrame(() => {
        innerRef?.current?.focus?.()
      })
    }
  }, [autoFocus])

  const buttonRef = useMergeRefs(ref, innerRef)

  if (variant === 'circle') {
    const translatedAriaLabel = buttonProps['aria-label']
    return (
      <Tooltip
        label={translatedAriaLabel}
        isDisabled={!children && !translatedAriaLabel}
      >
        <ChakraButton
          ref={buttonRef}
          variant={'link'}
          {...buttonProps}
          {...getParagraphStyle({ fontWeight: 'normal' })}
          label={undefined}
          gap={2}
        >
          <ChakraButton as={'span'} {...buttonStyles} />
          {children}
        </ChakraButton>
      </Tooltip>
    )
  }

  return (
    <ChakraButton ref={buttonRef} {...buttonStyles} {...buttonProps}>
      {children}
    </ChakraButton>
  )
})

export type GetButtonBehaviorParams = {
  to?: To
  onClick?: MouseEventHandler<HTMLElement>
  isDisabled?: boolean
  newTab?: boolean
}
export function getButtonBehavior(params: GetButtonBehaviorParams) {
  const { onClick, to, isDisabled: disabled, newTab = true } = params

  if (to) {
    if (disabled) {
      return { disabled } satisfies ChakraButtonProps & { disabled?: boolean }
    }

    if (typeof to === 'string') {
      if (/^(https?:\/\/)/.test(to) || to.startsWith('blob:')) {
        return {
          as: 'a' as any,
          href: to,
          role: 'link',
          onClick,
          disabled,
          target: isSameOrigin(window.location, to) || !newTab ? '' : '_blank',
        } satisfies ChakraButtonProps & { disabled?: boolean }
      }
    }

    return {
      as: RouterLink as any,
      to: to,
      role: 'link',
      onClick,
      disabled,
    } satisfies ChakraButtonProps & { disabled?: boolean }
  }

  if (onClick) {
    return {
      as: 'button' as any,
      onClick,
      role: 'button',
      type: 'button',
      disabled,
    } satisfies ChakraButtonProps & { disabled?: boolean }
  }

  return { disabled } satisfies ChakraButtonProps & { disabled?: boolean }
}

function isSameOrigin(linkA: Location | string, linkB: Location | string) {
  if (String(linkA).startsWith('blob:') || String(linkB).startsWith('blob:')) {
    return false
  }

  const urlA = new URL(String(linkA))
  const urlB = new URL(String(linkB))
  return urlA.origin === urlB.origin
}

type ConfirmButtonsProps = Omit<ButtonProps, 'onClick'>

type ButtonWithConfirmProps = {
  buttonProps: ConfirmButtonsProps
  dialogProps?: { title: TranslationKey; children: ReactNode }
  onConfirm: () => void | Promise<void>
  confirmButtonProps: ConfirmButtonsProps
}

export function ButtonWithConfirm(props: ButtonWithConfirmProps) {
  const { buttonProps, ...rest } = props

  return (
    <WithConfirm
      {...rest}
      renderButton={useCallback(
        (onClick) => (
          <Button {...buttonProps} onClick={onClick} />
        ),
        [buttonProps],
      )}
    />
  )
}

type DeleteButtonProps = Pick<ButtonWithConfirmProps, 'dialogProps'> & {
  mutateProps: {
    ids: HydraId[]
    onSuccess?: () => void
    redirect?: To
  }
}

export function DeleteButton(props: DeleteButtonProps) {
  const { mutateProps, ...dialogProps } = props
  const { ids, redirect, onSuccess } = mutateProps
  const { mutate: mutateDeleteMany } = useDeleteMany()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const resourceIdsMap = useMemo(() => {
    return groupBy(ids, (id) => {
      return getResourceType(id)
    })
  }, [ids])

  function onDelete() {
    Object.entries(resourceIdsMap).forEach((fromResource) => {
      const resourceName = fromResource[0]
      const resourceIds = fromResource[1]
      mutateDeleteMany(
        {
          resource: resourceName,
          ids: resourceIds,
        },
        {
          async onSuccess() {
            await queryClient.invalidateQueries()
            onSuccess?.()
            if (redirect) navigate(redirect)
          },
        },
      )
    })
  }
  return (
    <WithConfirm
      {...dialogProps}
      onConfirm={onDelete}
      renderButton={(onClick) => (
        <Button
          variant="primary"
          label="actions.delete"
          leftIcon={IconDelete}
          onClick={onClick}
        />
      )}
      confirmButtonProps={{
        label: 'actions.delete',
        variant: 'primary',
        isDangerous: true,
      }}
    />
  )
}

type WithConfirmProps = Omit<ButtonWithConfirmProps, 'buttonProps'> & {
  renderButton: (onClick: () => void) => ReactNode
}

export function WithConfirm(props: WithConfirmProps) {
  const { renderButton, dialogProps, onConfirm, confirmButtonProps } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)

  const translate = useTranslate()

  const onClick = dialogProps ? onOpen : onConfirm

  const button = useMemo(() => {
    return renderButton(onClick)
  }, [onClick, renderButton])

  return (
    <>
      {button}
      {dialogProps && (
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          trapFocus={false}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {translate(dialogProps.title)}
              </AlertDialogHeader>

              <AlertDialogBody>{dialogProps.children}</AlertDialogBody>

              <AlertDialogFooter>
                <HStack
                  spacing={unit('20')}
                  width="full"
                  justifyContent={'flex-start'}
                >
                  <Button
                    {...confirmButtonProps}
                    onClick={async () => {
                      await onConfirm()
                      onClose()
                    }}
                  />
                  <Button
                    ref={cancelRef}
                    variant="secondary"
                    onClick={onClose}
                    label="buttons.cancel"
                  />
                </HStack>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  )
}
