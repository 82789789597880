import type { Tint } from '@aubade/design'
import type { TranslationKey } from '@aubade/translation'
import { Icon, Tag } from '@chakra-ui/react'
import type {
  TagProps as ChakraTagProps,
  TagProps,
  FlexProps,
  IconProps as ChakraIconProps,
  As,
} from '@chakra-ui/react'
import React, { useId } from 'react'

import type { ParagraphProps, ParagraphSize } from '../Paragraph'
import { Paragraph } from '../Paragraph'

type ChipSize = 'big' | 'medium' | 'small' | 'xsmall'

type ChipVariant = 'filled' | 'thin' | 'thin-filled' | 'thick' | 'square'

export type ChipTint = Tint

type TintColors = {
  base: string
  alpha10: string
  alpha50: string
}

const FontSizeMap: Record<ChipSize, ParagraphSize> = {
  xsmall: 'xs',
  small: 'sm',
  medium: 'md',
  big: 'lg',
}
const IconSizeMap: Record<ChipSize, ChakraIconProps['width']> = {
  xsmall: '6px',
  small: 2,
  medium: 4,
  big: 5,
}

const GapMap: Record<ChipSize, FlexProps['gap']> = {
  xsmall: '5px',
  small: '5px',
  medium: 1,
  big: 2,
}

const SizeStyleMap: Record<ChipSize, ChakraTagProps> = {
  xsmall: {
    height: '25px',
    lineHeight: '150%',
    paddingX: '8px',
    paddingY: '4px',
  },
  small: {
    height: '22px',
    lineHeight: '150%',
    paddingX: '10px',
    paddingY: '1px',
  },
  medium: {
    height: '36px',
    lineHeight: '150%',
    paddingX: '12px',
    paddingY: '6px',
  },
  big: {
    height: '43px',
    lineHeight: '150%',
    paddingX: '14px',
    paddingY: '8px',
  },
}

const TintStyleMap = {
  primary: {
    base: 'lightGrey.500',
    alpha10: 'primary.alpha10',
    alpha50: 'primary.alpha50',
  },
  orange: {
    base: 'orange.base',
    alpha50: 'orange.alpha50',
    alpha10: 'orange.alpha10',
  },
  violet: {
    base: 'violet.base',
    alpha50: 'violet.alpha50',
    alpha10: 'violet.alpha10',
  },
  pink: {
    base: 'pink.base',
    alpha50: 'pink.alpha50',
    alpha10: 'pink.alpha10',
  },
  teal: {
    base: 'teal.base',
    alpha50: 'teal.alpha50',
    alpha10: 'teal.alpha10',
  },
  amber: {
    base: 'amber.base',
    alpha50: 'amber.alpha50',
    alpha10: 'amber.alpha10',
  },
  success: {
    base: 'success.medium',
    alpha50: 'success.alpha50',
    alpha10: 'success.alpha10',
  },
  error: {
    base: 'error.dark',
    alpha50: 'error.alpha50',
    alpha10: 'error.alpha10',
  },
  warning: {
    base: 'warning.medium',
    alpha50: 'warning.alpha50',
    alpha10: 'warning.alpha10',
  },
  disabled: {
    base: 'greyscale.200',
    alpha50: 'greyscale.100',
    alpha10: 'white.500',
  },
} satisfies Record<ChipTint, TintColors>

function VariantStyleMap(color: TintColors) {
  return {
    filled: {
      background: color.base,
      borderRadius: '24px',
      color: '#FFF',
    },
    'thin-filled': {
      background: 'lightGrey.500',
      borderRadius: '10px',
      color: color.base,
      borderColor: 'transparent',
      borderWidth: '1px',
    },
    thin: {
      background: 'white.500',
      borderRadius: '24px',
      color: color.base,
      borderColor: color.base,
      borderWidth: '1px',
    },
    thick: {
      background: '#FFF',
      borderRadius: '24px',
      color: color.base,
      borderColor: color.base,
      borderWidth: '2px',
    },
    square: {
      backgroundColor: color.base,
      borderRadius: '2px',
      color: '#FFF',
    },
  } satisfies Record<ChipVariant, ChakraTagProps>
}

type IconProps = Omit<ChakraIconProps, 'width' | 'height'> & { as: As }
export type ChipProps = Pick<
  TagProps,
  | 'aria-checked'
  | 'aria-label'
  | 'minWidth'
  | 'textTransform'
  | 'backgroundColor'
> & {
  onClick?: () => void
  onBlur?: () => void
  onFocus?: () => void
  label?: TranslationKey
  size?: ChipSize
  variant?: ChipVariant
  textProps?: ParagraphProps
  tint?: ChipTint
  iconLeftProps?: IconProps
  iconRightProps?: IconProps
}
export const Chip = React.forwardRef<HTMLElement, ChipProps>(function Chip(
  props,
  ref,
) {
  const {
    onClick,
    label,
    size = 'small',
    variant = 'thin-filled',
    tint = 'primary',
    textTransform,
    iconLeftProps,
    iconRightProps,
    textProps,
    ...rest
  } = props
  const id = useId()
  const isButton = Boolean(onClick)
  const color = TintStyleMap[tint]
  const variantColor = VariantStyleMap(color)[variant]

  const chipProps: ChakraTagProps = {
    ...SizeStyleMap[size],
    ...variantColor,
  }

  const hasLabel = Boolean(label)

  if (!label && !props['aria-label']) {
    throw Error('At least label or aria-label is required in Chip component')
  }

  const iconSize = IconSizeMap[size]

  return (
    <Tag
      ref={ref}
      as={isButton ? 'button' : 'span'}
      type="button"
      borderRadius="full"
      variant="solid"
      onClick={onClick}
      aria-labelledby={props['aria-label'] ? undefined : id}
      gap={GapMap[size]}
      {...chipProps}
      maxWidth={hasLabel ? 'auto' : chipProps.height}
      minWidth={chipProps.height}
      paddingX={hasLabel ? chipProps.paddingX : 0}
      justifyContent="center"
      {...rest}
    >
      {iconLeftProps && (
        <Icon {...iconLeftProps} width={iconSize} height={iconSize} />
      )}
      {hasLabel && (
        <label id={id} style={isButton ? { cursor: 'pointer' } : undefined}>
          <Paragraph
            fontWeight="bold"
            size={FontSizeMap[size]}
            color={variantColor.color}
            text={label}
            whiteSpace="nowrap"
            textTransform={textTransform}
            {...textProps}
          />
        </label>
      )}
      {iconRightProps && (
        <Icon {...iconRightProps} width={iconSize} height={iconSize} />
      )}
    </Tag>
  )
})
