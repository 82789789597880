import { createContextFunction } from '@aubade/core/libs'

export type ScopesType = typeof Scopes

const [ScopeProvider, useScope] = createContextFunction<ScopesType>('Scopes')

const Scopes = {
  publications: 'publications',
  notifications: 'notifications',
  agencies: 'agencies',
  draft: 'draft',
  conversations: 'conversations',
  collaboraters: 'collaboraters',
  customers: 'customers',
}

export { ScopeProvider, useScope, Scopes }
