import type { SearchClient as AlgoliaSearchClient } from 'algoliasearch'
import { SearchClient } from 'typesense'
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'
import type { NodeConfiguration } from 'typesense/lib/Typesense/Configuration'

export type SearchClientArgs<Collections extends string> = {
  typesenseKey: string
  typesenseUrl: string
  collectionSpecificSearchParameters: Record<Collections, { query_by: string }>
  additionalHeaders?: Record<string, string>
}

export function searchClient<Collections extends string>(
  props: SearchClientArgs<Collections>,
): SearchClient & AlgoliaSearchClient {
  const {
    typesenseKey,
    collectionSpecificSearchParameters,
    additionalHeaders,
  } = props
  const typesenseUrl = new URL(props.typesenseUrl)

  const protocol = typesenseUrl.protocol.replace(/:$/, '')
  const nodes: NodeConfiguration[] = [
    {
      host: typesenseUrl.host,
      port: parseInt(typesenseUrl.port) || (protocol === 'https' ? 443 : 80),
      path: typesenseUrl.pathname,
      protocol,
    },
  ]

  const { searchClient: algoliaSearchClient } =
    new TypesenseInstantSearchAdapter({
      server: {
        apiKey: typesenseKey,
        nodes,
        additionalHeaders: {
          ...additionalHeaders,
          'content-type': 'application/json',
        },
      },
      collectionSpecificSearchParameters,
    })

  const typesenseSearchClient = new SearchClient({
    nodes,
    apiKey: typesenseKey,
    additionalHeaders: {
      ...additionalHeaders,
      'content-type': 'application/json',
    },
  })

  return {
    ...algoliaSearchClient,
    ...typesenseSearchClient,
    multiSearch: {
      perform(searchRequests, commonParams) {
        return typesenseSearchClient.multiSearch.perform(
          {
            ...searchRequests,
            searches: searchRequests.searches.map((search) => {
              const collection = (search.collection ??
                commonParams?.collection) as Collections | undefined

              if (!collection) {
                throw Error(
                  'Collection param is mising. Please provide it via searchParams or commonParams',
                )
              }

              const collectionParams =
                collectionSpecificSearchParameters[collection]
              const searchParams = {
                ...(collectionParams ?? {}),
                ...search,
                query_by: search.query_by || collectionParams.query_by,
              }

              if (!searchParams.query_by) {
                throw Error(
                  `The 'query_by' params is empty. Please provide it on the request or via 'collectionSpecificSearchParameters'.\nThe search wath made with the following params : ${JSON.stringify(
                    searchParams,
                  )}`,
                )
              }
              return searchParams
            }),
          },
          commonParams,
        )
      },
    },
    collections(collection: Collections) {
      return {
        documents() {
          return {
            search(search, options) {
              const collectionParams =
                collectionSpecificSearchParameters[collection]
              const searchParams = {
                ...(collectionParams ?? {}),
                ...search,
                query_by: search.query_by || collectionParams.query_by,
              }

              if (!searchParams.query_by) {
                throw Error(
                  `The 'query_by' params is empty. Please provide it on the request or via 'collectionSpecificSearchParameters'.\nThe search wath made with the following params : ${JSON.stringify(
                    searchParams,
                  )}`,
                )
              }
              return typesenseSearchClient
                .collections(collection)
                .documents()
                .search(searchParams, options)
            },
          }
        },
      }
    },
  }
}
