import type { History as THistory, Blocker } from 'history'

import { createContextFunction } from '../libs'

const [HistoryProvider, useHistory] = createContextFunction<THistory>('history')
export { HistoryProvider, useHistory }

/*
 * Allow hot module replacement to work even is navigation is blocked
 */
export function wrapHistory(history: THistory) {
  if (process.env.NODE_ENV === 'development' && (module as any).hot) {
    const unBLocks: (() => void)[] = []
    ;(module as any).hot.addStatusHandler((status: string) => {
      if (status === 'abort') {
        unBLocks.forEach((unBlock) => unBlock())
      }
    })

    const historyBlock = history.block.bind(history)
    history.block = (blocker: Blocker) => {
      const unBlock = historyBlock(blocker)
      unBLocks.push(unBlock)
      return unBlock
    }
  }

  return history
}
