import type { AutoCompleteString } from '@aubade/core/libs'
import type { StackProps } from '@chakra-ui/react'
import { VStack } from '@chakra-ui/react'

import type { PropsWithChildren } from 'react'
import { useController } from 'react-hook-form'

export * from './controller'
export * from './TextInput'
export * from './DateInput'
export * from './NumberInput'
export * from './TextAreaInput'
export * from './Switch'

type HiddenInputProps<T> = {
  name: AutoCompleteString<keyof T>
  value?: any
}
export function HiddenInput<T>(props: HiddenInputProps<T>) {
  const { name, value } = props
  useController({
    name: name as string,
    defaultValue: value,
  })
  return null
}

export function VStackLayout(props: PropsWithChildren<StackProps>) {
  const { children, ...stackProps } = props
  return (
    <VStack spacing={8} align="left" width="full" {...stackProps}>
      {children}
    </VStack>
  )
}
