// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconCheck = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M45.7354 7.00197C44.6586 7.0333 43.6363 7.4825 42.8849 8.25412C41.9498 9.18965 35.7592 15.5523 29.3676 22.1169C24.178 27.4461 20.5217 31.1956 18.4631 33.3018L6.86741 24.4704H6.86789C6.00273 23.7175 4.86381 23.3565 3.72296 23.4736C2.5821 23.5907 1.54046 24.1749 0.846023 25.0878C0.151479 26.0007 -0.133376 27.1609 0.0584569 28.2916C0.25029 29.4223 0.901931 30.4234 1.85821 31.0562L16.3687 42.1121C17.1669 42.719 18.1579 43.0163 19.1584 42.9488C20.1586 42.8814 21.1008 42.4539 21.8098 41.7449C22.7449 40.8098 28.9355 34.4677 35.3271 27.9031C41.7187 21.3389 48.3113 14.5524 48.7579 14.1057C49.5561 13.3225 50.0039 12.2506 50 11.1328C49.9966 10.0146 49.5416 8.9455 48.7386 8.16766C47.9356 7.39021 46.8521 6.96944 45.7348 7.00173L45.7354 7.00197Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconCheck)
export default Memo
