const focussableElements = [
  'a:not([disabled])',
  'button:not([disabled])',
  'input:not([disabled])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  '[tabindex]:not([disabled]):not([tabindex="-1"])',
]

function focusAt(nextTo: HTMLElement | null, at: number) {
  if (!nextTo) return

  // add all elements we want to include in our selection
  const selectors = [
    ...focussableElements,
    nextTo.id && `[id=${JSON.stringify(nextTo.id)}]`,
  ].filter(Boolean)

  const focussable = Array.from(document.querySelectorAll(selectors.join(', ')))

  const index = focussable.indexOf(nextTo)
  if (index > -1) {
    const nextElement = focussable[index + at] ?? focussable[0]
    if ((nextElement as any)?.focus) {
      ;(nextElement as any).focus()
    }
  }
}

export function focusNext(nextTo: HTMLElement | null) {
  return focusAt(nextTo, +1)
}

export function focusPrev(nextTo: HTMLElement | null) {
  return focusAt(nextTo, -1)
}

export function focusFirstChild(parent: HTMLElement | null) {
  if (!parent) return

  const focussable = Array.from(
    parent.querySelectorAll(focussableElements.join(', ')),
  )

  const nextElement = focussable[0]
  if ((nextElement as any)?.focus) {
    ;(nextElement as any).focus()
  }
}
