import type { DocCreate } from '@aubade/core/adapters'
import { unit } from '@aubade/core/ui-kit'
import { IconPlus, IconFermer, IconProfil } from '@aubade/design/graphics'
import { useTranslate } from '@aubade/translation'
import type { Document } from '@aubade/types/api'
import { Icon, Avatar, AvatarBadge } from '@chakra-ui/react'
import { useDropzone } from 'react-dropzone'

import { useDocumentUrl } from '../../DocumentImage'
import type { RHFInputProps } from '../controller'
import { useInput } from '../controller'
import { initDocument, useBucket } from '../DropZoneInput/DropZoneInput'

type InputValue = DocCreate | Document.Read

type AvatarInputProps<T> = RHFInputProps<T, InputValue | null>
export function AvatarInput<T>(props: AvatarInputProps<T>) {
  const { id, wrap, field } = useInput<T, InputValue | null>(props)

  const translate = useTranslate()
  const bucket = useBucket()

  if (!bucket) {
    throw new Error(
      'Input.Avatar element needs to be provided a "Bucket" through the context (usually via `<Form {...controller} bucket={bucket} />`',
    )
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
    },

    async onDrop(acceptedFiles) {
      if (!acceptedFiles[0]) return
      const doc = initDocument(acceptedFiles[0], bucket)
      field.onChange(doc)
    },
  })

  const { value } = field
  const imagePreview = useDocumentUrl(value ?? undefined)

  const rootProps = getRootProps({
    id,
    role: 'button',
    title: props.label ? translate(props.label) : '',
  })

  const badgeProps = value
    ? {
        as: IconFermer,
        color: 'error.dark',
      }
    : {
        as: IconPlus,
        color: 'primary.500',
      }

  return wrap(
    <Avatar
      {...rootProps}
      background="greyscale.200"
      width={unit('150')}
      height={unit('150')}
      backgroundColor="white.500"
      icon={
        <Icon
          as={IconProfil}
          height={unit('100')}
          width={unit('100')}
          color="greyscale.200"
        />
      }
      src={imagePreview}
    >
      <input {...getInputProps()} />
      <AvatarBadge
        transform="none"
        right={unit('20')}
        bottom={unit('-6')}
        width={unit('36')}
        height={unit('36')}
        backgroundColor="white"
        borderWidth="thin"
        borderColor="primary.100"
        onClick={
          value
            ? (event) => {
                field.onChange(null)
                event.stopPropagation()
              }
            : undefined
        }
      >
        <Icon width={unit('20')} height={unit('20')} {...badgeProps} />
      </AvatarBadge>
    </Avatar>,
  )
}
