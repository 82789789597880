// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconNotification = (
  props: SVGProps<SVGSVGElement> & { slot?: string },
) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M43.5795 32.4736C42.1584 30.421 41.3166 28.0527 41.3166 25.579V20.6844C41.3166 12.6318 36.2637 6.52652 28.5797 4.89493L28.5793 3.00019C28.5793 1.36871 27.2635 0 25.5791 0H23.8947C22.2632 0 20.8945 1.31579 20.8945 3.00019V4.94747C13.2101 6.57896 8.15766 12.6843 8.15766 20.737V25.6315C8.15766 28.1052 7.26297 30.4736 5.89469 32.5262C5.31572 33.3155 5 34.3156 5 35.3681V35.7892C5 38.5787 7.26297 40.842 10.0528 40.842H39.4213C42.2108 40.842 44.4741 38.5791 44.4741 35.7892V35.3681C44.4741 34.263 44.1584 33.3158 43.5794 32.4736H43.5795Z"
      fill="currentColor"
    />
    <path
      d="M24.7374 50C27.6847 50 30.1059 47.5788 30.1585 44.5789H19.3163C19.3688 47.5788 21.79 50 24.7374 50Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconNotification)
export default Memo
