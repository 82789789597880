import type { Option as OptionSuggest } from '@aubade/core/ui-kit/DataPicker/types'
import type { ForwardedRef, ReactNode } from 'react'
import { forwardRef } from 'react'
import { useWatch } from 'react-hook-form'

import type {
  BaseRecord,
  GetListQueryParams,
} from '../../../adapters/dataProviders'
import { isReactNode, type Merge } from '../../../libs'
import type { RHFInputProps } from '../../Inputs/controller'
import { useRHFInput } from '../../Inputs/controller/useRHFInput'
import type { DataPickerProps } from '../DataPicker'
import { DataPickerValues } from '../DataPicker/Values'
import { Picker, type PickerRenderOptionsProps } from '../Picker'

import type { ModalCreateButtonProps } from './ModalCreateForm'
import { ModalCreateButton } from './ModalCreateForm'
import { ResourcePickerOptions } from './Options'

export * from './Options'
export * from './ModalCreateForm'

export type ResourcePickerProps<
  Option extends BaseRecord,
  Value extends BaseRecord = Option,
> = Merge<
  Omit<DataPickerProps<Option, Value>, 'getQuery'>,
  {
    filters?: GetListQueryParams<Option>['filters']
    resource: GetListQueryParams<Option>['resource']
    getListParams?: Omit<GetListQueryParams<Option>, 'resource' | 'filters'>
    withoutEmptyText?: boolean
    renderFilters?: (options: OptionSuggest[]) => ReactNode
    renderFooter?: (
      renderParams: PickerRenderOptionsProps<Option['@id']>,
    ) =>
      | ReactNode
      | Omit<ModalCreateButtonProps<Value>, 'onCreate' | 'resource'>
  }
>
const ResourcePickerImpl = forwardRef(function ResourcePicker<
  Option extends BaseRecord,
  Value extends BaseRecord = Option,
>(
  props: ResourcePickerProps<Option, Value>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const {
    getListParams,
    filters,
    resource,
    renderFooter,
    renderFilters,
    renderOption,
    renderValue,
    withoutEmptyText,
    ...rest
  } = props

  return (
    <Picker<Option['@id']>
      ref={ref as any}
      renderFilters={renderFilters}
      renderOptions={(renderParams) => {
        return (
          <ResourcePickerOptions<Option>
            {...renderParams}
            withoutEmptyText={withoutEmptyText}
            resource={resource}
            filters={filters}
            getListParams={getListParams}
            renderOption={renderOption}
          />
        )
      }}
      renderFooter={(renderParams) => {
        const result = renderFooter?.(renderParams)
        if (isReactNode(result)) return result
        if (!result) return null
        return (
          <ModalCreateButton<Value>
            {...result}
            onCreate={renderParams.onAdd}
            resource={resource}
          />
        )
      }}
      renderValues={(renderParams) => {
        return (
          <DataPickerValues<Value>
            {...renderParams}
            renderValue={renderValue}
          />
        )
      }}
      {...rest}
    />
  )
})

export const ResourcePicker = ResourcePickerImpl as <
  Option extends BaseRecord,
  Value extends BaseRecord = Option,
>(
  props: ResourcePickerProps<Option, Value>,
) => ReactNode

export type RHFResourcePickerProps<
  Form,
  Option extends BaseRecord,
  Value extends BaseRecord = Option,
> = Merge<
  Omit<ResourcePickerProps<Option, Value>, 'value'>,
  RHFInputProps<Form, Option['@id'][]>
>
export function RHFResourcePicker<
  Form,
  Option extends BaseRecord,
  Value extends BaseRecord = Option,
>(props: RHFResourcePickerProps<Form, Option, Value>) {
  const {
    renderOption,
    renderValue,
    renderFilters,
    getListParams,
    resource,
    filters,
    withoutEmptyText,
    ...rest
  } = props
  const rhfInputProps = useRHFInput<Form, Option['@id'][]>(rest)
  const currentId = useWatch({ name: '@id' })

  return (
    <ResourcePicker<Option, Value>
      {...rhfInputProps}
      resource={resource}
      withoutEmptyText={withoutEmptyText}
      filters={[
        ...(filters ?? []),
        { field: '@id', operator: 'ne', value: currentId },
      ]}
      renderOption={renderOption}
      renderValue={renderValue}
      renderFilters={renderFilters}
      getListParams={getListParams}
    />
  )
}

export type RHFSingleResourcePickerProps<
  Form,
  Outer extends BaseRecord,
  Value extends BaseRecord = Outer,
> = Omit<
  Merge<ResourcePickerProps<Outer, Value>, RHFInputProps<Form, Outer['@id']>>,
  'value' | 'transformer'
>
export function RHFSingleResourcePicker<
  Form,
  Outer extends BaseRecord,
  Value extends BaseRecord = Outer,
>(props: RHFSingleResourcePickerProps<Form, Outer, Value>) {
  const {
    renderOption,
    renderValue,
    getListParams,
    resource,
    filters,
    ...rest
  } = props
  const rhfInputProps = useRHFInput<Form, Outer['@id'], Outer['@id'][]>({
    ...rest,
    transformer: {
      read(value) {
        if (!value) return []
        return [value]
      },
      write(value) {
        if (!value?.length) return null
        return value.at(-1)
      },
    },
  })
  const recordId = useWatch({ name: '@id' })

  return (
    <ResourcePicker<Outer, Value>
      {...rhfInputProps}
      max={1}
      resource={resource}
      filters={[
        ...(filters ?? []),
        { operator: 'ne', field: '@id', value: recordId },
      ]}
      renderOption={renderOption}
      renderValue={renderValue}
      getListParams={getListParams}
    />
  )
}
