import { compactObj } from '@aubade/core/libs'
import { Paragraph, unit, DropdownMenu, makeForm } from '@aubade/core/ui-kit'
import { Box, HStack, VStack } from '@chakra-ui/react'
import { type BaseSyntheticEvent } from 'react'

import { SocietyAgencyFilter } from '../../../components'

import { ActiveFilters } from '../../Publications/Components/ActiveFliters'
import type { StatisticsFiltersType } from '../Filters'

const { Input, useWatch } = makeForm<
  StatisticsFiltersType,
  'date' | 'displayType'
>()

type Props = {
  filterState: StatisticsFiltersType
  onChange: (
    e?: BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>
}

export function FiltersSection(props: Props) {
  const { filterState, onChange } = props
  const name = useGetName(filterState)

  return (
    <VStack
      width="full"
      gap={unit('20')}
      alignItems={'flex-start'}
      marginY={unit('30')}
    >
      <Paragraph
        size="lg"
        fontWeight="bold"
        text="statistics.filters.global.title"
      />

      <HStack gap={unit('15')} width="full" justifyContent={'flex-start'}>
        <DropdownMenu title={name} variant="rounded">
          <SocietyAgencyFilter onFilterChange={onChange} single />
        </DropdownMenu>
        <DateRangeFilter onFilterChange={onChange} />
      </HStack>
      <ActiveFilters onChange={onChange} variant="negative" />
    </VStack>
  )
}

function useGetName(filterState: StatisticsFiltersType) {
  const { societies, agencies } = filterState

  if (Object.keys(compactObj(societies ?? {})).length) {
    return 'filters.statistics.society'
  }
  if (Object.keys(compactObj(agencies ?? {})).length) {
    return 'filters.statistics.agency'
  }

  return 'filters.statistics.national'
}

type DateRangeProps = {
  onFilterChange: (
    e?: BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>
}

function DateRangeFilter(props: DateRangeProps) {
  const { onFilterChange } = props
  const [date] = useWatch(['date'])
  return (
    <HStack width="full" gap={unit('15')}>
      <Paragraph text={'filters.date.from'} />
      <Box width={unit('170')}>
        <Input.Date
          variant="rounded"
          name="date.startDate"
          label=" "
          onChange={() => onFilterChange()}
          max={date?.endDate}
        />
      </Box>
      <Paragraph text={'filters.date.to'} />
      <Box width={unit('170')}>
        <Input.Date
          variant="rounded"
          name="date.endDate"
          min={date?.startDate}
          label=" "
          onChange={() => onFilterChange()}
        />
      </Box>
    </HStack>
  )
}
