import type { PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'
import { useDeepCompareMemo } from 'use-deep-compare'

const EMPTY = Symbol('empty context')

export function createContextFunction<T>(
  name: string,
  getDefaultValue?: () => T,
) {
  const Context = createContext<T | typeof EMPTY>(
    getDefaultValue ? getDefaultValue() : EMPTY,
  )

  function useContextFunction() {
    const resolved = useContext(Context)
    if (resolved === EMPTY) {
      throw Error(`No Provider for context "${name}"`)
    }
    return resolved
  }

  function Provider(props: PropsWithChildren<{ value: T }>) {
    const { value, children } = props

    const memoValue = useDeepCompareMemo(() => value, [value])
    return <Context.Provider value={memoValue}>{children}</Context.Provider>
  }

  return [Provider, useContextFunction] as const
}
