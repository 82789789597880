import { useOne } from '@aubade/core/adapters'
import { compactObj, objectIsEmpty } from '@aubade/core/libs'
import {
  unit,
  Paragraph,
  Button,
  makeForm,
  QueryOne,
} from '@aubade/core/ui-kit'
import { LogoEspaceAubade } from '@aubade/design/graphics'
import type { Tool } from '@aubade/types/api'
import { type Agency, type Society } from '@aubade/types/api'
import { Box, HStack, VStack, Icon, AspectRatio } from '@chakra-ui/react'

import { toHydraId } from '@nartex/api-platform'
import { useGetTimeDiff } from 'src/libs/getTimediff'

import { Picture } from '../CreateEdit'
import type { FormType } from '../types'
import { PublicationLinkEnum } from '../types'

const { useWatch } = makeForm<
  FormType,
  | 'documents'
  | 'author'
  | 'publishedAt'
  | 'urlLabel'
  | 'title'
  | 'content'
  | 'agencies'
  | 'societies'
  | 'linkType'
  | 'url'
  | 'toolId'
>()

export function PublicationPreview() {
  const [
    documents,
    publishedAt,
    title,
    content,
    agencies,
    societies,
    linkType,
  ] = useWatch([
    'documents',
    'publishedAt',
    'title',
    'content',
    'agencies',
    'societies',
    'linkType',
  ])

  const publishedDate = publishedAt ?? new Date()

  const publishedFrom = useGetTimeDiff(new Date(publishedDate as string))

  return (
    <>
      <VStack
        gap={0}
        width="full"
        height="90vh"
        borderRadius={unit('20')}
        overflow={'hidden'}
      >
        <AspectRatio
          ratio={1}
          width="full"
          backgroundColor="white.500"
          borderRadius={'20px 20px 0 0'}
          justifyContent={'center'}
        >
          <Picture document={documents[0]} />
        </AspectRatio>
        <VStack
          width="full"
          overflowY={'auto'}
          backgroundColor="white.500"
          padding={unit('20')}
          paddingTop={unit('7')}
          gap={'20px'}
        >
          <Box
            width={unit('80')}
            minHeight={unit('3')}
            borderRadius={unit('2')}
            backgroundColor="grey.500"
            marginBottom={unit('30')}
          />
          <VStack
            justifyContent="flex-start"
            width="full"
            alignItems="flex-start"
            gap={unit('20')}
          >
            <VStack gap={0} width="full" alignItems="flex-start">
              <HStack gap={unit('5')} width="full">
                {(agencies || societies) && (
                  <Author agencies={agencies} societies={societies} />
                )}
                <Paragraph
                  fontWeight="bold"
                  color="greyDark.500"
                  text={`- ${publishedFrom.diff} ${publishedFrom.text}`}
                />
              </HStack>
              <Paragraph fontWeight="bold" size="md" text={title} />

              {linkType !==
                PublicationLinkEnum['enum./publicationlink/WITHOUT_LINK'] && (
                <ButtonLink />
              )}
            </VStack>
            <Paragraph text={content} whiteSpace={'pre-line'} />
          </VStack>
        </VStack>
      </VStack>
    </>
  )
}

type AuthorProps = {
  agencies?: Record<string, boolean>
  societies?: Record<string, boolean>
}
function Author(props: AuthorProps) {
  const { agencies, societies } = props

  if (
    objectIsEmpty(compactObj(agencies ?? {})) &&
    objectIsEmpty(compactObj(societies ?? {}))
  ) {
    return (
      <>
        <Paragraph fontWeight="bold" color="blue.500" text="Publication" />
        <Icon as={LogoEspaceAubade} width={unit('40')} />
      </>
    )
  }
  const singleAgency = Object.keys(compactObj(agencies ?? {}))[0]
  const singleSociety = Object.keys(compactObj(societies ?? {}))[0]
  if (singleSociety) {
    return (
      <QueryOne<Society.Read>
        iri={toHydraId('societies', singleSociety)}
        children={(fullSociety) => {
          return (
            <Paragraph
              fontWeight="bold"
              color="blue.500"
              text={fullSociety.name}
            />
          )
        }}
      />
    )
  }
  if (singleAgency) {
    return (
      <QueryOne<Agency.Read>
        iri={toHydraId('agencies', singleAgency)}
        children={(fullAgency) => {
          return (
            <Paragraph
              fontWeight="bold"
              color="blue.500"
              text={fullAgency.name}
            />
          )
        }}
      />
    )
  }
}

function ButtonLink() {
  const [urlLabel, url, toolId] = useWatch(['urlLabel', 'url', 'toolId'])

  const [tool] = useOne<Tool.Read>(
    {
      resource: 'tools',
      id: toolId,
    },
    { enabled: Boolean(toolId) },
  )

  const externalUrl = tool ? tool.url : (url as string)
  const buttonLabel = tool ? tool.name : urlLabel

  return (
    <Box width="full" marginY={unit('20')}>
      <Button variant="primary" label={buttonLabel} to={externalUrl} />
    </Box>
  )
}
