import type { DataProvider } from '@aubade/core/adapters'

import type { AutoCompleteString } from '@aubade/core/libs'
import { compactObj } from '@aubade/core/libs'
import type {
  ExtendedPublicationsRead,
  ExtendedPublicationsWrite,
} from '@aubade/types'

import type { User } from '@aubade/types/api'
import type { Typesense } from '@aubade/types/typesense'
import { cleanHydraId } from '@nartex/api-platform'

import type { SearchResponse, SearchParams } from '@nartex/typesense'

import { PublicationLinkEnum } from '../../../Resources/Publications/types'
import { encodePayloadApiToTypesense } from '../index'

export function PublicationsProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    // @ts-ignore
    async getOne(params) {
      const { id } = params
      const publications = await dataProvider.custom!<ExtendedPublicationsRead>(
        {
          ...params,
          method: 'get',
          url: `publications/${id}/find`,
        },
      )
      const { data } = publications
      const { documents, author } = data
      const ids = documents.map((document) => cleanHydraId(document))

      const { data: fullDocuments } = await dataProvider.getMany!({
        ...params,
        resource: 'documents',
        ids: ids,
      })

      const { data: fullAuthor } = await dataProvider.getOne<User.Read>({
        ...params,
        resource: 'users',
        id: author,
      })

      return {
        ...publications,
        data: {
          ...decodePublications(data),
          documents: fullDocuments,
          fullAuthor,
        },
      }
    },
    update(params) {
      const { id } = params
      const { agencies, societies, isNational } =
        params.variables as ExtendedPublicationsWrite
      const booleanIsNational = isNational === 'true'
      const arraySocieties = fromBooleanRecordToArray(societies)
      const arrayAgencies = fromBooleanRecordToArray(agencies)

      return dataProvider.custom!({
        ...params,
        method: 'put',
        url: `publications/${id}/update`,
        payload: {
          ...params.variables,
          targets: undefined,
          targetSocieties: booleanIsNational ? [] : arraySocieties,
          targetAgencies: booleanIsNational ? [] : arrayAgencies,
          isNational: booleanIsNational,
        },
      })
    },
    async getList(params) {
      const { metaData } = params
      const payload = encodePayloadApiToTypesense(
        params,
        'publications',
        'title, content',
      )
      const searchResponse = await dataProvider.custom!<
        {
          results: [SearchResponse<any>]
        },
        PublicationsPayload
      >({
        method: 'post',
        url: `publications/ts/search`,
        metaData,
        payload,
      })
      const result = searchResponse.data.results[0]

      const data = result.hits?.map((hit) => hit.document) ?? []
      return {
        data,
        total: result.found,
        raw: { typesense: result },
      }
    },
  }
}

export function PublicationsCreateProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    create(params) {
      const { isNational, agencies, societies } =
        params.variables as ExtendedPublicationsWrite
      const booleanIsNational = isNational === 'true'

      const arraySocieties = fromBooleanRecordToArray(societies)
      const arrayAgencies = fromBooleanRecordToArray(agencies)

      return dataProvider.create({
        ...params,
        variables: {
          ...params.variables,
          targetSocieties: !booleanIsNational ? arraySocieties : [],
          targetAgencies: !booleanIsNational ? arrayAgencies : [],
          isNational: booleanIsNational,
        },
      })
    },
  }
}

type PublicationsPayload = {
  typesense: SearchParams & {
    collection: AutoCompleteString<Typesense.Collection>
  }
}

export function PublicationsListProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    async getList(params) {
      const { metaData } = params
      const payload = encodePayloadApiToTypesense(
        params,
        'publications',
        'title, content',
      )
      const searchResponse = await dataProvider.custom!<
        {
          results: [SearchResponse<any>]
        },
        PublicationsPayload
      >({
        method: 'post',
        url: `publications/ts/search`,
        metaData,
        payload,
      })
      const result = searchResponse.data.results[0]

      const data = result.hits?.map((hit) => hit.document) ?? []
      return {
        data,
        total: result.found,
        raw: { typesense: result },
      }
    },
  }
}

function decodePublications(
  data: ExtendedPublicationsRead,
): ExtendedPublicationsWrite {
  const {
    targetAgencies,
    targetSocieties,
    isNational,
    societyId,
    toolId,
    url,
    urlLabel,
  } = data

  let linkType = PublicationLinkEnum['enum./publicationlink/WITHOUT_LINK']

  if (toolId) {
    linkType = PublicationLinkEnum['enum./publicationlink/TOOL_LINK']
  }
  if (url || urlLabel) {
    linkType = PublicationLinkEnum['enum./publicationlink/EXTERNAL_LINK']
  }

  return {
    ...data,
    linkType,
    agencies: fromArrayToBooleanRecord(targetAgencies),
    societies: fromArrayToBooleanRecord(targetSocieties),
    isNational: isNational ? 'true' : 'false',
    isAgencies: societyId ? 'true' : 'false',
  }
}

export function fromArrayToBooleanRecord(values: string[]) {
  const newValue: Record<string, any> = {}
  values?.forEach((value) => {
    newValue[cleanHydraId(value)] = true
  })
  return newValue
}

export function fromBooleanRecordToArray(
  values: Record<string, any> | undefined,
) {
  return Object.keys(compactObj(values ?? {})).map((value) =>
    cleanHydraId(value),
  )
}
