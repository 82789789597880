import {
  type DayValue,
  type CalendarProps,
  Calendar,
} from '@amir04lm26/react-modern-calendar-date-picker'
import type { ForwardedRef } from 'react'
import { memo, forwardRef } from 'react'

import type { BaseInputProps } from '../controller'
import { useBaseInput } from '../controller'
import { BaseInputGroup } from '../TextInput/TextInput'

import '@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css'
import './styles.css'
import { frenchLocales } from './locale'

type DateInputProps = BaseInputProps<DayValue> & {
  daysWithValues?: CalendarProps<DayValue>['customDaysClassName']
}

export const CalendarDateInput = memo(
  forwardRef(function (
    props: DateInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    const { label, daysWithValues, ...rest } = props
    const { id, field, wrap } = useBaseInput<DayValue>(rest, {})

    return wrap(
      <BaseInputGroup label={label} id={id}>
        <Calendar
          ref={ref}
          customDaysClassName={daysWithValues}
          locale={frenchLocales}
          {...field}
          onChange={(value) => {
            if (!value) return
            if (!field.value) field.onChange(value)
            if (isSameDayValue(field.value, value)) {
              field.onChange(null)
            } else {
              field.onChange(value)
            }
          }}
          calendarSelectedDayClassName="customCalendarSelectedDay"
          calendarTodayClassName="customCalendarTodayClassName"
          calendarClassName="customCalendarClassName"
        />
      </BaseInputGroup>,
    )
  }),
)

function isSameDayValue(current: DayValue, newValue: DayValue) {
  if (!current || !newValue) return false
  return Boolean(
    current.day === newValue.day &&
      current.month === newValue.month &&
      current.year === newValue.year,
  )
}
