import { Paragraph } from '@aubade/core/ui-kit'
import { HStack, Box } from '@chakra-ui/react'
import type { ForwardedRef } from 'react'
import { memo, forwardRef } from 'react'

import type { BaseInputProps } from '../controller'
import { useBaseInput } from '../controller'
import { BaseInputGroup } from '../TextInput/TextInput'

export const ColorInput = memo(
  forwardRef(function (
    props: BaseInputProps<string, string, HTMLInputElement> & {
      placeholder?: string
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    const { label, placeholder, ...rest } = props
    const { id, field, wrap } = useBaseInput<
      string,
      string,
      string,
      HTMLInputElement
    >(rest)

    return wrap(
      <BaseInputGroup label={label} id={id}>
        <HStack
          width="full"
          padding={'10px'}
          justifyContent={'space-between'}
          bg="white.500"
          borderRadius="10px"
        >
          <Paragraph fontWeight="bold" text={placeholder ?? ' '} />
          <Box
            width={'30px'}
            height={'30px'}
            borderRadius={'4px'}
            overflow={'hidden'}
            borderColor={'transparent'}
          >
            <input
              {...field}
              style={{
                width: '30px',
                height: '30px',
              }}
              type="color"
              ref={ref}
              value={field.value ?? undefined}
            />
          </Box>
        </HStack>
      </BaseInputGroup>,
    )
  }),
)
