import type { TranslationKey } from '@aubade/translation'

import type { RHFInputProps } from '../controller'
import { useRHFInput } from '../controller'
import { getSelectOptions } from '../getSelectOptions'

import { SelectInput } from './SelectInput'

export { SelectInput } from './SelectInput'

export type SelectInputProps<T, Outer> = RHFInputProps<
  T,
  Outer,
  string | undefined
> & {
  optionsSelect: readonly string[] | string[] | Record<string, string>
  translationKey?: string
  placeholder?: TranslationKey | boolean
}

export function RHFSelectInput<T, Outer = string>(
  props: SelectInputProps<T, Outer>,
) {
  const { optionsSelect, translationKey, placeholder, ...rest } = props

  const shouldSelectFirst = rest.required && !placeholder
  const innerProps = useRHFInput<T, Outer, string | undefined>({
    defaultValue: shouldSelectFirst ? getFirstOption(optionsSelect) : undefined,
    ...rest,
  })

  return (
    <SelectInput
      {...innerProps}
      optionsSelect={optionsSelect}
      placeholder={placeholder}
      translationKey={translationKey}
    />
  )
}

function getFirstOption(
  options: readonly string[] | string[] | Record<string, string>,
) {
  const selectOptions = getSelectOptions(options)
  const [firstOption] = selectOptions[0] ?? []
  return firstOption
}
