// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconPlus = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.3118 22.6882V2.31179C27.3118 1.48578 26.8711 0.722747 26.1559 0.309615C25.4407 -0.103211 24.5593 -0.103199 23.8441 0.309613C23.1289 0.722806 22.6882 1.48579 22.6882 2.31179L22.6882 22.6882L2.31179 22.6882C1.48578 22.6882 0.722746 23.1289 0.309614 23.8441C-0.103212 24.5593 -0.103198 25.4407 0.309614 26.1559C0.722806 26.8711 1.48579 27.3118 2.31179 27.3118H22.6882L22.6882 47.6882C22.6882 48.5142 23.1289 49.2773 23.8441 49.6904C24.5593 50.1032 25.4407 50.1032 26.1559 49.6904C26.8711 49.2772 27.3118 48.5142 27.3118 47.6882V27.3118L47.6882 27.3118C48.5142 27.3118 49.2773 26.8711 49.6904 26.1559C50.1032 25.4407 50.1032 24.5593 49.6904 23.8441C49.2772 23.1289 48.5142 22.6882 47.6882 22.6882L27.3118 22.6882Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconPlus)
export default Memo
