import { createContextFunction } from '@aubade/core/libs'

export type AppMode = 'embedded' | 'standalone'
export type PlatformId = 'android' | 'ios' | 'web'

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    // Android
    androidBridge?: {
      postMessage(data: string): void
      onmessage?: (event: { data: string }) => void
    }

    // iOS
    webkit?: {
      messageHandlers?: {
        bridge: {
          postMessage(data: any): void
        }
        postMessageListener?: {
          postMessage(data: any): Promise<any>
        }
      }
    }
  }
}

export type INativeBridge = ReturnType<typeof NativeBridge>

export function NativeBridge() {
  return {
    getAuthToken() {
      const userAgentPrefix = 'nx-aubade:'

      if (navigator.userAgent.startsWith(userAgentPrefix)) {
        return navigator.userAgent.replace(userAgentPrefix, '')
      }
    },

    getPlatformId(): PlatformId {
      if (window.androidBridge) {
        return 'android'
      } else if (window.webkit?.messageHandlers?.bridge) {
        return 'ios'
      } else {
        return 'web'
      }
    },

    getAppMode(): AppMode {
      if (this.getAuthToken()) {
        return 'embedded'
      }
      return 'standalone'
    },

    postMessage<T, U>(content: T): Promise<U> {
      if (window.webkit?.messageHandlers?.postMessageListener) {
        return window.webkit?.messageHandlers.postMessageListener.postMessage(
          content,
        )
      }

      throw Error(
        `The method "postMessage" is not detected in this platform. Detected platform is ${JSON.stringify(
          this.getPlatformId(),
        )}. iOS is the only supported platform in aubade for now.`,
      )
    },
  }
}

export const [NativeBridgeProvider, useNativeBridge] =
  createContextFunction<INativeBridge>('NativeBridge', NativeBridge)

export function useIsEmbedded() {
  const nativeBridge = useNativeBridge()
  return nativeBridge.getAppMode() === 'embedded'
}
