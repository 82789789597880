// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconDownload = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.1108 27.8573V2.63158C28.1108 1.17821 26.9418 0 25.4997 0C24.0576 0 22.8886 1.17821 22.8886 2.63158V27.8573L14.2904 19.1918C13.2708 18.1641 11.6175 18.1641 10.5978 19.1918C9.57807 20.2195 9.57807 21.8857 10.5978 22.9134L25.4997 37.9321L40.4018 22.9134C41.4212 21.8857 41.4212 20.2195 40.4018 19.1918C39.3819 18.1642 37.7286 18.1642 36.7087 19.1918L28.1108 27.8573Z"
      fill="currentColor"
    />
    <path
      d="M4.61111 34.2105C6.05318 34.2105 7.22222 35.3887 7.22222 36.8421V41.0526C7.22222 43.0874 8.85887 44.7368 10.8778 44.7368H40.1222C42.1411 44.7368 43.7778 43.0874 43.7778 41.0526V36.8421C43.7778 35.3887 44.947 34.2105 46.3889 34.2105C47.8307 34.2105 49 35.3887 49 36.8421V41.0526C49 45.9942 45.0254 50 40.1222 50H10.8778C5.97474 50 2 45.9942 2 41.0526V36.8421C2 35.3887 3.16905 34.2105 4.61111 34.2105Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconDownload)
export default Memo
