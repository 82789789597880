import { fromS3ToResizedUrl } from '@aubade/core/libs'
import { useDocumentUrl } from '@aubade/core/ui-kit'
import { Box, Image, VStack } from '@chakra-ui/react'

const videoPlaceholder =
  'https://i.vimeocdn.com/video/499134794-0e30b7b4f310669590490d68c546fe9a4c00f2a16a66579bfb7cff3451fd3ea3-d_640'

const vimeoParams =
  '&byline=0&portrait=0&speed=0&pip=0&quality=undefined&texttrack=undefined&controls=true&title=0'

export function FeedPicture(props: {
  document: {
    url: string
    mimeType: string
    pictureLink?: string
    playerEmbedUrl?: string
  }
  clickable?: boolean
}) {
  const { document, clickable = false } = props

  const parsedUrl = fromS3ToResizedUrl(document.url)
  const pictureUrl = useDocumentUrl(parsedUrl, {
    size: { width: 600, height: 600 },
    ratio: 'crop',
  })

  const videoUrl = document?.playerEmbedUrl ?? undefined

  const imageUrl = pictureUrl ?? videoPlaceholder

  if (videoUrl) {
    return (
      <VStack
        alignItems={'center'}
        justifyContent={'center'}
        onClick={(e) => e.preventDefault()}
        height="full"
        width="full"
      >
        <Box width="full" height="full">
          <iframe
            src={`${videoUrl}${vimeoParams}`}
            allow="fullscreen; picture-in-picture"
            title="sample"
            style={{
              width: '100%',
              aspectRatio: '1',
              backgroundColor: 'red.100',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </Box>
      </VStack>
    )
  }

  return (
    <Image
      src={imageUrl}
      alt={imageUrl}
      minWidth="100%"
      minHeight="100%"
      objectFit="cover"
      cursor={videoUrl && clickable ? 'pointer' : undefined}
    />
  )
}
