import type { PropsWithChildren, ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import { ErrorPage } from './ErrorPage'
import { NavItem, NavGroup } from './NavItem'
import { SiderLayout } from './SiderLayout'

export { useOnNavigateAway } from './ErrorPage'

function NakedLayout(props: PropsWithChildren<{}>): ReactNode {
  if (props.children) {
    return <>{props.children}</>
  }

  return <Outlet />
}

function LoadingPage() {
  return <></>
}

export const Layout = {
  Naked: NakedLayout,
  Sider: Object.assign(SiderLayout, { NavItem, NavGroup }),
  Loading: LoadingPage,
  Error: ErrorPage,
}
