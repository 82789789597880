import type { LogicalFilter } from '@aubade/core/adapters'
import { compactObj } from '@aubade/core/libs'
import { toCrudFilters, useFiltersSearchParams } from '@aubade/core/ui-kit'

import type { ScopesType } from '../../../components/ScopeProvider'
import type { PublicationFilterType } from '../../Publications/filters/types'
import type { UserFilterType } from '../types'

export function useUserFilters(
  scope: ScopesType['collaboraters'] | ScopesType['customers'],
) {
  return useFiltersSearchParams<UserFilterType>({
    scope: scope,
    toCrudFilters(filtersState) {
      const { roles, agencies, societies, ...defaultParse } = filtersState
      return [
        ...toCrudFilters(defaultParse),
        roles && roleFilterToCrud(roles),
        ...societiesFilterToCrud(societies),
        ...agenciesFilterToCrud(agencies),
      ]
    },
  })
}

function societiesFilterToCrud<T extends Record<string, any>>(
  values: T | undefined,
): LogicalFilter<PublicationFilterType>[] {
  if (!values)
    return [
      {
        field: 'societies',
        operator: 'eq',
        value: undefined,
      },
    ]
  const societies = Object.entries(compactObj(values)).map(([id]) => id)

  return [
    {
      field: 'societies',
      operator: 'eq',
      value: societies.length ? societies : undefined,
    },
  ]
}

function agenciesFilterToCrud<T extends Record<string, any>>(
  values: T | undefined,
): LogicalFilter<PublicationFilterType>[] {
  if (!values)
    return [
      {
        field: 'agencies',
        operator: 'eq',
        value: undefined,
      },
    ]
  const agencies = Object.entries(compactObj(values)).map(([id]) => id)

  return [
    {
      field: 'agencies',
      operator: 'eq',
      value: agencies.length ? agencies : undefined,
    },
  ]
}

function roleFilterToCrud(
  roles: Record<string, any>,
): LogicalFilter<UserFilterType> {
  const selectedRoles = Object.entries(compactObj(roles!)).map(
    ([selectedRole]) => selectedRole,
  )
  return {
    field: 'roles',
    operator: 'eq',
    value: selectedRoles,
  }
}
