import type { Tint } from '@aubade/design'
import type { TranslationKey } from '@aubade/translation'
import type { TextProps, Theme } from '@chakra-ui/react'
import { Text, Tooltip } from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'

import { useTranslatable } from '../useTranslatable'

export type ParagraphSize = keyof Pick<
  Theme['fontSizes'],
  '3xs' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
>
type FontWeight = keyof Pick<Theme['fontWeights'], 'bold' | 'normal'>

const EllipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: 'full',
} satisfies TextProps

type ParagraphStyleParams = {
  tint?: Tint
  size?: ParagraphSize | ParagraphSize[]
  fontWeight?: FontWeight
  isDangerous?: boolean
  ellipsis?: boolean
  opacity?: 0.5 | 0.75 | 1
} & Pick<TextProps, 'color' | 'textTransform'>

export function getParagraphStyle(props?: ParagraphStyleParams) {
  const {
    size = 'sm',
    fontWeight = 'normal',
    isDangerous,
    color = 'inherit',
    textTransform,
    ellipsis,
    ...rest
  } = props ?? {}

  return {
    color: isDangerous ? 'error.dark' : color,
    fontFamily: 'body',
    fontSize: size,
    fontWeight: fontWeight,
    textTransform,
    lineHeight: '150%',
    maxWidth: 'inherit',
    ...rest,
    ...(ellipsis ? EllipsisStyle : {}),
  } satisfies TextProps
}

export type ParagraphProps = ParagraphStyleParams &
  Pick<
    TextProps,
    | 'as'
    | 'flex'
    | 'id'
    | 'whiteSpace'
    | 'role'
    | 'aria-label'
    | 'aria-disabled'
    | 'textAlign'
    | 'casing'
    | 'lineHeight'
  > & {
    text?: TranslationKey
  }
/**
 * 3xs: 7px |
 * 2xs: 9px |
 * xs: 10px |
 * sm: 12px |
 * md: 16px |
 * lg: 20px |
 * xl: 24px |
 * 2xl: 28px |
 * 3xl: 32px
 */
export function Paragraph(props: PropsWithChildren<ParagraphProps>) {
  const {
    children,
    id,
    flex,
    casing,
    color = 'greyscale.900',
    ...styleProps
  } = useTranslatable(props)
  const { ellipsis } = styleProps

  if (ellipsis)
    return (
      <Tooltip label={props.text}>
        <Text
          id={id}
          flex={flex}
          wordBreak={'break-word'}
          casing={casing}
          {...getParagraphStyle({
            ...styleProps,
            color,
            textTransform: casing,
          })}
        >
          {children}
        </Text>
      </Tooltip>
    )
  return (
    <Text
      id={id}
      flex={flex}
      {...getParagraphStyle({ ...styleProps, color, textTransform: casing })}
      wordBreak={'break-word'}
    >
      {children}
    </Text>
  )
}

export function Caption(props: PropsWithChildren<ParagraphProps>) {
  return (
    <Paragraph
      as={'label'}
      fontWeight="normal"
      size="2xs"
      color={'darkGrey.500'}
      {...props}
    />
  )
}
