import { useWatcher } from '@aubade/core/ui-kit'
import { useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import type {
  FieldValues,
  FieldPathValue,
  FieldPath,
  DeepPartial,
  WatchObserver,
} from 'react-hook-form'
import { equals } from 'remeda'

const NONE = Symbol('NONE')

export function useReactiveValue<
  TFieldValues extends FieldValues,
  TFieldPath extends FieldPath<TFieldValues>,
>(
  name: TFieldPath,
  computeFunction:
    | ((
        values: DeepPartial<TFieldValues>,
        value: DeepPartial<FieldPathValue<TFieldValues, TFieldPath>>,
      ) => DeepPartial<FieldPathValue<TFieldValues, TFieldPath>>)
    | undefined,
  dependencies: FieldPath<TFieldValues>[],
) {
  const { getValues, setValue } = useFormContext<TFieldValues>()

  const lastSetValue = useRef<any>(NONE)
  const watcherFunction: WatchObserver<TFieldValues> = (values, info) => {
    if (!computeFunction) return

    let previousValue: any = NONE
    const isSelfChange = info.name === name && !info.type
    if (isSelfChange) {
      previousValue = getValues(name)
      if (lastSetValue.current === previousValue) return
    }

    if (info.type !== 'load') {
      if (!dependencies.includes(info.name as any)) return
    }

    previousValue = previousValue === NONE ? getValues(name) : previousValue
    const newValue = computeFunction(values, previousValue)

    if (!equals(previousValue, newValue)) {
      lastSetValue.current = newValue
      setValue<TFieldPath>(name, newValue as any, { shouldDirty: false })
    }
  }

  useWatcher(watcherFunction, { eager: true })
}
