import { once } from '@aubade/core/libs/once' // importing like this fixes a weird import bug
import { makeInputs } from '@aubade/core/ui-kit'
import type { FieldValues, UseFormWatch } from 'react-hook-form'
import {
  useFormContext,
  useWatch as useRHFWatch,
  useFormState as useRHFFormState,
} from 'react-hook-form'

import { useFormSelector, useWatcher } from './libs'

function useWatchUntyped(name?: any) {
  if (!name) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useRHFWatch() as any
  }
  if (typeof name === 'function') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useWatcher<any>(name) as any
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useRHFWatch<any>({ name }) as any
}

export const makeForm = once(function makeForm<
  T extends FieldValues,
  Props extends keyof T = never,
>() {
  type InnerT = Pick<T, Props> // easy way to improve "name" resolution performances by limiting the keys

  return {
    Input: makeInputs<T>(), // but keep on providing the complete type for inputs autocompletion
    useWatch: useWatchUntyped as UseFormWatch<InnerT>,
    useWatcher: useWatcher<InnerT>,
    useFormState: useRHFFormState<InnerT>,
    useFormContext: useFormContext<InnerT>,
    useFormSelector<U>(...args: Parameters<typeof useFormSelector<InnerT, U>>) {
      return useFormSelector(...args)
    },
    fieldValues: {} as InnerT,
  }
})
