import type { FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'

type Elevation = 0 | 1 | 2

export type SurfaceProps = Pick<
  FlexProps,
  | 'padding'
  | 'paddingX'
  | 'paddingTop'
  | 'paddingY'
  | 'direction'
  | 'alignItems'
  | 'justifyContent'
  | 'width'
  | 'height'
  | 'minHeight'
  | 'gap'
  | 'maxWidth'
  | 'overflowY'
  | 'transform'
  | 'maxHeight'
  | 'flex'
  | 'position'
  | 'overflow'
> & {
  elevation?: Elevation
  tint?: 'error' | 'orange' | 'success' | 'teal'
}

export function Surface(props: PropsWithChildren<SurfaceProps>) {
  const { children, elevation, tint, ...rest } = props

  return (
    <Flex {...surfaceStyle(elevation, tint)} {...rest}>
      {children}
    </Flex>
  )
}

const ElevationStyles = {
  0: { backgroundColor: 'white.500', borderRadius: '30px', border: 0 },
  1: {
    backgroundColor: 'white.500',
    borderRadius: '20px',
    border: 0,
    overflow: 'hidden',
    padding: 0,
  },
  2: {
    backgroundColor: 'white.500',
    borderRadius: 'lg',
  },
} satisfies Record<Elevation, FlexProps>

export function surfaceStyle(
  elevation: Elevation = 0,
  tint: SurfaceProps['tint'] = undefined,
) {
  const elevationStyle = ElevationStyles[elevation]
  if (!tint) {
    return {
      padding: 5,
      width: 'full',
      background: ' white.500',
      ...elevationStyle,
    } satisfies FlexProps
  }
  return {
    padding: 5,
    width: 'full',
    ...elevationStyle,
    backgroundColor: `${tint}.alpha10`,
    borderColor: `${tint}.alpha50`,
  } satisfies FlexProps
}
