import type { LogicalFilter } from '@aubade/core/adapters'
import { useList } from '@aubade/core/adapters'
import { compactObj, isEmpty } from '@aubade/core/libs'
import { unit, Form } from '@aubade/core/ui-kit'
import { VStack, useId } from '@chakra-ui/react'

import { differenceInDays, lastDayOfYear, startOfYear } from 'date-fns'

import { useForm } from 'react-hook-form'
import { cleanValues } from 'src/libs/cleanValues'

import {
  ClickSection,
  ExportSection,
  FiltersSection,
  GraphSection,
  MessageSection,
} from './Components'
import type { StatisticsFiltersType } from './Filters/index'
import { useStatisticsFilters } from './Filters/index'
import type { TemporaryStatSummary } from './types'

const defaultFilters: Partial<StatisticsFiltersType> = {
  date: {
    startDate: startOfYear(new Date()),
    endDate: lastDayOfYear(new Date()),
  },
  displayType: 'day',
  displayMessageType: 'all',
}

export function Statistics() {
  const { crudFilters, filtersStore } = useStatisticsFilters(defaultFilters)

  const methods = useForm<StatisticsFiltersType>({
    defaultValues: filtersStore.state,
  })
  const formId = useId()
  const { handleSubmit, setValue } = methods
  const onFilterChange = handleSubmit(() => {
    transformValues(methods.getValues(), setValue)

    // @ts-expect-error
    filtersStore.setState(cleanValues(methods.getValues()))
  })
  return (
    <VStack
      width="full"
      backgroundColor="lightGrey.500"
      paddingX={unit('10')}
      gap={unit('10')}
    >
      <Form {...methods} onSubmit={onFilterChange} id={formId}>
        <ExportSection />
        <FiltersSection
          onChange={onFilterChange}
          filterState={filtersStore.state}
        />
        <GraphSection
          onChange={onFilterChange}
          crudFilters={crudFilters}
          filterState={filtersStore.state}
        />
        <ClickSection crudFilters={crudFilters} />
        <MessageSection
          crudFilters={crudFilters}
          onChange={onFilterChange}
          state={filtersStore.state}
        />
      </Form>
    </VStack>
  )
}

function transformValues(values: StatisticsFiltersType, setValue: any) {
  const { date, displayType, societies } = values
  if (!date || !date.startDate || !date.endDate || !displayType) {
    return
  }

  if (
    differenceInDays(new Date(date.endDate), new Date(date.startDate)) > 30 &&
    displayType === 'day'
  ) {
    setValue('displayType', 'month')
  }
  if (
    differenceInDays(new Date(date.endDate), new Date(date.startDate)) <= 30 &&
    displayType === 'month'
  ) {
    setValue('displayType', 'day')
  }

  if (
    societies &&
    !isEmpty(cleanValues(compactObj(societies))) &&
    displayType === 'society'
  ) {
    setValue('displayType', 'agency')
  }
  if (
    !societies ||
    (isEmpty(cleanValues(compactObj(societies ?? {}))) &&
      displayType === 'agency')
  ) {
    setValue('displayType', 'society')
  }
}

export function useStatList(
  crudFilters: LogicalFilter<TemporaryStatSummary>[],
) {
  const [stats] = useList<TemporaryStatSummary>({
    dataProviderName: 'default',
    resource: 'statistics',
    filters: crudFilters,
  })
  return stats
}
