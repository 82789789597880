import { equals } from 'remeda'

import type { Merge } from './utilityTypes'

type SetOverrides<T> = {
  withAdded: (val: T) => ImmutableSet<T>
  withDeleted: (val: T) => ImmutableSet<T>
  map: <U>(transformFn: (value: T, index: number) => U) => ImmutableSet<U>
  chain: <U>(transformFn: (values: T[]) => U[]) => ImmutableSet<U>
}

export type ImmutableSet<T> = Merge<Set<T>, SetOverrides<T>>

export function createImmutableSet<T>(vals?: T[]): ImmutableSet<T> {
  const set = new Set(vals)
  const instance: ImmutableSet<T> = Object.assign(set, {
    withAdded(val: T) {
      if (set.has(val)) return instance
      return createImmutableSet([...set.values(), val])
    },
    withDeleted(val: T) {
      if (!set.has(val)) return instance
      return createImmutableSet(
        [...set.values()].filter((item) => !equals(item, val)),
      )
    },
    map<U>(transformFn: (value: T, index: number) => U) {
      return createImmutableSet(Array.from(set.values()).map(transformFn))
    },
    chain<U>(transformFn: (values: T[]) => U[]) {
      return createImmutableSet(transformFn(Array.from(set.values())))
    },
  } satisfies SetOverrides<T>)

  return instance
}

export type TImmutableMap<K, V> = Omit<Map<K, V>, 'set' | 'delete'> & {
  withSetted(key: K, value: V): TImmutableMap<K, V>
  withDeleted(key: K): TImmutableMap<K, V>
}

export function ImmutableMap<K, V>(
  values: Map<K, V> = new Map(),
): TImmutableMap<K, V> {
  const instance = Object.assign(values, {
    withSetted(key: K, value: V) {
      const isNoOp = values.has(key) && values.get(key) === value
      if (isNoOp) return instance

      const copy = new Map(values)
      copy.set(key, value)
      return ImmutableMap(copy)
    },
    withDeleted(key: K) {
      const isNoOp = !values.has(key)
      if (isNoOp) return instance

      const copy = new Map(values)
      copy.delete(key)
      return ImmutableMap(copy)
    },
  })

  return instance
}
