import { useEvent } from '@aubade/core/libs'
import { useEffect } from 'react'
import type { WatchObserver, FieldValues } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'

type UseWatcherOptions = {
  eager?: boolean
}
export function useWatcher<T extends FieldValues>(
  callback: WatchObserver<T>,
  options?: UseWatcherOptions,
) {
  const { eager } = options ?? {}
  callback = useEvent(callback)

  const { watch, getValues } = useFormContext<T>()

  return useEffect(() => {
    if (eager) {
      // eslint-disable-next-line node/no-callback-literal
      callback(getValues() as any, {
        name: undefined,
        type: 'load',
      })
    }
    const { unsubscribe } = watch(callback as any, undefined) as any
    return unsubscribe
  }, [callback, eager, getValues, watch])
}
