import type { BaseRecord } from '@aubade/core/adapters'
import { forwardRef } from 'react'
import type { ForwardedRef, ReactNode } from 'react'

import type { Merge } from '../../../libs'
import type { PickerProps } from '../Picker'
import { Picker } from '../Picker'

import type { DataPickerOptionsProps } from './Options'
import { DataPickerRenderOption, DataPickerOptions } from './Options'
import type { DataPickerValuesProps } from './Values'
import { DataPickerValues } from './Values'

export * from './Values'
export * from './Options'

export type DataPickerProps<
  Option extends BaseRecord,
  Value extends BaseRecord = Option,
> = Merge<
  // omitting transformer for now, typings are broken
  Omit<
    PickerProps<Option['@id']>,
    'renderOptions' | 'renderValues' | 'transformer'
  >,
  DataPickerOptionsProps<Option> & DataPickerValuesProps<Value>
>
const DataPickerImpl = forwardRef(function DataPickerImpl<
  Option extends BaseRecord,
  Value extends BaseRecord = Option,
>(props: DataPickerProps<Option, Value>, ref: ForwardedRef<HTMLInputElement>) {
  const { getQuery, renderOption, renderValue, ...pickerProps } = props

  return (
    // @ts-ignore
    <Picker
      ref={ref as any}
      {...pickerProps}
      renderOptions={(renderParams) => {
        return (
          <DataPickerOptions<Option>
            {...renderParams}
            getQuery={getQuery}
            renderOption={renderOption}
          />
        )
      }}
      renderValues={(renderParams) => {
        return (
          <DataPickerValues<Value>
            {...renderParams}
            renderValue={renderValue}
          />
        )
      }}
    />
  )
})

export const DataPicker = Object.assign(
  DataPickerImpl as <
    Option extends BaseRecord,
    Value extends BaseRecord = Option,
  >(
    props: DataPickerProps<Option, Value>,
  ) => ReactNode,
  {
    Option: Picker.Option,
    RenderOption: DataPickerRenderOption,
  },
)
