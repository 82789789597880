import { unit } from '@aubade/core/ui-kit'
import { IconRecherche } from '@aubade/design/graphics'
import { useTranslate } from '@aubade/translation'
import {
  InputGroup,
  InputLeftElement,
  Input,
  Icon,
  Stack,
} from '@chakra-ui/react'
import { useMemo, type ChangeEventHandler, useState } from 'react'

import { StringParam } from 'use-query-params'

import { useQueryParamState } from '../../../libs/useParams'

type SearchBarParams = {
  scope: string
  placeholder?: string
  variant?: 'search'
}
export function SearchBar(params: SearchBarParams) {
  const { scope, placeholder, variant } = params

  const paramName = useMemo(() => `${scope}.q`, [scope])
  const [queryValue, setQueryValue] = useQueryParamState(paramName, StringParam)

  const value = queryValue || ''

  const translate = useTranslate()

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = event.target.value
    if (newValue) {
      setLocaleValue(newValue)
      setQueryValue(newValue)

      return
    }
    setLocaleValue('')
    setQueryValue(undefined)
  }

  const [localeValue, setLocaleValue] = useState<string>(value)

  return (
    <Stack spacing={4} width="100%">
      <InputGroup width={'full'}>
        <InputLeftElement
          pointerEvents="none"
          right="auto"
          left={5}
          backgroundColor={'transparent'}
        >
          <Icon
            as={IconRecherche}
            width={unit('15')}
            height={unit('15')}
            color="grey.500"
            backgroundColor="transparent"
          />
        </InputLeftElement>

        <Input
          variant={variant}
          placeholder={placeholder ?? translate('typesense.placeholder')}
          value={localeValue}
          onChange={onChange}
          borderRadius="25px"
          border="none"
          paddingLeft={14}
          paddingRight={14}
        />
      </InputGroup>
    </Stack>
  )
}
