import { Paragraph, Title } from '@aubade/core/ui-kit'
import type { As, FlexProps } from '@chakra-ui/react'
import { Flex, Icon } from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'
import { forwardRef } from 'react'
import type { To } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { surfaceStyle } from './Surface'

type Props = FlexProps & {
  to?: To
}

export const Card = Object.assign(
  forwardRef<HTMLDivElement, PropsWithChildren<Props>>(function Card(
    props,
    ref,
  ) {
    const { children, to, ...restProps } = props

    const flexProps: FlexProps = {
      ...surfaceStyle(),
      direction: 'column',
      gap: 6,
      alignItems: 'flex-start',
      ...restProps,
    }

    if (to) {
      return (
        <Flex {...flexProps} as={Link} to={to} ref={() => ref}>
          {children}
        </Flex>
      )
    }

    return (
      <Flex {...flexProps} ref={ref}>
        {children}
      </Flex>
    )
  }),
  {
    Item: CardItem,
  },
)

type CardItemProps = {
  variant: 'dimmed' | 'normal' | 'title'
  icon: As
}
function CardItem(props: PropsWithChildren<CardItemProps>) {
  const { variant, icon, children } = props

  if (!children) return null
  return (
    <Flex
      align={'center'}
      gap={1}
      color={variant === 'dimmed' ? 'white.500' : 'greyscale.900'}
    >
      <Icon
        as={icon}
        flex={0}
        minWidth={4}
        color={variant === 'dimmed' ? 'inherit' : 'primary.500'}
      />
      {variant === 'title' ? (
        <Title.H6 fontWeight="normal">{children}</Title.H6>
      ) : (
        <Paragraph size="sm" fontWeight="normal" color="inherit">
          {children}
        </Paragraph>
      )}
    </Flex>
  )
}
