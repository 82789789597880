import type { History } from 'history'
import type { PropsWithChildren } from 'react'
import { useState, useLayoutEffect } from 'react'
import type { BrowserRouterProps } from 'react-router-dom'
import { Router, useLocation, useNavigate } from 'react-router-dom'
import { type QueryParamAdapter } from 'use-query-params'

import { HistoryProvider } from '../adapters/History'

// usePrompt and useBlocker removed on V6
// Related issue (open): https://github.com/remix-run/react-router/issues/8139

type HistoryRouterProps = BrowserRouterProps & {
  history: History
}

export function HistoryRouter(props: PropsWithChildren<HistoryRouterProps>) {
  const { history, children } = props
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  useLayoutEffect(() => {
    const unlisten = history.listen(setState)
    return unlisten
  }, [history])

  return (
    <HistoryProvider value={history}>
      <Router
        {...props}
        location={state.location}
        navigationType={state.action}
        navigator={history}
      >
        {children}
      </Router>
    </HistoryProvider>
  )
}

export function HistoryRouterAdapter(props: {
  children: (adapter: QueryParamAdapter) => React.ReactElement | null
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const adapter = {
    location,
    replace(location2: any) {
      navigate(location2.search || '?', {
        replace: true,
        state: location2.state,
      })
    },
    push(location2: any) {
      navigate(location2.search || '?', {
        replace: false,
        state: location2.state,
      })
    },
  }
  return props.children(adapter)
}
