import type {
  DataProvider as IDataProvider,
  BaseRecord,
} from '@pankod/refine-core'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { axiosRequestBuilder } from './requestBuilder'
import type {
  CreateManyParams,
  CreateParams,
  CustomParams,
  DeleteManyParams,
  DeleteOneParams,
  GetListParams,
  GetOneParams,
  HydraCollection,
  UpdateManyParams,
  UpdateParams,
} from './types'

export * from './types'
export * from './hydraId'
export { buildFilters } from './buildFilters'
export * from './requestBuilder'

type DataProviderConfig = {
  httpClient: AxiosInstance
  apiUrl: string
}

export function DataProvider(config: DataProviderConfig): IDataProvider {
  const { httpClient, apiUrl } = config

  function request(requestConfig: AxiosRequestConfig) {
    return httpClient.request({
      baseURL: apiUrl,
      ...requestConfig,
    })
  }

  const provider: IDataProvider = {
    async create<T extends Record<string, any>>(options: CreateParams) {
      const response = await request(axiosRequestBuilder.create(options))
      return { data: response.data as unknown as T }
    },

    async createMany<T extends Record<string, any>>(options: CreateManyParams) {
      const { variables } = options
      const responses = await Promise.all(
        variables.map((values) =>
          provider.create<T>({ ...options, variables: values as any }),
        ),
      )

      return { data: responses.map((x) => x.data) }
    },

    async getList<T extends Record<string, any>>(options: GetListParams) {
      const { data } = await request(axiosRequestBuilder.getList(options))
      return processListResponse<T>(data)
    },

    async getOne<T extends Record<string, any>>(options: GetOneParams) {
      const { data } = await request(axiosRequestBuilder.getOne(options))
      return { data: data as unknown as T }
    },

    async getMany(options) {
      const { ids } = options

      const responses = await Promise.all(
        ids.map((id) => provider.getOne({ ...options, id })),
      )
      const data = responses.map((x) => x.data as any).filter(Boolean)
      return { data, total: data.length }
    },

    async update<T extends Record<string, any>>(options: UpdateParams) {
      const { variables } = options
      const response = await request(
        axiosRequestBuilder.update({
          ...options,
          variables: { ...(variables as any), q: undefined },
        }),
      )
      return { data: response.data as unknown as T }
    },

    async updateMany<T extends Record<string, any>>(options: UpdateManyParams) {
      const { ids } = options

      const responses = await Promise.all(
        ids.map((id) =>
          provider.update<T>({
            ...options,
            id,
            variables: options.variables as any,
          }),
        ),
      )
      return { data: responses.map((res) => res.data) }
    },

    async deleteOne<T extends Record<string, any>>(options: DeleteOneParams) {
      const { data } = await request(axiosRequestBuilder.delete(options))
      return {
        data: data as unknown as T,
      }
    },

    async deleteMany<T extends Record<string, any>>(options: DeleteManyParams) {
      const { ids, variables } = options

      const responses = await Promise.all(
        ids.map((id) =>
          provider.deleteOne<T>({
            ...options,
            variables: variables as any,
            id,
          }),
        ),
      )

      return {
        data: responses
          .map((deleteResult) => deleteResult?.data)
          .filter(Boolean),
      }
    },

    async custom(options: CustomParams) {
      const { data } = await request(axiosRequestBuilder.custom(options))
      return { data }
    },

    getApiUrl() {
      return apiUrl
    },
  }

  return provider
}

export function processListResponse<T extends BaseRecord>(
  response: HydraCollection,
) {
  return {
    data: response['hydra:member'] as unknown as T[],
    total: response['hydra:totalItems'],
  }
}
