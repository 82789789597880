import { useMakeTest } from 'src/adapters/useVestTest'
import { create, enforce, omitWhen } from 'vest'

import type { ExtendedTool } from '../types'

export function useFormValidation() {
  const test = useMakeTest<ExtendedTool>()
  return create(function (data: ExtendedTool) {
    const {
      backgroundColor,
      toolCategory,
      url,
      lineOne,
      lineTwo,
      template,
      document,
    } = data
    omitWhen(template && template === 'logo', function () {
      test('lineOne', 'tools.lineOne.required', function () {
        enforce(lineOne).isNotBlank()
      })
      test('lineTwo', 'tools.lineTwo.required', function () {
        enforce(lineTwo).isNotBlank()
      })
    })
    omitWhen(template && template === 'text', function () {
      test('document', 'tools.document.required', function () {
        enforce(document).isNotUndefined()
      })
    })
    test('backgroundColor', 'tools.backgroundColor.required', function () {
      enforce(backgroundColor).isNotBlank()
    })
    test('toolCategory', 'tools.toolCategory.required', function () {
      enforce(toolCategory).isNotBlank()
    })
    test('url', 'tools.url.required', function () {
      enforce(url).isNotBlank()
    })
    test('url', 'tools.url.matchRegex', function () {
      enforce(url).matches(/^(http:\/\/|https:\/\/)/)
    })
  })
}
