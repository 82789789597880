import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import type { ReactNode } from 'react'

import type { Merge } from '../../../libs'
import type { Option } from '../types'

import type { RenderSuggestOptionProps } from './optionsController'
import { usePickerOption } from './optionsController'

export type SuggestOptionProps = Merge<
  Option,
  {
    onClick?: () => void | Promise<void>
  }
>

type Props = SuggestOptionProps & {
  children: ReactNode | ((params: RenderSuggestOptionProps) => ReactNode)
}
export function SuggestOption(props: Props): ReactNode {
  const { children, ...option } = props

  const { optionState, optionProps } = usePickerOption(option)

  if (typeof children === 'function') {
    return <>{children({ optionState, optionProps })}</>
  }

  const state: SuggestOptionState = optionState.isHighlighted
    ? 'highlighted'
    : 'idle'
  const style = styles[state]

  return (
    <Box
      {...(optionProps as any)}
      // _hover={styles.highlighted}
      textAlign={'left'}
      width={'full'}
      borderRadius={'md'}
      // padding={1}
      // margin={-1}
      {...style}
    >
      {children}
    </Box>
  )
}

type SuggestOptionState = 'highlighted' | 'idle'

const styles = {
  highlighted: {
    boxShadow: 'var(--chakra-shadows-outline)',
  },
  idle: {},
} satisfies Record<SuggestOptionState, BoxProps>
