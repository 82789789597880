import { Button, Paragraph, unit } from '@aubade/core/ui-kit'
import { IconDownload } from '@aubade/design/graphics'
import { useTranslate } from '@aubade/translation'
import type { ExportUsersEventExportUser } from '@aubade/types/api'
import { Box, HStack, VStack } from '@chakra-ui/react'
import { useExport } from '@pankod/refine-core'

type ExportCardProps = {
  resource: 'customers' | 'associates'
  count: number
}
export function ExportCard(props: ExportCardProps) {
  const { resource, count } = props

  const translate = useTranslate()
  const fileNameResource =
    resource === 'associates' ? 'collaborateurs' : 'clients'
  const fileName = `statistiques ${fileNameResource}-${new Date().toLocaleDateString(
    'fr',
  )}`

  const { triggerExport, isLoading } =
    useExport<ExportUsersEventExportUser.Read>({
      resourceName: `export/${resource}`,
      exportOptions: {
        filename: fileName,
      },
      mapData(item) {
        return mapData(item, translate, resource)
      },
    })
  return (
    <HStack
      width="full"
      justifyContent={'space-around'}
      alignItems={'center'}
      paddingY={unit('40')}
      paddingX={unit('60')}
      borderRadius={'30px'}
      backgroundColor="white"
    >
      <VStack gap={0} alignItems={'flex-start'}>
        <Paragraph size="3xl" text={String(count)} fontWeight="bold" />
        <Paragraph size="sm" text={`export.subtitile.${resource}`} />
      </VStack>
      <Box width="1px" height="65px" backgroundColor={'darkGrey.alpha10'} />

      <Button
        onClick={triggerExport}
        leftIcon={IconDownload}
        isLoading={isLoading}
        variant="circle"
        label="actions.export"
      />
    </HStack>
  )
}

function mapData(
  item: ExportUsersEventExportUser.Read,
  translate: (key: string) => string,
  resource: 'customers' | 'associates',
) {
  const prefix = resource === 'associates' ? 'ROLE' : 'CRAFTER'
  const singleRole =
    item.roles && item?.roles.find((role: string) => role?.startsWith(prefix))

  const translatedRole = translate(singleRole ? `roles.${singleRole}` : '')
  const csvData = {}

  Object.assign(csvData, {
    [`${translate('export.firstname')}`]: item?.firstname ?? '',
  })
  Object.assign(csvData, {
    [`${translate('export.lastname')}`]: item?.lastname ?? '',
  })
  Object.assign(csvData, {
    [`${translate('export.email')}`]: item?.email ?? '',
  })
  Object.assign(csvData, { [`${translate('export.role')}`]: translatedRole })
  Object.assign(csvData, {
    [`${translate('export.societyName')}`]: item?.society ?? '',
  })
  Object.assign(csvData, {
    [`${translate('export.agencyName')}`]: item?.agency ?? '',
  })
  Object.assign(csvData, {
    [`${translate('export.craftCompanyName')}`]: item?.craftCompany ?? '',
  })

  return csvData
}
