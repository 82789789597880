import type { ApiToken } from '@aubade/domain/components'
import type { OidcConfiguration } from '@axa-fr/react-oidc'
import { OidcProvider, TokenRenewMode } from '@axa-fr/react-oidc'
import { Center, Spinner } from '@chakra-ui/react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { type PropsWithChildren } from 'react'
import { useEnv } from 'src/adapters/Env'

import type { EventNames } from './authTypes'

export function Auth(props: PropsWithChildren<{}>) {
  const env = useEnv()
  const { KEYCLOAK_CONFIG, BASE_PATH } = env
  const authority = `${KEYCLOAK_CONFIG.url}/realms/${KEYCLOAK_CONFIG.realm}`
  const oidcConfig: OidcConfiguration = {
    client_id: 'app-crafter-web',
    // service_worker_relative_url: '/bo/OidcServiceWorker.js',
    redirect_uri:
      window.location.origin + `${BASE_PATH}/authentication/callback`,

    silent_redirect_uri:
      window.location.origin + `${BASE_PATH}/authentication/silent-callback`,
    scope: 'openid',
    service_worker_only: false,
    refresh_time_before_tokens_expiration_in_second: 300, // 5 minutes
    service_worker_convert_all_requests_to_cors: true,
    token_renew_mode: TokenRenewMode.access_token_invalid,
    authority: authority,
  }

  const onEvent = async (_configuration: string, name: string, data: any) => {
    const apiToken = sessionStorage.getItem('jwt')
    const keycloakToken = getKeycloakToken()

    if ((name as EventNames) === 'token_aquired') {
      await tryConnect({
        keycloakToken: data.accessToken,
        apiUrl: env.API_URL,
        reload: true,
      })
    }
    if (
      (name as EventNames) === 'refreshTokensAsync_end' &&
      data.success === true
    ) {
      await tryConnect({
        keycloakToken: keycloakToken!,
        apiUrl: env.API_URL,
      })
    }
    if (
      (name as EventNames) === 'tryKeepExistingSessionAsync_end' &&
      data.success === true
    ) {
      await tryConnect({
        keycloakToken: keycloakToken!,
        apiUrl: env.API_URL,
      })
    }
    if (
      (name as EventNames) === 'tryKeepExistingSessionAsync_end' &&
      data.success === false
    ) {
      sessionStorage.removeItem('oidc.default')
      sessionStorage.removeItem('jwt')
    }
    if ((name as EventNames) === 'token_timer' && isInvalidToken(apiToken)) {
      await tryConnect({
        keycloakToken: keycloakToken!,
        apiUrl: env.API_URL,
        reload: true,
      })
    }
  }

  return (
    <>
      <OidcProvider
        configuration={oidcConfig}
        loadingComponent={KeycloakSpinner}
        authenticatingComponent={KeycloakSpinner}
        callbackSuccessComponent={KeycloakSpinner}
        onEvent={onEvent}
      >
        {props.children}
      </OidcProvider>
    </>
  )
}

type LoginProps = {
  keycloakToken: string
  apiUrl: string
  reload?: boolean
}

async function tryConnect(props: LoginProps) {
  const { keycloakToken, apiUrl, reload = false } = props

  try {
    const res = await axios.post(`${apiUrl}/login`, {
      token_keycloack: keycloakToken,
    })
    if (res && res.status === 200 && !res.data.error) {
      sessionStorage.setItem('jwt', res?.data.token)
      if (reload) {
        window.location.reload()
      }
    } else {
      throw Error('Connexion impossible')
    }
  } catch (error) {
    console.log(error)
  }
}

function KeycloakSpinner() {
  return (
    <Center width="100vw" height="100vh">
      <Spinner />
    </Center>
  )
}

export function isInvalidToken(token?: string | null) {
  if (!token || token === null) {
    return true
  }
  const tok = jwt_decode<ApiToken>(token)

  if (tok.exp) {
    const now = new Date()
    const exp = new Date(tok.exp * 1000)
    if (exp < now) {
      return true
    }
  }

  return false
}

function getKeycloakToken() {
  const oidcDefault = sessionStorage.getItem('oidc.default')

  if (oidcDefault === null) {
    return undefined
  }
  return JSON.parse(sessionStorage.getItem('oidc.default')!).tokens?.accessToken
}
