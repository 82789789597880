import type { NumberInputProps as ChakraNumberInputProps } from '@chakra-ui/react'
import { useEffect, type ReactNode } from 'react'
import { useFormContext } from 'react-hook-form'

import type { RHFInputProps } from '../controller'
import { useRHFInput } from '../controller'

import { NumberInput } from './NumberInput'

export * from './NumberInput'

export type NumberInputProps<T, Outer = number> = RHFInputProps<
  T,
  Outer,
  number
> &
  ChakraNumberInputProps & { iconRight?: ReactNode }

export function RHFNumberInput<T, Out = number>(
  props: NumberInputProps<T, Out>,
) {
  const innerProps = useRHFInput<T, Out, number, number>(props)

  const { setValue } = useFormContext()
  const { value, name } = innerProps
  const { min, max } = props
  useEffect(() => {
    if (!name) return
    if (value == null) return

    let boundedValue = value
    if (min !== undefined) boundedValue = Math.max(min, boundedValue)
    if (max !== undefined) boundedValue = Math.min(max, boundedValue)
    if (boundedValue !== value) {
      setValue(name, boundedValue, { shouldDirty: false, shouldValidate: true })
    }
  }, [value, min, max, name, setValue])

  return <NumberInput {...innerProps} />
}
