import { useMemoOne } from 'use-memo-one'

import 'requestidlecallback-polyfill'
import { useEvent } from '../libs'

export function useLowPriorityCallback<T extends (...args: any[]) => void>(
  callback: T,
): T {
  callback = useEvent(callback)
  return useMemoOne(() => {
    return debounceIdleCallback(callback, 200)
  }, [callback])
}

export function debounceIdleCallback<T extends (...args: any[]) => void>(
  callback: T,
  maxWait: number,
) {
  let idleId: number | null = null
  return ((...args) => {
    if (idleId) cancelIdleCallback(idleId)

    idleId = requestIdleCallback(
      () => {
        // eslint-disable-next-line node/no-callback-literal
        callback(...args)
      },
      { timeout: maxWait },
    )
  }) as typeof callback
}

export function useImmediateCallback<T extends (...args: any[]) => void>(
  callback: T,
): T {
  callback = useEvent(callback)
  return useMemoOne(() => {
    return debounceImmediateCallback(callback)
  }, [callback])
}

export function debounceImmediateCallback<T extends (...args: any[]) => void>(
  callback: T,
) {
  let id = -1
  return ((...args) => {
    id = id + 1
    const callId = id
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Promise.resolve().then(() => {
      if (callId === id) {
        // eslint-disable-next-line node/no-callback-literal
        callback(...args)
      }
    })
  }) as typeof callback
}
