import { listify } from '@aubade/core/libs'
import { Paragraph } from '@aubade/core/ui-kit'
import { useTranslate } from '@aubade/translation'
import type { ExtendedUserConversation } from '@aubade/types'
import { HStack, VStack } from '@chakra-ui/react'
import { Trans } from 'react-i18next'

type Props = {
  contacts: ExtendedUserConversation[]
}

export function PrivateMention(props: Props) {
  const { contacts } = props
  const translate = useTranslate()
  if (!contacts) return <></>
  const multi = contacts.length > 1
  return (
    <HStack gap={'4px'} width="full" justifyContent={'center'}>
      <Trans
        i18nKey="privateMention.private"
        components={{
          paragraph: <Paragraph whiteSpace={'pre'} textAlign={'center'} />,
          span: <span style={{ fontWeight: 'bold', color: '#009EE0' }} />,
        }}
        values={{
          me: translate('privateMention.you'),
          others: multi
            ? translate('privateMention.others')
            : [contacts[0]?.firstname, contacts[0]?.lastname]
                .filter(Boolean)
                .join(' '),
        }}
      />
    </HStack>
  )
}

export function UnavailableUsers(props: Props) {
  const { contacts } = props
  const translate = useTranslate()

  if (!contacts) return <></>

  const unavailableContacts = contacts.filter(
    (contact) => contact.isAvailable === false,
  )
  if (unavailableContacts.length === 0) return <></>
  const fullNames = unavailableContacts.map((unavailableContact) => {
    return [unavailableContact.firstname, unavailableContact.lastname]
      .filter(Boolean)
      .join(' ')
  })

  return (
    <VStack width="full" gap={0}>
      <Trans
        i18nKey="privateMention.users"
        count={fullNames.length}
        components={{
          paragraph: <Paragraph textAlign={'center'} />,
          span: <span style={{ fontWeight: 'bold', color: '#009EE0' }} />,
        }}
        values={{
          users: listify(fullNames, ', ', ' et '),
        }}
      />

      <Paragraph
        text={translate('privateMention.users.later', {
          count: fullNames.length,
        })}
      />
    </VStack>
  )
}
