import { createContextFunction } from '@aubade/core/libs'

import type { DataProviders } from './types'

export * from './queryBuilder'
export * from './TypesenseDataProvider'
export * from './types'
export * from './utils'

export const [NxDataProvidersProvider, useNxDataProviders] =
  createContextFunction<Partial<DataProviders> | undefined>(
    'nxDataProvider',
    () => undefined,
  )
