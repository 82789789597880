import type { CrudSorting, Pagination } from '@pankod/refine-core'
import type { AxiosRequestConfig } from 'axios'
import { isDate, mapValues } from 'remeda'

import { buildFilters } from './buildFilters'
import { isHydraId } from './hydraId'
import type {
  CreateParams,
  GetOneParams,
  GetListParams,
  UpdateParams,
  DeleteOneParams,
  HydraParams,
  CustomParams,
} from './types'

function toItemUrl(resource: string, id: string | number) {
  if (typeof id === 'string' && isHydraId(id)) {
    return `/${id.split('/').slice(-2).join('/')}`
  }
  return `/${resource}/${id}`
}

function toResourceUrl(resource: string) {
  return `/${resource}`
}

const baseHeaders = { Accept: 'application/ld+json' }

export const axiosRequestBuilder = {
  getOne(options: GetOneParams): AxiosRequestConfig {
    const { id, resource, metaData } = options

    return {
      headers: { ...baseHeaders, ...metaData?.headers },
      method: 'GET',
      url: toItemUrl(resource, id),
    }
  },

  getList(options: GetListParams): AxiosRequestConfig {
    const { resource, pagination, hasPagination, sort, filters, metaData } =
      options

    const params: HydraParams = {
      ...(hasPagination === false ? {} : buildPagination(pagination)),
      ...buildSort(sort ?? []),
      ...buildFilters(filters ?? []),
    }

    return {
      headers: { ...baseHeaders, ...metaData?.headers },
      method: 'GET',
      url: toResourceUrl(resource),
      params,
    }
  },

  create(options: CreateParams): AxiosRequestConfig {
    const { resource, variables, metaData } = options

    return {
      headers: { ...baseHeaders, ...metaData?.headers },
      method: 'POST',
      url: toResourceUrl(resource),
      data: clearIds(variables),
    }
  },

  update(options: UpdateParams): AxiosRequestConfig {
    const { resource, id, variables, metaData } = options

    return {
      headers: { ...baseHeaders, ...metaData?.headers },
      method: 'PUT',
      url: toItemUrl(resource, id),
      data: clearIds(variables),
    }
  },

  delete(options: DeleteOneParams): AxiosRequestConfig {
    const { id, resource, metaData } = options

    return {
      headers: { ...baseHeaders, ...metaData?.headers },
      method: 'DELETE',
      url: id ? toItemUrl(resource, id) : toResourceUrl(resource),
    }
  },

  custom(options: CustomParams): AxiosRequestConfig {
    const {
      url,
      method,
      filters,
      headers,
      payload,
      query,
      sort,
      metaData,
      pagination,
      hasPagination = pagination !== undefined,
    } = options

    return {
      url,
      method: method,
      headers: { ...baseHeaders, ...metaData?.headers, ...headers },
      params: {
        // the implementation of hasPagination is slightly different from getList, to prevent breaking changes
        ...(hasPagination ? buildPagination(pagination) : {}),
        ...buildSort(sort ?? []),
        ...buildFilters(filters ?? []),
        ...(query as any),
      },
      data: payload,
    }
  },
}

export function buildPagination(pagination: Pagination | undefined) {
  return {
    page: pagination?.current ?? 1,
    itemsPerPage: pagination?.pageSize ?? 25,
  }
}

export function buildSort(sortParams: CrudSorting) {
  const result: Record<string, string> = {}
  sortParams.forEach((sort) => {
    result[`order[${sort?.field}]`] = sort?.order ?? 'asc'
  })

  return result
}

function clearIds(data: unknown): unknown {
  if (!data) return data
  if (typeof data !== 'object' || data === null) return data

  if (isDate(data)) return data

  if (Array.isArray(data)) {
    return data.map((value) => clearIds(value))
  }

  if (
    'id' in data &&
    '@id' in data &&
    (data as object & Record<'id', unknown> & Record<'@id', unknown>)['@id'] !==
      undefined
  ) {
    data = { ...data, id: undefined }
  }
  return mapValues(data as any, (value) => clearIds(value))
}
