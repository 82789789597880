// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconPublications = (
  props: SVGProps<SVGSVGElement> & { slot?: string },
) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2C0 0.895429 0.895431 0 2 0H48C49.1046 0 50 0.895431 50 2V31.3333C50 32.4379 49.1046 33.3333 48 33.3333H2C0.895432 33.3333 0 32.4379 0 31.3333V2ZM0 38.1111C0 37.0065 0.895431 36.1111 2 36.1111H48C49.1046 36.1111 50 37.0065 50 38.1111V39.6667C50 40.7712 49.1046 41.6667 48 41.6667H2C0.895432 41.6667 0 40.7712 0 39.6667V38.1111ZM2 44.4444C0.895431 44.4444 0 45.3399 0 46.4444V48C0 49.1046 0.895429 50 2 50H31.3333C32.4379 50 33.3333 49.1046 33.3333 48V46.4444C33.3333 45.3399 32.4379 44.4444 31.3333 44.4444H2Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconPublications)
export default Memo
