import { useGetLocale } from '@pankod/refine-core'
import { enUS, fr, es, de, it } from 'date-fns/locale'

export function useDateFnsLocale() {
  const getLocale = useGetLocale()
  const locale = getLocale()

  if (locale === 'fr') return fr
  if (locale === 'es') return es
  if (locale === 'de') return de
  if (locale === 'it') return it
  return enUS
}
