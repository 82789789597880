import { useTranslate, type StrictTranslationKey } from '@aubade/translation'
import { Flex, Wrap, WrapItem } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import type { To } from 'react-router'

import { type BaseRecord } from '../../../adapters'
import { isReactNode } from '../../../libs'
import { Button, Paragraph, QueryOne, getButtonBehavior } from '../../../ui-kit'
import type { PickerRenderValuesProps } from '../Picker'

export type DataPickerValuesProps<T extends BaseRecord> = {
  renderValue: (value: T) => ReactNode | DataPickerValueProps
}

type Props<T extends BaseRecord> = PickerRenderValuesProps<T['@id']> &
  DataPickerValuesProps<T>
export function DataPickerValues<T extends BaseRecord>(props: Props<T>) {
  const { renderValue, values, onRemove } = props

  const valuesArray = Array.from(values.values())

  return (
    <Wrap spacing={3} width={'full'} justify={'center'}>
      {valuesArray.map((value) => {
        return (
          <WrapItem
            {...getButtonBehavior({ onClick: () => onRemove(value) })}
            aria-label={'actions.remove'}
            display="flex"
            key={value}
            gap={2}
            alignItems={'center'}
            width="full"
          >
            <QueryOne<T>
              iri={value}
              children={(record) => {
                const result = renderValue(record)
                if (isReactNode(result)) return <>{result}</>
                else return <DataPickerValue {...result} />
              }}
            />
          </WrapItem>
        )
      })}
    </Wrap>
  )
}

type DataPickerValueProps = {
  to?: To
  children?: ReactNode
  label: StrictTranslationKey
}
export function DataPickerValue(props: DataPickerValueProps) {
  const { children, to, label } = props

  const translate = useTranslate()
  return (
    <Flex as={'span'} flexWrap={'wrap'} gap={'0 1ch'}>
      <Paragraph size="sm" fontWeight="bold">
        {translate(label)} :{' '}
      </Paragraph>
      <Button variant={'text'} to={to}>
        {children}
      </Button>
    </Flex>
  )
}
