import { useTranslate } from '@aubade/translation'
import { differenceInMinutes, format } from 'date-fns'
import { fr } from 'date-fns/locale'

export function useGetTimeDiff(
  lastDate: Date,
  referenceDate: Date = new Date(),
): { diff?: number | string; text: string } {
  const translate = useTranslate()
  const timeDiff = differenceInMinutes(referenceDate, lastDate)
  if (timeDiff < 1) return { diff: '', text: translate('feed.timediff.now') }
  if (timeDiff === 1)
    return { diff: timeDiff, text: translate('feed.timediff.minute') }
  if (timeDiff < 60)
    return { diff: timeDiff, text: translate('feed.timediff.minutes') }
  if (timeDiff >= 60 && timeDiff <= 120)
    return {
      diff: Math.round(timeDiff / 60),
      text: translate('feed.timediff.hour'),
    }
  if (timeDiff < 60 * 24) {
    return {
      diff: Math.round(timeDiff / 60),
      text: translate('feed.timediff.hours'),
    }
  }
  if (timeDiff >= 60 * 24 && timeDiff <= 120 * 24) {
    return {
      diff: Math.round(timeDiff / (60 * 24)),
      text: translate('feed.timediff.day'),
    }
  }
  if (timeDiff < 60 * 24 * 6) {
    return {
      diff: Math.round(timeDiff / (60 * 24)),
      text: translate('feed.timediff.days'),
    }
  }
  return { diff: format(lastDate, 'dd LLLL yyyy', { locale: fr }), text: '' }
}
