export type Falsy = false | 0 | '' | null | undefined

/* Type guard to filter falsy values out of arrays.
 * It's a simple wrapper around `Boolean`, which is not a type guard
 *
 * ex:
 * const values = ["A value", false | 0 | '' | null | undefined] as const
 * typeof values
 * // => ("A value" | false | 0 | '' | null | undefined)[]
 *
 * typeof values.filter(truthy)
 * // => ("A value")[]
 */

export function isTruthy<T>(x: T | Falsy): x is T {
  return Boolean(x)
}

export function entryIsTruthy<K, V>(entry: [K, V | Falsy]): entry is [K, V] {
  return Boolean(entry[1])
}
