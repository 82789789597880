import { makeForm } from '@aubade/core/ui-kit'

import type { NotificationFormType } from '../types'

const { Input, useWatch } = makeForm<
  NotificationFormType,
  'planify' | 'publishedAt'
>()

export function PlanifySection() {
  const planify = useWatch('planify')

  return (
    <>
      <Input.Checkbox
        name="planify"
        label="notifications.fields.planify"
        inputLabel="notifications.title.planify"
      />

      {planify && (
        <Input.DateTime
          name="publishedAt"
          label="notifications.fields.publishedAt"
          list
          min
        />
      )}
    </>
  )
}
