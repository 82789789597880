import type { SearchClient as AlgoliaSearchClient } from 'algoliasearch'
import type { PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'
import type { SearchClient } from 'typesense'
import { useMemoOne } from 'use-memo-one'

import type { SearchClientArgs } from './searchClient'
import { searchClient } from './searchClient'

const Context = createContext<(SearchClient & AlgoliaSearchClient) | undefined>(
  undefined,
)

export function useSearchClient() {
  const resolved = useContext(Context)
  if (!resolved) {
    throw new Error('No Provider for context "SearchClient"')
  }
  return resolved
}

export function SearchClientProvider<Collections extends string = string>(
  props: PropsWithChildren<SearchClientArgs<Collections>>,
) {
  const { children, ...searchClientProps } = props
  const typesenseSearchClient = useMemoOne(
    () => searchClient(searchClientProps),
    [searchClientProps],
  )

  return (
    <Context.Provider value={typesenseSearchClient}>
      {children}
    </Context.Provider>
  )
}
