// inspired from https://usehooks.com/useOnClickOutside/

import type { PropsWithChildren, ReactNode } from 'react'
import {
  useCallback,
  useEffect,
  useId,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useEvent } from './useEvent'

type UseClickOutsideWrapperOptions = {
  ignoredElements?: (HTMLElement | null)[]
}
export function useOnClickOutside(
  listener: (event: unknown) => void,
  options?: UseClickOutsideWrapperOptions,
) {
  const onDismiss = useEvent(listener)
  const ignoredElements = useMemo(() => {
    return options?.ignoredElements
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(options?.ignoredElements ?? [])])

  const [stackIndex, onSetStackIndex] = useState(() => overlaysStack.length)

  return [
    { stackIndex, zIndex: stackIndex * 1000 },
    useCallback(
      function withClickOutsideListener(children: ReactNode) {
        return (
          <ClickOutsideListener
            onDismiss={onDismiss}
            onSetStackIndex={onSetStackIndex}
            ignoredElements={ignoredElements}
          >
            {children}
          </ClickOutsideListener>
        )
      },
      [onDismiss, ignoredElements],
    ),
  ] as const
}

export function useLayerIndex() {
  // gets layer index only on mount
  // it's not really robust
  // it may be needed to change this for something more robust sometimes
  const [stackIndex] = useState(() => overlaysStack.length)

  return { stackIndex, zIndex: stackIndex * 1000 }
}

// a global state here should not be so bad, otherwise some context could help too
const overlaysStack: string[] = []

// This must be a component and not a hook, because we must detect when this specific piece of UI is mounted to push it on the global overlaysStack
type ClickOutsideListenerProps = {
  onDismiss: (event: unknown) => void
  ignoredElements?: (HTMLElement | null)[]
  onSetStackIndex: (stackIndex: number) => void
}
function ClickOutsideListener(
  props: PropsWithChildren<ClickOutsideListenerProps>,
) {
  const { onDismiss, children, ignoredElements, onSetStackIndex } = props
  const setStackIndex = useEvent(onSetStackIndex)

  const ownId = useId()
  useLayoutEffect(() => {
    overlaysStack.push(ownId)

    // The following part could break:
    // if overlaysStack is modified : the zIndex state could be wrong
    setStackIndex(overlaysStack.length)

    return () => {
      overlaysStack.splice(overlaysStack.indexOf(ownId), 1)
    }
  }, [ownId, setStackIndex])

  const stableHandler = useEvent((event: unknown) => {
    const isLastInStack = overlaysStack.at(-1) === ownId
    if (isLastInStack) {
      onDismiss(event)
    }
  })

  const ref = useRef<HTMLDivElement>(null)
  const clickListener = useEvent((event: any) => {
    // Do nothing if clicking ref's element or descendent elements
    if (!ref.current || ref.current.contains(event.target)) {
      return
    }

    if (ignoredElements?.some((element) => element?.contains(event.target))) {
      return
    }

    stableHandler(event)
  })

  useEffect(
    () => {
      const options = { capture: true }
      document.addEventListener('mousedown', clickListener, options)
      document.addEventListener('touchstart', clickListener, options)
      document.addEventListener('focusin', clickListener, options)
      return () => {
        document.removeEventListener('mousedown', clickListener, options)
        document.removeEventListener('touchstart', clickListener, options)
        document.removeEventListener('focusin', clickListener, options)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, clickListener],
  )

  // TODO handle mobile backButton too
  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        stableHandler(event)
      }
    }

    document.addEventListener('keydown', keydownListener)
    return () => {
      document.removeEventListener('keydown', keydownListener)
    }
  }, [stableHandler])

  return <div ref={ref}>{children}</div>
}
