import type { I18nProvider } from '@pankod/refine-core'
import { Refine } from '@pankod/refine-core'
import routerProvider from '@pankod/refine-react-router-v6'
import type { QueryClient } from '@tanstack/react-query'

import { type PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

import type { DataProviderName, DataProvider } from './dataProviders'

const customRouterProvider = {
  ...routerProvider,
  Link,
  routes: [],
  RouterComponent: undefined,
}

const dummyResources = [{ name: '' }]

type Props = {
  dataProviders: Record<DataProviderName, DataProvider>
  i18nProvider?: I18nProvider
  queryClient: QueryClient
}

export function RefineOverride(props: PropsWithChildren<Props>) {
  const { dataProviders, i18nProvider, queryClient, children } = props
  return (
    <Refine
      routerProvider={customRouterProvider}
      dataProvider={dataProviders as any}
      i18nProvider={i18nProvider}
      options={{
        reactQuery: {
          clientConfig: queryClient,
        },
        disableTelemetry: true,
      }}
      resources={dummyResources}
    >
      {children}
    </Refine>
  )
}
