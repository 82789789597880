import type { LogicalFilter } from '@aubade/core/adapters'
import type { ResourcePickerProps } from '@aubade/core/ui-kit'
import { makeForm, unit } from '@aubade/core/ui-kit'
import type { Option } from '@aubade/core/ui-kit/DataPicker/types'
import { IconOutilsCouleurs } from '@aubade/design/graphics'
import type { ConnectedUser } from '@aubade/domain/components'
import {
  ProfileCard,
  useGetUser,
  isAdmin,
  isAgency,
  isSociety,
} from '@aubade/domain/components'
import { useTranslate } from '@aubade/translation'
import type { ExtendedConversationWrite } from '@aubade/types'
import type { User } from '@aubade/types/api'
import type { Contact } from '@aubade/types/index'
import type { Typesense } from '@aubade/types/typesense'
import { HStack } from '@chakra-ui/react'
import type { ReactNode } from 'react'

const { Input } = makeForm<ExtendedConversationWrite>()

type ContactPickerProps = Partial<
  Pick<ResourcePickerProps<User.Read>, 'label' | 'renderValue' | 'renderOption'>
> & {
  name: string
  filters?: LogicalFilter<User.Read>[]
  renderFilters?: (options: Option[]) => ReactNode
  max?: number
  scope?: string
}

type ExtendedTypesenseUser = (Typesense.Clients | Typesense.Associates) & {
  craft_company?: {
    id: string
    name: string
  }
  avatarUrl: string
  role: string
  agency?: {
    name: string
  }
  society?: {
    name: string
  }
}

export function ContactPicker(props: ContactPickerProps) {
  const { label, name, filters, renderFilters, max, scope } = props
  const { id: me } = useGetUser()

  return (
    <Input.ResourcePicker<ExtendedTypesenseUser, Contact>
      name={name}
      label={label}
      withoutEmptyText
      // @ts-ignore
      resource="contacts"
      filters={filters}
      scope={scope}
      renderFilters={renderFilters}
      max={max}
      getListParams={{ dataProviderName: 'default' }}
      renderOption={(user) => {
        const { craft_company } = user
        return (
          <UserCard
            origin="typesense"
            user={user}
            crafter={Boolean(craft_company)}
          />
        )
      }}
      renderValue={(user) => {
        if (user.id === me) return <></>
        const { craftCompany } = user

        return (
          <UserCard
            origin="api"
            user={user}
            selected
            crafter={Boolean(craftCompany)}
          />
        )
      }}
    />
  )
}

type UserCardProps = {
  selected?: boolean
  crafter: boolean
  origin: 'api' | 'typesense'
} & (
  | {
      user: Contact
    }
  | {
      user: ExtendedTypesenseUser
    }
)

function UserCard(props: UserCardProps) {
  const { origin, user, selected = false, crafter } = props

  const translate = useTranslate()

  const { firstname, lastname, avatarUrl } = user
  const userName = [firstname, lastname].filter(Boolean).join(' ')
  const companyName = getCompanyName(origin, user, translate)
  return (
    <HStack
      width="full"
      borderRadius={'20px'}
      gap={unit('10')}
      backgroundColor="white"
      padding={unit('20')}
      border="1px solid"
      borderColor={selected ? 'blue.500' : 'transparent'}
    >
      <ProfileCard
        name={userName}
        url={''}
        picture={avatarUrl}
        role={companyName}
        icon={crafter ? IconOutilsCouleurs : undefined}
      />
    </HStack>
  )
}

function getCompanyName(
  origin: 'api' | 'typesense',
  user: ExtendedTypesenseUser | Contact,
  translate: any,
) {
  if (origin === 'api') {
    const { craftCompany, society, agency, job, acRoles } = user as Contact
    const role = acRoles.AC
    if (isAdmin(role)) return ''
    if (isSociety(role)) {
      return [job && translate.enum(job), society?.name]
        .filter(Boolean)
        .join(' - ')
    }
    if (isAgency(role)) {
      return [job && translate.enum(job), agency?.name]
        .filter(Boolean)
        .join(' - ')
    }
    return [job && translate.enum(job), craftCompany?.name]
      .filter(Boolean)
      .join(' - ')
  } else {
    const { craft_company, agency, society, roles, job } =
      user as ExtendedTypesenseUser

    const role = roles?.find((userRole: string) =>
      userRole?.startsWith('ROLE_APP'),
    )
    if (isAdmin(role as ConnectedUser['role'])) return ''
    if (isSociety(role as ConnectedUser['role'])) {
      return [job && translate.enum(job), society?.name]
        .filter(Boolean)
        .join(' - ')
    }
    if (isAgency(role as ConnectedUser['role'])) {
      return [job && translate.enum(job), agency?.name]
        .filter(Boolean)
        .join(' - ')
    }
    return [job && translate.enum(job), craft_company?.name]
      .filter(Boolean)
      .join(' - ')
  }
}
