// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconArrowLeft = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0904 27.6322C10.6365 26.1783 10.6365 23.8204 12.0904 22.3666L33.3666 1.09038C34.821 -0.363459 37.1784 -0.363459 38.6322 1.09038C40.0866 2.54421 40.0866 4.90217 38.6322 6.35594L19.9881 25L38.6322 43.6441C40.0866 45.0979 40.0866 47.4558 38.6322 48.9096C37.1783 50.3635 34.821 50.3635 33.3666 48.9096L12.0904 27.6322Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconArrowLeft)
export default Memo
