import { unit, Paragraph } from '@aubade/core/ui-kit'
import { ChevronDownIcon } from '@chakra-ui/icons'
import type { BoxProps } from '@chakra-ui/react'
import {
  Menu,
  MenuButton,
  MenuList,
  Button as ChakraButton,
  Box,
  Icon,
  HStack,
  Tooltip,
} from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'
import React from 'react'
import flattenChildren from 'react-flatten-children'

export function DropdownMenu(
  props: PropsWithChildren<{
    title: string
    variant?: 'rounded'
    light?: boolean
  }>,
) {
  const { title, variant, light = false, children } = props
  const childrens = flattenChildren(children)

  const lightStyle = getLightStyle(light)

  if (variant === 'rounded') {
    return (
      <Tooltip label={title}>
        <Menu closeOnSelect={false}>
          <MenuButton
            as={ChakraButton}
            borderRadius={unit('25')}
            padding={0}
            width={unit('256')}
            justifyContent={'center'}
            display={'flex'}
            flexDirection={'row'}
            alignItems="center"
            bg="white"
            height={unit('50')}
          >
            <HStack width="full" gap={unit('8')} paddingX={unit('40')}>
              <Box width="full">
                <Paragraph size="sm" fontWeight="bold" text={title} ellipsis />
              </Box>
              <Box width="fit-content">
                <Icon
                  as={ChevronDownIcon}
                  width={unit('10')}
                  height={unit('10')}
                  color="black.500"
                />
              </Box>
            </HStack>
          </MenuButton>
          <MenuList
            background="lightGrey.500"
            borderRadius={unit('10')}
            padding={5}
            gap={5}
            display="flex"
            flexDir="column"
            zIndex={1010}
            {...lightStyle}
          >
            {childrens.map((child, index) => {
              return <Box key={index}>{child}</Box>
            })}
          </MenuList>
        </Menu>
      </Tooltip>
    )
  }

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={ChakraButton}
        rightIcon={
          <ChevronDownIcon
            width={unit('10')}
            height={unit('10')}
            color="black.500"
            position="absolute"
            top={1}
          />
        }
        borderRadius={0}
        padding={0}
        minWidth={10}
        maxWidth="fit-content"
        alignItems="flex-start"
        bg="transparent"
        height="fit-content"
      >
        <Paragraph size="sm" fontWeight="bold" text={title} />
      </MenuButton>
      <MenuList
        background="lightGrey.500"
        borderRadius={unit('10')}
        padding={5}
        gap={5}
        display="flex"
        flexDir="column"
        zIndex={1010}
        {...lightStyle}
      >
        {childrens.map((child, index) => {
          return <React.Fragment key={index}>{child}</React.Fragment>
        })}
      </MenuList>
    </Menu>
  )
}

function getLightStyle(light: boolean): BoxProps {
  if (!light)
    return {
      borderColor: 'transparent',
    }
  return {
    border: '1px solid',
    borderColor: 'rgba(0,0,0,0.1)',
  }
}
