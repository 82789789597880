import type { ImageProps } from '@chakra-ui/react'
import { Box, Image as ChakraImage } from '@chakra-ui/react'
import { useQueryMetaData } from '@nartex/api-platform'

import { createImmutableSet, useEvent } from '../libs'
import { ExternalStore } from '../libs/ExternalStore'

import { useDesignSystemConfig } from '.'

const errored = new ExternalStore(createImmutableSet())

export function Image(props: ImageProps) {
  const { src } = props
  const { s3 } = useDesignSystemConfig()
  const { headers } = (useQueryMetaData() ?? {}) as {
    headers?: Record<string, string>
  }

  const [failedImages, setFailedImages] = errored.use()
  const onError = useEvent(() => {
    setFailedImages((set) => set.withAdded(src))
  })

  if (!src || failedImages.has(src)) {
    return <Box {...props} />
  }

  if (
    src?.startsWith('/presigned_s3_get') ||
    src?.startsWith('/s3/presigned_s3_get')
  ) {
    const inlineHeaders = new URLSearchParams(headers ?? {}).toString()
    const url = `${s3.url}${src}?${inlineHeaders}`.replace('/s3/s3/', '/s3/')
    return <ChakraImage {...props} src={url} onError={onError} />
  }

  return <ChakraImage {...props} src={src} onError={onError} />
}
