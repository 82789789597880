import type { Merge } from '@aubade/core/libs'
import { compactObj, removeEmptyValues } from '@aubade/core/libs'
import { Chip, QueryOne, makeForm } from '@aubade/core/ui-kit'
import { IconFermer } from '@aubade/design/graphics'
import type { Society, Agency } from '@aubade/types/api'
import { HStack } from '@chakra-ui/react'
import { toHydraId } from '@nartex/api-platform'
import { useFormContext } from 'react-hook-form'

import type { PublicationFilterType } from '../filters/types'

const { useWatch } = makeForm<
  PublicationFilterType,
  'agencies' | 'societies' | 'me'
>()

type AgenciesAndSocieties = Merge<Society.Read, Agency.Read>

type Props = {
  onChange: () => void
  variant?: 'negative'
  scope?: 'notifications' | 'publications'
}
export function ActiveFilters(props: Props) {
  const { onChange, variant, scope } = props

  const author = useWatch('me')
  const formAgencies = useWatch('agencies')
  const formSocieties = useWatch('societies')

  const { setValue } = useFormContext()

  return (
    <HStack width="full" wrap="wrap" gap={2}>
      {Object.keys(compactObj(removeEmptyValues(formAgencies ?? {}))).map(
        (agency) => {
          return (
            <QueryOne<AgenciesAndSocieties>
              key={agency}
              iri={toHydraId('agencies', agency)}
              children={(record) => {
                return (
                  <QueryOne<Society.Read>
                    key={`society - ${agency}`}
                    // @ts-ignore
                    iri={record.society['@id']}
                    children={(society) => {
                      const label = [society.name, record.name]
                        .filter(Boolean)
                        .join(': ')
                      return (
                        <FilterChip
                          label={label}
                          variant={variant}
                          onClick={() => {
                            setValue(`agencies.${agency}`, undefined)
                            onChange()
                          }}
                        />
                      )
                    }}
                  />
                )
              }}
            />
          )
        },
      )}
      {Object.keys(compactObj(removeEmptyValues(formSocieties ?? {}))).map(
        (society) => {
          return (
            <QueryOne<AgenciesAndSocieties>
              key={society}
              iri={toHydraId('societies', society)}
              children={(record) => {
                return (
                  <FilterChip
                    label={record.name}
                    variant={variant}
                    onClick={() => {
                      setValue(`societies.${society}`, undefined)
                      onChange()
                    }}
                  />
                )
              }}
            />
          )
        },
      )}

      <>
        {author && scope && (
          <FilterChip
            label={`filters.${scope}.me`}
            onClick={() => {
              setValue(`me`, false)
              onChange()
            }}
          />
        )}
      </>
    </HStack>
  )
}

type FilterChipProps = {
  label: string
  onClick: () => void
  variant?: 'negative' | 'default'
}

function FilterChip(props: FilterChipProps) {
  const { label, onClick, variant = 'default' } = props
  return (
    <Chip
      size="xsmall"
      label={label}
      iconLeftProps={{
        as: IconFermer,
        color: 'darkGrey.500',
      }}
      textProps={{
        color: 'darkGrey.500',
        fontWeight: 'bold',
      }}
      onClick={onClick}
      backgroundColor={variant === 'negative' ? 'white' : undefined}
    />
  )
}
