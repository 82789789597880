import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { useWatch } from 'react-hook-form'
import type { FieldValues, FieldPath } from 'react-hook-form'

export type ArrayTableInputProps<
  TFieldValues extends FieldValues,
  TFieldPath extends FieldPath<TFieldValues>,
> = {
  name: TFieldPath & string
  renderForm: (prefix: string, index: number, field: string) => ReactNode
  renderTitle: (item: any, index: number) => ReactNode
}

export function ArrayTableInput<
  TFieldValues extends FieldValues,
  TFieldPath extends FieldPath<TFieldValues>,
>(props: ArrayTableInputProps<TFieldValues, TFieldPath>) {
  const { name, renderForm, renderTitle } = props
  const [tabIndex, setTabIndex] = useState(0)

  const fields = useWatch<TFieldValues, TFieldPath>({ name })

  if (!fields || !fields.length) return null
  if (!Array.isArray(fields))
    throw Error(`Form value at path ${name} should be an array`)

  return (
    <Tabs
      isLazy
      index={Math.min(fields.length - 1, tabIndex)}
      onChange={(index) => setTabIndex(index)}
      variant="soft-rounded"
      maxWidth="100%"
      defaultIndex={0}
    >
      <TabList overflowX={'scroll'}>
        {/* TODO better typings */}
        {/* at least it still works */}
        {(fields as unknown[]).map((field: any, index) => (
          <Tab key={index} minWidth="fit-content" mb={4}>
            {renderTitle(field, index)}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {(fields as unknown[]).map((field, index) => (
          <TabPanel key={index}>
            {renderForm(name, index, (field as any)?.['@type'])}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}
