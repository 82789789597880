import { IconPlus } from '@aubade/design/graphics'
import type { TranslationKey } from '@aubade/translation'
import { Box, ModalFooter } from '@chakra-ui/react'
import { type PropsWithChildren } from 'react'
import type { DeepPartial } from 'react-hook-form'

import { Button, Form, useForm, useModal, usePickerOption } from '../../'
import type { BaseRecord, ResourceOf } from '../../../adapters/dataProviders'
import { createContextFunction, useEvent } from '../../../libs'

export type ModalCreateButtonProps<T extends BaseRecord> = Omit<
  ModalCreateFormProps<T>,
  'onClose'
> & {
  label?: TranslationKey
  index?: number
}
export function ModalCreateButton<T extends BaseRecord>(
  props: ModalCreateButtonProps<T>,
) {
  const { label, index = 0, ...modalProps } = props

  const [modal, openModal] = useModal()

  const { optionProps, optionState } = usePickerOption({
    // TODO create a magical scope index
    index: 999 + index,
    id: '_create_new',
    async onClick() {
      await openModal((onClose) => (
        <ModalCreateForm {...modalProps} onClose={onClose} />
      ))
    },
  })
  const { isHighlighted } = optionState

  return (
    <>
      {modal}
      <Box padding={3}>
        <Button
          {...optionProps}
          variant={'circle'}
          isActive={isHighlighted}
          leftIcon={IconPlus}
          label={label ?? 'inputs.picker.new'}
        />
      </Box>
    </>
  )
}

const [ModalCreateFormContext, useModalCreateForm] = createContextFunction<
  | {
      succeedWith: (iri: BaseRecord['@id']) => void
    }
  | undefined
>('ModalCreateForm', () => undefined)
export { useModalCreateForm }

export type ModalCreateFormProps<T extends BaseRecord> = PropsWithChildren<{
  defaultValues?: DeepPartial<T>
  resource: ResourceOf<T>
  onCreate: (iri: T['@id']) => void
  onClose: () => void
}>
export function ModalCreateForm<T extends BaseRecord>(
  props: ModalCreateFormProps<T>,
) {
  const { defaultValues, resource, onCreate, onClose, children } = props

  const onSuccess = useEvent((iri: T['@id']) => {
    onCreate(iri)
    onClose()
  })
  const { saveButtonProps, ...methods } = useForm<BaseRecord>({
    mode: 'create',
    resource,
    hookFormOptions: {
      defaultValues,
    },
    redirect() {
      return false
    },
    mutationOptions: {
      onSuccess(data) {
        onSuccess(data.data['@id'])
      },
    },
  })

  return (
    <Form {...methods}>
      <ModalCreateFormContext value={{ succeedWith: onSuccess }}>
        {children}
        <ModalFooter width="100%">
          <Form.Submit {...saveButtonProps} />
        </ModalFooter>
      </ModalCreateFormContext>
    </Form>
  )
}
