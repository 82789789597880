import { createContextFunction } from '@aubade/core/libs'
import type { TranslationKey } from '@aubade/translation'
import { useTranslate } from '@aubade/translation'
import { HStack, ModalFooter, ModalHeader } from '@chakra-ui/react'
import type { PropsWithChildren, ReactNode } from 'react'
import { useCallback, useState, useRef } from 'react'

import { Button } from './Button'
import { Modal } from './Modal'

type ConfirmFunction = (
  titleOrRender:
    | TranslationKey
    | ((onConfirm: () => void, onCancel: () => void) => ReactNode),
) => Promise<boolean>

const [Provider, useConfirmModal] =
  createContextFunction<ConfirmFunction>('ConfirmModal')

type DefaultModalProps = {
  title: TranslationKey
  onConfirm: () => void
  onCancel: () => void
}
function DefaultModal(props: DefaultModalProps) {
  const { title, onConfirm, onCancel } = props
  const translate = useTranslate()
  return (
    <>
      <ModalHeader>{translate(title)}</ModalHeader>
      <ModalFooter>
        <HStack>
          <Button variant="primary" label="actions.cancel" onClick={onCancel} />
          <Button
            variant="primary"
            isDangerous
            label="actions.confirm"
            onClick={onConfirm}
            autoFocus
          />
        </HStack>
      </ModalFooter>
    </>
  )
}

export function ConfirmModalProvider(props: PropsWithChildren<{}>) {
  const [modalContent, setModalContent] = useState<ReactNode>()
  const resolveFnRef = useRef<Function>()

  const onClose = useCallback(() => {
    resolveFnRef.current = undefined
    setModalContent(undefined)
  }, [])

  const handleConfirm = useCallback(() => {
    if (resolveFnRef.current) {
      resolveFnRef.current(true)
    }
    onClose()
  }, [onClose])

  const handleCancel = useCallback(() => {
    if (resolveFnRef.current) {
      resolveFnRef.current(false)
    }
    onClose()
  }, [onClose])

  const handleShow = useCallback<ConfirmFunction>(
    (titleOrRender) => {
      if (typeof titleOrRender === 'string') {
        setModalContent(
          <DefaultModal
            title={titleOrRender}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />,
        )
      } else {
        setModalContent(titleOrRender(handleConfirm, handleCancel))
      }

      return new Promise<boolean>(function (resolve) {
        resolveFnRef.current = resolve
      })
    },
    [handleCancel, handleConfirm],
  )

  return (
    <Provider value={handleShow}>
      {props.children}

      {modalContent && (
        <Modal isOpen onClose={handleCancel} isCentered size="xl">
          {modalContent}
        </Modal>
      )}
    </Provider>
  )
}

export { useConfirmModal }
