import type { DataProvider } from '@aubade/core/adapters'
import type { AutoCompleteString } from '@aubade/core/libs'
import { compactObj } from '@aubade/core/libs'
import type { User } from '@aubade/types/api'
import type { ExtendedNotificationWrite } from '@aubade/types/src/index'
import type { Typesense } from '@aubade/types/typesense'
import { cleanHydraId } from '@nartex/api-platform'

import type { SearchResponse, SearchParams } from '@nartex/typesense'

import { encodePayloadApiToTypesense } from '../index'

import { fromBooleanRecordToArray } from './publications'

type NotificationsPayload = {
  typesense: SearchParams & {
    collection: AutoCompleteString<Typesense.Collection>
  }
}

export function NotificationsProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    async getList(params) {
      const { metaData } = params
      const payload = encodePayloadApiToTypesense(
        params,
        'notifications',
        'title, content',
      )
      const searchResponse = await dataProvider.custom!<
        {
          results: [SearchResponse<any>]
        },
        NotificationsPayload
      >({
        method: 'post',
        url: `notifications/ts/search`,
        metaData,
        payload,
      })
      const result = searchResponse.data.results[0]

      const data = result.hits?.map((hit) => hit.document) ?? []
      return {
        data,
        total: result.found,
        raw: { typesense: result },
      }
    },
    // @ts-ignore
    async getOne(params) {
      const { id } = params
      const notification = await dataProvider.getOne<ExtendedNotificationWrite>(
        {
          ...params,
          id: `${id}`,
          resource: 'notifications',
        },
      )

      const { data } = notification
      const { author, publishedAt } = data
      const { data: fullAuthor } = await dataProvider.getOne<User.Read>({
        ...params,
        resource: 'users',
        id: author,
      })

      const fullNotification = decodeNotifications(notification.data)

      const planify = Boolean(
        publishedAt && new Date(publishedAt).getTime() > new Date().getTime(),
      )

      return {
        data: { ...fullNotification, fullAuthor: fullAuthor, planify: planify },
      }
    },
    async update(params) {
      const { id } = params
      const { isNational, agencies, societies } =
        params.variables as ExtendedNotificationWrite

      const arraySocieties = fromBooleanRecordToArray(societies)
      const arrayAgencies = fromBooleanRecordToArray(agencies)

      const booleanIsNational = isNational === 'true'
      return dataProvider.custom!({
        ...params,
        method: 'put',
        url: `notifications/${id}/update`,
        payload: {
          ...params.variables,
          targets: undefined,
          targetSocieties: booleanIsNational ? [] : arraySocieties,
          targetAgencies: booleanIsNational ? [] : arrayAgencies,
          isNational: booleanIsNational,
        },
      })
    },
  }
}

export function NotificationsCreateProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    create(params) {
      const { isNational, agencies, societies } =
        params.variables as ExtendedNotificationWrite

      const booleanIsNational = isNational === 'true'

      return dataProvider.create({
        ...params,
        variables: {
          ...params.variables,
          targets: undefined,
          targetSocieties: !booleanIsNational
            ? Object.keys(compactObj(societies ?? {}))
            : [],
          targetAgencies: !booleanIsNational
            ? Object.keys(compactObj(agencies ?? {}))
            : [],

          isNational: booleanIsNational,
        },
      })
    },
  }
}

export function decodeNotifications(
  data: ExtendedNotificationWrite,
): Partial<ExtendedNotificationWrite> {
  const { targetAgencies, targetSocieties, isNational, societyId } = data

  const decodedAgencies: Record<string, any> = {}
  targetAgencies?.forEach((targetAgency) => {
    if (targetAgency === undefined) return
    decodedAgencies[cleanHydraId(targetAgency)] = true
  })
  const decodedSocieties: Record<string, any> = {}
  targetSocieties?.forEach((targetSociety) => {
    if (targetSociety === undefined) return

    decodedSocieties[cleanHydraId(targetSociety)] = true
  })

  return {
    ...data,
    societies: decodedSocieties,
    agencies: decodedAgencies,
    isNational: isNational ? 'true' : 'false',
    isAgencies: societyId ? 'true' : 'false',
  }
}
