// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconEmployees = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.71979 14.0043C5.71979 11.3479 6.76949 8.82005 8.65467 6.93488C10.5398 5.0497 13.0677 4 15.7241 4C18.3805 4 20.9083 5.0497 22.7935 6.93488C24.6787 8.82005 25.7284 11.3479 25.7284 14.0043C25.7284 16.6607 24.6787 19.1885 22.7935 21.0737C20.9083 22.9589 18.3805 24.0086 15.7241 24.0086C13.0677 24.0086 10.5398 22.9589 8.65467 21.0737C6.79092 19.2099 5.71979 16.6607 5.71979 14.0043ZM18.5733 26.8578H12.8535C9.4473 26.8578 6.16967 28.2074 3.77035 30.6281C1.34961 33.0274 0 36.3051 0 39.7112C0 41.5964 0.749786 43.4173 2.0994 44.7669C3.42759 46.0951 5.2485 46.8663 7.1551 46.8663H24.2931C26.1782 46.8663 27.9991 46.1165 29.3488 44.7669C30.6769 43.4387 31.4482 41.6178 31.4482 39.7112C31.4482 36.3051 30.0985 33.0274 27.6778 30.6281C25.2571 28.2288 22.0009 26.8578 18.5947 26.8578H18.5733Z"
      fill="currentColor"
    />
    <path
      d="M46.2296 30.6281C43.8303 28.2288 40.5527 26.8578 37.1465 26.8578H31.4267C30.0128 26.8578 28.6418 27.1148 27.3136 27.5433C27.9563 28.0146 28.5775 28.5287 29.1559 29.1071C31.9837 31.9349 33.5475 35.7052 33.5475 39.7112C33.5475 42.1962 32.5835 44.5099 30.8269 46.2665C30.6127 46.4807 30.3556 46.6521 30.1199 46.8449H42.8449C44.7301 46.8449 46.551 46.0951 47.9006 44.7455C49.2288 43.4173 50 41.5964 50 39.6898C50 36.2836 48.6504 33.006 46.2296 30.6067V30.6281Z"
      fill="currentColor"
    />
    <path
      d="M27.8492 14.0043C27.8492 16.1037 27.2708 18.1174 26.2639 19.8955C26.5638 20.3025 26.8637 20.7095 27.2279 21.0737C29.0917 22.9374 31.641 24.0086 34.2973 24.0086C36.9537 24.0086 39.4816 22.9589 41.3667 21.0737C43.2519 19.1885 44.3016 16.6607 44.3016 14.0043C44.3016 11.3479 43.2519 8.82005 41.3667 6.93488C39.4816 5.0497 36.9537 4 34.2973 4C31.641 4 29.1131 5.0497 27.2279 6.93488C26.8637 7.29906 26.5638 7.70608 26.2639 8.11311C27.2708 9.9126 27.8492 11.9049 27.8492 14.0043Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconEmployees)
export default Memo
