import Color from 'color'

import { colors } from './tokens'

export const themeColors = {
  white: {
    500: colors.white,
  },
  lightGrey: {
    500: colors.lightGrey,
  },
  iconsBg: {
    500: colors.iconsBg,
  },
  grey: {
    500: colors.grey,
  },
  darkGrey: {
    500: colors.darkGrey,
    alpha10: Color(colors.darkGrey).alpha(0.1).string(),
  },
  blue: {
    500: colors.blue,
  },
  neutral: {
    500: colors.neutral,
  },
  red: {
    500: colors.red,
  },
  black: {
    500: 'rgba(0, 0, 0, 1)',
    alpha10: Color('rgba(0, 0, 0, 1)').alpha(0.1).string(),
  },
  primary: {
    50: '#E5EEFF',
    100: '#E9F1FF',
    200: '#A9C6FF',
    300: '#699CFF',
    400: '#4C89FF',
    500: '#2972FF',
    alpha10: Color('#2972FF').alpha(0.1).string(),
    alpha50: Color('#2972FF').alpha(0.5).string(),
  },
  secondary: {
    50: '#E5FCFF',
    100: '#B8F7FF',
    200: '#8AF1FF',
    300: '#5CECFF',
    400: '#2EE6FF',
    500: '#00E1FF',
  },
  error: {
    light: '#FEEFEF',
    medium: '#F75555',
    dark: '#DA1414',
    alpha10: Color('#DA1414').alpha(0.1).string(),
    alpha50: Color('#DA1414').alpha(0.5).string(),
  },
  warning: {
    light: '#FFF4EC',
    medium: '#FA8E3F',
    dark: '#E26408',
    alpha10: Color('#FA8E3F').alpha(0.1).string(),
    alpha50: Color('#FA8E3F').alpha(0.5).string(),
  },
  success: {
    light: '#EDF9F0',
    medium: '#22C55E',
    dark: '#16A34A',
    alpha10: Color('#22C55E').alpha(0.1).string(),
    alpha50: Color('#22C55E').alpha(0.5).string(),
  },
  greyscale: {
    25: Color('#F8FAFC').alpha(0.5).string(),
    50: '#F8FAFC',
    100: '#F1F5F9',
    200: '#E2E8F0',
    300: '#CBD5E1',
    400: '#94A3B8',
    500: '#64748B',
    600: '#475569',
    700: '#334155',
    800: '#1E293B',
    900: '#121826',
  },
  orange: {
    base: '#F9791F',
    alpha10: Color('#F9791F').alpha(0.1).string(),
    alpha50: Color('#F9791F').alpha(0.5).string(),
  },
  violet: {
    base: '#8B5CF6',
    alpha10: Color('#8B5CF6').alpha(0.1).string(),
    alpha50: Color('#8B5CF6').alpha(0.5).string(),
  },
  pink: {
    base: '#EC4899',
    alpha10: Color('#EC4899').alpha(0.1).string(),
    alpha50: Color('#EC4899').alpha(0.5).string(),
  },
  teal: {
    base: '#DEECF1',
    alpha10: Color('#DEECF1').alpha(0.1).string(),
    alpha50: Color('#DEECF1').alpha(0.5).string(),
  },
  amber: {
    base: '#FBBF24',
    alpha10: Color('#FBBF24').alpha(0.1).string(),
    alpha50: Color('#FBBF24').alpha(0.5).string(),
  },

  overlay: {
    base: 'rgba(30, 41, 59, 0.8)',
  },
} as const
