import type { HydraId } from './types'

export function cleanHydraId(id: string) {
  if (isHydraId(id)) {
    return last(id.split('/')) as string
  }
  return id
}

export function getResourceType(hydraId: HydraId) {
  return `${hydraId.split('/').slice(1, -1).join('/')}`
}

export function toHydraId<T extends string>(
  resource: T,
  id: string,
): HydraId<T> {
  if (typeof id !== 'string') return id
  return `/${resource}/${cleanHydraId(id)}`
}

export function isHydraId(id?: string): id is HydraId {
  if (!id) return false
  if (typeof id !== 'string') return false

  return Boolean(id.match(/^\/.+\/.+(\/.+)*$/))
}

function last<T>(array: T[]): T | undefined {
  return array[array.length - 1]
}
