import type { Merge } from '@aubade/core/libs'

import type { RHFInputProps } from '../controller'
import { useRHFInput } from '../controller'

import type { RangeSelectProps } from './RangeSelect'
import { RangeSelect } from './RangeSelect'

export { RangeSelect as BaseRangeSelect } from './RangeSelect'

export const RHFRangeSelect = function <T>(
  props: Merge<
    RHFInputProps<T, number | undefined, number | undefined>,
    Pick<RangeSelectProps, 'options' | 'label'>
  >,
) {
  const { options, label } = props
  const innerProps = useRHFInput<
    T,
    number | undefined,
    number | undefined,
    string
  >(props)

  return <RangeSelect {...innerProps} options={options} label={label} />
}
