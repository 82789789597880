import { IconArrowBottom } from '@aubade/design/graphics'
import type { TranslationKey } from '@aubade/translation'
import { useTranslate } from '@aubade/translation'
import { Icon, Select } from '@chakra-ui/react'
import { memo, forwardRef } from 'react'
import type { LegacyRef } from 'react'

import type { BaseInputProps, Transformer } from '../controller'
import { useBaseInput } from '../controller'
import { getSelectOptions } from '../getSelectOptions'
import { BaseInputGroup } from '../TextInput/TextInput'

const selectTransformer: Transformer<string | null | undefined, string> = {
  read(value: string | undefined): string {
    if (!value) return ''
    return value
  },
  write(value: string): string | undefined {
    if (!value) return undefined
    return value
  },
}

type SelectInputProps = BaseInputProps<string | undefined> & {
  optionsSelect: readonly string[] | string[] | Record<string, string>
  translationKey?: string
  placeholder?: TranslationKey | boolean
  variant?: 'flushed' | 'light'
}

export const SelectInput = memo(
  forwardRef(function (
    props: SelectInputProps,
    ref: LegacyRef<HTMLSelectElement>,
  ) {
    const {
      label,
      optionsSelect,
      translationKey,
      placeholder,
      variant,
      ...rest
    } = props
    const { id, field, wrap } = useBaseInput<string | undefined>(rest, {
      baseTransformer: selectTransformer,
    })

    const translate = useTranslate()

    function getDefaultPlaceholder() {
      if (!label) return '--'
      return `-- ${translate(label)} --`
    }

    function getPlaceholder() {
      if (placeholder === false) return undefined
      if (placeholder === true) return getDefaultPlaceholder()

      if (placeholder) {
        if (translationKey) {
          return translate(`${translationKey}.${placeholder}`)
        }
        return translate(placeholder)
      }

      if (field.required) return undefined
      return getDefaultPlaceholder()
    }

    function getOptionLabel(optionLabel: string) {
      if (translationKey) {
        return translate(`${translationKey}.${optionLabel}`)
      }
      return translate(optionLabel)
    }

    const options = getSelectOptions(optionsSelect)

    return wrap(
      <BaseInputGroup label={label} id={id}>
        <Select
          {...field}
          variant={variant}
          disabled={field.readOnly || field.disabled}
          ref={ref}
          placeholder={getPlaceholder()}
          icon={<Icon as={IconArrowBottom} color="darkGrey.500" />}
          iconSize="15px"
          fontSize="sm"
        >
          {options.map(([optionValue, optionLabel]) => {
            return (
              <option key={optionValue} value={optionValue}>
                {getOptionLabel(optionLabel)}
              </option>
            )
          })}
        </Select>
      </BaseInputGroup>,
    )
  }),
)
