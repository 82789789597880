import type { MetaDataQuery } from '@pankod/refine-core'
import { createContext, useContext } from 'react'

const MetaDataContext = createContext<MetaDataQuery | undefined>(undefined)

function useQueryMetaData() {
  return useContext(MetaDataContext)
}

const MetaDataProvider = MetaDataContext.Provider

export { MetaDataProvider, useQueryMetaData }
