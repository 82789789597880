import { drawerAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

import { surfaceStyle } from '../../../core/src/ui-kit/Surface'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(drawerAnatomy.keys)

const baseStyle = definePartsStyle({
  overlay: {
    backgroundColor: 'overlay.base',
  },

  dialog: {
    ...surfaceStyle(),
    maxWidth: '1200px',
  },
  body: {
    padding: 0,
  },
})

export const drawerTheme = defineMultiStyleConfig({ baseStyle })
