import type { RHFInputProps } from '../controller'
import { useRHFInput } from '../controller'
import type { TextInputProps } from '../TextInput/TextInput'
import { TextInput } from '../TextInput/TextInput'

export const RHFTextAreaInput = function <T, Outer = string>(
  props: RHFInputProps<T, Outer, string> &
    Omit<TextInputProps, 'type' | 'suggestions'>,
) {
  const { maxLength, variant = 'TextArea' } = props
  const innerProps = useRHFInput<T, Outer, string>({
    ...props,
    rules: {
      validate: function (value) {
        return isValidInput({ value, maxLength })
      },
    },
  })

  return <TextInput {...innerProps} variant={variant} />
}

type IsValidInputParams = {
  value: string
  maxLength?: number
}

function isValidInput(params: IsValidInputParams) {
  const { value, maxLength } = params
  if (!maxLength) return
  if (!value) return
  if (value.length > maxLength) return 'errors.tooLongText'
}
