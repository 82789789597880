import type { TranslationKey } from '@aubade/translation'
import { Input, InputRightElement } from '@chakra-ui/react'
import type { ForwardedRef, ReactNode } from 'react'
import { memo, forwardRef } from 'react'

import type { BaseInputProps, Transformer } from '../controller'
import { useBaseInput } from '../controller'
import { BaseInputGroup } from '../TextInput/TextInput'

type BaseNumberInputProps = BaseInputProps<number> & {
  placeholder?: TranslationKey
  precision?: number
  iconRight?: ReactNode
}

export const NumberInput = memo(
  forwardRef(function (
    props: BaseNumberInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    const { label, placeholder, precision, iconRight, ...rest } = props

    const numberTransformer: Transformer<number | null | undefined, string> = {
      read(value) {
        if (value == null || Number.isNaN(value)) return ''
        return value.toString()
      },
      write(value) {
        if (!value) return null
        const val = parseFloat(value)
        if (Number.isNaN(val)) return null
        if (precision) return Number(val.toFixed(precision))
        return val
      },
    }

    const { id, field, wrap } = useBaseInput<number, number, string>(rest, {
      baseTransformer: numberTransformer,
    })

    return wrap(
      <BaseInputGroup label={label} id={id}>
        <Input
          {...field}
          disabled={field.readOnly || field.disabled}
          min={0}
          type="number"
          ref={ref}
          placeholder={placeholder}
        />
        {iconRight && <InputRightElement>{iconRight}</InputRightElement>}
      </BaseInputGroup>,
    )
  }),
)
