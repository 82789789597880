import { themeColors } from '@aubade/design/themeColors'
import { useTranslate } from '@aubade/translation'
import type { FlexProps } from '@chakra-ui/react'
import {
  Flex,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  VStack,
  Skeleton,
  SkeletonText,
  Box,
  Center,
  Spinner,
} from '@chakra-ui/react'
import type { FallbackRender } from '@sentry/react'
import { ErrorBoundary } from '@sentry/react'
import Color from 'color'
import type { ForwardedRef, PropsWithChildren } from 'react'
import { Suspense, forwardRef } from 'react'

import { useLayerIndex } from '../libs'

export type BoundaryProps = {
  skeletonsCount?: number
}
export function Boundary(props: PropsWithChildren<BoundaryProps>) {
  const { children } = props

  return (
    <ErrorBoundary
      fallback={(fallbackProps) => <FetchError {...fallbackProps} />}
    >
      <Suspense fallback={<></>}>{children}</Suspense>
    </ErrorBoundary>
  )
}

// old suspense fallback: <Skeletons count={skeletonsCount} />

export const FetchError: FallbackRender = function FetchError(props) {
  const { error } = props

  const translate = useTranslate()

  return (
    <Alert status="error" flexDirection={'column'}>
      <AlertIcon />
      <Box>
        <AlertTitle>{translate('inputs.picker.error')}</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Box>
    </Alert>
  )
}

type SkeletonsProps = {
  count?: number
}
export function Skeletons(props: SkeletonsProps) {
  const { count = 1 } = props
  return (
    <Flex direction={'column'} paddingX={4} gap={4} width={'full'}>
      {Array.from({ length: count }, (_, index) => {
        if (index % 3 === 0) {
          return (
            <VStack key={index} alignItems={'flex-start'} spacing={2}>
              <Skeleton height={6} width={'50%'} />
              <SkeletonText noOfLines={1} width={'40%'} />
            </VStack>
          )
        }

        if (index % 3 === 1) {
          return (
            <VStack key={index} alignItems={'flex-start'} spacing={2}>
              <Skeleton height={6} width={'60%'} />
              <SkeletonText noOfLines={1} width={'30%'} />
            </VStack>
          )
        }

        if (index % 3 === 2) {
          return (
            <VStack key={index} alignItems={'flex-start'} spacing={2}>
              <Skeleton height={6} width={'40%'} />
              <SkeletonText noOfLines={1} width={'60%'} />
            </VStack>
          )
        }

        return null as never
      })}
    </Flex>
  )
}

export type SpinnerOverlayProps = FlexProps & {
  isLoading?: boolean
}
export const SpinnerOverlay = forwardRef(function SpinnerOverlay(
  props: PropsWithChildren<SpinnerOverlayProps>,
  ref: ForwardedRef<unknown>,
) {
  const { isLoading, children, ...rest } = props

  const { zIndex } = useLayerIndex()

  return (
    <Box width={'full'} position={'relative'} {...rest} ref={ref}>
      {children}
      {isLoading && (
        <Center
          backgroundColor={Color(themeColors.greyscale[200])
            .alpha(0.5)
            .toString()}
          cursor="progress"
          position="absolute"
          zIndex={zIndex + 1}
          top={0}
          bottom={0}
          left={0}
          right={0}
        >
          <Spinner />
        </Center>
      )}
    </Box>
  )
})
