export const colors = {
  white: 'rgba(255, 255, 252, 1)',
  lightGrey: 'rgba(243, 245, 247, 1)',
  iconsBg: 'rgba(222, 236, 241, 1)',
  grey: 'rgba(191, 191, 191, 1)',
  darkGrey: 'rgba(45, 45, 45, 1)',
  blue: 'rgba(0, 158, 224, 1)',
  neutral: 'rgba(241, 195, 179, 1)',
  red: 'rgba(206, 14, 45, 1)',
} as const

export const fontFamilies = {
  montserrat: 'Montserrat',
} as const

export const fontSizes = {
  '9': '0.5625rem',
  '12': '0.75rem',
  '16': '1rem',
  '20': '1.25rem',
  '24': '1.5rem',
  '28': '1.75rem',
} as const

export const fontWeights = {
  semiBold: 600,
  regular: 400,
} as const

export const radii = {
  '0': '0px',
  '1': '5px',
  '2': '10px',
  '4': '20px',
  '6': '30px',
} as const

export const shadows = {
  rectangle233: '0px 0px 20px rgba(0, 0, 0, 0.05)',
} as const
