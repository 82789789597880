import type { AutoCompleteString } from '@aubade/core/libs'
import type { TranslationKey } from '@aubade/translation'
import type { BoxProps } from '@chakra-ui/react'
import type { FocusEvent, ChangeEvent, ReactNode, ReactElement } from 'react'
import type {
  FieldError,
  RefCallBack,
  UseControllerProps,
} from 'react-hook-form'

import type { Merge } from '../../../libs'

/** Transformer */
export type Transformer<Outer, Inner> = {
  read(value: Outer): Inner
  write(value: Inner): Outer
}
export const noopTransformer: Transformer<unknown, unknown> = {
  read(value: unknown): unknown {
    return value
  },
  write(value: unknown): unknown {
    return value
  },
} satisfies Transformer<any, any>

/** Field */
export type Field<Inner, Element extends HTMLElement = HTMLElement> = {
  ref?: RefCallBack
  id: string
  label: TranslationKey
  name: string

  disabled: boolean
  required: boolean
  readOnly: boolean

  error: FieldError | undefined
  value: Inner | undefined
  onChange: (
    eventOrValue: ChangeEvent | string | Inner | null | undefined,
  ) => void
  shouldUpdate?: (newValue: unknown) => boolean

  onBlur: (event: FocusEvent<Element>) => void
  onFocus: (event?: FocusEvent<Element>) => void
}

/** BaseInput */
export type BaseInputProps<
  Outer,
  Inner = Outer,
  Element extends HTMLElement = HTMLElement,
> = Merge<
  Partial<Field<Inner, Element>>,
  {
    value?: Outer | null | undefined
    onChange?: (newValue: Outer | null | undefined) => void
    helperText?: TranslationKey
    transformer?: Transformer<Outer | null | undefined, Inner>
    onClick?: (e: any) => void
  }
>

export type UseBaseInputParams<
  Outer,
  Inner = Outer,
  Element extends HTMLElement = HTMLElement,
> = BaseInputProps<Outer, Inner, Element>

export type UseBaseInputResult<
  Inner,
  Element extends HTMLElement = HTMLElement,
> = {
  id: string
  field: Field<Inner, Element>
  wrap: (
    children: ReactNode,
    options?: Pick<BoxProps, 'width'>,
  ) => ReactElement | null
}

/** RHFInput */
export type RHFInputProps<
  T,
  Outer = string | number,
  Inner = Outer,
  Element extends HTMLElement = HTMLElement,
> = Merge<
  Omit<BaseInputProps<Outer, Outer, Element>, 'value' | 'error'>,
  Pick<BaseInputProps<unknown, unknown, Element>, 'shouldUpdate'> & {
    name: AutoCompleteString<keyof T & string>

    rules?: UseControllerProps['rules']
    shouldUnregister?: boolean
    defaultValue?: unknown
    transformer?: Transformer<Outer | null | undefined, Inner>
  }
>

export type UseRHFInputOptions<Inner, InnerDeep> = {
  baseTransformer?: Transformer<Inner | null | undefined, InnerDeep>
}

export type UseBaseInputOptions<Inner, InnerDeep> = UseRHFInputOptions<
  Inner,
  InnerDeep
> & { noLocalValue?: boolean }
