import { Image, useDocumentUrl } from '@aubade/core/ui-kit'
import type { Document } from '@aubade/types/api'
import type { AvatarProps } from '@chakra-ui/react'
import { Box, Avatar as ChakraAvatar, Icon } from '@chakra-ui/react'
import type { ElementType } from 'react'

import { useOne } from '../../../../core/src/adapters'
import { fromS3ToResizedUrl } from '../../../../core/src/libs'

export type Variant = 'small' | 'big' | 'xxl'

const avatarStyleMap = {
  small: {
    width: '40px',
    height: '40px',
    borderRadius: '20px',
  },
  big: {
    width: '60px',
    height: '60px',
    borderRadius: '30px',
  },
  xxl: {
    width: '80px',
    height: '80px',
    borderRadius: '40px',
  },
} satisfies Record<Variant, AvatarProps>

type Props = {
  name: string
  picture?: string
  variant?: Variant
  icon?: ElementType<any>
}

export function Avatar(props: Props) {
  const { name, picture, icon, variant = 'small', ...boxProps } = props
  const styles = avatarStyleMap[variant]
  const parsedUrl = fromS3ToResizedUrl(picture)
  const documentUrl = useGetDocumentUrl(picture)
  const pictureUrl = useDocumentUrl(parsedUrl, {
    size: {
      width: 300,
      height: 300,
    },
    ratio: 'crop',
  })

  return (
    <Box position="relative" {...boxProps}>
      <ChakraAvatar
        as={Image}
        name={name}
        {...styles}
        src={documentUrl ?? pictureUrl ?? picture}
      />
      {icon && (
        <Icon
          position="absolute"
          bg="lightGrey.500"
          borderRadius="50%"
          width="30px"
          height="30px"
          padding="7.5px"
          bottom="-7px"
          right="-7px"
          as={icon}
        />
      )}
    </Box>
  )
}

function useGetDocumentUrl(picture?: string) {
  const [document] = useOne<Document.Read>(
    {
      resource: 'documents',
      id: picture! as `/documents/${string}`,
    },
    {
      enabled: Boolean(
        picture && picture !== null && picture.includes('/documents'),
      ),
    },
  )
  const documentUrl = useDocumentUrl(document, {
    size: {
      width: 300,
      height: 300,
    },
    ratio: 'crop',
  })
  return documentUrl
}
