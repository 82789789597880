export function getSelectOptions<T extends number | string>(
  optionsSelect: readonly T[] | T[] | Record<string, string> | [T, string][],
): [T, T | string][] {
  const options = Array.isArray(optionsSelect)
    ? optionsSelect.map((option) => {
        if (Array.isArray(option)) {
          return option
        }
        return [option, option] as [T, T]
      })
    : (Object.entries(optionsSelect) as [T, string][])

  return options.map((option) => {
    if (isEnum(option)) {
      const enumValue = option[1] as T
      return [enumValue, `enums.${enumValue}`]
    }
    return option
  })
}

function isEnum(option: readonly [string | number, string | number]) {
  if (typeof option[1] === 'string' && option[1].startsWith('/enums/'))
    return true
  return false
}
