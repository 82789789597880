import { useDateFnsLocale } from '@aubade/core/libs'
import { format } from 'date-fns'

type DateFormatProps = {
  value?: string | number | Date
}

export function DateFormat(props: DateFormatProps) {
  const { value } = props
  const locale = useDateFnsLocale()
  if (!value) return <></>
  const parsed = parseDate(value)

  if (!parsed) return <></>

  return (
    <>
      {format(parsed, `EEEE dd LLLL yyyy à kk'h'mm`, {
        locale,
      })}
    </>
  )
}

type UnixDateFormatProps = {
  value?: number
}

export function UnixDateFormat(props: UnixDateFormatProps) {
  const { value } = props
  return <DateFormat value={value === undefined ? undefined : value * 1000} />
}

function parseDate(value: string | number | Date): Date | undefined {
  const parsed = new Date(value) // GMT +2

  if (isNaN(parsed.getTime())) {
    return undefined
  }

  // remove this line because  format(parsed, pattern, {locale})} don't format with local offset
  // parsed.setTime(parsed.getTime() + parsed.getTimezoneOffset() * 60 * 1000) => GMT+0

  return parsed
}
