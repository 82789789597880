// @ts-nocheck
/* eslint-disable @getify/proper-arrows/where */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconRecherche = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.1848 45.2578L34.9968 31.0662C37.4301 27.8192 38.8883 23.8025 38.8883 19.4441C38.8883 8.7221 30.1636 0 19.4441 0C8.72469 0 0 8.72192 0 19.4441C0 30.1664 8.72469 38.8883 19.4441 38.8883C23.8022 38.8883 27.8218 37.4274 31.0688 34.994L45.2578 49.1856C45.7994 49.7273 46.5105 49.9996 47.2217 49.9996C47.9324 49.9996 48.6436 49.7273 49.1852 49.1856C50.2716 48.0996 50.2716 46.3443 49.1852 45.2582M19.444 33.3335C11.7859 33.3335 5.55593 27.1032 5.55593 19.4455C5.55593 11.7874 11.7862 5.55745 19.444 5.55745C27.1021 5.55745 33.332 11.7877 33.332 19.4455C33.332 27.1036 27.1017 33.3335 19.444 33.3335Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconRecherche)
export default Memo
