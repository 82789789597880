import { useTranslate } from '@aubade/translation'
import { Text, VStack } from '@chakra-ui/react'
import type { FallbackRender } from '@sentry/react/types/errorboundary'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'

import { useEvent } from '../../libs/useEvent'
import { Button } from '../Button'

import { Title } from '../Title'

type ErrorProps =
  | { code: 500; report: Parameters<FallbackRender>[0] }
  | {
      code: 404
    }
  | {
      code: 401
    }
export function ErrorPage(props: ErrorProps) {
  const { code } = props
  const navigate = useNavigate()
  const translate = useTranslate()
  const location = useLocation()
  useOnNavigateAway(() => {
    if ('report' in props) {
      props.report.resetError()
    }
  })

  function getErrorMessage() {
    if (code === 404) {
      return translate('pages.error.404', { location: location.pathname })
    }
    if (code === 500) {
      const { error } = props.report
      console.error(error)
      return translate(error.message)
    }
  }

  return (
    <VStack alignContent="center" margin={16}>
      <Title.H1>{translate('pages.error.title')}</Title.H1>
      <Text>{getErrorMessage()}</Text>

      <Button
        variant="primary"
        onClick={() => navigate(-1)}
        label="pages.error.backHome"
      />
      <Button
        variant="primary"
        onClick={() => window.location.reload()}
        label="buttons.refresh"
      />
    </VStack>
  )
}

export function useOnNavigateAway(callback: () => void) {
  const location = useLocation()
  const onNavigateAway = useEvent(callback)

  const prevRoute = useRef(location)
  useEffect(() => {
    if (prevRoute.current !== location) {
      onNavigateAway()
    }

    return () => {
      prevRoute.current = location
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
}
